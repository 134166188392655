import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForceAtlas2Service {
  private worker: Worker | null = null;
  private graphUpdate$ = new BehaviorSubject<any[]>([]);

  constructor() {}

 async applyLayout(cytoscapeCore: any, params: any) {
    const workerCode = `
  let nodes = [];
  let edges = [];
  let params = {};

  self.onmessage = ({ data }) => {
    if (data.type === 'start') {
      nodes = data.graphData.nodes || [];
      edges = data.graphData.edges || [];
      params = data.params;
      runForceAtlas2();
    }
  };

  function runForceAtlas2() {
    const { iterations, gravity, scalingRatio, jitterTolerance, strongGravityMode, barnesHutTheta, barnesHutSplits, linLogMode, targetChangePerNode } = params;

    for (let step = 0; step < iterations; step++) {
      // 1. Build Barnes-Hut Tree
      const tree = buildBarnesHutTree(nodes, 0, barnesHutSplits);

      // 2. Apply Repulsion Forces using Barnes-Hut
      for (const node of nodes) {
        applyBarnesHutRepulsion(node, tree, scalingRatio, barnesHutTheta);
      }

      // 3. Apply Attraction Forces
      for (const edge of edges) {
        const source = nodes.find((n) => n.id === edge.source);
        const target = nodes.find((n) => n.id === edge.target);
        if (!source || !target) continue;

        const dx = source.x - target.x;
        const dy = source.y - target.y;
        const distance = Math.max(Math.sqrt(dx * dx + dy * dy), 1);
        const attraction = linLogMode ? Math.log(distance + 1) / 10 : distance / 10;

        source.vx = (source.vx || 0) - (dx / distance) * attraction;
        source.vy = (source.vy || 0) - (dy / distance) * attraction;
        target.vx = (target.vx || 0) + (dx / distance) * attraction;
        target.vy = (target.vy || 0) + (dy / distance) * attraction;
      }

      // 4. Apply Gravity
      for (const node of nodes) {
        const dx = -node.x;
        const dy = -node.y;
        const distance = Math.max(Math.sqrt(dx * dx + dy * dy), 1);
        const gravityForce = strongGravityMode ? gravity * node.mass : gravity;

        node.vx = (node.vx || 0) - (dx / distance) * gravityForce;
        node.vy = (node.vy || 0) - (dy / distance) * gravityForce;
      }

      // 5. Apply Jitter and Update Positions
      let maxDisplacement = 0;
      for (const node of nodes) {
        const dx = node.vx * jitterTolerance;
        const dy = node.vy * jitterTolerance;
        node.x += dx;
        node.y += dy;
        maxDisplacement = Math.max(maxDisplacement, Math.abs(dx) + Math.abs(dy));
        node.vx = 0;
        node.vy = 0;
      }

      // Early stopping if displacement is small
      if (maxDisplacement / nodes.length < targetChangePerNode) {
        break;
      }

      // Progress Update
      if (step % 10 === 0) {
        self.postMessage({ type: 'progress', step, nodes });
      }
    }

    self.postMessage({ type: 'done', nodes });
  }

  function buildBarnesHutTree(nodes, depth, maxDepth) {
    if (nodes.length === 0 || depth >= maxDepth) {
      return { mass: 0, centerX: 0, centerY: 0, size: 0, children: [] };
    }
    if (nodes.length === 1) {
      const [node] = nodes;
      return { mass: node.mass || 1, centerX: node.x, centerY: node.y, size: 0, children: [] };
    }

    const centerX = nodes.reduce((sum, n) => sum + n.x, 0) / nodes.length;
    const centerY = nodes.reduce((sum, n) => sum + n.y, 0) / nodes.length;
    const size = Math.max(...nodes.map((n) => Math.abs(n.x - centerX) + Math.abs(n.y - centerY)));

    const children = [[], [], [], []];
    for (const node of nodes) {
      const index = (node.x < centerX ? 0 : 1) + (node.y < centerY ? 0 : 2);
      children[index].push(node);
    }

    return {
      mass: nodes.reduce((sum, n) => sum + (n.mass || 1), 0),
      centerX,
      centerY,
      size,
      children: children.map((childNodes) => buildBarnesHutTree(childNodes, depth + 1, maxDepth)),
    };
  }

  function applyBarnesHutRepulsion(node, tree, scalingRatio, theta) {
    if (!tree.children || tree.children.length === 0) {
      // Directly calculate repulsion
      const dx = node.x - tree.centerX;
      const dy = node.y - tree.centerY;
      const distance = Math.max(Math.sqrt(dx * dx + dy * dy), 1);
      const repulsion = (scalingRatio * node.mass * tree.mass) / (distance * distance);

      node.vx += (dx / distance) * repulsion;
      node.vy += (dy / distance) * repulsion;
    } else {
      const dx = node.x - tree.centerX;
      const dy = node.y - tree.centerY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      if ((tree.size / distance) < theta) {
        const repulsion = (scalingRatio * node.mass * tree.mass) / (distance * distance);
        node.vx += (dx / distance) * repulsion;
        node.vy += (dy / distance) * repulsion;
      } else {
        for (const child of tree.children) {
          applyBarnesHutRepulsion(node, child, scalingRatio, theta);
        }
      }
    }
  }
`;

  
  

  


  
    // Inline Web Worker
    const blob = new Blob([workerCode], { type: 'application/javascript' });
    this.worker = new Worker(URL.createObjectURL(blob));

    const graphData = {
      nodes: cytoscapeCore.nodes().map((n: any) => ({
        id: n.id(),
        x: n.position('x'),
        y: n.position('y'),
        vx: 0,
        vy: 0,
        mass: 1 + n.degree(),
      })),
      edges: cytoscapeCore.edges().map((e: any) => ({
        source: e.source().id(),
        target: e.target().id(),
      })),
    };

    this.worker.postMessage({ type: 'start', graphData, params });

    this.worker.onmessage = ({ data }) => {
      if (data.type === 'done') {
        this.updateCytoscape(cytoscapeCore, data.nodes);
      }
    };

    return 0
  }

  private updateCytoscape(cytoscapeCore: any, updatedNodes: any[]) {
    updatedNodes.forEach((node) => {
      const cyNode = cytoscapeCore.getElementById(node.id);
      cyNode.position({ x: node.x, y: node.y });
    });
    cytoscapeCore.layout({ name: 'preset' }).run();
  }

  destroy() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
  }
}
