/*
Author : Abhishek
github : Abhi5h3k
*/

import {
  Component,
  OnInit,
  Output,
  Query,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Directive,
  EventEmitter
} from '@angular/core';
import * as introJs from 'intro.js/intro.js';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import automove from 'cytoscape-automove';

cytoscape.use(automove);

// import { DialogComponent } from 'app/cli/dialog/dialog.component';
// import this.graph_spec from 'src/assets/graph_config_specifications.json';

// import cytoscape from 'cytoscape';
// import graph_positions from 'src/assets_json/main_combined_indoco_positions.json';
import cytoscape from 'node_modules/cytoscape/dist/cytoscape.umd.js';

import * as cytoscapePanzoom from 'node_modules/cytoscape-panzoom/cytoscape-panzoom.js';

import * as bc from 'src/assets/graph_config_specifications.json'
import spread from 'node_modules/cytoscape-spread/cytoscape-spread.js';
// import euler from 'node_modules/cytoscape-euler';
import cise from 'node_modules/cytoscape-cise';
// import cise from 'cytoscape-cise';
cytoscape.use(cise)
import avsdf from 'node_modules/cytoscape-avsdf'
cytoscape.use(avsdf)
import fcose from 'node_modules/cytoscape-fcose';
cytoscape.use(fcose)
import cola from 'node_modules/cytoscape-cola'
cytoscape.use(cola);

import euler from 'node_modules/cytoscape-euler'
cytoscape.use(euler)

import { ForceAtlas2Service } from '../forceatlas2.service';
import cxtmenu from 'cytoscape-cxtmenu';
// import * as jquery from 'jquery';
import $ from 'jquery';
import * as cytoscapeEdgeEditing from 'node_modules/cytoscape-edge-editing/cytoscape-edge-editing.js';
import contextMenus from 'node_modules/cytoscape-context-menus/cytoscape-context-menus.js';

import * as _ from 'underscore';

import Picker from 'vanilla-picker';

import popper from 'cytoscape-popper';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import {
  ChangeDetectorRef
} from "@angular/core";
// Export excel
import * as XLSX from 'xlsx';
// import cise from 'cytoscape-cise';
// rating
// request
import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/core';
import {
  AppService
} from '../app.service';
import { DialogComponent } from './dialog/dialog.component';
import { all, forEach, object, shuffle } from 'underscore';
import internal from 'assert';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/cdk/overlay/overlay-directives';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import jwtDecode from 'jwt-decode';

// import { resolve } from 'dns';
var graph_positions;
//clicked node
var selected_node = '';

//all legends selected
var all_legends_selected = [];

// all nodes in selected legends

var selected_nodes_legend = [];

// all nodes cosidered while filtering from legend/clicking on a node
var all_nodes_considered = [];


@Component({
  selector: 'app-cli',
  templateUrl: './cli.component.html',
  styleUrls: ['./cli.component.css'],
  encapsulation: ViewEncapsulation.None // to let dynamic added element's css to be applies. here we are using this for graph legend

})
// @Directive({
//   selector:'domChange'
// })
export class CliComponent implements OnInit, OnChanges {

  loadGraph: any;
  fileName: any;


  // ----------------------------->
  // private http: HttpClient,


  // Jquery
  $: any;

  // store full data
  graphFullData;

  // variables to store no of nodes cosidered according to the slider, sorted nodes list, edgelist
  maxNoOfNodes;
  noOfNodesConsidered;
  sortedNodesList;
  edgeList;
  graph_positions_found: boolean;

  constructor(private cd: ChangeDetectorRef, private appservice: AppService, private dialog: MatDialog,
    private httpClient: HttpClient, private elementRef: ElementRef, private fa2Service: ForceAtlas2Service) {
    // this.introJS.setOptions({
    //   steps: [
    //     {
    //       element: '#step1',
    //       intro: '<img src ="assets/OrgLens-logo.png"id="step3" style ="float:left;height: fit-content;margin-left:20px;"><br> <img src="https://media.giphy.com/media/8ah1D978pNLT8bUC5s/giphy.gif" height="200px"width="200px"/><br>Welcome to your new app!',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '#step2',
    //       intro: "Ok, wasn't that fun?",
    //       position: 'left'
    //     },
    //     {
    //       element: '#step3',
    //       intro: "let's keep going",
    //       position: 'bottom'
    //     },
    //     {
    //       element: '#step4',
    //       intro: 'More features, more fun.',
    //       position: 'top'
    //     }
    //   ],
    //   showProgress: true
    // });
  }

  // public onDomChange(mutationRecord: MutationRecord): void {
  //   console.log("mutation record",mutationRecord);
  // }
  isNoverlapLoading: boolean = false;

  // response data
  response;
  // cy is the cytoscape object created and used to manipulate graph
  cy;
  // Flag to check if graph is` already loaded on not
  // also using for png and spread click image
  grphLoaded = false;
  // img tag get element
  imgTag;
  download_img;
  // using set for unique value
  collectionOnRowClick;

  // value stores the query input
  value = '';
  // valueOri = '';

  // numericArgs is a list of attributes that store a numeric data [so that cytoscape compare numeric values as a number and not a string]
  // readonly numericArgs = ['bc', 'cc', 'dc'];
  readonly numericArgs = ['gate_keeper_score', 'pulse_taker_score', 'social_hubs_score', 'homophily_designation', 'homophily_department', 'homophily_hierarchy_level', 'homophily_gender', 'rank_social_hubs_score', 'social_hubs_score', 'rank_pulse_taker_score', 'pulse_taker_score', 'out_degree', 'rank_keystone_score', 'keystone_score', 'influencer_score', 'indegree', 'rank_go_to_score', 'go_to_score', 'rank_gate_keeper_score', 'gate_keeper_score', 'degree'];

  // Output of the query is stored in result[] and number of result found in resultCount
  result = [];
  lastResultCopy = [];
  resultCount = '';
  // saving last selected nodes [so we can 'set' something on next command],getnodes()
  lastSelection;
  lastEdgeSelection;
  // saving last hidden nodes [so we can unhide]
  hiddenNodes: any = "";
  update_node_slider_two_entities: boolean = true;
  // list of possible operators in a query
  readonly relationalOpSingle = ['<', '>', '='];
  readonly relationalOpCombi = ['!*=', '<=', '>=', '!=', '*=', '^=', '$='];
  relationalOp = _.union(this.relationalOpCombi, this.relationalOpSingle);
  // network metric data
  network_metric_data = {}
  network_column_to_show = ['centralization', 'density'];

  // collection of nodes selected on click
  nodesSelectedOnClick;
  general_tab_active: boolean = true;
  teams_tab_active: boolean = false;

  individual_tab_active: boolean = false;


  // All query types: for query check
  readonly queryType = ['do_all', 'show_rank', 'get_top', 'selectall', 'cluster', 'parallel', 'formal_non_field_reduced', 'informal_non_field_reduced', 'reduce4', 'reduce_i', 'reduce_f', 'reduce_formal_department_5', 'reduce_informal_department_5', 'graph_info', 'reload', 'undo', 'reduce', 'reduce2', 'isolated', 'init', 'toogle_label', 'download_as_png', 'set', 'select', 'sort', 'deselect', 'load', 'invert', 'hide', 'show', 'unhide', 'remove', 'table', 'neighbours', 'style', 'node'];
  // All available selectors as per node data: for query check
  querySelectors = [];
  // no need to show all data in table
  columnsNamesNotToShow = ['id', 'email', 'phone', 'dob', 'status_id', 'study_id', 'degree', 'influencer_score', 'homophily_hierarchy_level', 'homophily_designation'
    , 'value', 'name', 'neighbors', 'InNeighbors', 'OutNeighbors', 'joining_date', 'in_neighbors', 'out_neighbors'];
  columnsForTable = []
  // edge selectors
  edgeQuerySelectors = ['frequency', 'count'];
  // querySelector + network name = [all selectors]
  readonly querySelectorsForNetworkNames = ["all", "work", "expertise", "trust",
    "innovation", "awareness", "decision_making", "career_advice"
  ];


  // Bool for details table (show/hide)
  isVisible: boolean = false;
  // PlaceHolder
  placeHolder: string = 'Enter a query..';
  predictionList: string[] = [];
  typed;
  posibleArgList;
  // default table sort
  default_table_sort = 'rank_go_to_score';
  // All query set: set style ,set size
  readonly querySelectorsForTypeSet = ['node_size', 'node_color', "label_size", 'node_rank', '_node_label', '_edge_label', 'rank_by'];
  readonly querySelectorsForTypeSort = ['selected', '>', '<'];
  readonly relationalOpForTypeSort = ['>', '<'];
  readonly querySelectorsForTypeLoad = ['study_id'];
  querySelectorsForTypeRankBy = [];

  //------------
  // unique hash key
  uid: string = '';
  unique_hash: string = '';
  // team type
  team_type: string = '';
  //team
  team: string = '';
  //network type
  n_type_url_global: string = '';
  //module type
  g_type_url_global: string = '';
  //node color
  node_color: string = '';

  last_selected_node_color_value: string = '';
  top_boss_email: string = '';

  //entity type map 
  entity_class_map: any = [];
  // name of people's json
  module_specific: string = '';
  //graph json name
  graph_name: string = '';
  // graph sepecfication json
  graph_spec: any;
  // list of nodes to be highlighted for specific nodes
  module_specific_nodes: any;
  // config json data
  config_json_data: any;
  //maximum degree amongst collection of nodes
  max_degree: number;
  //minimum degree amongst collection of nodes
  min_degree: number;
  // k_core_data
  k_core_data: any;
  //selected node/edge
  selected_target: any;
  //report filter from config json
  dropdown_reportfilter: object;

  //flag whethere to show a group of nodes as a single node
  group_as_single_node: number = 1;

  //team type and team dropdown values
  childclassdispdropdown: any;
  childclassdropdown: any;
  dropdowndata: any;
  dropdownvalue: any;

  //show leadership team only
  show_leadership_team: boolean = false;

  //stats object

  graph_stats_obj: object = {};

  //rm email
  rm_email: string = '';
  rm_name: string = '';
  show_collab_bet_entity: boolean = false;
  show_collab_within_entity: boolean = false
  //highlight entity in collab between unit
  collab_bet_entity_highlight: boolean = false;

  collab_within_entity_highlight: boolean = false;

  collab_within_small_unit_show: boolean = false;
  //entity to be highlighted
  entity_highlight_name: string = '';
  entity_type_highlight: string = '';

  entity_highlight_name_small_unit: string = '';
  entity_highlight_name_small_unit_display: string = '';

  entity_type_highlight_small_unit: string = '';
  //flag to whether to show connections between two specific entities
  two_specific_entities_boolean: boolean = false;

  //entity type for two specific entities
  entity_type_two_entities: string = '';

  //two entities
  entity_1: string = '';
  entity_2: string = '';

  who_section_in_graph_json: any = 0;

  template = '{ "ver": "1.0", "commands": [{ "graph": [{ "operation": "subgraph", "parameters": { "field": ["designation= \'Area Sales Manager \'"] }, "type": "node", "calcvalues": ["none"] }], "inputgraph": "main", "saveas": "test_on_demand_graph", "description": "Designation wise graph for bu heads from formal network" } ] }';
  json_temp = JSON.stringify(JSON.parse(this.template), undefined, 4);


  show_isoNodes: number = 1;

  initial_no_of_nodes: any = 100
  colorsingraphvalue: any = [];
  defaults_automove_nodes = {
    // specify nodes that should be automoved with one of
    // - a function that returns true for matching nodes
    // - a selector that matches the nodes
    // - a collection of nodes (very good for performance)
    nodesMatching: function (node) { return false; },

    // specify how a node's position should be updated with one of
    // - function( node ){ return { x: 1, y: 2 }; } => put the node where the function returns
    // - { x1, y1, x2, y2 } => constrain the node position within the bounding box (in model co-ordinates)
    // - { x1, y1, x2, y2, type: 'inside' } => constrain the node position within the bounding box (in model co-ordinates)
    // - { x1, y1, x2, y2, type: 'outside' } => constrain the node position outside the bounding box (in model co-ordinates)
    // - 'mean' => put the node in the average position of its neighbourhood
    // - 'viewport' => keeps the node body within the viewport
    // - 'drag' => matching nodes are effectively dragged along
    reposition: 'mean',

    // specify when the repositioning should occur by specifying a function that
    // calls update() when reposition updates should occur
    // - function( update ){ /* ... */ update(); } => a manual function for updating
    // - 'matching' => automatically update on position events for nodesMatching
    // - set efficiently and automatically for
    //   - reposition: 'mean'
    //   - reposition: { x1, y1, x2, y2 }
    //   - reposition: 'viewport'
    //   - reposition: 'drag'
    // - default/undefined => on a position event for any node (not as efficient...)
    when: undefined,



    //
    // customisation options for non-function `reposition` values
    //

    // `reposition: 'mean'`

    // specify nodes that should be ignored in the mean calculation
    // - a function that returns true for nodes to be ignored
    // - a selector that matches the nodes to be ignored
    // - a collection of nodes to be ignored (very good for performance)
    meanIgnores: function (node) { return false; },

    // specify whether moving a particular `nodesMatching` node causes repositioning
    // - true : the mid node can't be independently moved/dragged
    // - false : the mid node can be independently moved/dragged (useful if you want the mid node to use `reposition: 'drag' in another rule with its neighbourhood`)
    meanOnSelfPosition: function (node) { return true; },

    // `reposition: 'drag'`

    // specify nodes that when dragged cause the matched nodes to move along (i.e. the master nodes)
    // - a function that returns true for nodes to be listened to for drag events
    // - a selector that matches the nodes to be listened to for drag events
    // - a collection of nodes to be listened to for drag events (very good for performance)
    dragWith: function (node) { return false; }
  };
  selectedLayout = "fa2";
  onLayoutChange(event: Event) {
    this.selectedLayout = (event.target as HTMLSelectElement).value;
  }
  formValues: any = {
    graph_filename: "main_combined",
    layout: "fa2",
    study_id: 718,
    fa2_nsteps: 5000,
    fa2_gravity: 10,
    fa2_scalingRatio: 20,
    fa2_barnesHutSplits: 12,
    fa2_barnesHutTheta: 89,
    fa2_barnesHutUpdateIter: 34,
    fa2_2d: "false",
    fa2_directed: "false",
    fa2_updateCenter: 77,
    fa2_jitterTolerance: 56,
    fa2_linLogMode: "false",
    fa2_targetChangePerNode: 48,
    fa2_strongGravityMode: 15,
    fa2_outboundAttractionDistribution: 92,
    no_ntimesalgo: 38,
    no_speed: 73,
    no_ratio: 61,
    no_margin: 84,
    fr_ntimesalgo: 29,
    fr_speed: 40,
    fr_gravity: 66,
    fr_area: 19,

  }
  teams_tab_present: boolean = true;
  individual_tab_present: boolean = true;
  // all_shades_tints = [
  //   // "#b30600", 
  //   "#ff0800",
  //   "#337eff",
  //   "#ffa966",
  //   "#009954",
  //   "#ff9d00",
  //   "#f50290",
  //   "#b3ff00",
  //   "#9a6ee0",
  //   "#0042b3",

  //   "#00b7ff",

  //   "#ccbe00",
  //   "#ffee00",
  //   "#80034b",

  //   "#4dcdff",

  //   "#6600ff",
  //   "#b300a7",

  //   "#66ff8c",

  //   "#6b9900",
  //   "#ff8c33",
  //   "#0080b3",
  //   "#caff4d",
  //   "#486600",
  //   "#009926",
  //   "#00ff40",
  //   "#99ffb3",
  //   "#00ff8c",
  //   "#4dffaf",
  //   "#99ffd1",
  //   "#ffb133",
  //   "#cc7e00",
  //   "#fff780",
  //   "#3d0099",
  //   "#944dff",
  //   "#c299ff",
  //   "#ff00ee",
  //   "#ffb3fa",
  //   "#b30053",
  //   "#ff0077",
  //   "#ff4da0",
  //   "#ffb3d6",
  //   "#ff524d",
  //   "#b34e00",
  //   "#669eff",
  //   "#99e2ff",
  //   "#ffba4d",
  //   "#005eff",
  //   "#0a0542",
  //   "#993467",
  //   "#4d911c",
  //   "#91601c",
  //   "#1c917f",
  //   "#363109",
  //   "#609c93",
  //   "#875558",
  //   "#e8747b",
  //   "#fca832",
  //   "#fc97e5",
  //   "#97fcca",
  //   "#2a6146",

  // ]





  // all_shades_tints = [
  //   // First 10 highly distinguishable colors
  //   "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF",
  //   "#00FFFF", "#FF8000", "#80FF00", "#0080FF", "#FF0080",

  //   // Remaining 50 colors
  //   "#FF4040", "#40FF40", "#4040FF", "#FFFF40", "#FF40FF",
  //   "#40FFFF", "#FFC040", "#C0FF40", "#40C0FF", "#FF40C0",
  //   "#FF8080", "#80FF80", "#8080FF", "#FFFF80", "#FF80FF",
  //   "#80FFFF", "#FFB080", "#B0FF80", "#80B0FF", "#FF80B0",
  //   "#FF6060", "#60FF60", "#6060FF", "#FFFF60", "#FF60FF",
  //   "#60FFFF", "#FFA060", "#A0FF60", "#60A0FF", "#FF60A0",
  //   "#FFCC80", "#CCFF80", "#80CCFF", "#FF80CC", "#FFAA80",
  //   "#AAFF80", "#80AAFF", "#FF80AA", "#FFDD80", "#DDFF80",
  //   "#80DDFF", "#FF80DD", "#FFEE80", "#EEFF80", "#80EEFF",
  //   "#FF80EE", "#FFBB80", "#BBFF80", "#80BBFF", "#FF80BB"
  // ];

  all_shades_tints = [
    // First 20 Highly Distinguishable Colors
    "#FF0000", // Red
    "#00FF00", // Green
    "#0000FF", // Blue
    "#FFFF00", // Yellow
    "#00FFFF", // Cyan
    "#FF00FF", // Magenta
    "#FFA500", // Orange
    "#BFFF00", // Lime
    "#800080", // Purple
    "#FF69B4", // Pink
    "#008080", // Teal
    "#A52A2A", // Brown
    "#FFD700", // Gold
    "#87CEFA", // Light Blue
    "#006400", // Dark Green
    "#8F00FF", // Violet
    "#40E0D0", // Turquoise
    "#FF4500", // Orange Red
    "#DA70D6", // Orchid
    "#ADFF2F", // Green Yellow

    // Remaining 30 Colors (Still Clearly Distinguishable)
    "#6495ED", // Cornflower Blue
    "#DC143C", // Crimson
    "#FF6347", // Tomato
    "#7FFF00", // Chartreuse
    "#00CED1", // Dark Turquoise
    "#8B4513", // Saddle Brown
    "#6A5ACD", // Slate Blue
    "#2E8B57", // Sea Green
    "#D2691E", // Chocolate
    "#FFB6C1", // Light Pink
    "#9ACD32", // Yellow Green
    "#BA55D3", // Medium Orchid
    "#FFDEAD", // Navajo White
    "#5F9EA0", // Cadet Blue
    "#CD853F", // Peru
    "#32CD32", // Lime Green
    "#800000", // Maroon
    "#20B2AA", // Light Sea Green
    "#FF8C00", // Dark Orange
    "#C71585", // Medium Violet Red
    "#708090", // Slate Gray
    "#F08080", // Light Coral
    "#8B0000", // Dark Red
    "#48D1CC", // Medium Turquoise
    "#7B68EE", // Medium Slate Blue
    "#F0E68C", // Khaki
    "#B0C4DE", // Light Steel Blue
    "#2F4F4F", // Dark Slate Gray
    "#FF1493", // Deep Pink
    "#228B22"  // Forest Green
  ];

  frequency_str_map: any = {
    "1": "Quarterly",
    "2": "Monthly",
    "3": "Weekly",
    "4": "Daily",

  }

  // arg for sub_graph
  // queryArgForSelectorSubGraph = ["formal_chrysalis",
  //   "formal_axis",
  //   "formal_aspire",
  //   "formal_diva",
  //   "formal_altius",
  //   "formal_anacrest",
  //   "formal_international",
  //   "formal_manufacturing",
  //   // "formal_top_management",
  //   // "formal_senior_management",
  //   "formal_bu_heads",
  //   "formal_top_management_and_senior_management",
  //   //
  //   "formal_urocure",
  //   //
  //   "formal_hr_ir_admin",
  //   "formal_finance",
  //   "formal_quality_control",
  //   "formal_quality_assurance",
  //   "formal_marketing",
  //   //
  //   // 'corporate_formal',
  //   // 'instaviz_formal',
  //   // 'nepal_bsvl_formal',
  //   // 'products_inclusion_team_formal',
  //   // 'quality_assurance_formal',
  //   // 'r&d_equine_formal',
  //   // 'quality_control_formal',
  //   // 'hr_ir_admin_formal',
  //   // 'cr_&_pv_formal',
  //   // 'finance_formal',
  //   // 'domestic_sales_formal',
  //   // 'marketing_formal',
  //   // 'institution_business_formal',
  //   // 'purchase_formal',
  //   // 'distribution_formal',
  //   // 'goverment_affairs_formal',
  //   // 'bd_&_pm_formal',
  //   // 'veterinary_formal',
  //   // 'information_technology_formal',
  //   // 'r&d_ndds_formal',
  //   // 'r&d_preclinical_formal',
  //   // 'r&d_biotech_formal',
  //   //
  //   // special case testing cc
  //   "formal_cal_finance",


  //   "informal_chrysalis",
  //   "informal_axis",
  //   "informal_aspire",
  //   "informal_diva",
  //   "informal_altius",
  //   "informal_anacrest",
  //   "informal_international",
  //   "informal_manufacturing",
  //   // "informal_top_management",
  //   // "informal_senior_management",
  //   "informal_bu_heads",
  //   "informal_top_management_and_senior_management",
  //   //
  //   "informal_urocure",
  //   //
  //   "informal_hr_ir_admin",
  //   "informal_finance",
  //   "informal_quality_control",
  //   "informal_quality_assurance",
  //   "informal_marketing",

  //   "c_chrysalis",
  //   "c_axis",
  //   "c_aspire",
  //   "c_diva",
  //   "c_altius",
  //   "c_anacrest",
  //   "c_international",
  //   "c_manufacturing",
  //   "c_marketing",
  //   // "c_top_management",
  //   // "c_senior_management",
  //   "c_bu_heads",
  //   "c_top_management_and_senior_management",
  //   //
  //   "c_urocure",
  //   "c_finance",
  //   "c_hr_ir_admin",
  //   "c_quality_assurance",
  //   "c_quality_control"
  //   //
  //   // 'corporate_informal',
  //   // 'instaviz_informal',
  //   // 'nepal_bsvl_informal',
  //   // 'products_inclusion_team_informal',
  //   // 'quality_assurance_informal',
  //   // 'r&d_equine_informal',
  //   // 'quality_control_informal',
  //   // 'hr_ir_admin_informal',
  //   // 'cr_&_pv_informal',
  //   // 'finance_informal',
  //   // 'domestic_sales_informal',
  //   // 'marketing_informal',
  //   // 'institution_business_informal',
  //   // 'purchase_informal',
  //   // 'distribution_informal',
  //   // 'goverment_affairs_informal',
  //   // 'bd_&_pm_informal',
  //   // 'veterinary_informal',
  //   // 'information_technology_informal',
  //   // 'r&d_ndds_informal',
  //   // 'r&d_preclinical_informal',
  //   // 'r&d_biotech_informal'

  //   //  ,"ii"
  // ];

  // auto download excel
  autoDownload = true;

  // Show Table or Direct json
  resultAsTable = false;
  // show result for(hold value of selctors in query ex: dept,gender)
  // used to show the selector value in result(json row html) : ex if your query is "select gender=male" then row will show "male" if true for the row
  selectorsInQuery: string[];
  // Flag for previous query
  // saveTopreviousFlag: Boolean = true;
  // pridiction list visible if show = true
  show: boolean = false;
  focus: boolean;
  str = "";
  // Is row selected (For both json and table)
  rowSelected;
  // store History  (query + result)
  history = [];
  // selected value from -> right arrow key
  isValueSelectedByRightArrow = false;
  // <-----------------------
  // Dictionary of style flag
  style_flag = {
    "all": true,
    "all_single_edge": true,
    "network_7": false,
    "reduced_graph": false,
    "formal_informal_graph": false,
    "sub_graph": false
  };
  // top Id list of nodes to be styled diff
  _top_id = [];
  // node style
  readonly possible_node_shape = ["ellipse", "triangle", "round-triangle", "rectangle", "round-rectangle", "bottom-round-rectangle", "cut-rectangle", "barrel", "rhomboid", "diamond", "round-diamond", "pentagon", "round-pentagon", "hexagon", "round-hexagon", "concave-hexagon", "heptagon", "round-heptagon", "octagon", "round-octagon", "star", "tag", "round-tag", "vee"];
  style_node_shape_type = {
    //   "default": "round-triangle",
    "default": "ellipse",
    "special": "star"
  };
  style_for_graph = {
    "label_size": 15,
    "node_size": 20,
    "_node_label": "first_name",
    "_edge_label": "frequency",
    "graphSize": 0,
    "maxGraphSizeForSmallGraph": 500,
    "min-zoomed-font-size": 5,
    "remove_defaulter_nodes": false,
    // temporary putting these here
    "formal": false,
    "informal": false,
    'show_rank': false,
    'rank_by': 'rank_go_to_score',
    'rank_top': 5

    // "color_graph":"default"

  };

  loading = false;
  // As graph size increase it is hard to make good visuals. so we can use some tricks to style accordingly.
  // nodesWithConnection will store all the nodes that have some edge so that we can fit them only.
  nodesWithConnection;
  // history of nodes removed
  history_of_nodes_removed = [];
  // show notification
  show_notification = false;
  // collection of notifications
  notifications = [];
  // status
  message = {
    success: '<span class="badge badge-success">Success : </span>',
    error: '<span class="badge badge-danger">Error : </span>',
    info: '<span class="badge badge-info">Info : </span>'
  };
  // emp id who did not open survey
  survey_defaulter = []

  introJS = introJs();

  notificationContainer = $('#notification-container');
  notification = $('#side_notification');
  closeButton = $('#close-button');
  ngOnInit() {
    localStorage.clear();
    document.getElementById('cli-input').focus();

    // this.http.get('./assets/main_combined_indoco_positions.json').subscribe((response)=>{ console.log("response",response)});
    // fetch('graph_config_specifications.json').then(res => res.json()).then(json_data => {console.log("jsondata",json_data)})

    this.value = String.raw`
<br>
<div class="alert alert-success" role="alert">
Relational Logic :
</div>

<div class="alert alert-primary" role="alert">
[name = value] Matches elements if their data attribute matches a specified value (e.g. [foo = 'bar'] or [num = 2]).
</div>
<div class="alert alert-secondary" role="alert">
[name != value] Matches elements if their data attribute doesn’t match a specified value (e.g. [foo != 'bar'] or [num != 2]).
</div>
<div class="alert alert-success" role="alert">
[name > value] Matches elements if their data attribute is greater than a specified value (e.g. [foo > 'bar'] or [num > 2]).
</div>
<div class="alert alert-danger" role="alert">
[name >= value] Matches elements if their data attribute is greater than or equal to a specified value (e.g. [foo >= 'bar'] or [num >= 2]).
</div>
<div class="alert alert-warning" role="alert">
[name < value] Matches elements if their data attribute is less than a specified value (e.g. [foo < 'bar'] or [num < 2]).
</div>

<div class="alert alert-primary" role="alert">
[name <= value] Matches elements if their data attribute is less than or equal to a specified value (e.g. [foo <= 'bar'] or [num <= 2]).
</div>
<div class="alert alert-secondary" role="alert">
[name *= value] Matches elements if their data attribute contains the specified value as a substring (e.g. [foo *= 'bar']).
</div>
<div class="alert alert-success" role="alert">
[name ^= value] Matches elements if their data attribute starts with the specified value (e.g. [foo ^= 'bar']).
</div>
<div class="alert alert-danger" role="alert">
[name $= value] Matches elements if their data attribute ends with the specified value (e.g. [foo $= 'bar']).
</div>
<div class="alert alert-warning" role="alert">
@ (data attribute operator modifier) Prepended to an operator so that is case insensitive (e.g. [foo @$= 'ar'], [foo @>= 'a'], [foo @= 'bar'])
</div>

`;


    //   this.onEnter("select gender *= ale");

    this.imgTag = document.getElementById('png');
    // set for onrow click
    this.collectionOnRowClick = new Set();
    //   this.onEnter("style = all_single_edge");
    // console.log("Grpah to JSON :");
    // console.log(this.cy.json());



    // register cytoscape-panzoom once
    cytoscapePanzoom(cytoscape);
    cytoscape.use(cxtmenu);
    cytoscapeEdgeEditing(cytoscape, $);
    cytoscape.use(contextMenus, $);

    // console.log(Object.keys(this.graph_json));
    // this.queryArgForSelectorSubGraph.concat(Object.keys(this.graph_json));
    // this.queryArgForSelectorSubGraph = Object.keys(this.graph_json);

    // this.onEnter("load sub_graph = career_advice_5");
    this.onGraphLinkLoad();


  }

  // startSteps(): void {
  //   this.introJS
  //     .setOptions({
  //       steps: [
  //         {
  //           element: '#step0',
  //           intro: '<img class="animated-gif" src ="assets/gif/intro.png" style ="height:200px;width:max-content;"><br>ONE is a tool where you can play around with any network graph of your organization / team. You will be able to inspect the graph from different angles to derive your own insights.',
  //           title: '',
  //         },
  //         {
  //           element: '#step2',
  //           intro: '<img class="animated-gif" src ="assets/gif/toggle-setting1.gif" style ="height:270px;width:350px;;"><br>You can load the desired network graph using this. Before loading, you can finetune the network graph by selecting different options according to your needs.',
  //           title: '<h3 style="text-align:center"><i class="fas fa-cog"></i>&nbsp;Graph Generation</h3>',
  //           // position: 'bottom-left-aligned'
  //         },
  //         {
  //           element: '#cy',
  //           intro: '<img class="animated-gif" src ="assets/gif/zoom-in_zoom-out.gif" style ="height:180px;width:100%;"><br>Once loaded, you can zoom in and zoom out the graph using this icon. You can also do this using the laptop trackpad or mouse.',
  //           title: '<h3 style="text-align:center">Zoom in-Zoom out</h3>'
  //         },
  //         {
  //           element: '#cy',
  //           intro: '<img class="animated-gif" src ="assets/gif/graph-drag-drop.gif" style ="height:200px;width:100%;"><br>You can also move the whole graph to the desired position on the screen.',
  //           title: '<h3 style="text-align:center">Graph Drag-Drop</h3>'
  //         },

  //         {
  //           element: '#cy',
  //           intro: '<img class="animated-gif" src ="assets/gif/drag-drop.gif" style ="height:180px;width:100%;"><br>You can also move a nodes to anywhere in the canvas.',
  //           title: '<h3 style="text-align:center">Drag Nodes</h3>'
  //         },
  //         {
  //           element: '#cy',
  //           intro: '<img class="animated-gif" src ="assets/gif/node-hover.gif" style ="height:180px;width:100%;"><br>Just hover on any node, you can see details of that node.',
  //           title: '<h3 style="text-align:center">Hover Node</h3>'
  //         },
  //         {
  //           element: '#cy',
  //           intro: '<img class="animated-gif" src ="assets/gif/node-click.gif" style ="height:200px;width:100%;"><br>By clicking on a node, you can see connections of that particular node.',
  //           title: '<h3 style="text-align:center">Node Click</h3>'
  //         },
  //         {
  //           element: '#cy',
  //           intro: '<img class="animated-gif" src ="assets/gif/click-legend.gif" style ="height:200px;width:350px;"><br>You can filter out the nodes by clicking on the legends. Clicking again on that legend will remove that filter also you can apply multiple filters at a time.',
  //           title: '<h3 style="text-align:center">Filtering</h3>'
  //         },
  //         {
  //           element: '#_nodeSlider',
  //           intro: '<img class="animated-gif" src ="assets/gif/node-count.gif" style ="height:260px;width:350px;"><br>In graph with large number of nodes, all the nodes will not be loaded initially due to performance reason. In such cases you can increase and decrease number of nodes using this slider.  ',
  //           title: '<h3 style="text-align:center">Node Slider</h3>',
  //           position: 'left'
  //         },
  //         {
  //           element: '#step3',
  //           intro: "You can see overall Statistics of your network here.",
  //           title: '<h3 style="text-align:center"><i class="fas fa-chart-bar"></i>&nbsp;Stats</h3>'
  //         },
  //         {
  //           element: '#step1',
  //           intro: 'You can download the current graph as image.',
  //           title: '<h3 style="text-align:center"><i class="fas fa-download"></i>&nbsp;Download</h3>',
  //         },
  //         {
  //           element: '#step',
  //           intro: '<img class="animated-gif" src ="assets/gif/toggle-setting1.gif" style ="height:270px;width:350px;"><br>Let\'s look at in detail, How to load a graph and finetune the loading option.',
  //           title: '<h3 style="text-align:center"><i class="fas fa-cog"></i>&nbsp;Generating Graph</h3>',
  //         },
  //         {
  //           element: '#team_type_dd',
  //           intro: 'You can select the team type for which you need to generate a graph. Team can be a partcular Department, Location, Organization etc.',
  //           title: '<h3 style="text-align:center">Team Type</h3>'
  //         },
  //         {
  //           element: '#team_dd',
  //           intro: 'You can select the team or group by for which you need to generate a graph. This can be a particular department, role, tenure etc.',
  //           title: '<h3 style="text-align:center">Group by</h3>'
  //         },
  //         {
  //           element: '#network_dd',
  //           intro: 'You can select a network type from here. A network type can be work, trust, energy etc.',
  //           title: '<h3 style="text-align:center">Network Type</h3>'
  //         },
  //         {
  //           element: '#node_size_dd',
  //           intro: 'In the network graph, the size of nodes can be set based on the incoming/outgoing connections etc. You can select the desired property and based on this the node size will be set.',
  //           title: '<h3 style="text-align:center">Node Size by</h3>'
  //         },
  //         {
  //           element: '#node_color_dd',
  //           intro: 'In the network graph, the color of node can be set based on a particular enity(Departments, HL, Tenure Group, Gender etc). You can select the desire property, based on this the node color will be set to graph.',
  //           title: '<h3 style="text-align:center">Node Color by</h3>'
  //         },
  //         {
  //           element: '#layout_dd',
  //           intro: 'While Ploting a network graph, the node position(layout) is determined using some standard algorithms. You can select the desired algorithm here and see which one suits requirement.',
  //           title: '<h3 style="text-align:center">Layout</h3>'
  //         },
  //         {
  //           element: '#nodes_sort_advance',
  //           intro: 'If the number of nodes are too large the graph will be loaded with a subset of the nodes due to performance reasons. You can select how the initial node is selected using this option. In smaller graphs this does not have any effect.',
  //           title: '<h3 style="text-align:center">Load nodes according to</h3>'
  //         },
  //         {
  //           element: '#group_as_single_node_check',
  //           intro: 'Load groups as a single node.',
  //           title: '<h3 style="text-align:center">Group Nodes</h3>'
  //         },
  //         {
  //           element: '#d_s_iso_nodes_check',
  //           intro: 'If you want to see isolated nodes(nodes without incoming and outgoing connections). In larger graph, hiding isolates nodes may give you a larger canvas.',
  //           title: '<h3 style="text-align:center">Load Isolated Nodes</h3>'
  //         },

  //         {
  //           element: '#generate_graph_button',
  //           intro: 'Finally, click on this button to generate and load the graph according to the options you have selected.',
  //           title: '<h3 style="text-align:center">Generate Graph</h3>'
  //         }
  //         // ,
  //         // {
  //         //   element: '#open_options_pane',
  //         //   intro: '<img class="animated-gif" src ="assets/gif/toggle-setting2.gif" style ="height:200px;width:350px;;"><br>Once you load a graph, you can finetune that graph from here.',
  //         //   title: '<h3 style="text-align:center">Finetune Graph</h3>'
  //         // }
  //       ],
  //       showProgress: true,
  //       hidePrev: true,
  //       hideNext: false,
  //       showBullets: false,
  //       // showStepNumbers:true,
  //       nextLabel: 'Next >',
  //       // skipLabel:'Skip',
  //       prevLabel: '< Back'

  //     })
  //     .onchange(function (targetElement) {
  //       // console.log(targetElement.id); 
  //       switch (targetElement.id) {
  //         case "step1":
  //           $(".sidenav").css("width", "50%");
  //           console.log("filter changed")
  //           break;
  //       }
  //       switch (targetElement.id) {
  //         case "layout_dd":
  //           $("#advanced_settings_container").toggle();
  //           break;
  //       }
  //       switch (targetElement.id) {
  //         case "open_options_pane":
  //           $(".sidenav").css("width", "0");
  //           break;
  //       }

  //     }).start();
  // }






  //Logic for loading graph from link

  startSteps(): void {
    this.introJS
      .setOptions({
        steps: [
          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/intro.jpeg" style ="height:200px;width:max-content;"><br>ONE is a tool where you can play around with any network graph of your organization / team. You will be able to inspect the graph from different angles to derive your own insights.',
            title: '',
          },
          {
            element: '#step2',
            intro: '<img class="animated-gif" src ="assets/gif/toggle-setting1.gif" style ="height:270px;width:350px;;"><br>You can load the desired network graph using this. Before loading, you can finetune the network graph by selecting different options according to your needs.',
            title: '<h3 style="text-align:center"><b><i class="fas fa-cog"></i>&nbsp;Graph Generation</b></h3>',
            // position: 'bottom-left-aligned'
          },
          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/zoom-in_zoom-out.gif" style ="height:200px;width:350px;"><br>Once loaded, you can zoom in and zoom out the graph using this icon. You can also do this using the laptop trackpad or mouse.',
            title: '<h3 style="text-align:center"><b>Zoom in-Zoom out</b></h3>'
          },
          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/graph-drag-drop.gif" style ="height:200px;width:350px;"><br>You can also move the whole graph to the desired position on the screen.',
            title: '<h3 style="text-align:center"><b>Graph Drag-Drop</b></h3>'
          },

          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/drag-drop.gif" style ="height:200px;width:350px;"><br>You can also move a node anywhere in the canvas.',
            title: '<h3 style="text-align:center"><b>Drag Nodes</b></h3>'
          },
          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/node-hover.gif" style ="height:200px;width:350px;"><br>Just hover on any node, you can see details of that node.',
            title: '<h3 style="text-align:center"><b>Hover Node</b></h3>'
          },
          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/node-click.gif" style ="height:200px;width:350px;"><br>By clicking on a node, you can see connections of that particular node.',
            title: '<h3 style="text-align:center"><b>Node Click</b></h3>'
          },
          {
            element: '#cy',
            intro: '<img class="animated-gif" src ="assets/gif/click-legend.gif" style ="height:200px;width:350px;"><br>You can filter out the nodes by clicking on the legends. Clicking again on that legend will remove that filter also you can apply multiple filters at a time.',
            title: '<h3 style="text-align:center"><b>Filtering</b></h3>'
          },
          {
            element: '#_nodeSlider',
            intro: '<img class="animated-gif" src ="assets/gif/node-count.gif" style ="height:260px;width:350px;"><br>In a graph with a large number of nodes, all the nodes will not be loaded initially due to performance reasons. In such cases you can increase and decrease the number of nodes using this slider.  ',
            title: '<h3 style="text-align:center"><b>Node Slider</b></h3>',
            position: 'left'
          },
          {
            element: '#step3',
            intro: "You can see overall Statistics of your network here.",
            title: '<h3 style="text-align:center"><b><i class="fas fa-chart-bar"></i>&nbsp;Stats</b></h3>'
          },
          {
            element: '#step1',
            intro: 'You can download the current graph as image.',
            title: '<h3 style="text-align:center"><b><i class="fas fa-download"></i>&nbsp;Download</b></h3>',
          },
          {
            element: '#step',
            intro: '<img class="animated-gif" src ="assets/gif/toggle-setting1.gif" style ="height:270px;width:350px;"><br>Let\'s look at in detail, How to load a graph and finetune the loading option.',
            title: '<h3 style="text-align:center"><b><i class="fas fa-cog"></i>&nbsp;Generating Graph</b></h3>',
          },
          {
            element: '#team_type_dd',
            intro: 'You can select the team type for which you need to generate a graph. Team can be a particular Department, Location, Organization etc.',
            title: '<h3 style="text-align:center"><b>Team Type</b></h3>'
          },
          {
            element: '#team_dd',
            intro: 'You can select the team or group by for which you need to generate a graph. This can be a particular department, role, tenure etc.',
            title: '<h3 style="text-align:center"><b>Group by</b></h3>'
          },
          {
            element: '#network_dd',
            intro: 'You can select a network type from here. A network type can be work, trust, energy etc.',
            title: '<h3 style="text-align:center"><b>Network Type</b></h3>'
          },
          {
            element: '#node_size_dd',
            intro: 'In the network graph, the size of nodes can be set based on the incoming/outgoing connections etc. You can select the desired property and based on this the node size will be set.',
            title: '<h3 style="text-align:center"><b>Node Size by</b></h3>'
          },
          {
            element: '#node_color_dd',
            intro: 'In the network graph, the color of node can be set based on a particular entity(Departments, HL, Tenure Group, Gender etc). You can select the desire property, based on this the node color will be set to graph.',
            title: '<h3 style="text-align:center"><b>Node Color by</b></h3>'
          },
          {
            element: '#layout_dd',
            intro: 'While Plotting a network graph, the node position(layout) is determined using some standard algorithms. You can select the desired algorithm here and see which one suits requirement.',
            title: '<h3 style="text-align:center"><b>Layout</b></h3>'
          },
          {
            element: '#nodes_sort_advance',
            intro: 'If the number of nodes are too large the graph will be loaded with a subset of the nodes due to performance reasons. You can select how the initial node is selected using this option. In smaller graphs this does not have any effect.',
            title: '<h3 style="text-align:center"><b>Load nodes according to</b></h3>'
          },
          {
            element: '#group_as_single_node_check',
            intro: 'Load groups as a single node.',
            title: '<h3 style="text-align:center"><b>Group Nodes</b></h3>'
          },
          {
            element: '#d_s_iso_nodes_check',
            intro: 'If you want to see isolated nodes(nodes without incoming and outgoing connections). In larger graph, hiding isolates nodes may give you a larger canvas.',
            title: '<h3 style="text-align:center"><b>Load Isolated Nodes</b></h3>'
          },

          {
            element: '#generate_graph_button',
            intro: 'Finally, click on this button to generate and load the graph according to the options you have selected.',
            title: '<h3 style="text-align:center"><b>Generate Graph</b></h3>'
          }
          // ,
          // {
          //   element: '#open_options_pane',
          //   intro: '<img class="animated-gif" src ="assets/gif/toggle-setting2.gif" style ="height:200px;width:350px;;"><br>Once you load a graph, you can finetune that graph from here.',
          //   title: '<h3 style="text-align:center">Finetune Graph</h3>'
          // }
        ],
        showProgress: true,
        hidePrev: true,
        hideNext: false,
        showBullets: false,
        // showStepNumbers:true,
        nextLabel: 'Next >',
        // skipLabel:'Skip',
        prevLabel: '< Back'

      })
      .onchange(function (targetElement) {
        // console.log(targetElement.id); 
        switch (targetElement.id) {
          case "step1":
            $(".sidenav").css("width", "50%");
            console.log("filter changed")
            break;
        }
        switch (targetElement.id) {
          case "layout_dd":
            $("#advanced_settings_container").toggle();
            break;
        }
        switch (targetElement.id) {
          case "open_options_pane":
            $(".sidenav").css("width", "0");
            break;
        }

      }).start();
  }

  onGraphLinkLoad() {
    setTimeout(() => {
      var timestamp_start = new Date().getTime()
      //initial loading screen shown
      $(".lds-hourglass").css("display", "block");
      $(".hide_before_load").css("display", "none");
      $("#top_heading_container").css("display", "none");
      $(".action").css("display", "none");
      $("#nodeSliderCont").css("display", "none");



      //get the unique has key from the url
      this.uid = this.getUrlParameter('uid');
      this.unique_hash = this.getUrlParameter('uid');
      var json_payload = jwt_decode(this.uid);
      this.uid = json_payload['study_id']
      //get team type from the url
      this.team_type = this.getUrlParameter('tt');
      //get team from the url
      this.team = this.getUrlParameter('team');
      //get module type from url
      this.g_type_url_global = this.getUrlParameter("g_type");
      // this.g_type_url_global = "none"
      console.log(this.g_type_url_global, "this.g_type_url_global")
      this.node_color = this.getUrlParameter('node_c')
      console.log("node color ", this.node_color)
      if (this.node_color == undefined || this.node_color == '' || this.getUrlParameter('node_c') == undefined) {
        this.node_color = this.g_type_url_global
      }
      if (this.getUrlParameter('show_collab_bet_entity') == "true") this.show_collab_bet_entity = true
      if (this.getUrlParameter('show_collab_within_entity') == "true") this.show_collab_within_entity = true

      if (this.getUrlParameter('leadership_team') == "true") {
        console.log('')
        this.show_leadership_team = true
        $("#leadership_team").prop("checked", true)
        console.log('show leadership team ', $("#leadership_team").prop("checked"))
      }

      this.rm_email = this.getUrlParameter('rm_email')
      this.rm_name = this.getUrlParameter('rm_name');
      if (this.getUrlParameter('collab_bet_entity_highlight') == 'true') {
        this.collab_bet_entity_highlight = true
        this.entity_highlight_name = this.getUrlParameter('entity_highlight_name')
      }
      if (this.getUrlParameter('collab_within_entity_highlight') == 'true') {
        this.collab_within_entity_highlight = true
        this.entity_highlight_name = this.getUrlParameter('entity_highlight_name')
        this.entity_type_highlight = this.getUrlParameter('entity_type_highlight')

      }
      if (this.getUrlParameter('collab_within_small_unit_show') == 'true') {
        this.collab_within_small_unit_show = true;
        this.entity_type_highlight_small_unit = this.getUrlParameter('entity_type_highlight_small_unit');
        this.entity_highlight_name_small_unit = this.getUrlParameter('entity_highlight_name_small_unit');


      }

      if (this.getUrlParameter('show_two_specific_entities') == 'true') {
        this.update_node_slider_two_entities = true
        $("#two_specific_entities_check").prop("checked", true);
        this.two_specific_entities_boolean = true;
        this.entity_type_two_entities = this.getUrlParameter('entity_type_two_entities')
        this.entity_1 = this.getUrlParameter('entity_1')
        this.entity_2 = this.getUrlParameter('entity_2')

      }

      console.log("this.collab_bet_entity_highlight", this.getUrlParameter('collab_bet_entity_highlight'))
      console.log("this.collab_bet_entity_highlight", this.collab_bet_entity_highlight)
      if (this.g_type_url_global == "rm_team") {
        $("#team_type_dd").prop("disabled", true);
        $("#team_dd").prop("disabled", true);
        $("#nodes_sort_advance").prop("disabled", true);
        $("#group_as_single_node_check").prop("disabled", true);
        $("#d_s_iso_nodes_check").prop("disabled", true);
        $("#leadership_team").prop("disabled", true);



      }
      if (this.g_type_url_global == undefined || this.g_type_url_global == "") {
        this.g_type_url_global = "none"
      }
      // this.g_type_url_global = String($("#module_type").children("option:selected").val());

      //get network type from url
      this.n_type_url_global = this.getUrlParameter('n_type');
      if (this.n_type_url_global == 'execution') {
        this.n_type_url_global = 'work'
      }
      if (this.n_type_url_global == 'ideation') {
        this.n_type_url_global = 'innovation'
      }
      console.log("n_type_url_global", this.n_type_url_global)
      //first getContext and the populate the dropdowns
      this.getContext().then(() => {
        if (this.getUrlParameter("teams_tab_active") != "true" && this.getUrlParameter("individual_tab_active") != "true") {
          this.change_network_tab('general')

        }

        if (this.getUrlParameter('teams_tab_active') == "true") {
          this.teams_tab_active = true

          this.change_network_tab('teams')

        }
        if (this.getUrlParameter('individual_tab_active') == "true") {
          this.individual_tab_active = true

          this.change_network_tab('individual')

        }
        var timestamp_end = new Date().getTime()
        console.log("Time taken for getting context of the graph ", (timestamp_end - timestamp_start))
      })


      $(document).on('keydown', (event) => {
        if (event.key == "Escape") {
          this.close_stats_modal();
        }
      });
      var timestamp_end = new Date().getTime()
      console.log("Time taken for graphlinkload ", (timestamp_end - timestamp_start))

    }, 1000)


  }

  async getContext() {
    var timestamp_start = new Date().getTime()
    //get graph specifications json
    this.appservice.getGraph_specifications().then(resp => {
      this.graph_spec = resp;
      console.log("graph spec ", this.graph_spec);
      //check if
      if (!resp[String(this.g_type_url_global)]) {
        $("#secondDiv").css("display", "none");
        $("#errorDiv").css("display", "flex");
        return;
      }
      if (!resp[String(this.g_type_url_global)][this.n_type_url_global]) {
        $("#secondDiv").css("display", "none");
        $("#errorDiv").css("display", "flex");
        return;
      }
      var layouts = resp[String(this.g_type_url_global)][this.n_type_url_global]["layout"]
      $('#layout_dd').html("")

      for (let i in layouts) {
        $('#layout_dd').append("<option style = 'background-color:white' value ='" + layouts[i] + "'>" + layouts[i] + "</option>");

      }
      var node_size_conditions = Object.keys(resp[String(this.g_type_url_global)][this.n_type_url_global]["node_size_by"]);
      $('#node_size_dd').html("")
      for (let i in node_size_conditions) {
        $('#node_size_dd').append("<option style = 'background-color:white' value ='" + node_size_conditions[i] + "'>" + resp[String(this.g_type_url_global)][this.n_type_url_global]["node_size_by"][node_size_conditions[i]] + "</option>");

      }
      var timestamp_end = new Date().getTime()
      console.log("Time taken for getting graph_specifications json ", (timestamp_end - timestamp_start))



    });
  }
  entity_colors_array: any = {};
  populate_Settings_Dropdowns(start_graph_generation = 1) {
    var timestamp_start = new Date().getTime()
    // variables to store values for settings dropdowns
    var entity_class, sorteddropdown2data;

    // get the config json to populate the dropdowns
    this.appservice.getConfig_reportJson(this.unique_hash).then((data) => {

      console.log("config report data", data);
      //config json data to class variable
      this.config_json_data = data;
      if (this.config_json_data["settings"]["rm_teams_present"] == "false") this.teams_tab_present = false
      if (this.config_json_data["settings"]["individual_network_present"] == "false") this.individual_tab_present = false

      this.top_boss_email = data["settings"]["top_boss_email"]
      //storing all the different ways of writing team type (eg. Department,dept,department)
      entity_class = data["entity_class"]
      this.childclassdispdropdown = data["child_class_disp"];
      this.dropdowndata = data["entity_class_disp"];
      this.dropdownvalue = data["entity_class_short"];
      this.dropdown_reportfilter = data["report_filter"];

      //mapping each way of writing team type
      this.entity_class_map = []
      for (let k in data["entity_class"]) {

        var tuple = [];
        tuple.push(entity_class[k]);
        tuple.push(this.dropdownvalue[k]);
        tuple.push(this.dropdowndata[k]);
        this.entity_class_map.push(tuple);

      }

      //sorting the mapping array according to entity_class_disp
      this.entity_class_map = _.sortBy(this.entity_class_map, (element) => { return element[2] })
      for (let i of this.entity_class_map) {
        if (this.entity_type_highlight_small_unit == i[0]) {
          for (let j of this.config_json_data["report_filter"][i[1]]) {
            if (this.entity_highlight_name_small_unit == j["entity"]["value"]) {
              this.entity_highlight_name_small_unit = j["entity"]["name"]

            }
          }
        }
        this.entity_colors_array[i[0]] = []
        var sort_entities = _.sortBy(this.config_json_data['report_filter'][i[1]], (element) => { return element['entity']['name'] })
        console.log("sort_entities ", sort_entities);
        for (let j in sort_entities) {
          var tuple = []
          tuple.push(sort_entities[j]['entity']['name'])
          tuple.push(this.all_shades_tints[j])
          console.log('j ', j)
          this.entity_colors_array[i[0]].push(tuple);
        }

      }
      console.log("entity_colors_array", this.entity_colors_array)

      if (this.collab_bet_entity_highlight) {
        for (let i of this.config_json_data["report_filter"][this.team]) {
          if (i["entity"]["include"]) {
            if (this.entity_highlight_name == i["entity"]["value"])
              this.entity_highlight_name = i["entity"]["name"]
          }
        }
      }
      console.log("this.collab_within_entity_highlight ", this.collab_within_entity_highlight)

      if (this.collab_within_entity_highlight) {
        console.log("this.collab_within_entity_highlight ", this.collab_within_entity_highlight)
        console.log("entity_class_map", this.entity_class_map)

        for (let i of this.entity_class_map) {
          if (this.entity_type_highlight == i[0]) {
            for (let j of this.config_json_data["report_filter"][i[1]]) {
              if (j["entity"]["include"]) {
                if (this.entity_highlight_name == j["entity"]["value"])
                  this.entity_highlight_name = j["entity"]["name"]
              }
            }
          }
        }
        // for (let i of this.config_json_data["report_filter"][this.team_type]) {
        //   if (i["entity"]["include"]) {
        //     if (this.entity_highlight_name == i["entity"]["value"])
        //       this.entity_highlight_name = i["entity"]["name"]
        //   }
        // }
      }
      console.log("this.entity_highlight_name_small_unit", this.entity_highlight_name_small_unit)
      //emptying the original arrays to push the alphabetically sorted values
      this.childclassdropdown = [];
      this.dropdowndata = [];
      this.dropdownvalue = [];
      for (let i in this.entity_class_map) {
        this.childclassdropdown.push(this.entity_class_map[i][0]);
        this.dropdownvalue.push(this.entity_class_map[i][1]);
        this.dropdowndata.push(this.entity_class_map[i][2]);
      }

      //populating node coloring options
      $("#node_color_dd").html("");
      $('#node_color_dd').append("<option style = 'background-color:white' value ='" + this.g_type_url_global + "'>Default</option>");
      // $('#node_color_dd').append("<option style = 'background-color:white' value ='designation'>Designation</option>");

      //populating node labels
      $("#node_label_dd").html("");
      $('#node_label_dd').append("<option style = 'background-color:white' value ='none'>None</option>");
      $('#node_label_dd').append("<option style = 'background-color:white' value ='full_name'>Name</option>");
      $('#node_label_dd').append("<option style = 'background-color:white' value ='designation'>Designation</option>");


      //checking if other coloring options should be allowed in the dropdown
      if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['use_other_coloring_options'] == true) {

        for (let m in this.entity_class_map) {

          if (this.entity_class_map[m][1] != $("#filterboxdropdown1").children("option:selected").val() && this.entity_class_map[m][1] != 'org') {

            $('#node_color_dd').append("<option style = 'background-color:white' value ='" + this.entity_class_map[m][0] + "'>" + this.entity_class_map[m][2] + "</option>");
            $('#node_label_dd').append("<option style = 'background-color:white' value ='" + this.entity_class_map[m][0] + "'>" + this.entity_class_map[m][2] + "</option>");

          }
        }
        if (this.uid == "90") {
          $('#node_color_dd').append("<option style = 'background-color:white' value ='hierarchy_level'>Hierarchy Level</option>");
          $('#node_color_dd').append("<option style = 'background-color:white' value ='group_name1'>Conclave</option>");
          $('#node_color_dd').append("<option style = 'background-color:white' value ='group_name2'>OC</option>");
          $('#node_color_dd').append("<option style = 'background-color:white' value ='group_name3'>Band</option>");

          $('#node_color_dd').append("<option style = 'background-color:white' value ='group_name4'>Leadership Level</option>");
        }
        //appedning gender and tenure options
        $('#node_color_dd').append("<option style = 'background-color:white' value ='gender'>Gender</option>");
        $('#node_color_dd').append("<option style = 'background-color:white' value ='tenure_status'>Tenure Status</option>");
        $('#node_label_dd').append("<option style = 'background-color:white' value ='gender'>Gender</option>");
        $('#node_label_dd').append("<option style = 'background-color:white' value ='tenure_status'>Tenure Status</option>");

      }


      //these coloring options are compulsory for the module and other options can be removed
      if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['compulsory_node_coloring_options']) {
        console.log("compulsory_node_coloring_options", this.graph_spec[this.g_type_url_global][this.n_type_url_global]['compulsory_node_coloring_options'])
        $("#node_color_dd").html("");
        var compulsory_node_coloring_options = this.graph_spec[this.g_type_url_global][this.n_type_url_global]['compulsory_node_coloring_options']
        for (let m in compulsory_node_coloring_options) {

          $('#node_color_dd').append("<option style = 'background-color:white' value ='" + compulsory_node_coloring_options[m]['value'] + "'>" + compulsory_node_coloring_options[m]['disp_name'] + "</option>");
          $('#node_label_dd').append("<option style = 'background-color:white' value ='" + compulsory_node_coloring_options[m]['value'] + "'>" + compulsory_node_coloring_options[m]['disp_name'] + "</option>");

        }
      }
      for (let i of this.entity_class_map) {
        if (this.node_color == i[1]) this.node_color = i[0]
      }
      if (this.node_color == 'tenure') this.node_color = 'tenure_status'
      $("#node_color_dd").val(this.node_color).trigger('change')





      //emptying team type and team dropdown elements

      $('#team_type_dd').html("");
      $('#team_dd').html("");


      // populating team type
      if (this.general_tab_active) {
        for (let i in this.dropdowndata) {
          if (this.dropdownvalue[i] == "org") {
            $('#team_type_dd').append("<option style = 'background-color:white' childClassValue='organisation' value ='" + this.dropdownvalue[i] + "'>" + this.dropdowndata[i] + "</option>");

          }
          else {
            $('#team_type_dd').append("<option style = 'background-color:white' childClassValue='" + this.childclassdropdown[i] + "' value ='" + this.dropdownvalue[i] + "'>" + this.dropdowndata[i] + "</option>");

            // this dropdown is to show connections between two teams of a team type in advanced settings
            $('#team_type_2').append("<option style = 'background-color:white' childClassValue='" + this.childclassdropdown[i] + "' value ='" + this.dropdownvalue[i] + "'>" + this.dropdowndata[i] + "</option>");

          }

          var drop1ValuetoFetchJSON = $("#team_type_dd").children("option:selected").val()

        }
        //sorting alphabetically and assigning teams to the variable 
        sorteddropdown2data = _.sortBy(this.dropdown_reportfilter[String($("#team_type_dd").children("option:selected").val())], (obj) => { return obj["entity"]["name"] })

        //populating teams for the selected team type
        if ($("#team_type_dd").children("option:selected").val() != "org") {
          for (let j in sorteddropdown2data) {
            $('#team_dd').append("<option style = 'background-color:white' value ='" + sorteddropdown2data[j]["entity"]["value"] + "'>" + sorteddropdown2data[j]["entity"]["name"] + "</option>");

            //populating dropdowns in advanced settings to show connections between two specific teams
            $('#team_dd_1').append("<option style = 'background-color:white' value ='" + sorteddropdown2data[j]["entity"]["value"] + "'>" + sorteddropdown2data[j]["entity"]["name"] + "</option>");
            $('#team_dd_2').append("<option style = 'background-color:white' value ='" + sorteddropdown2data[j]["entity"]["value"] + "'>" + sorteddropdown2data[j]["entity"]["name"] + "</option>");


          }
        }


        else if ($("#team_type_dd").children("option:selected").val() == "org") {
          for (let j in this.childclassdropdown) {
            if (this.childclassdispdropdown[j] != 'organisation') {
              $('#team_dd').append("<option style = 'background-color:white' childClassValue='" + this.childclassdropdown[j] + "' value ='" + this.dropdownvalue[j] + "'>" + this.dropdowndata[j] + "</option>");
            }
            else if (this.childclassdispdropdown[j] == 'organisation') {
              $('#team_dd').append("<option style = 'background-color:white' childClassValue='organisation' value ='" + this.dropdownvalue[j] + "'>" + this.dropdowndata[j] + "</option>");
            }
          }
        }

        //populating network 

      }


      else if (this.teams_tab_active) {
        var rm_filename = 'rm_email_list'
        if (parseInt(this.config_json_data["settings"]["study_id"]) >= 739 || parseInt(this.config_json_data["settings"]["study_id"]) == 20) {
          rm_filename = "rm_list"
        }
        this.appservice.getTeamGraph(this.unique_hash, rm_filename).subscribe((data) => {
          console.log("rm data", data)
          var rm_list: any = data
          if (parseInt(this.config_json_data["settings"]["study_id"]) < 73 && parseInt(this.config_json_data["settings"]["study_id"]) != 20) {
            rm_list = data['rm_list']
          }

          rm_list = _.sortBy(rm_list, (obj) => { return obj[1] })

          rm_list = _.sortBy(rm_list, (obj) => { return obj[1] })

          console.log("rm list ", rm_list)
          $('#team_type_dd').html("");
          $('#team_dd').html("");
          $('#team_type_dd').append("<option style = 'background-color:white' childClassValue='rm' value ='rm'>Reporting Managers</option>");
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='work'>Work</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='innovation'>Innovation</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='expertise'>Expertise</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='trust'>Trust</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='energy'>Energy</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='main'>All Networks</option>");

          $("#node_size_dd").val('indegree').trigger('change')



          $("#node_color_dd").val(this.g_type_url_global).trigger('change')
          $("#node_label_dd").val("full_name").trigger('change')


          for (let rm of rm_list) {

            $('#team_dd').append("<option style = 'background-color:white' childClassValue='" + rm[0] + "' value ='" + rm[0] + "'>" + rm[1] + " " + rm[2] + "</option>");


          }
          $("#team_dd").val($("#team_dd option:first").val());
          console.log(String($("#team_dd option:selected").attr('value')))
          if (this.getUrlParameter('teams_tab_active') == "true") $("#team_dd").val(this.getUrlParameter('rm_email'));
          console.log(String($("#team_dd option:selected").attr('value')))

          setTimeout(() => {
            $("#loadingicon").css("display", "none")
            $(".lds-hourglass").css("display", "none");

            ;
          }, 200)
          // //start graph generation
          // if (start_graph_generation == 1) {
          //   this.start_graph_generation();

          // }
        }
        )

      }
      else if (this.individual_tab_active) {
        this.appservice.getIndividualGraph(this.unique_hash, 'ind_graph').subscribe((data) => {
          console.log("id list individual ", data)
          var ids = data['entity_class']
          var ids_value_map: any = {};
          for (let i in ids) {
            ids_value_map[data['entity_class_disp'][i][1] + data['entity_class_disp'][i][2]] = [ids[i], data['entity_class_disp'][i]]
          }
          var keys = Object.keys(ids_value_map)
          keys.sort()
          //  ids_value_map = _.sortBy(ids_value_map,(element)=>{ return _.contains(ids_value_map,element)})
          console.log("ids list ", ids_value_map)
          $('#team_type_dd').html("");
          $('#team_dd').html("");
          $('#team_type_dd').append("<option style = 'background-color:white' childClassValue='individual' value ='individual'>Individual Employee Graphs</option>");

          for (let key of keys) {

            $('#team_dd').append("<option style = 'background-color:white' childClassValue='" + ids_value_map[key][1][0] + "' value ='" + ids_value_map[key][0] + "'>" + ids_value_map[key][1][1] + " " + ids_value_map[key][1][2] + "</option>");


          }
          $("#team_dd").val($("#team_dd option:first").val());
          console.log(String($("#team_dd option:selected").attr('value')))
          $("#network_dd").val(this.n_type_url_global).trigger("'change")
          $("#node_color_dd").val(this.g_type_url_global).trigger('change')
          $("#node_label_dd").val("full_name").trigger('change')

          setTimeout(() => {
            $("#loadingicon").css("display", "none")
            $(".lds-hourglass").css("display", "none");

            ;
          }, 200)

          //start graph generation
          // if (start_graph_generation == 1) {
          //   this.start_graph_generation();

          // }

        }
        )

      }
      $('#network_dd').html("");
      if (this.g_type_url_global == "none") {
        var networks: any = [];
        for (let i in this.config_json_data["settings"]["networks"]) {
          var name = this.config_json_data["settings"]["networks"][i]
          var display_name = this.config_json_data["settings"]["networks_display"][i]
          if (name == 'combined')
            networks.push({ "name": 'main_' + name, "display_name": display_name })
          else
            networks.push({ "name": name, "display_name": display_name })

        }
        networks.push({ "name": "main_professional", "display_name": "Professional" })
        networks.push({ "name": "main_personal", "display_name": "Personal" })
        networks.push({ "name": "main", "display_name": "All Networks" })



        for (let j in networks) {
          if (networks[j]["name"] == "main_combined") {
            $('#network_dd').append("<option style = 'background-color:white' value ='combined'>" + networks[j]["display_name"] + "</option>");
            continue;
          }
          else if (networks[j]["name"] == "main_professional") {
            $('#network_dd').append("<option style = 'background-color:white' value ='professional'>" + networks[j]["display_name"] + "</option>");
            continue;
          }
          else if (networks[j]["name"] == "main_personal") {
            $('#network_dd').append("<option style = 'background-color:white' value ='personal'>" + networks[j]["display_name"] + "</option>");
            continue;
          }
          $('#network_dd').append("<option style = 'background-color:white' value ='" + networks[j]["name"] + "'>" + networks[j]["display_name"] + "</option>");

        }

      } else {
        for (let i in this.config_json_data["settings"]["who_modules"]) {

          var module_name = this.config_json_data["settings"]["who_modules"][i]["name"]
          if (module_name == "v3_" + this.g_type_url_global) {
            var module_networks = this.config_json_data["settings"]["who_modules"][i]["networks"]
            for (let j in module_networks) {
              if (module_networks[j]["name"] == "main_combined") {
                $('#network_dd').append("<option style = 'background-color:white' value ='combined'>" + module_networks[j]["display_name"] + "</option>");
                continue;
              }
              else if (module_networks[j]["name"] == "main_professional") {
                $('#network_dd').append("<option style = 'background-color:white' value ='professional'>" + module_networks[j]["display_name"] + "</option>");
                continue;
              }
              else if (module_networks[j]["name"] == "main_personal") {
                $('#network_dd').append("<option style = 'background-color:white' value ='personal'>" + module_networks[j]["display_name"] + "</option>");
                continue;
              }
              $('#network_dd').append("<option style = 'background-color:white' value ='" + module_networks[j]["name"] + "'>" + module_networks[j]["display_name"] + "</option>");

            }
          }
        }
        //TODO: change these for what modules, to be taken from config json
        if (this.g_type_url_global == "onps") {
          $('#network_dd').html("");

          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");

        }
        else if (this.g_type_url_global == "inclusion") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          // $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");

          $('#network_dd').append("<option style = 'background-color:white' value ='work'>Execution</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='innovation'>Ideation</option>");

          $('#network_dd').append("<option style = 'background-color:white' value ='expertise'>Expertise</option>");
          // $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");

          $('#network_dd').append("<option style = 'background-color:white' value ='trust'>Trust</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='energy'>Energy</option>");




        }
        else if (this.g_type_url_global == 'collaboration' && this.show_collab_bet_entity) {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");



        }
        else if (this.g_type_url_global == 'collaboration' && !this.show_collab_bet_entity) {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='work'>Execution</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='innovation'>Ideation</option>");

          $('#network_dd').append("<option style = 'background-color:white' value ='expertise'>Expertise</option>");
          // $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");

          $('#network_dd').append("<option style = 'background-color:white' value ='trust'>Trust</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='energy'>Energy</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");



        }
        else if (this.g_type_url_global == "rm_team") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='work'>Work</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='innovation'>Innovation</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='expertise'>Expertise</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='trust'>Trust</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='energy'>Energy</option>");



        }

        else if (this.g_type_url_global == "engagement") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");

        }
        else if (this.g_type_url_global == "informal-communities") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");
        }
        else if (this.g_type_url_global == "leadership") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='work'>Work</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='innovation'>Innovation</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='expertise'>Expertise</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='trust'>Trust</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='energy'>Energy</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");

        }
        else if (this.g_type_url_global == "influencers") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");



        }
        else if (this.g_type_url_global == "connectors") {
          $('#network_dd').html("");
          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");


        }
        else if (this.g_type_url_global == "trust") {
          $('#network_dd').html("");

          $('#network_dd').append("<option style = 'background-color:white' value ='main'>Main Personal</option>");

        }
        else if (this.g_type_url_global == "disc_emp") {
          $('#network_dd').html("");

          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='work'>Work</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='innovation'>Innovation</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='expertise'>Expertise</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='trust'>Trust</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='energy'>Energy</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='professional'>Professional</option>");
          $('#network_dd').append("<option style = 'background-color:white' value ='personal'>Personal</option>");

        }
        else if (this.g_type_url_global == "collab_overload") {
          $('#network_dd').html("");

          $('#network_dd').append("<option style = 'background-color:white' value ='combined'>Combined</option>");


        }


      }


      //trigger dropdown change for the current selection from the url parameters
      this.team_type = this.getUrlParameter('tt')
      for (let i of this.entity_class_map) {
        console.log('tt', this.team_type, 'i[1]', i[0])
        if (this.team_type == i[0]) {
          this.team_type = i[1]
          break;
        }

      }
      $("#team_type_dd").val(this.team_type).trigger('change');
      this.change_Team_Type();
      this.team = this.getUrlParameter('team')

      $("#team_dd").val(this.team).trigger('change')
      this.change_Team()
      $("#network_dd").val(this.n_type_url_global).trigger('change')

      $("#node_color_dd").val(this.g_type_url_global).trigger('change')
      if (this.node_color != '') $("#node_color_dd").val(this.node_color).trigger('change')


      console.log("node_label_dd gtypeurl", this.g_type_url_global);
      if (this.two_specific_entities_boolean) {
        $("#team_type_2").val(this.entity_type_two_entities).trigger('change')
        $("#node_label_dd").val('full_name').trigger('change')

        for (let i of this.entity_class_map) {
          if (this.entity_type_two_entities == i[0]) {
            $("#team_type_2").val(i[1])

          }
        }
        this.change_specificEntities_checkBox()
        $("#team_dd_1").val(this.entity_1)
        $("#team_dd_2").val(this.entity_2)

      }
      $("#node_label_dd").prop('disabled', false)

      $("#node_size_dd").val('indegree').trigger('change')

      if (this.show_leadership_team) {
        this.show_leadership_team = true
        $("#node_size_dd").val('indegree').trigger('change')
        $("#node_label_dd").val('full_name').trigger('change')

      }
      else {
        this.show_leadership_team = false
        $("#node_size_dd").val('none').trigger('change')

      }
      if (this.show_collab_bet_entity == true) {
        $("#node_size_dd").val('collab_score').trigger('change')

      }
      if (this.two_specific_entities_boolean) {
        $("#node_label_dd").val('full_name').trigger('change')

        $("#node_size_dd").val('indegree').trigger('change')

      }
      if (this.g_type_url_global == "engagement") {
        $("#node_size_dd").val('engagement').trigger('change')
        $("#node_label_dd").val('none').trigger('change')
        $("#node_label_dd option[value='full_name']").remove();

      }
      else if (this.g_type_url_global == "onps") {
        $("#node_label_dd").val('none').trigger('change')
        $("#node_size_dd").val('ivi_score').trigger('change')
        $("#node_label_dd option[value='full_name']").remove();


      }
      else if (this.g_type_url_global == "influencers") {
        $("#node_label_dd").val('none').trigger('change')
        $("#node_size_dd").val('ivi_score').trigger('change')
      }
      else if (this.g_type_url_global == "connectors") {
        $("#node_label_dd").val('none').trigger('change')
        $("#node_size_dd").val('gatekeeper_score').trigger('change')
      }
      else if (this.g_type_url_global == "trust") {
        $("#node_label_dd").val('none').trigger('change')
        $("#node_size_dd").val('indegree_centrality').trigger('change')
      }
      else if (this.g_type_url_global == "collab_overload") {
        $("#node_label_dd").val('none').trigger('change')
        $("#node_size_dd").val('indegree').trigger('change')
      }
      else if (this.g_type_url_global == "none") {
        console.log("node label disabled ",)
        $("#node_label_dd").prop('disabled', false)

      }
      else if (this.g_type_url_global == "disc_emp") {
        $("#node_label_dd").val('full_name').trigger('change')
      }
      if (this.show_collab_within_entity) {

        $("#node_label_dd").val('full_name').trigger('change')
        $("#node_size_dd").val('indegree').trigger('change')
      }
      if (this.g_type_url_global == "informal-communities" && this.getUrlParameter('show_single_community') == "true") {

        $("#node_label_dd").val('full_name').trigger('change')
      }
      if (start_graph_generation == 0) {
        setTimeout(() => {
          $("#loadingicon").css("display", "none")
          $(".lds-hourglass").css("display", "none");

          ;
        }, 200)
      }
      this.change_network_dd()


      //start graph generation
      setTimeout(()=>{
        if (start_graph_generation == 1) {
          this.start_graph_generation();
  
        }
      },2000)
     









      var timestamp_end = new Date().getTime()
      console.log("Time taken for populate_settings_dropdown ", (timestamp_end - timestamp_start))
    },
      (error) => {
        console.log(error)
        $("#secondDiv").css("display", "none");
        $("#errorDiv").css("display", "flex");
        return;
      }

    );



  }
  change_showGroupAsSingleNode() {
    if (String($("#team_dd").children("option:selected").val()) != 'none' && String($("#team_type_dd").children("option:selected").val()) == 'org' && $("#group_as_single_node_check").prop("checked") == true) {
      $("#node_color_dd").prop('disabled', true);
    }
    else if (String($("#team_dd").children("option:selected").val()) != 'none' && String($("#team_type_dd").children("option:selected").val()) == 'org' && $("#group_as_single_node_check").prop("checked") == false) {
      $("#node_color_dd").prop('disabled', false);
      $('#node_color_dd > option[value="' + $("#team_dd").children("option:selected").attr('childclassvalue') + '"]').prop('selected', true);
    }
    else {
      $("#node_color_dd").prop('disabled', false);

    }
  }
  onSubmit() {
    console.log("Form submitted with values:", this.formValues);
    this.appservice.generate_layout(this.formValues).then((positions) => {
      console.log('generated positions', positions)
    })
  }
  change_Team_Type() {
    //assign selected option to variable
    var selectedoption1 = $("#team_type_dd").children("option:selected").val();
    $("#team_type_dd  option[value='" + selectedoption1 + "']").prop("selected", true);

    //emtpying the team dropdown for every change
    $("#team_dd").html("");


    //sorting team dropdown alphabetically
    var sorteddropdown2data = _.sortBy(this.dropdown_reportfilter[String($("#team_type_dd").children("option:selected").val())], (obj) => { return obj["entity"]["name"] })

    //populating team dropdown
    if ($("#team_type_dd").children("option:selected").val() != "org") {
      $(".team_dd_caption").html(`Team:&nbsp;<span class="material-symbols-outlined" style="cursor:pointer; font-size: 15px;text-align: end;grid-column: 1;grid-row: 3;" 
      data-tooltip="You can select a team"
      data-tooltip-location="top">help</span>`)
      for (let j in sorteddropdown2data) {
        $('#team_dd').append("<option style = 'background-color:white' value ='" + sorteddropdown2data[j]["entity"]["value"] + "'>" + sorteddropdown2data[j]["entity"]["name"] + "</option>");


      }
    }
    else if ($("#team_type_dd").children("option:selected").val() == "org") {
      $(".team_dd_caption").html(`Group by:&nbsp;<span class="material-symbols-outlined" style="cursor:pointer; font-size: 15px;text-align: end;grid-column: 1;grid-row: 3;" 
      data-tooltip="You can group the nodes according to this selection"
      data-tooltip-location="top">help</span>`)
      $('#team_dd').append("<option style = 'background-color:white' childClassValue='none' value ='none'>None</option>");

      for (let j in this.childclassdropdown) {
        if (this.childclassdropdown[j] != 'organisation') {
          $('#team_dd').append("<option style = 'background-color:white' childClassValue='" + this.childclassdropdown[j] + "' value ='" + this.dropdownvalue[j] + "'>" + this.dropdowndata[j] + "</option>");
        }
      }

      if (this.g_type_url_global == "inclusion" || this.g_type_url_global == "onps" || this.g_type_url_global == "informal-communities") {
        $('#team_dd').html("<option style = 'background-color:white' childClassValue='none' value ='none'>None</option>");

      }
    }
    var drop1ValuetoFetchJSON = $("#team_dd").children("option:selected").val() + "_" + $("#team_type_dd").children("option:selected").val();
    // fetchcutjson();
    if (String($("#team_dd").children("option:selected").val()) != 'none' && String($("#team_type_dd").children("option:selected").val()) == 'org') {
      $("#node_color_dd").prop('disabled', true);
    }
    else {
      $("#node_color_dd").prop('disabled', false);

    }

  }
  change_Team() {
    // node coloring is not applicable to graphs org graphs grouped by another team type
    if (String($("#team_dd").children("option:selected").val()) != 'none' && String($("#team_type_dd").children("option:selected").val()) == 'org' && $("#group_as_single_node_check").prop("checked") == true) {
      $("#node_color_dd").prop('disabled', true);
      $("#node_label_dd").val(String($("#team_dd").children("option:selected").attr('childClassValue')));
      $("#node_label_dd").prop('disabled', true);


    }
    else if (String($("#team_dd").children("option:selected").val()) != 'none' && String($("#team_type_dd").children("option:selected").val()) == 'org' && $("#group_as_single_node_check").prop("checked") == false) {
      $('#node_color_dd > option[value="' + $("#team_dd").children("option:selected").attr('childclassvalue') + '"]').prop('selected', true);
      $("#node_color_dd").prop('disabled', false);
    }
    else {
      $("#node_color_dd").prop('disabled', false);
      $("#node_label_dd").prop('disabled', false);

    }

  }
  change_network_tab(network_tab, also_generate_graph = 1) {
    setTimeout(() => {
      $("#loadingicon").css("display", "flex")
      $(".lds-hourglass").css("display", "block");
    }, 200)
    if (network_tab == 'general') {
      this.general_tab_active = true
      this.teams_tab_active = false
      this.individual_tab_active = false

      // $("#general_tab").css("background-color", "none")
      // $("#general_tab").css("color", "gray")
      // $("#general_tab").css("border-top", "none")
      setTimeout(() => {
        $("#general_tab").css("box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#general_tab").css("-webkit-box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#general_tab").css("-moz-box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#general_tab").css("border-top", "solid gray")
        $("#teams_tab").css("box-shadow", "none")
        $("#teams_tab").css("-webkit-box-shadow", "none")
        $("#teams_tab").css("-moz-box-shadow", "none")
        $("#teams_tab").css("border-top", "none")

        $("#individual_tab").css("box-shadow", "none")
        $("#individual_tab").css("-webkit-box-shadow", "none")
        $("#individual_tab").css("-moz-box-shadow", "none")
        $("#individual_tab").css("border-top", "none")


        // $("#teams_tab").css("background-color", "white")
        // $("#teams_tab").css("color", "rgb(119, 118, 118)")
        // $("#teams_tab").css("border-top", "black solid 1px")

        // $("#individual_tab").css("background-color", "white")
        // $("#individual_tab").css("color", "rgb(119, 118, 118)")
        // $("#individual_tab").css("border-top", "black solid 1px")
        $("#advanced_settings_container_parent").css("display", "flex")
        $("#reciprocal_nodes").prop("disabled", false)
        this.getContext()

        $(".team_dd_caption").html('Group by:&nbsp;<span class="material-symbols-outlined" style="cursor:pointer; font-size: 15px;text-align: end;grid-column: 1;grid-row: 3;')
        this.populate_Settings_Dropdowns(also_generate_graph);

      }, 300)


    }
    if (network_tab == 'teams') {
      this.general_tab_active = false

      this.teams_tab_active = true
      this.individual_tab_active = false
      // $("#general_tab").css("background-color", "white")
      // $("#general_tab").css("color", "rgb(119, 118, 118)")
      // $("#general_tab").css("border-top", "black solid 1px")

      // $("#teams_tab").css("background-color", "#002a57")
      // $("#teams_tab").css("color", "white")
      // $("#teams_tab").css("border-top", "none")


      // $("#individual_tab").css("background-color", "white")
      // $("#individual_tab").css("color", "rgb(119, 118, 118)")
      // $("#individual_tab").css("border-top", "black solid 1px")
      setTimeout(() => {
     
        $("#teams_tab").css("box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#teams_tab").css("-webkit-box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#teams_tab").css("-moz-box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#teams_tab").css("border-top", "solid gray")

        $("#general_tab").css("box-shadow", "none")
        $("#general_tab").css("-webkit-box-shadow", "none")
        $("#general_tab").css("-moz-box-shadow", "none")
        $("#general_tab").css("border-top", "none")

        $("#individual_tab").css("box-shadow", "none")
        $("#individual_tab").css("-webkit-box-shadow", "none")
        $("#individual_tab").css("-moz-box-shadow", "none")
        $("#individual_tab").css("border-top", "none")



        $(".team_dd_caption").html('Team:&nbsp;<span class="material-symbols-outlined" style="cursor:pointer; font-size: 15px;text-align: end;grid-column: 1;grid-row: 3;')
        $("#advanced_settings_container_parent").css("display", "none")
        $("#reciprocal_nodes").prop("checked", false)
        $("#reciprocal_nodes").prop("disabled", true)
        this.getContext()
        this.populate_Settings_Dropdowns(also_generate_graph);
        this.change_network_dd()

      }, 500)





    }
    if (network_tab == 'individual') {
      this.general_tab_active = false

      this.teams_tab_active = false

      this.individual_tab_active = true
      // $("#general_tab").css("background-color", "white")
      // $("#general_tab").css("color", "rgb(119, 118, 118)")
      // $("#general_tab").css("border-top", "black solid 1px")


      // $("#teams_tab").css("background-color", "white")
      // $("#teams_tab").css("color", "rgb(119, 118, 118)")
      // $("#teams_tab").css("border-top", "black solid 1px")


      // $("#individual_tab").css("background-color", "#002a57")
      // $("#individual_tab").css("color", "white")
      // $("#individual_tab").css("border-top", "none")
      setTimeout(() => {
    
        $("#individual_tab").css("box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#individual_tab").css("-webkit-box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#individual_tab").css("-moz-box-shadow", "0px -1px 6px 0px rgba(0,0,0,0.75);")
        $("#individual_tab").css("border-top", "solid gray")
        $("#individual_tab").css("border-top", "solid gray")

        $("#general_tab").css("box-shadow", "none")
        $("#general_tab").css("-webkit-box-shadow", "none")
        $("#general_tab").css("-moz-box-shadow", "none")
        $("#general_tab").css("border-top", "none")

        $("#teams_tab").css("box-shadow", "none")
        $("#teams_tab").css("-webkit-box-shadow", "none")
        $("#teams_tab").css("-moz-box-shadow", "none")
        $("#teams_tab").css("border-top", "none")



        this.getContext()
        $("#reciprocal_nodes").prop("checked", false)
        $("#reciprocal_nodes").prop("disabled", true)
        $(".team_dd_caption").html('Individual :&nbsp;<span class="material-symbols-outlined" style="cursor:pointer; font-size: 15px;text-align: end;grid-column: 1;grid-row: 3;')
        $("#advanced_settings_container_parent").css("display", "none")

        this.populate_Settings_Dropdowns(also_generate_graph);
        this.change_network_dd()

      }, 500)



    }

  }


  change_Advanced_Team_Type() {
    var selectedoption1 = $("#team_type_2").children("option:selected").val();
    $("#team_type_2  option[value='" + selectedoption1 + "']").prop("selected", true);

    //emptying team dropdowns in advanced settings
    $("#team_dd_1").html("");
    $("#team_dd_2").html("");


    //sorting values for team dropdowns in advanced settings
    var sorteddropdown2data = _.sortBy(this.dropdown_reportfilter[String($("#team_type_2").children("option:selected").val())], (obj) => { return obj["entity"]["name"] })

    //populating values
    if ($("#team_type_2").children("option:selected").val() != "org") {
      for (let j in sorteddropdown2data) {
        $('#team_dd_1').append("<option style = 'background-color:white' value ='" + sorteddropdown2data[j]["entity"]["value"] + "'>" + sorteddropdown2data[j]["entity"]["name"] + "</option>");
        $('#team_dd_2').append("<option style = 'background-color:white' value ='" + sorteddropdown2data[j]["entity"]["value"] + "'>" + sorteddropdown2data[j]["entity"]["name"] + "</option>");


      }
    }
    $("#node_color_dd").val($("#team_type_2 option:selected").attr("childclassvalue"))




  }
  change_specificEntities_checkBox() {
    //if checkbox to show two specific entities is checked then enable dropdowns in advanced settings and disable grouping as single node
    console.log("two specific teams graph check ",$("#two_specific_entities_check").prop("checked"))
    if ($("#two_specific_entities_check").prop("checked") == true) {
      this.update_node_slider_two_entities = true
      $("#team_type_2").prop("disabled", false);
      $("#team_dd_1").prop("disabled", false)
      $("#team_dd_2").prop("disabled", false)
      $('#group_as_single_node_check').prop("disabled", true)
      if (this.n_type_url_global == "combined") {
        this.graph_name = "main_combined";

      }
      else if (this.n_type_url_global == "personal") {
        this.graph_name = "main_personal";

      }
      else if (this.n_type_url_global == "professional") {
        this.graph_name = "main_professional";

      }
      else if (this.n_type_url_global == "main") {
        var study_id = jwtDecode(this.getUrlParameter("uid"))["study_id"]
        this.graph_name = "main_" + study_id;

      }
      else {
        this.graph_name = this.n_type_url_global;
      }
      this.two_specific_entities_boolean = true
      this.last_selected_node_color_value = String($("#node_color_dd").val())
      console.log("this.last_selected_node_color_value", this.last_selected_node_color_value)
      this.change_Advanced_Team_Type()
    }
    else {
      $("#team_type_2").prop("disabled", true);
      $("#team_dd_1").prop("disabled", true)
      $("#team_dd_2").prop("disabled", true)
      $('#group_as_single_node_check').prop("disabled", false)
      this.two_specific_entities_boolean = false
      $("#node_color_dd").val(this.last_selected_node_color_value)

    }
  }

  node_slider_change() {
    console.log("change in node slider");
    setTimeout(() => {
      $("#loadingicon").css("display", "flex")
      $(".lds-hourglass").css("display", "block");
    }, 200)
    // assign new value from slider
    var nodeSlider_val = $("#_nodeSlider").val();
    $("#_nodeCount").html(String(nodeSlider_val));
    //reload the graph
    this.load(parseInt(String($("#_nodeSlider").val())), this.sortedNodesList, this.edgeList, this.max_degree, this.min_degree, true);

  }
  change_leadership_check() {
    console.log($("#leadership_team").prop("checked"))
    if ($("#leadership_team").prop("checked")) {
      this.show_leadership_team = true
    }
    else if (!$("#leadership_team").prop("checked")) {
      this.show_leadership_team = false

    }
  }


  //start generation of graph 
  start_graph_generation() {
    var timestamp_start = new Date().getTime()
    //hide the settings side bar
    this.hide_sidenav();
    //show loading icon
    $("#cy").html("");
    $("#loadingicon").css("display", "flex")
    $(".lds-hourglass").css("display", "block");
    $(".hide_before_load").css("display", "none");
    //assign team type and team
    this.team_type = String($("#team_type_dd").children("option:selected").val());
    this.team = String($("#team_dd").children("option:selected").val());
    if ($("#leadership_team").prop("checked")) {
      this.show_leadership_team = true
    }
    else if (!$("#leadership_team").prop("checked")) {
      this.show_leadership_team = false

    }

    if (this.general_tab_active) {


      //graph heading according to team type selection
      var network_text = $("#network_dd").children("option:selected").text() + " Network"
      if ($("#network_dd").children("option:selected").val() == "main") {
        network_text = "All Networks"
      }
      if (this.team_type == 'org' && this.g_type_url_global != "rm_team") {
        $("#graph_heading").html("Your Organization" + " Graph (" + network_text + ")");
        if (this.show_leadership_team) {
          $("#graph_heading").html("Your Organization - Leadership " + " Graph (" + network_text + ")");

        }
        if (this.team != 'none') {
          $("#graph_heading").html("Your Organization Grouped by " + $("#team_dd").children("option:selected").text() + " Graph (" + network_text + ")");
          if (this.collab_bet_entity_highlight) {
            $("#graph_heading").html("Your Organization Grouped by " + $("#team_dd").children("option:selected").text() + " Graph (" + network_text + "). Showing connections for " + this.entity_highlight_name)
          }
        }
      }
      if (this.team_type != 'org' && this.g_type_url_global != "rm_team") {
        $("#graph_heading").html("Your " + $("#team_type_dd").children("option:selected").text() + " : " + $("#team_dd").children("option:selected").text() + " Graph (" + network_text + ")");
        if (this.show_leadership_team) {
          $("#graph_heading").html("Your " + $("#team_type_dd").children("option:selected").text() + " : " + $("#team_dd").children("option:selected").text() + " - Leadership Graph (" + network_text + ")");

        }
        if (this.collab_within_entity_highlight) {
          $("#graph_heading").html("Your " + $("#team_type_dd").children("option:selected").text() + " : " + $("#team_dd").children("option:selected").text() + " Graph (" + network_text + ") - Showing connections for " + this.entity_highlight_name);

        }
        if (this.collab_within_small_unit_show) {
          $("#graph_heading").html("Your " + $("#team_type_dd").children("option:selected").text() + " : "
            + $("#team_dd").children("option:selected").text()
            + " Graph (" + network_text + ") - Showing connections between peers of " + this.entity_type_highlight_small_unit + ":" + this.entity_highlight_name_small_unit);

        }
      }
      if (this.two_specific_entities_boolean && this.g_type_url_global != "rm_team") {
        $("#d_s_iso_nodes_check").prop("checked", false)
        this.show_isoNodes = 0
        $("#graph_heading").html("Graph showing connections between " + $("#team_type_2").children("option:selected").text() + ": " + $("#team_dd_1").children("option:selected").text() + " and " + $("#team_type_2").children("option:selected").text() + ": " + $("#team_dd_2").children("option:selected").text());
        if (this.team_type != 'org') {
          $("#graph_heading").html("Graph showing connections between " + $("#team_type_2").children("option:selected").text() + ": " + $("#team_dd_1").children("option:selected").text() + " and " + $("#team_type_2").children("option:selected").text() + ": " + $("#team_dd_2").children("option:selected").text() + " within "
            + $("#team_dd").children("option:selected").text());

        }
      }
      if (this.g_type_url_global == "rm_team") {
        $("#graph_heading").html(this.rm_name + "'s " + " " + network_text + "");

      }
      //assign network type 
      this.n_type_url_global = String($("#network_dd").children("option:selected").val());

      //for org cut create graph json name
      if ($("#team_type_dd").children("option:selected").val() == 'org' && this.g_type_url_global != "rm_team") {
        //if group by none
        if ($("#team_dd").children("option:selected").val() == 'none') {
          this.graph_name = String($("#network_dd").children("option:selected").val());
          console.log("graph name network", String($("#network_dd").children("option:selected").val()))
          if (this.graph_name == "combined") {
            this.graph_name = "main_combined"
            if (this.show_leadership_team) {
              this.graph_name = "main_leadership_combined"
            }
          }
          if (this.graph_name == "professional") {
            this.graph_name = "main_professional";
            if (this.show_leadership_team) {
              this.graph_name = "main_leadership_professional"
            }
          }
          if (this.graph_name == "personal") {
            this.graph_name = "main_personal";
            if (this.show_leadership_team) {
              this.graph_name = "main_leadership_personal"
            }
          }
          if (this.graph_name == "main") {
            var study_id = jwtDecode(this.getUrlParameter("uid"))["study_id"]
            this.graph_name = "main_" + study_id;
            if (this.show_leadership_team) {
              this.graph_name = "main_leadership_main"
            }
          }

          if (this.show_leadership_team && String($("#network_dd").children("option:selected").val()) != "main") {
            this.graph_name = "main_leadership_" + String($("#network_dd").children("option:selected").val());
          }
          console.log("graph name", this.graph_name);

        }
        // if group by team type
        else {
          if ($("#group_as_single_node_check").prop("checked") == true) {
            this.group_as_single_node = 1
            this.graph_name = 'main_groupby_' + String($("#team_dd").children("option:selected").val()).toLowerCase() + "_" + $("#network_dd").children("option:selected").val()

            if (String($("#network_dd").children("option:selected").val()) == "main") {
              this.graph_name = 'main_groupby_' + String($("#team_dd").children("option:selected").val()).toLowerCase() + "_combined"

            }

          }
          else {
            this.graph_name = "main_combined"

            this.group_as_single_node = 0

          }
        }
      }
      //for other cuts create graphjson name
      else if ($("#team_type_dd").children("option:selected").val() != 'org' && this.g_type_url_global != "rm_team") {
        this.graph_name = String($("#team_dd").children("option:selected").val()).toLowerCase() + "_" + $("#team_type_dd").children("option:selected").val() + "_org_" + $("#network_dd").children("option:selected").val()
        if (this.show_leadership_team) {
          this.graph_name = String($("#team_dd").children("option:selected").val()).toLowerCase() + "_" + $("#team_type_dd").children("option:selected").val() + "_org_leadership_" + $("#network_dd").children("option:selected").val()
        }
        // if (String($("#network_dd").children("option:selected").val()) == "main") {
        //   var study_id = jwtDecode(this.getUrlParameter("uid"))["study_id"]
        //   this.graph_name = "main_"+study_id;
        // }
      }

      if (this.g_type_url_global == "rm_team") {
        this.graph_name = this.rm_email + "_rm_org_" + $("#network_dd").children("option:selected").val()
      }
      if (this.g_type_url_global == "trust") {
        var study_id = jwtDecode(this.getUrlParameter("uid"))["study_id"]
        this.graph_name = "main_" + study_id;
      }



    }

    if (this.teams_tab_active) {
      this.graph_name = String($("#team_dd option:selected").attr('value')) + "_" + String($("#network_dd option:selected").attr('value'))
      if (parseInt(this.config_json_data["settings"]["study_id"]) >= 739 ) {
        this.graph_name = String($("#team_dd option:selected").attr('value')) + "_rm_org_" + String($("#network_dd option:selected").attr('value'))
      }
      if (parseInt(this.config_json_data["settings"]["study_id"]) == 20 ) {
        this.graph_name = String($("#team_dd option:selected").attr('value')) + "_rm_org_" + String($("#network_dd option:selected").attr('value'))
      }  
      console.log("graph_name ", this.graph_name)
      $("#graph_heading").html(String($("#team_dd option:selected").text()) + "'s " + " " + $("#network_dd").children("option:selected").text() + " Network");

    }
    if (this.individual_tab_active) {
      this.graph_name = String($("#team_dd option:selected").attr('value')) + "_" + String($("#network_dd option:selected").attr('value'))

      console.log("graph_name ", this.graph_name)
      $("#graph_heading").html(String($("#team_dd option:selected").text()) + "'s " + " " + $("#network_dd").children("option:selected").text() + " Network");


    }

    //fetch graph positions json according to the layout
    this.getGraphPositions();

    var timestamp_end = new Date().getTime()
    console.log("Time taken for start_graph_generation ", (timestamp_end - timestamp_start))

    $(document).on("mouseenter", "#open_options_pane", (e) => {
      $("#options_pane").css("display", "block");

      $("#options_pane").css("visibility", "visible");
      $("#options_pane_container").css("height", "300px");

      $("#options_pane_container").css("width", "850px");

      $("#options_pane").css("width", "800px");
      $("#options_pane").css("opacity", "1");
      $("#options_pane").css("left", "10px");


    })
    $(document).on("mouseenter", "#options_pane", (e) => {
      $("#options_pane").css("visibility", "visible");

      $("#options_pane_container").css("width", "850px");

      $("#options_pane").css("width", "800px");
      $("#options_pane").css("opacity", "1");
      $("#options_pane").css("left", "10px");

    })

    $(document).on("mouseleave", "#options_pane", (e) => {
      $("#options_pane").css("width", "0px");

      $("#options_pane").css("opacity", "0");
      $("#options_pane").css("left", "30px");
      $("#options_pane_container").css("height", "0px");

      $("#options_pane_container").css("width", "50px");
      $("#options_pane").css("visibility", "hidden");

      $("#options_pane").css("display", "none");

      // $("#options_pane_container").css("display","none");




    })


    $(document).on("mouseleave", "#open_options_pane", (e) => {
      $("#options_pane").css("visibility", "hidden");

      $("#options_pane").css("width", "0px");

      $("#options_pane").css("opacity", "0");
      $("#options_pane").css("left", "30px");
      $("#options_pane_container").css("width", "50px");



    })

  }

  change_network(network_name) {
    $("#combined_element").css("background-color", "");
    $("#work_element").css("background-color", "");
    $("#innovation_element").css("background-color", "");
    $("#expertise_element").css("background-color", "");
    $("#trust_element").css("background-color", "");
    $("#energy_element").css("background-color", "")
    $("#combined_element").css("color", "");
    $("#work_element").css("color", "");
    $("#innovation_element").css("color", "");
    $("#expertise_element").css("color", "");
    $("#trust_element").css("color", "");
    $("#energy_element").css("color", "")


    if (network_name == 'combined') {
      $("#combined_element").css("background-color", " #027606");
      $("#combined_element").css("color", " #FFF")
      $("#hub_element").prop('disabled', false);
      $("#gatekeeper_element").prop('disabled', false);
      $("#pulsetaker_element").prop('disabled', false);
      $("#influencer_element").prop('disabled', false);
      $("#power_players_element").prop('disabled', false);
      $("#hub_element").css('pointer-events', "");
      $("#gatekeeper_element").css('pointer-events', "");
      $("#pulsetaker_element").css('pointer-events', "");
      $("#influencer_element").css('pointer-events', "");
      $("#power_players_element").css('pointer-events', "");
    }
    else if (network_name == 'work') {
      $("#work_element").css("background-color", "#0895cc")
      $("#work_element").css("color", " #FFF")

    }
    else if (network_name == 'innovation') {
      $("#innovation_element").css("background-color", "#001ec7")
      $("#innovation_element").css("color", " #FFF")

    }
    else if (network_name == 'expertise') {
      $("#expertise_element").css("background-color", "#e47900")
      $("#expertise_element").css("color", " #FFF")

    }
    else if (network_name == 'trust') {
      $("#trust_element").css("background-color", "#b6d600")
      $("#trust_element").css("color", " #FFF")

    }
    else if (network_name == 'energy') {
      $("#energy_element").css("background-color", "#41ff37")
      $("#energy_element").css("color", " #FFF")

    }

    if (network_name == 'work' || network_name == 'innovation' || network_name == 'expertise' || network_name == 'trust' || network_name == 'energy') {
      $("#hub_element").prop('disabled', true);
      $("#gatekeeper_element").prop('disabled', true);
      $("#pulsetaker_element").prop('disabled', true);
      $("#influencer_element").prop('disabled', true);
      $("#power_players_element").prop('disabled', true);
      $("#hub_element").css('pointer-events', "none");
      $("#gatekeeper_element").css('pointer-events', "none");
      $("#pulsetaker_element").css('pointer-events', "none");
      $("#influencer_element").css('pointer-events', "none");
      $("#power_players_element").css('pointer-events', "none");


    }

    $("#network_dd").val(network_name).trigger('change');
    this.start_graph_generation()
  }

  change_module(module_name) {

    $("#hub_element").css("background-color", "");
    $("#pulsetaker_element").css("background-color", "");
    $("#gatekeeper_element").css("background-color", "");
    $("#influencer_element").css("background-color", "");
    $("#silo_element").css("background-color", "");
    $("#isolated_element").css("background-color", "")
    $("#none_element").css("background-color", "")
    $("#power_players_element").css("background-color", "")


    $("#hub_element").css("color", "");
    $("#pulsetaker_element").css("color", "");
    $("#gatekeeper_element").css("color", "");
    $("#influencer_element").css("color", "");
    $("#silo_element").css("color", "");
    $("#isolated_element").css("color", "")
    $("#none_element").css("color", "")
    $("#power_players_element").css("color", "")


    if (module_name == 'hub') {
      $("#hub_element").css("background-color", "#7ec44f");
      $("#hub_element").css("color", " #FFF")

    }
    else if (module_name == 'pulse_taker') {
      $("#pulsetaker_element").css("background-color", "#ffc851");
      $("#pulsetaker_element").css("color", " #FFF")

    }
    else if (module_name == 'gate_keeper') {
      $("#gatekeeper_element").css("background-color", "#ff9050");
      $("#gatekeeper_element").css("color", " #FFF")

    }
    else if (module_name == 'silo') {
      $("#silo_element").css("background-color", "#b9015d");
      $("#silo_element").css("color", " #FFF")

    }
    else if (module_name == 'disc_emp') {
      $("#isolated_element").css("background-color", "#ff0000")
      $("#isolated_element").css("color", " #FFF")

    }
    else if (module_name == 'none') {
      $("#none_element").css("background-color", "#000")
      $("#none_element").css("color", " #FFF")

    }
    else if (module_name == 'influencer') {
      $("#influencer_element").css("background-color", "#ff6f00")
      $("#influencer_element").css("color", " #FFF")

    }
    else if (module_name == 'power_players') {
      $("#power_players_element").css("background-color", "#5c5eff")
      $("#power_players_element").css("color", "#FFF")

    }


    if (module_name == "silo" || module_name == 'disc_emp' || module_name == 'none') {
      $("#work_element").prop("disabled", false);
      $("#innovation_element").prop("disabled", false);
      $("#expertise_element").prop("disabled", false);
      $("#trust_element").prop("disabled", false);
      $("#energy_element").prop("disabled", false);
      $("#work_element").css("pointer-events", "");
      $("#innovation_element").css("pointer-events", "");
      $("#expertise_element").css("pointer-events", "");
      $("#trust_element").css("pointer-events", "");
      $("#energy_element").css("pointer-events", "")
    }
    else if (module_name == "hub" || module_name == 'pulse_taker' || module_name == 'gate_keeper'
      || module_name == 'influencer' || module_name == 'power_players') {
      $("#work_element").prop("disabled", true);
      $("#innovation_element").prop("disabled", true);
      $("#expertise_element").prop("disabled", true);
      $("#trust_element").prop("disabled", true);
      $("#energy_element").prop("disabled", true)
      $("#work_element").css("pointer-events", "none");
      $("#innovation_element").css("pointer-events", "none");
      $("#expertise_element").css("pointer-events", "none");
      $("#trust_element").css("pointer-events", "none");
      $("#energy_element").css("pointer-events", "none")
    }

    this.g_type_url_global = module_name;
    this.getContext().then(() => {
      this.populate_Settings_Dropdowns()
    })
  }

  getGraphPositions() {
    var timestamp_start = new Date().getTime()
    //assign layout type 
    var layout_type = '';
    if ($("#layout_dd").children("option:selected").text() == 'Forceatlas2-Noverlap') {
      layout_type = 'Forceatlas2-Noverlap'
    }
    else if ($("#layout_dd").children("option:selected").text() == "Fruchterman-Reingold") {
      layout_type = "Fruchterman-Reingold"

    }
    else if ($("#layout_dd").children("option:selected").text() == "Formal Structure") {
      this.getGraphJson();

      return

    }

    var graph_name = this.graph_name
    //get graph positions 
    if (this.graph_name == "main") graph_name = "main_" + this.config_json_data["settings"]["study_id"]
    this.appservice.getGraphPositions(graph_name, this.unique_hash, layout_type).then(
      (data) => {
        this.graph_positions_found = true;
        graph_positions = data;
        console.log("positions ", graph_positions)
        var timestamp_end = new Date().getTime()
        //fetch graph json
        this.getGraphJson();
        console.log("Time taken for get graph positions ", (timestamp_end - timestamp_start))
      },
      (error) => {
        this.graph_positions_found = false;
        console.log("no positions ", graph_name, error)
        graph_positions = []
        var timestamp_end = new Date().getTime()
        console.log("Time taken for get graph positions ", (timestamp_end - timestamp_start))
        this.getGraphJson();

      }
    );
  }
  open_close_advanced_settings() {
    $("#advanced_settings_container").toggle()
    if ($("#advanced_setting_arrow").text() == "keyboard_arrow_down") {
      $("#advanced_setting_arrow").text("keyboard_arrow_up")
    }
    else {
      $("#advanced_setting_arrow").text("keyboard_arrow_down")
    }
  }

  getGraphJson() {
    var timestamp_start = new Date().getTime()
    if (this.general_tab_active) {
      //get graph cyto json
      this.appservice.getGraph(this.unique_hash, this.graph_name).subscribe(graph => {
        this.last_selected_node_color_value = String($("#node_color_dd").val())
        console.log("returned graph", graph);
        $("#layout_dd").prop("disabled", false);
        $("#layout_dd_help").attr("data-tooltip", "This is the layout of the network graph based on some standard algorithms.");

        //if number of nodes are less than 20 then we are not using any layout, they will be placed in a circle/euler position
        if (graph["graph"]) {
          if (graph["graph"]["elements"]["nodes"].length <= 20) {
            $("#layout_dd").prop("disabled", true);
            $("#layout_dd_help").css("display", "inline-block");
            $("#layout_dd_help").attr("data-tooltip", "Layouts are not applied for this selection. Generate a new graph and look for its layouts.");

          }
          for (let i of Object.keys(graph["graph"]["elements"]["nodes"][0]["data"])) {
            if (i == 'gate_keeper') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='gate_keeper'>Connectors/GateKeepers</option>");
              this.who_section_in_graph_json = 1
            }

            if (i == 'hub') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='hub'>Hub</option>");
              this.who_section_in_graph_json = 1

            }
            if (i == 'influencer') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='influencer'>Influencers</option>");
              this.who_section_in_graph_json = 1

            }
            if (i == 'silo') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");
              this.who_section_in_graph_json = 1

            }
            if (i == 'disconnected') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='disconnected'>Disconnected Employees</option>");
              this.who_section_in_graph_json = 1

            }
            // if(i == 'collab_overload'){
            //   $('#node_color_dd').append("<option style = 'background-color:white' value ='collab_overload'></option>");

            // }
            // if(i == 'silo'){
            //   $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");

            // }
          }
        }
        else if (graph["elements"]) {
          if (graph["elements"]["nodes"].length <= 20) {
            $("#layout_dd").prop("disabled", true);
            $("#layout_dd_help").css("display", "inline-block");
            $("#layout_dd_help").attr("data-tooltip", "Layouts are not applied for this selection. Generate a new graph and look for its layouts.");

          }
          for (let i of Object.keys(graph["elements"]["nodes"][0]["data"])) {
            if (i == 'gate_keeper') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='gate_keeper'>Connectors/GateKeepers</option>");
              this.who_section_in_graph_json = 1
            }

            if (i == 'hub') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='hub'>Hub</option>");
              this.who_section_in_graph_json = 1

            }
            if (i == 'influencer') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='influencer'>Influencers</option>");
              this.who_section_in_graph_json = 1

            }
            if (i == 'silo') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");
              this.who_section_in_graph_json = 1

            }
            if (i == 'disconnected') {
              $('#node_color_dd').append("<option style = 'background-color:white' value ='disconnected'>Disconnected Employees</option>");
              this.who_section_in_graph_json = 1

            }
            // if(i == 'collab_overload'){
            //   $('#node_color_dd').append("<option style = 'background-color:white' value ='collab_overload'></option>");

            // }
            // if(i == 'silo'){
            //   $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");

            // }
          }
        }

        $("#node_color_dd > option[value='gate_keeper']").remove();
        $("#node_color_dd> option[value='critical_gate_keeper']").remove();
        $("#node_color_dd> option[value='hub']").remove();
        $("#node_color_dd> option[value='influencer']").remove();
        $("#node_color_dd> option[value='silo']").remove();
        $("#node_color_dd> option[value='disconnected']").remove();
        $("#node_color_dd> option[value='burnout']").remove();








        $("#node_color_dd ").val(this.last_selected_node_color_value)

        // localStorage.setItem('graph', JSON.stringify(graph));
        // // this.matdialogRef.close();
        // this.loadGraph = JSON.parse(localStorage.getItem('graph'));

        this.loadGraph = graph


        if (this.loadGraph == null) {
          this.loading = false;
          console.log("null loadgraph");

        } else {
          console.log(this.loadGraph);
          //passing the graph to loadManualData
          this.loadManualData(this.loadGraph);
          this.loading = false;
        }
        var timestamp_end = new Date().getTime()
        console.log("Time taken for get graph json ", (timestamp_end - timestamp_start))
      },
        (error) => {
          console.log(error)
          $("#secondDiv").css("display", "none");
          $("#errorDiv").css("display", "flex");
          return;
        }
      );
    }
    if (this.teams_tab_active) {
      //get graph cyto json
      var team_filename = this.graph_name
      console.log("team filename ",team_filename)
      this.appservice.getTeamGraph(this.unique_hash, this.graph_name).subscribe((graph) => {
        this.last_selected_node_color_value = String($("#node_color_dd").val())
        if (graph["graph"]) graph = graph["graph"]
        console.log("returned graph", graph);
        // $("#layout_dd").prop("disabled", true);
        // $("#layout_dd_help").attr("data-tooltip", "A general layout is applied on this graph.");

        //if number of nodes are less than 20 then we are not using any layout, they will be placed in a circle/euler position
        // if (graph["elements"]["nodes"].length <= 20) {
        //   $("#layout_dd").prop("disabled", true);
        //   $("#layout_dd_help").css("display", "inline-block");
        //   $("#layout_dd_help").attr("data-tooltip", "Layouts are not applied for this selection. Generate a new graph and look for its layouts.");

        // }
        $("#node_color_dd > option[value='gate_keeper']").remove();
        $("#node_color_dd> option[value='critical_gate_keeper']").remove();
        $("#node_color_dd> option[value='hub']").remove();
        $("#node_color_dd> option[value='influencer']").remove();
        $("#node_color_dd> option[value='silo']").remove();
        $("#node_color_dd> option[value='disconnected']").remove();
        $("#node_color_dd> option[value='burnout']").remove();





        for (let i of Object.keys(graph["elements"]["nodes"][0]["data"])) {
          if (i == 'gate_keeper') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='gate_keeper'>Connectors/GateKeepers</option>");
            this.who_section_in_graph_json = 1
          }

          if (i == 'hub') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='hub'>Hub</option>");
            this.who_section_in_graph_json = 1

          }
          if (i == 'influencer') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='influencer'>Influencers</option>");
            this.who_section_in_graph_json = 1

          }
          if (i == 'silo') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");
            this.who_section_in_graph_json = 1

          }
          if (i == 'disconnected') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='disconnected'>Disconnected Employees</option>");
            this.who_section_in_graph_json = 1

          }
          // if(i == 'collab_overload'){
          //   $('#node_color_dd').append("<option style = 'background-color:white' value ='collab_overload'></option>");

          // }
          // if(i == 'silo'){
          //   $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");

          // }
        }


        $("#node_color_dd ").val(this.last_selected_node_color_value)

        localStorage.setItem('graph', JSON.stringify(graph));
        // this.matdialogRef.close();
        this.loadGraph = JSON.parse(localStorage.getItem('graph'));
        if (this.loadGraph == null) {
          this.loading = false;
          console.log("null loadgraph");

        } else {
          console.log(this.loadGraph);
          //passing the graph to loadManualData
          this.loadManualData(this.loadGraph);
          this.loading = false;
        }
        var timestamp_end = new Date().getTime()
        console.log("Time taken for get graph json ", (timestamp_end - timestamp_start))
      },
        (error) => {
          console.log(error)
          $("#secondDiv").css("display", "none");
          $("#errorDiv").css("display", "flex");
          return;
        }
      );
    }
    if (this.individual_tab_active) {
      //get graph cyto json
      this.appservice.getIndividualGraph(this.unique_hash, this.graph_name).subscribe((graph) => {
        this.last_selected_node_color_value = String($("#node_color_dd").val())
        console.log("returned graph", graph);
        $("#layout_dd").prop("disabled", true);
        $("#layout_dd_help").attr("data-tooltip", "A general layout is applied on this graph.");

        //if number of nodes are less than 20 then we are not using any layout, they will be placed in a circle/euler position
        if (graph["elements"]["nodes"].length <= 20) {
          $("#layout_dd").prop("disabled", true);
          $("#layout_dd_help").css("display", "inline-block");
          $("#layout_dd_help").attr("data-tooltip", "Layouts are not applied for this selection. Generate a new graph and look for its layouts.");

        }
        $("#node_color_dd > option[value='gate_keeper']").remove();
        $("#node_color_dd> option[value='critical_gate_keeper']").remove();
        $("#node_color_dd> option[value='hub']").remove();
        $("#node_color_dd> option[value='influencer']").remove();
        $("#node_color_dd> option[value='silo']").remove();
        $("#node_color_dd> option[value='disconnected']").remove();
        $("#node_color_dd> option[value='burnout']").remove();





        for (let i of Object.keys(graph["elements"]["nodes"][0]["data"])) {
          if (i == 'gate_keeper') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='gate_keeper'>Connectors/GateKeepers</option>");
            this.who_section_in_graph_json = 1
          }

          if (i == 'hub') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='hub'>Hub</option>");
            this.who_section_in_graph_json = 1

          }
          if (i == 'influencer') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='influencer'>Influencers</option>");
            this.who_section_in_graph_json = 1

          }
          if (i == 'silo') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");
            this.who_section_in_graph_json = 1

          }
          if (i == 'disconnected') {
            $('#node_color_dd').append("<option style = 'background-color:white' value ='disconnected'>Disconnected Employees</option>");
            this.who_section_in_graph_json = 1

          }
          // if(i == 'collab_overload'){
          //   $('#node_color_dd').append("<option style = 'background-color:white' value ='collab_overload'></option>");

          // }
          // if(i == 'silo'){
          //   $('#node_color_dd').append("<option style = 'background-color:white' value ='silo'>Silos</option>");

          // }
        }


        $("#node_color_dd ").val(this.last_selected_node_color_value)

        localStorage.setItem('graph', JSON.stringify(graph));
        // this.matdialogRef.close();
        this.loadGraph = JSON.parse(localStorage.getItem('graph'));
        if (this.loadGraph == null) {
          this.loading = false;
          console.log("null loadgraph");

        } else {
          console.log(this.loadGraph);
          //passing the graph to loadManualData
          this.loadManualData(this.loadGraph);
          this.loading = false;
        }
        var timestamp_end = new Date().getTime()
        console.log("Time taken for get graph json ", (timestamp_end - timestamp_start))
      },
        (error) => {
          console.log(error)
          $("#secondDiv").css("display", "none");
          $("#errorDiv").css("display", "flex");
          return;
        }
      );
    }

  }
  noverlapLayout(cy: any, options: { padding?: number; maxIterations?: number } = {}) {
    const padding = options.padding || 10; // Default padding
    const maxIterations = options.maxIterations || 500; // Max iterations to resolve overlaps
    const nodes = cy.nodes();

    // Function to get node position and size
    function getNodeBounds(node: any) {
      const pos = node.position();
      const width = node.width();
      const height = node.height();

      return {
        node,
        x: pos.x - width / 2 - padding,
        y: pos.y - height / 2 - padding,
        width: width + 2 * padding,
        height: height + 2 * padding,
      };
    }

    // Check for overlaps between two nodes
    function nodesOverlap(n1: any, n2: any) {
      return (
        n1.x < n2.x + n2.width &&
        n1.x + n1.width > n2.x &&
        n1.y < n2.y + n2.height &&
        n1.y + n1.height > n2.y
      );
    }

    // Resolve overlaps by moving nodes
    function resolveOverlaps() {
      let iteration = 0;
      let moved = true;

      while (moved && iteration < maxIterations) {
        moved = false;

        for (let i = 0; i < nodes.length; i++) {
          const n1 = getNodeBounds(nodes[i]);

          for (let j = i + 1; j < nodes.length; j++) {
            const n2 = getNodeBounds(nodes[j]);

            if (nodesOverlap(n1, n2)) {
              moved = true;

              // Displacement direction
              const dx = (n1.x + n1.width / 2) - (n2.x + n2.width / 2);
              const dy = (n1.y + n1.height / 2) - (n2.y + n2.height / 2);
              const distance = Math.max(Math.sqrt(dx * dx + dy * dy), 1); // Avoid divide by zero

              const moveBy = 5; // Small step size to avoid overshooting

              // Adjust positions to resolve overlap
              n1.node.position({
                x: n1.node.position('x') + (dx / distance) * moveBy,
                y: n1.node.position('y') + (dy / distance) * moveBy,
              });

              n2.node.position({
                x: n2.node.position('x') - (dx / distance) * moveBy,
                y: n2.node.position('y') - (dy / distance) * moveBy,
              });
            }
          }
        }

        iteration++;
      }
    }

    // Apply layout adjustment
    cy.startBatch();
    resolveOverlaps();
    cy.endBatch();
    this.isNoverlapLoading = false;
  }




  // return desired parameter from url
  getUrlParameter(sParam: string) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? '' : decodeURIComponent(sParameterName[1]);
      }
    }
    return '';
  };
  // Logic when enter key is hit in input [query] box
  onEnter(value: string) {
    // clear the set
    localStorage.clear();
    this.collectionOnRowClick.clear();
    // reset png
    this.download_img = false;
    this.imgTag.className = 'hide';

    // clear input field
    this.typed = "";
    // inputNotFocused used to show or hide the prediction box
    this.inputNotFocused();
    // fix entered query for spaces
    value = this.queryFixer(value);
    // lastResultCopy stores result for table conversion, if result =0 then use last saved ,as result can be empty on sort(if sort is invalid)
    this.lastResultCopy = this.result.length == 0 ? this.lastResultCopy : this.result;
    // clear last result and result count
    this.result = [];
    this.resultCount = '';
    // trim value and convert to lowercase
    // value = value.trim();
    value = value.toLocaleLowerCase();

    // save the value for global use
    this.value = value;
    // this.valueOri = value;

    // console.log("value = "+value+" : this.value = "+this.value);

    var query = this.queryBuider(value);
    // this.lastTyped = this.saveTopreviousFlag ? value : this.lastTyped;
    // this.saveTopreviousFlag = !this.saveTopreviousFlag;

    // used to show the selector value in result(json row) : ex if your query is "select gender=male" then row will show "male" if true for the row
    this.selectorsInQuery = query['selector'];
    console.log('abhi~tokenizer');
    console.log(query);



    if (query['type'] == 'load' && query['condition'][0] == '=' && query['arg'] != null && query['selector'][0] == 'study_id') {

      // <-ankit- Dialog Call For table [~abhi~] code updated, removed timer added afterClosed()

      console.log('study_id = ' + query['arg'][0].split(' ')[1]);

      // Ankit: getting two agrs and split to get them two different ones
      if (query['arg'][0].split(' ')[0] && query['arg'][0].split(' ')[1] != 'auto') {
        let dialogRef = this.dialog.open(DialogComponent, { minWidth: '80%', minHeight: '90vh', disableClose: true, data: { id: query['arg'][0].split(' ')[0] } });
        this.loading = true;

        // use of afterClosed() function to monitor dilog
        dialogRef.afterClosed().subscribe(result => {
          this.loadGraph = JSON.parse(localStorage.getItem('graph'));
          if (this.loadGraph == null) {
            this.loading = false;
          } else {
            console.log(this.loadGraph);
            // this.fileName = this.loadGraph.file_name;
            this.loadManualData(this.loadGraph);
            this.loading = false;
          }
        });
      } // Ankit: getting two agrs and split to get them, auto is the keyword
      else if (query['arg'][0].split(' ')[1] === 'auto') {
        this.appservice.getTableData(query['arg'][0].split(' ')[0]).subscribe(response => {
          console.log(response);
          response.forEach((element, index) => {
            // Ankit: delay to avoid errors and for proper polt
            setTimeout(() => {
              // Ankit: File name is initailised to used futher
              this.fileName = element.file_name;
              this.appservice.getGraph(query['arg'][0].split(' ')[0], this.fileName).subscribe(graph => {
                setTimeout(() => {
                  // Ankit: Callings different functions for automation
                  console.log('next graph ' + index);
                  // Calling loadMaualData for graph formation
                  this.loadManualData(graph);
                  // Ankit: tablebuilder() function
                  this.tablebuilder();
                  // exporttoexcel()
                  this.ExportTOExcel();
                  // Ankit: pngDownloader() to download graph image
                  this.pngDownloader();
                }, 3000 * index);

              });
            }, 1000);

          });
        });
      }

      // ->

      // //Destroy old cy instance
      // if (this.grphLoaded) {
      //   console.log("Is cy instance destroyed : " + this.cy.destroyed());
      //   if (!this.cy.destroyed()) {
      //     console.log("Destroying cy instance");
      //     this.cy.destroy();
      //     console.log("Destroyed cy instance : " + this.cy.destroyed());
      //   }

      // }
      // // console.log("Default load");
      // // this.setStyleFlag("2 edge informal marketing");
      // // this.graphFullData = this.study_id_4_all_signle_edge;
      // // this.querySelectors = _.without(Object.keys(this.graphFullData["nodes"][0]["data"]), "response");
      // // // this.edgeQuerySelectors = Object.keys(this.graphFullData["edges"][0]["data"]);
      // // this.genratePossibleArgList();
      // // this.load();
      // this.reSizeCount = 3;
      // this.reSize();
      // // spread layout after load
      // // this.cy.layout({
      // //     name: 'spread'
      // // }).run();
      // this.spreadLayout();
    } else if (query['type'] == 'init') {
      let style_for_graph = this.style_for_graph;
      // keep track of successful init or fails
      let log = [];
      if (query['condition'] != null && query['arg'] != null && query['selector'] != null && (query['condition'].length == query['arg'].length) && (query['arg'].length == query['selector'].length)) {

        _.each(query["selector"], function (selector, index) {
          console.log(index + " : " + selector)
          console.log(style_for_graph);
          if (selector in style_for_graph) {
            if (query['condition'][index] == '=') {
              style_for_graph[selector] = query["arg"][index];
              log.push(this.message["success"] + " [set] " + selector + " = " + query["arg"][index]);
            } else {
              log.push(this.message["error"] + " invalid condition : " + query['condition'][index]);
            }
          } else {
            log.push(this.message["error"] + " invalid selector : " + selector);
          }
        });


        this.value = log.join('<br>');
        console.log(this.value);

      } else if (query['condition'] == null) {

        console.log("Init config");
        let details = "";
        _.mapObject(style_for_graph, function (val, key) {
          details += (key + " : " + val + "<br>");
        });
        console.log(details);
        this.value = details;
      }
      console.log(style_for_graph);
      this.style_for_graph = style_for_graph;
    } else if (query['type'] == 'style' && query['selector'] == null && query['condition'][0] == "=" && query['condition'].length == 1 && query['arg'].length == 1) {
      console.log("setting style Flag");
      this.value = this.message["info"] + ' setting style Flag';
      if (query['arg'][0] == "all_single_edge" && this.grphLoaded) {
        this.genrateSingleEdgeGraph(this.cy.json());
      }
      this.setStyleFlag(query['arg'][0]);
      this.reloadGraph();

    } else if (query['type'] == '_top_id' && query['selector'] == null && query['condition'][0] == "=" && query['condition'].length == 1 && query['arg'].length == 1) {
      this.value = this.message["info"] + ' setting top ID list';
      // expected command =>_top_id = 1490, 1485, 1482,1478
      var _top_id = (query['arg'][0]).split(',');
      // check if arg is correct
      console.log("---------------");
      console.log("input : " + _top_id);
      console.log("check if _top_id is a valid list");
      _.each(_top_id, function (value: any, index) {
        value = value.trim();
        console.log("index [ " + index + " ] " + value + " length (" + value.length + ") is valid : " + !isNaN(value));
        if (isNaN(value)) {
          _top_id = [];
        } else {
          _top_id[index] = value;
        }
      });
      console.log("output : " + _top_id);
      console.log("---------------");

      if ((_top_id).length == 0) {
        this.value = this.message["error"] + ' _top_id list not valid';
      } else {
        this._top_id = _top_id;
      }

    } else if ((this.isValid(query['type'], query['selector'], query['condition'], query['arg'])) && (query['type'] == 'load' && query['selector'].length == 1 && query['condition'].length == 1 && query['arg'].length == 1 && query['condition'][0] == '=' && !isNaN((Number(query['arg'][0]))))) {

      this.loading = true;
      console.log("loading data for network = " + query['selector'][0] + " and study id = " + query['arg'][0]);

      this.appservice.dbcall(query['arg'][0], query['selector'][0])
        .subscribe((response: any) => {
          this.loading = false;
          // Destroy old cy instance
          if (this.grphLoaded) {
            console.log("Is cy instance destroyed : " + this.cy.destroyed());
            if (!this.cy.destroyed()) {
              console.log("Destroying cy instance");
              this.cy.destroy();
              console.log("Destroyed cy instance : " + this.cy.destroyed());
            }

          }

          this.survey_defaulter = response.null_nodes;
          console.log("loaded Data..");
          console.log(response);
          this.style_for_graph["graphSize"] = (response.elements["nodes"]).length;
          console.log("setting style automatically as per type of load");
          // <-------------------------------------
          if (query['selector'][0] == "all") {
            this.setStyleFlag("all");
          } else if (_.contains(this.querySelectorsForNetworkNames, query['selector'][0])) {
            this.setStyleFlag("network_7");
          } else {
            this.setStyleFlag("all_single_edge");
          }
          // -------------------------------------->
          // <--------------------------------------
          if ("response" in response.elements['nodes'][0]["data"]) {
            this.querySelectors = _.without(Object.keys(response.elements['nodes'][0]["data"]), "response");
          } else {
            this.querySelectors = Object.keys(response.elements['nodes'][1]["data"]);
          }
          // this.edgeQuerySelectors = Object.keys(this.graphFullData["edges"][0]["data"]);
          this.graphFullData = response.elements;

          console.log("---------------");
          console.log(response.elements);
          this.genratePossibleArgList();

          // ---------------------------------------->

          (response.elements["nodes"]).push({
            "data": {
              "id": "-1",
              "name": "-1",
              "value": "-1",
              "first_name": "other"
            }
          });

          this.load(response.elements);
          this.reSizeCount = 1;
          this.reSize();
          // console.log(this.querySelectors);
          // spread layout after load
          // this.cy.layout({
          // name: 'spread'
          //     }).run();


        });
      // <-----------------------------------------
      // save query to history [generally we save query with result only(table)]
      this.history.push({
        "query": this.value,
        "result": "graph loaded"
      });
      console.log("history : ");
      console.log(this.history);


      // ----------------------------------------->

    } else if (query['type'] == 'node' && query['selector'][0] in this.style_node_shape_type && query['condition'][0] == "=" && query['condition'].length == 1 && query['arg'].length == 1) {

      console.log("setting node style: ");
      this.setNodeShapeStyle(query['selector'][0], query['arg'][0]);
      this.reloadGraph();


    } else if (this.grphLoaded) {
      if (query['type'] == 'selectall') {
        console.log('select all nodes');
        this.lastSelection = this.cy.nodes();

        let allNodeAsResult = [];
        _.each(this.cy.nodes(), function (node: any) {
          allNodeAsResult.push(node._private.data);
        });
        this.lastResultCopy = allNodeAsResult;
      }
      if (query['type'] == 'get_top' && query['condition'] != null && query['condition'][0] == '=' && query['selector'] != null && query['arg'] != null) {
        let numericArgs = this.numericArgs;
        let graphSize = (this.cy.nodes()).length;
        let nodes = this.cy.nodes();

        _.each(query['selector'], function (val: any, index) {
          console.log('index : ' + index + " val : " + val);
          if (_.contains(numericArgs, val)) {
            let index = query['selector'].indexOf(val);
            console.log('numeric arg ' + index + " arg = " + query['arg'][index]);
            //
            let num = query['arg'][index];
            let attr = val;
            console.log('num : ' + num + " attr : " + attr);
            let percent = (num / 100) * graphSize

            let values = []
            _.each(nodes, function (data: any) {
              // console.log(data._private.data[attr]);
              values.push(parseFloat(data._private.data[attr]));

            });

            values.sort(function (a, b) {
              return a - b;
            });
            console.log(values);
            if (val.includes('rank')) {
              console.log('select for rank');
              query['arg'][index] = (values[query['arg'][index]]).toString();
              query['condition'][index] = "<";
            } else {
              values.sort(function (a, b) {
                return b - a;
              });
              console.log(values);
              query['arg'][index] = (values[query['arg'][index]]).toString();
              query['condition'][index] = ">";
            }



            //

          }
        });

        console.log(query);
        this.showResult(this.getNodes(query));
        this.reSizeCount = 1;
        this.reSize();
      }
      if (query['type'] == 'show_rank' && query['selector'] == null && query['condition'] == null) {

        if (this.lastSelection.length != 0) {
          let node_attr_to_check = this.style_for_graph['rank_by'];
          let values = [];
          _.each(this.lastSelection, function (val: any, index) {
            values.push(parseInt(val._private.data[node_attr_to_check]));
          });
          values.sort(function (a, b) {
            return b - a;
          });
          let max = values[0];
          console.log(values);
          console.log(max);
          this.style_for_graph['show_rank'] = true;
          // let top = (nodes_to_rank / 100) * total_nodes;
          // // console.log('select : '+ top);
          this.style_for_graph['rank_top'] = max;
          this.lastSelection.addClass('rank');
        }


      }
      if (query['type'] == 'select' || query['type'] == 'set') {
        if (this.isValid(query['type'], query['selector'], query['condition'], query['arg'])) {
          if (query['type'] == 'select' && query['condition'] != null && query['arg'] != null) {

            this.showResult(this.getNodes(query));
            this.reSizeCount = 1;
            this.reSize();
          } else if (query['type'] == 'set' && query['selector'][0] == 'node_rank' && query['condition'][0] == '=') {

            // let total_nodes = this.cy.nodes()['length'];
            let total_nodes = this.style_for_graph["graphSize"];
            // let nodes_to_rank = query['arg'][0];
            let nodes_to_rank = query['arg'][0];
            if (total_nodes >= 5 && (nodes_to_rank <= 100 || nodes_to_rank >= 1)) {
              this.value = this.message["info"] + 'set node_rank for top = ' + query['arg'][0] + ' %';
              this.style_for_graph['show_rank'] = true;
              let top = (nodes_to_rank / 100) * total_nodes;
              // console.log('select : '+ top);
              this.style_for_graph['rank_top'] = top;

              this.cy.nodes().addClass('rank');
            } else {
              this.value = this.message["error"] + 'atleast 5 nodes needed and  1=> % <=100 ';
            }


          } else if (query['type'] == 'set' && query['condition'] != null && query['condition'][0] == '=' && query['arg'] != null) {
            this.setStyle(query);

          }
        }
      } else if (query['type'] == 'deselect' && query['selector'] == null) {
        this.value = this.message["info"] + 'Deselected selected nodes';
        this.lastSelection = this.cy.nodes();
      } else if (query['type'] == 'reload' && query['selector'] == null) {
        this.value = this.message["info"] + ' Reload last loaded graph';
        this.load();
      } else if (query['type'] == 'undo' && query['selector'] == null) {
        if (this.history_of_nodes_removed.length) {
          this.value = this.message["info"] + ' Undo last';
          this.history_of_nodes_removed.pop().restore();
        } else {
          this.value = this.message["info"] + ' Nothing to restore';
        }
      } else if (query['type'] == 'isolated' && query['selector'] == null) {
        let orphans = this.cy.collection();
        _.each(this.cy.nodes(), function (node: any) {
          if ((node._private['edges']).length == 0) {
            orphans.merge(node);
          }
        });
        // console.log(orphans);
        this.value = this.message["info"] + ' Isolated nodes in Graph [loaded] ';
        // this.showResult(this.cy.nodes().difference(this.cy.$(this.nodesWithConnection)));
        // this.lastSelection = this.cy.nodes().difference(this.cy.$(this.nodesWithConnection));
        this.showResult(orphans);
        this.lastSelection = orphans;
      } else if (query['type'] == 'graph_info' && query['selector'] == null) {
        let nodes_len = (this.cy.nodes()).length
        let edge_len = (this.cy.edges()).length
        this.value = this.message["info"] + ' Nodes : ' + nodes_len + " edges : " + edge_len;
        this.createNotification("info", ' Nodes : ' + nodes_len + " edges : " + edge_len);
      } else if (query['type'] == 'cluster' && query['selector'] == null) {
        this.value = this.message["info"] + ' cluster coloring by frequency ';
        // console.log(clusters);
        var clusters = this.cy.elements().mcl({
          attributes: [
            function (edge) {
              return edge.data('frequency');
            }
          ]
        });
        // console.log(clusters);
        _.each(clusters, function (ele: any) {

          var letters = '0123456789ABCDEF';
          var color = '#';
          for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          console.log("--------");
          console.log(ele.length);
          console.log(color);

          _.each(ele, function (node: any) {
            console.log(node);
            node.style({
              'background-color': color
            });
          });

          console.log("--------");
        });

        console.log('height : ' + this.cy.height());
        console.log('width : ' + this.cy.width());
        console.log('extent : ' + this.cy.extent());

      } else if (query['type'] == 'toogle_label') {
        this.value = this.message["info"] + ' Toogle label hide/visible';
        this.toogleLabel();
      } else if (query['type'] == 'hide') {
        console.log('hiding selected nodes ');
        this.value = this.message["info"] + ' hiding selected nodes ';
        if (query['type'] == 'hide' && query['selector'] != null && query['condition'][0] != "" && query['arg'] != null) {
          // remove as per query [directly without select]
          // hide first_name = abc
          this.cy.filter(this.getNodes(query)).style({
            'display': 'none'
          });
        } else {
          this.cy.filter(this.lastSelection).style({
            'display': 'none'
          });
        }
        this.hiddenNodes = this.lastSelection;
      } else if (query['type'] == 'show' || query['type'] == 'unhide') {
        // console.log('unhide hidden nodes ');
        this.value = this.message["info"] + ' unhide hidden nodes ';
        this.hiddenNodes.style({
          'display': 'element'
        });
      } else if (query['type'] == 'delete' || query['type'] == 'remove') {
        this.value = this.message["info"] + ' Deleted selected nodes';

        if (query['type'] == 'remove' && query['selector'] != null && query['arg'] == null) {
          console.log(query['selector']);
          if (query['selector'][0] == '-i') {
            console.log(this.lastSelection);
            this.history_of_nodes_removed.push(this.cy.remove(this.cy.nodes().difference(this.lastSelection)));
            this.lastSelection = this.cy.nodes();
            console.log('remive -i');
          } else {
            // remove as per query [directly without select]
            this.history_of_nodes_removed.push(this.cy.remove(this.getNodes(query)));
          }

        } else {
          // delete last selected
          this.history_of_nodes_removed.push(this.cy.remove(this.lastSelection));
        }
        // change last selected nodes back to remaining graph so that styles can be applied in next command like set node_size =20
        this.lastSelection = this.cy.nodes();
        console.log("History of Nodes removed");
        console.log(this.history_of_nodes_removed);
      } else if (query['type'] == 'invert') {
        this.value = this.message["info"] + ' Invert selected nodes';
        // console.log("Invert : ( Total Nodes ) " + this.cy.nodes().size() + " - " + this.lastSelection.size() + " = " + this.cy.nodes().difference(this.lastSelection).size());
        this.lastSelection = this.cy.nodes().difference(this.lastSelection);
        // show the selected
        this.lastSelection.flashClass('querySelect', 5000);
        this.showResult(this.lastSelection);
      } else if (query['type'] == 'sort') {
        this.value = this.message["info"] + ' sorting nodes';
        this.sort(query);
        // console.log(this.lastResultCopy);
      } else if (query['type'] == 'top') {
        this.value = this.message["info"] + ' sorting nodes';
        this.getTop(query);

      } else if (query['type'] == 'do_all') {
        // this.automate();
      } else if (query['type'] == 'download_as_png') {

        // Ankit: pngDownloader() function called
        this.pngDownloader();
        // if (this.grphLoaded) {
        //   this.imgTag.className = 'show';
        //   this.value = this.message["success"] + ' captured graph as PNG ~abhi';
        //   this.download_img = true;
        //   var png64 = this.cy.png();
        //   // put the png data in an img tag
        //   document.querySelector('#png').setAttribute('src', png64);
        // }

      } else if (query['type'] == 'neighbours') {
        if (query['type'] == 'neighbours' && query['selector'] != null && query['condition'][0] != "" && query['arg'] != null) {
          // remove as per query [directly without select]
          this.lastSelection = this.getNodes(query);
          this.value = this.message["info"] + ' nodes and their neighbours, Also added to collection';
          this.lastSelection = this.lastSelection.union(this.lastSelection.neighborhood().nodes());
          this.showResult(this.lastSelection);
        } else {
          this.value = this.message["info"] + ' nodes and their neighbours, Also added to collection';
          this.lastSelection = this.lastSelection.union(this.lastSelection.neighborhood().nodes());
          this.showResult(this.lastSelection);
        }

      } else if (query['type'] == 'parallel') {

        this.value = this.message["info"] + ' Highlight parallel edges if exist';
        let elements = this.cy.collection();
        let edges = this.cy.edges();

        if (this.style_flag["formal_informal_graph"]) {
          for (let i = 0; i < edges.length; i++) {
            let edge = edges[i];
            if (edge._private.data['bidirectional'] > 0) {
              elements.merge(edge);
            }
          }

        } else {

          // modified the original cytoscape js code


          // look at all the edges in the collection
          for (let i = 0; i < edges.length; i++) {
            let edge1 = edges[i];
            let edge1_p = edge1._private;
            let src1 = edge1_p.source;
            let srcid1 = src1._private.data.id;
            let tgtid1 = edge1_p.data.target;
            let srcEdges1 = src1._private.edges;

            // look at edges connected to the src node of this edge
            for (let j = 0; j < srcEdges1.length; j++) {
              let edge2 = srcEdges1[j];
              let edge2data = edge2._private.data;
              let tgtid2 = edge2data.target;
              let srcid2 = edge2data.source;

              // let codirected = tgtid2 === tgtid1 && srcid2 === srcid1;
              let oppdirected = srcid1 === tgtid2 && tgtid1 === srcid2;

              if (oppdirected) {
                elements.merge(edge2);
              }
            }
          }
        }
        elements.flashClass('parallel', 5000);
      } else if (query['type'] == 'table') {
        console.log('start');

        // Ankit: tablebuilder() function for building table
        this.tablebuilder();

        // // this.lastResultCopy = this.cy.nodes();
        // if(this.lastResultCopy.length == 0) {
        //   let allNodeAsResult =[];
        //   console.log('as lastresultCopy empty, selecting all nodes data ');
        //   _.each(this.cy.nodes(),function(node:any) {
        //     // console.log(node._private.data);
        //     allNodeAsResult.push(node._private.data);
        //   });
        //   console.log(allNodeAsResult)
        //   this.lastResultCopy = allNodeAsResult;
        // }

        // this.columnsForTable = []
        // let columnsForTable = []
        // let columnsNamesNotToShow = this.columnsNamesNotToShow;
        // _.each(this.querySelectors, function(val:any) {
        //   if(! _.contains(columnsNamesNotToShow,val)) {
        //     columnsForTable.push(val);
        //   }
        // });

        // let nodes_len = (this.cy.nodes()).length
        // let edge_len = (this.cy.edges()).length
        // this.network_metric_data['Total Number of nodes'] = nodes_len;
        // this.network_metric_data['Total Number of edges'] = edge_len;
        // this.columnsForTable = columnsForTable;
        // // console.log(this.lastResultCopy);
        // this.result = this.lastResultCopy;

        // console.log(this.result);
        // let grey_count = 0;
        // _.each(this.result,function(data) {
        //   console.log(data['grey'])
        //   if(data['grey'] == true) {
        //     grey_count++;
        //   }
        // });
        // this.resultCount = "Result showing : " + ((Object.keys(this.result).length) - grey_count);
        // // this.resultCount = "Result showing : " + Object.keys(this.result).length;
        // // console.log(this.result);
        // this.value = this.message["info"] + ' Showing result as table';
        // this.resultAsTable = !this.resultAsTable;

        // if (this.resultAsTable) {
        //   let element = document.getElementById('tableForResult');
        //   // this.cd.detectChanges();
        //   element.className = 'show';
        //   document.getElementById('jsonResult').className = 'hide';
        //   this.onEnter('sort selected < rank_go_to_score');

        // } else {
        //   let element = document.getElementById('tableForResult');
        //   element.className = 'hide';
        //   document.getElementById('jsonResult').className = 'show';
        // }
        // console.log('end');
      }
    } else {
      this.value = this.message["error"] + " First Load a graph $> load ";
    }
  }
  // // download = true;
  // autoDownload_event() {
  //   // console.log(len+" : "+index);
  //   // if(len == index){
  //     this.ExportTOExcel();
  //   // }
  // }
  // automate() {
  //   // this.spreadLayout();
  //   // this.onEnter('table');
  //   // this.ExportTOExcel();
  //   // this.onEnter('download_as_png');
  //   // document.getElementById('a_png').click();

  // }
  loadManualData(jsonData: any) {
    // (jsonData.graph.elements.nodes).push({ data: { id: "-1", name: 'others', value: 1495, first_name: "others" } });
    var timestamp_start = new Date().getTime()
    // Destroy old cy instance
    if (this.grphLoaded) {
      console.log("Is cy instance destroyed : " + this.cy.destroyed());
      if (!this.cy.destroyed()) {
        console.log("Destroying cy instance");
        this.cy.destroy();
        console.log("Destroyed cy instance : " + this.cy.destroyed());
      }
      this.style_for_graph['rank_top'] = 0;
      this.network_metric_data = {};
      this.resultAsTable = false;
      this.lastResultCopy = [];
      this.lastSelection = []
      this.style_for_graph["graphSize"] = 0;
    }
    // <-network metric
    let network_metric_data = {};
    let network_column_to_show = this.network_column_to_show;
    _.each(jsonData.network_metric, function (v, k: any) {
      console.log(k + " : " + v);
      if (network_column_to_show.includes(k)) {
        network_metric_data[k] = v;
      }
    });
    this.network_metric_data = network_metric_data;
    // ->
    if (this.general_tab_active) {
      if (jsonData.graph)
        jsonData = jsonData.graph.elements;
      else if (jsonData.elements)
        jsonData = jsonData.elements;


    }
    if (this.teams_tab_active) {
      jsonData = jsonData.elements;

    }
    if (this.individual_tab_active) {
      jsonData = jsonData.elements;

    }

    //  minus one because currently we are having 1 others in data
    this.style_for_graph["graphSize"] = (jsonData["nodes"]).length - 1;
    // this.survey_defaulter = jsonData.null_nodes;
    // this.style_for_graph["graphSize"] = (jsonData.elements["nodes"]).length;
    console.log(this.network_metric_data);
    console.log("graph json data", jsonData);

    this.graphFullData = jsonData;
    let node_attr_keys = Object.keys(this.graphFullData["nodes"][0]["data"]);
    this.querySelectors = _.without(node_attr_keys, "response");
    let querySelectorsForTypeRankBy = [];
    _.each(node_attr_keys, function (val) {
      if (val.includes('rank')) {
        querySelectorsForTypeRankBy.push(val);
      }
    });
    this.querySelectorsForTypeRankBy = querySelectorsForTypeRankBy;

    // this.edgeQuerySelectors = Object.keys(this.graphFullData["edges"][0]["data"]);
    this.genratePossibleArgList();




    this.edgeList = jsonData["edges"];
    var nodesList = jsonData["nodes"];
    if (this.collab_within_small_unit_show) {
      nodesList = [];
      for (let i of jsonData["nodes"]) {
        if (String(i["data"][this.entity_type_highlight_small_unit]) == this.entity_highlight_name_small_unit) {
          nodesList.push(i)
        }
      }
    }
    if (this.two_specific_entities_boolean) {
      nodesList = [];

      for (let node of jsonData["nodes"]) {


        //create collection of nodes for team 1
        if (node["data"][$("#team_type_2").children("option:selected").attr("childClassValue")] == $("#team_dd_1").children("option:selected").text()) {
          console.log(node["data"][$("#team_type_2").children("option:selected").attr("childClassValue")]);
          console.log($("#team_dd_1").children("option:selected").text());

          nodesList.push(node)
        }
        //create collection fo nodes for team 2
        else if (node["data"][$("#team_type_2").children("option:selected").attr("childClassValue")] == $("#team_dd_2").children("option:selected").text()) {
          console.log(node["data"][$("#team_type_2").children("option:selected").attr("childClassValue")]);
          console.log($("#team_dd_2").children("option:selected").text());
          nodesList.push(node)
        }


      }
    }

    var hl_order = this.config_json_data["settings"]["hl_order"]
    var leadership_levels = []

    if (this.config_json_data["report_filter"]["hl"]) {
      var no_of_hl = this.config_json_data["report_filter"]["hl"].length;
      var leadership_count = parseInt(this.config_json_data["settings"]["hl_count"]);
      if (this.config_json_data["settings"]["hl_order"] == "ASC") {
        var highest_level = 1;
        leadership_levels = [1]
        for (let i = 1; i < leadership_count; i++) {
          highest_level += 1

          leadership_levels.push(highest_level);
        }
      }
      else if (this.config_json_data["settings"]["hl_order"] == "DESC") {
        var highest_level = parseInt(no_of_hl);
        leadership_levels = [highest_level];
        for (let i = 1; i < leadership_count; i++) {
          highest_level -= 1

          leadership_levels.push(highest_level);
        }
      }
    }

    if (this.general_tab_active) {
      if ($("#team_type_dd option:selected").val() != "org" && $("#network_dd option:selected").val() == "main") {
        nodesList = []
        for (let i of jsonData["nodes"]) {
          if (i["data"][$("#team_type_dd option:selected").attr("childclassvalue")] == $("#team_dd option:selected").text()) {
            if (this.show_leadership_team) {
              var leader_boolean = false
              for (let j of leadership_levels) {
                if (i["data"]["hierarchy_level"] == j) {
                  leader_boolean = true
                }
              }
              if (leader_boolean) {
                nodesList.push(i)

              }

            }
            else {
              nodesList.push(i)

            }
          }
        }
      }
      else if ($("#team_type_dd option:selected").val() == "org" && $("#network_dd option:selected").val() == "main") {
        nodesList = []

        for (let i of jsonData["nodes"]) {
          if (this.show_leadership_team) {
            var leader_boolean = false
            for (let j of leadership_levels) {
              if (i["data"]["hierarchy_level"] == j) {
                leader_boolean = true
              }
            }
            if (leader_boolean) {
              nodesList.push(i)

            }

          }
          else {
            nodesList.push(i)

          }

        }

      }
    }
    if (this.teams_tab_active) { }
    if (this.individual_tab_active) {

    }




    //we are sorting the nodes according to their ids over here
    if ($("#nodes_sort_advance").children("option:selected").val() == "k-core" || $("#nodes_sort_advance").children("option:selected").val() == "value") {
      this.sortedNodesList = _.sortBy(nodesList, (obj) => {
        return obj["data"]["value"];
      });
    }
    //we are sorting the nodes according to the selected option from the dropdown
    else {
      this.sortedNodesList = _.sortBy(nodesList, (obj) => {
        return obj["data"][$("#nodes_sort_advance").children("option:selected").val()];
      });

    }
    if (nodesList.length == 0) {
      $("#cy").html("No employees in this network.")
    }
    var sortedNodesListIds = [];

    console.log("sorted node", this.sortedNodesList)

    for (let i in this.sortedNodesList) {
      if ($("#team_type_dd").val() == "org" && $("#team_dd").val() != "none") {
        sortedNodesListIds.push(this.sortedNodesList[i]["data"]["id"]);

      }
      else {
        sortedNodesListIds.push(parseInt(this.sortedNodesList[i]["data"]["id"]));

      }
    }

    console.log("sorted node ids", sortedNodesListIds)
    var temp_edgeList = this.edgeList;

    console.log("temp_Edge_list", temp_edgeList)
    //creating this structure to quickly access edges
    var edgelist_objectform = {}
    for (let i in temp_edgeList) {
      if (edgelist_objectform[temp_edgeList[i]["data"]["source"]]) {
        edgelist_objectform[temp_edgeList[i]["data"]["source"]][temp_edgeList[i]["data"]["target"]] = temp_edgeList[i]
      }
      else {
        edgelist_objectform[temp_edgeList[i]["data"]["source"]] = {}
        edgelist_objectform[temp_edgeList[i]["data"]["source"]][temp_edgeList[i]["data"]["target"]] = temp_edgeList[i]

      }
    }

    // use sorted list of node ids to create list of edges for those nodes

    var edgeListDynamic = _.filter(temp_edgeList, (obj) => {
      var sourcepresent = _.indexOf(sortedNodesListIds, parseInt(obj["data"]["source"]));

      var targetpresent = _.indexOf(sortedNodesListIds, parseInt(obj["data"]["target"]));
      if (sourcepresent == -1 || targetpresent == -1) {
      }
      if (sourcepresent != -1 && targetpresent != -1) {
        if (this.g_type_url_global == "trust" && this.n_type_url_global == "main") {
          if (obj["data"]["network"] == "trust" || obj["data"]["network"] == "energy") return _.contains(temp_edgeList, obj);

        }
        else {
          return _.contains(temp_edgeList, obj);

        }

      }
    });
    console.log()


    if ($("#team_type_dd").val() == "org" && $("#team_dd").val() != "none" && this.g_type_url_global != "trust") {
      console.log("this.collab_bet_entity_ highlight", this.collab_bet_entity_highlight)
      var collab_bet_entity_highlight_local = this.collab_bet_entity_highlight
      var entity_highlight_name_local = this.entity_highlight_name
      console.log("entity_highlight_name_local", entity_highlight_name_local)

      edgeListDynamic = _.filter(temp_edgeList, function (obj) {
        var sourcepresent = _.indexOf(sortedNodesListIds, String(obj["data"]["source"]));

        var targetpresent = _.indexOf(sortedNodesListIds, String(obj["data"]["target"]));
        if (sourcepresent == -1 || targetpresent == -1) {
        }
        else if (sourcepresent != -1 && targetpresent != -1) {
          if (collab_bet_entity_highlight_local) {
            if (obj["data"]["source"] == entity_highlight_name_local || obj["data"]["target"] == entity_highlight_name_local) {
              return _.contains(temp_edgeList, obj);

            }
          }
          else {
            console.log(obj)
            return _.contains(temp_edgeList, obj);

          }
        }
      });
    }
    if ($("#network_dd option:selected").val() == "main") {
      this.edgeList = edgeListDynamic
    }











    //setting ndoe count as no. of nodes totally in the graph
    this.maxNoOfNodes = nodesList.length;
    this.noOfNodesConsidered = nodesList.length;
    if (this.maxNoOfNodes > 200 && $("#network_dd option:selected").val() != "main") {
      this.noOfNodesConsidered = 100;
      this.showNotification("Initially, <b>" + this.noOfNodesConsidered +
        "</b> nodes have been loaded. You can change the number of nodes by using the count slider on the top right.", 10000);

    }
    if (this.g_type_url_global == "informal-communities" || this.g_type_url_global == "disc_emp") {
      this.noOfNodesConsidered = nodesList.length;

    }

    this.max_degree = 0;
    this.min_degree = 10000;
    for (let i in nodesList) {
      if (nodesList[i]["data"]["degree"] > this.max_degree) {
        this.max_degree = nodesList[i]["data"]["degree"]
      }
      if (nodesList[i]["data"]["degree"] < this.min_degree) {
        this.min_degree = nodesList[i]["data"]["degree"]
      }
    }

    //if the selected option is k-core
    if ($("#nodes_sort_advance").children("option:selected").val() == "k-core") {
      $("#node_count_caption").text("Minimum connections per employee:")
      //fetching the k core data from pregenerated jsons
      this.getKcoreJsons();

    }
    else {

      $("#node_count_caption").text("Employee Count")
      //set min,max and exact values for node count slider
      $("#_nodeSlider").attr("max", this.maxNoOfNodes);
      $("#_nodeSlider").attr("min", 0);
      $("#_maxNodeCount").html(String(this.maxNoOfNodes));
      $("#_nodeSlider").val(this.noOfNodesConsidered);
      console.log("node slider value", $("#_nodeSlider").val());
      $("#_nodeCount").html(this.noOfNodesConsidered);

      if (this.show_collab_within_entity) {

      }
      var timestamp_end = new Date().getTime()
      console.log("Time taken for load manual data - calculate intial nodes/edges and other metrics dependind on the settings parameters from url ", (timestamp_end - timestamp_start))
      //load the graph
      this.load(this.noOfNodesConsidered, this.sortedNodesList, this.edgeList, this.max_degree, this.min_degree);


    }

  }
  update_node_slider(max_slider_value, slider_value) {
    console.log("update node slide", max_slider_value, slider_value)
    //set min,max and exact values for node count slider
    $("#_nodeSlider").attr("max", max_slider_value);
    $("#_nodeSlider").attr("min", 0);
    $("#_maxNodeCount").html(String(max_slider_value));
    $("#_nodeSlider").val(slider_value);
    $("#_nodeCount").html(String(slider_value));
  }

  getKcoreJsons() {
    //call function from app.service.ts
    this.appservice.getKCore(this.graph_name, this.uid).then((k_core_data) => {
      this.k_core_data = k_core_data

      //assigning min and max k-core values
      $("#_nodeSlider").attr("max", k_core_data["kcore_values"]["max_value"]);
      $("#_nodeSlider").attr("min", k_core_data["kcore_values"]["min_value"]);
      $("#_maxNodeCount").html(String(k_core_data["kcore_values"]["max_value"]));
      //setting min value as exact k-core slider value
      $("#_nodeSlider").val(k_core_data["kcore_values"]["max_value"]);
      $("#_nodeCount").html(String(k_core_data["kcore_values"]["max_value"]));
      //load the graph
      this.load(parseInt(String($("#_nodeSlider").val())), this.sortedNodesList, this.edgeList, this.max_degree, this.min_degree);

    })
  }
  change_dsIsoNodes() {
    if ($("#d_s_iso_nodes_check").prop("checked")) {
      this.show_isoNodes = 1;
    }
    else if (!$("#d_s_iso_nodes_check").prop("checked")) {
      console.log(" dont show iso nodes")
      this.show_isoNodes = 0;
    }

  }
  change_module_type() {
    console.log(String($("#module_type").children("option:selected").val()))
    this.g_type_url_global = String($("#module_type").children("option:selected").val());
    this.getContext().then(() => {
      this.populate_Settings_Dropdowns()
    })
    console.log(this.g_type_url_global);

  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  inputFocused() {
    // show or hide the prediction
    this.show = true;
  }
  inputNotFocused() {
    // show or hide the prediction
    this.show = false;
  }
  file: any;
  fileChanged(e) {
    console.log('called file');
    this.file = e.target.files[0];
    console.log(e.target.files);
    if (typeof this.file != 'undefined')
      this.uploadDocument(this.file);
    this.file = 'undefined';
  }
  uploadDocument(file) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      var lines = (fileReader.result as string).split('\n');
      console.log(lines);
      for (var line = 0; line < lines.length; line++) {
        console.log(lines[line]);
        this.onEnter(lines[line]);
      }
    }
    fileReader.readAsText(this.file);
  }

  onChange(event, value) {
    if (this.isValueSelectedByRightArrow) {
      this.isValueSelectedByRightArrow = false;
      this.inputNotFocused();
      return;
    }

    // console.log(value.length);
    value = value.toLocaleLowerCase();
    if (value.length == 0 || event.keyCode == 32) {
      this.show = false;
      this.predictionList = [];
      // console.log("empty");
      return;
    }

    this.typed = this.queryFixer(value + " ");
    value = this.queryFixer(value + " ");
    // console.log(value + " : " + value.split())
    var magicToken = this.queryBuider(value);
    // console.log(magicToken);
    var newValue = [];
    var newQuery = "";
    if (value.includes(' and ')) {
      newValue.push(magicToken['type']);
      newValue.push(_.last(value.split(' and ')));
      // console.log(newValue);
      newQuery = newValue.join(" ");
      // console.log(newQuery);
    }

    // console.log(newValue.length);
    this.typed = this.queryFixer(value);
    var magicToken = this.queryBuider(newValue.length == 0 ? value : newQuery);
    // console.log(magicToken);
    var keys = Object.keys(magicToken);
    // console.log(keys);


    for (var index = Object.keys(magicToken).length; index > 0; index--) {

      if (magicToken[keys[index - 1]] != null) {
        // console.log(magicToken[keys[index - 1]]);
        switch (keys[index - 1]) {
          case "type":
            this.predictionList = this.queryType;
            console.log("type called");
            break;
          case "selector":
            // this.predictionList = magicToken['type'] == "set" ? this.querySelectorsForTypeSet : this.querySelectors;
            switch (magicToken['type']) {
              case "get_top":
                this.predictionList = this.numericArgs;
                break;
              case "set":
                this.predictionList = this.querySelectorsForTypeSet;
                break;
              case "sort":
                this.predictionList = this.querySelectorsForTypeSort;
                break;
              case "load":
                // this.predictionList = this.querySelectorsForNetworkNames.concat(this.querySelectorsForTypeLoad);
                this.predictionList = this.querySelectorsForTypeLoad;
                break;
              case "init":
                this.predictionList = Object.keys(this.style_for_graph);
                break;
              default:
                this.predictionList = this.querySelectors;
            }
            console.log("selector called");
            break;
          case "condition":
            if (magicToken["selector"][0] == "sub_graph") {
              this.predictionList = ["="];
              console.log(" condition for sub_graph called");

            } else if (magicToken["selector"][0] == "get_top") {
              this.predictionList = ["="];
              console.log(" condition for sub_graph called");

            } else {
              this.predictionList = this.relationalOp;
              console.log("condition called");

            }
            break;
          case "arg":
            console.log('arg');
            if (this.grphLoaded) {
              if (magicToken["type"][0] == "selected") {
                this.predictionList = this.numericArgs;
                console.log(" for sort called");


              }
              if (magicToken["selector"][0] == "rank_by") {
                this.predictionList = this.querySelectorsForTypeRankBy;
                console.log(this.querySelectorsForTypeRankBy)
                console.log(" for rank_by called");

              }
              if (magicToken["selector"][0] == "node_size") {
                this.predictionList = this.numericArgs;
                console.log(this.querySelectorsForTypeRankBy)
                console.log(" for node_size called");

              } else {
                this.predictionList = this.getArgList(magicToken);
                console.log("arg called");
              }
            } else {
              // if (magicToken["selector"][0] == "sub_graph") {
              //   this.predictionList = this.queryArgForSelectorSubGraph;
              //   console.log(" for sub_graph called");

              // }
              // else
              console.log("as graph is not loaded yet no prediction")
            }
            break;
          default:
            this.predictionList = this.queryType;
            console.log("default called");


        }
        this.cd.detectChanges();

        break;
      }
    }
    this.predictionList = this.match(value);
    // <-If no result then don't shot the list
    if (this.predictionList.length == 0) {
      this.show = false;
      return;
    } else this.show = true;
    // If no result then don't shot the list - >
    this.cd.detectChanges();
    console.log(value.length);
  }

  match(value) {
    value = _.last(value.split(" "));
    let element = document.getElementById('predictions');
    var list = _.map(this.predictionList, function (num) {

      if ((num.toString()).includes(value)) return num;
    });
    var listF = [];
    for (var i = 0; i < list.length; i++) {
      if (typeof list[i] != 'undefined') {
        listF.push(list[i]);
      }
    }
    return listF;
  }


  // <-
  // on key press take top value of prediction list and add to typing query
  getTopOfThePredictionList() {
    if (this.predictionList && this.show) {
      this.isValueSelectedByRightArrow = true;
      this.selected(_.first(this.predictionList));
    } else return;

  }

  selected(itemInList: any) {
    var splitAll = [];
    if (this.typed.includes(' and ')) {
      splitAll = this.typed.split(" and ");
      var split = (this.queryFixer(_.last(splitAll))).split(" ");
    } else {
      var split = this.typed.split(" ");
    }
    split[split.length - 1] = itemInList + " ";
    console.log(split[split.length - 1].length);
    if (splitAll.length != 0) {
      splitAll[splitAll.length - 1] = split.join(" ");
      this.typed = splitAll.join(" and ");
    } else {
      this.typed = split.join(" ");
    }
    document.getElementById('cli-input').focus();
  }

  // ->
  getVal() {
    if (typeof this.typed != "undefined")
      return this.typed;
    else
      return "";
  }
  showNotification(string: any, time: number) {
    console.log("showing notification")
    $('#side_notification').css("display", "flex");
    setTimeout(() => {
      $('#side_notification').css("width", "max-content");

    }, 500);

    $('#notification-text').html(string);
    setTimeout(() => { this.closeNotification() }, time); // Close notification after 10 seconds
  }

  closeNotification() {
    $('#side_notification').css("width", "0px");

    $('#side_notification').css("display", "none");

    console.log("closeNotification")
  }



  genratePossibleArgList() {

    var posibleArgList = {};
    var querySelectors = this.querySelectors;
    var stop = false;
    _.each(querySelectors, function (val) {
      posibleArgList[val] = []
    });
    // console.log(this.graphFullData);
    // console.log(Object.keys(this.graphFullData).length);

    // will change this logic in future, short on time so using this quick fix
    let graphFullData = _.union(this.graphFullData['nodes'], this.graphFullData['edges']);
    _.find(graphFullData, function (val) {
      //   console.log(val);
      _.mapObject(val['data'], function (value, key) {
        //   console.log(value+"  :  "+key);
        if (_.contains(querySelectors, key)) {
          posibleArgList[key].push(value);
        }
      });

    });
    _.mapObject(posibleArgList, function (val, key) {
      posibleArgList[key] = _.uniq(posibleArgList[key]);

    });
    // list of all args ex : gender=[male,female]
    this.posibleArgList = posibleArgList;
    console.log("Possible argument list :");
    console.log(this.posibleArgList);

  }
  getArgList(queryTokens) {
    // console.log(queryTokens['selector']);
    return this.posibleArgList[queryTokens['selector']];
  }

  // Ankit: tablebuilder() that create the table
  tablebuilder() {

    console.log('in table builder');
    if (this.lastResultCopy.length == 0) {
      let allNodeAsResult = [];
      //console.log('as lastresultCopy empty, selecting all nodes data ');
      _.each(this.cy.nodes(), function (node: any) {
        // //console.log(node._private.data);
        allNodeAsResult.push(node._private.data);
      });
      //console.log(allNodeAsResult)
      this.lastResultCopy = allNodeAsResult;
    }

    this.columnsForTable = []
    let columnsForTable = []
    let columnsNamesNotToShow = this.columnsNamesNotToShow;
    _.each(this.querySelectors, function (val: any) {
      if (!_.contains(columnsNamesNotToShow, val)) {
        columnsForTable.push(val);
      }
    });

    let nodes_len = (this.cy.nodes()).length
    let edge_len = (this.cy.edges()).length
    this.network_metric_data['Total Number of nodes'] = nodes_len;
    this.network_metric_data['Total Number of edges'] = edge_len;
    this.columnsForTable = columnsForTable;
    // //console.log(this.lastResultCopy);
    this.result = this.lastResultCopy;
    this.resultCount = "Result showing : " + Object.keys(this.result).length;
    // //console.log(this.result);
    this.value = this.message["info"] + ' Showing result as table';
    this.resultAsTable = !this.resultAsTable;

    if (this.resultAsTable) {
      let element = document.getElementById('tableForResult');
      // this.cd.detectChanges();
      element.className = 'show';
      document.getElementById('jsonResult').className = 'hide';
      this.onEnter('sort selected < rank_go_to_score');

    } else {
      let element = document.getElementById('tableForResult');
      element.className = 'hide';
      document.getElementById('jsonResult').className = 'show';
    }
  }

  // Ankit: pngDownload() function that build the image snapshot and downloads it
  pngDownloader() {
    console.log('in pngDownloader');
    if (this.grphLoaded) {
      this.imgTag.className = 'show';
      this.value = this.message["success"] + ' captured graph as PNG ~abhi';
      this.download_img = true;
      var png64 = this.cy.png({ "full": true });

      // put the png data in an img tag
      document.querySelector('#png').setAttribute('src', png64);

      // Ankit: download() function call
      this.download(png64, this.fileName || 'graphSnapshot');

    }
  }

  // Ankit: download() function to automatically download the graph image
  download(dataurl, filename) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
  }


  reSizeCount = 1;

  reSize() {
    let element1 = document.getElementById('firstDiv');
    let element2 = document.getElementById('secondDiv');
    var size1 = 'col-sm-4 ';
    var size2 = 'col-sm';
    switch (this.reSizeCount) {
      case 1:
        size1 = 'col-sm-4';
        size2 = 'col-sm';
        this.reSizeCount++;
        break;
      case 2:
        size1 = 'col-sm-8 p-5';
        size2 = 'col-sm-4';
        this.reSizeCount++;
        break;
      case 3:
        size1 = 'col-sm-6 p-5';
        size2 = 'col-sm-6';
        this.reSizeCount++;
        break;
      case 4:
        size1 = 'col-sm-4 p-5';
        size2 = 'col-sm-8';
        this.reSizeCount = 1;
        break;
      // only for direct use
      case 5:
        size1 = 'col-sm-3 p-5';
        size2 = 'col-sm-9';
        this.reSizeCount = 1;
        break;
    }

    // element1.classList.remove("col-sm-7");
    // element1.className = size1;
    // element2.classList.remove("col-sm-4");
    // element2.className = size2;



  }

  // on arrow key up get last query from history
  lastTyped = null;
  getLastQuery() {
    console.log("using last query" + this.history.length);
    if (this.history.length) {
      this.lastTyped = this.typed;
      this.typed = _.last(this.history)['query'];
      console.log(_.last(this.history)['query']);
    }
  }
  getLastTyped() {
    if (this.lastTyped != null) {
      this.typed = this.lastTyped;
      this.lastTyped = null;
    }
  }

  test() {
    if (this.show) {
      let ul = document.getElementById('predictions');
      let len = ul.getElementsByTagName('li').length - 1;
      let index = -1;
      console.log(this.predictionList.length - 1);
      console.log(len);
      let next = ul.getElementsByTagName('li')[index++];
      console.log(next);
    }

  }

  reloadGraph() {
    // reload the graph with style
    var elements = this.cy.elements();
    this.cy.elements().remove();
    this.cy.add(elements);
    this.cy.reset();
  }
  showResult(resp) {
    // Hide details
    let element = document.getElementById('details');
    element.innerHTML = "";
    element.className = 'hide';
    document.getElementById('result').className = 'show';

    // show result
    console.log(resp);

    for (var i = 0; i < resp['length']; i++) {
      if (resp[i] != 'length' || resp[i] != '_private') {
        //   console.clear();
        // console.log(resp[i]._private.data)
        this.result.push(resp[i]._private.data);
      }
    }
    this.resultCount = "Result showing : " + resp['length'];
    // console.log(this.result);

    // Color male and female in selected node network
    // this.colorNodes(resp);

    this.history.push({
      "query": this.value,
      "result": resp
    });
    console.log("histor : ");
    console.log(this.history);


  }

  colorNodes(resp) {
    // Color male and female in selected node network
    this.cy.elements(resp).forEach(function (ele) {
      if (ele._private.group == "nodes") {
        // console.log( ele._private.data['gender']);
        if (ele._private.data['gender'] == 'female') {
          // ele.addClass('imageF') ;
          ele.flashClass('imageF', 9000);
        } else if (ele._private.data['gender'] == 'male') {
          ele.flashClass('imageM', 9000);
        }
      }
    });
  }

  // xlsx export
  @ViewChild('TABLE') TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    console.log(this.style_for_graph['graphSize'] + '==' + (this.cy.nodes()).length);
    if (this.style_for_graph['graphSize'] == (this.cy.nodes()).length) {
      console.log('remove hide class');
      $('#resultTableSpan tr').removeClass("hide");
    }
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Cli-Sheet-' + this.fileName || '' + new Date().toLocaleString() + '.xlsx');
    $('#resultTableSpan tr').addClass("hide");
  }

  // Query Fixer
  queryFixer(value) {
    var relationalOpSingle = this.relationalOpSingle;
    var relationalOpCombi = this.relationalOpCombi;
    console.log('---------------');
    console.log('Fixing query ex: ' + '"="' + '->' + '" = "');
    var querySplit;
    if (value.includes(' and ')) {
      querySplit = value.split(' and ');
    } else {
      querySplit = [value];
    }
    console.log(querySplit);
    for (var i = 0; i < querySplit.length; i++) {
      console.log(querySplit[i]);
      var isSingleOp = true;
      for (var j = 0; j < relationalOpCombi.length; j++) {
        if (querySplit[i].includes(relationalOpCombi[j])) {
          isSingleOp = false;
          console.log(querySplit[i] + " => " + (querySplit[i] = querySplit[i].split(relationalOpCombi[j]).join(' ' + relationalOpCombi[j] + ' ')));
          break;
        }
      }
      if (isSingleOp) {
        for (var j = 0; j < relationalOpSingle.length; j++) {
          if (querySplit[i].includes(relationalOpSingle[j])) {
            console.log(querySplit[i] + " => " + (querySplit[i] = querySplit[i].split(relationalOpSingle[j]).join(' ' + relationalOpSingle[j] + ' ')));
            break;
          }
        }
      }
    }
    value = querySplit.join(' and ');
    // remove extra space ["a","","","b"]=>["a","b"]
    value = (_.difference(value.split(" "), [""])).join(" ");
    console.log('---------------');
    return value;
  }

  queryBuider(rawQuery: String) {

    var splitQuery = (rawQuery.trim()).split(" ");
    var relationalOp = this.relationalOp;

    if (_.contains(splitQuery, 'and')) {
      var type = _.first(splitQuery);
      var conditions = _.filter(splitQuery, function (val) {
        return _.contains(relationalOp, val);
      });
      splitQuery = _.rest(splitQuery, 1);
      var selectors = [];
      var args = [];
      // ! 'and', using ' and ' as some name contain 'and' ex: ch[and]er @abhi
      var query = splitQuery.join(' ').toString().split(' and ');
      for (var index = 0; index < conditions.length; index++) {
        var value = query[index].split(conditions[index]);
        selectors.push(value[0].trim());
        args.push(value[1].trim());
      }
      return {
        'type': typeof type != 'undefined' ? type.trim() : null,
        'selector': selectors,
        'condition': conditions,
        'arg': args
      }
    } else {
      var condition = _.filter(splitQuery, function (val) {
        return _.contains(relationalOp, val);
      });
      var query = (rawQuery.trim()).split(condition[0]);
      var selectQuery = query[0].split(" ");
      var type = selectQuery[0];
      var selector = typeof selectQuery[1] != 'undefined' ? selectQuery[1] == "" ? null : [selectQuery[1].trim()] : null;
      return {
        'type': typeof type != 'undefined' ? type.trim() : null,
        'selector': typeof selector != 'undefined' ? selector : null,
        'condition': condition.length != 0 ? condition : null,
        'arg': typeof query[1] != 'undefined' ? query[1] == "" ? null : [query[1].trim()] : null
      }
    }
  }

  isValid(type, selectors, conditions, args) {
    // First Null check
    if (conditions != null && type != null && selectors != null && args != null) {
      // Check valid Type ex: select or set
      if (!_.contains(this.queryType, type)) {
        this.value = this.message["error"] + " [ " + type + " ] Not a valid type";
        return false;
      } else if (args.length != conditions.length) {
        this.value = this.message["error"] + " [ Conditions " + conditions + " ] and arguments mismatch [ " + args + " ]";
        // console.log(selectors.length + ' != ' + conditions.length);
        return false;
      } else {
        var isValid = true;
        var querySelectors = this.querySelectors.concat(this.querySelectorsForNetworkNames, Object.keys(this.style_node_shape_type), this.querySelectorsForTypeSet);
        var validSelector = _.filter(selectors, function (val) {
          return _.contains(querySelectors, val);
        });
        if (selectors.length != validSelector.length) {
          this.value = this.message["error"] + " [ " + _.difference(selectors, validSelector) + " ] Not a valid Data selector. ";
          isValid = false;
        }

        // also checking if condition are valid eX: =,!= are valid but ===== not
        var relationalOp = this.relationalOp;
        var validConditions = _.filter(conditions, function (val) {
          return _.contains(relationalOp, val);
        });
        if (conditions.length != validConditions.length) {
          this.value = this.message["error"] + " " + this.value + " [ " + conditions + " ] Not  valid condition . ";
          isValid = false;
        }
        return isValid;
      }

    } else {

      //  changed
      if (!_.contains(this.queryType, type)) {
        this.value = this.message["error"] + " [ " + this.value + " ] ## invalid ##";

        return false;
      }


    }
    return true;
  }

  getNodes(query) {

    // Author : Abhishek
    // github : Abhi5h3k
    // Notes for future Dev:
    // 1. check how many selectors ex: gender,dept
    // 2. check if a selector is of numeric type, ex: dc and cc
    //    2.1 If so then make the arg for the respected slector as numeric ex: "1"->1
    //    2.2 else make it a string inside quotes "string"
    // ifOrCase best XD
    var s = '';
    var arr = [];
    var listOfAllSubSelectors = [];
    var orCase: boolean = false;
    var notEqualCase: boolean = false;
    // Final query for cy
    var nodeQuery;
    console.log(query);
    for (var index = 0; index < query['selector'].length; index++) {
      // console.clear();
      console.log(index + " < " + query['selector'].length);
      s = '';
      arr = [];
      var selector = query['selector'][index].trim();
      console.log(selector);

      var arg = query['arg'][index].trim();

      var condition = query['condition'][index].trim();
      if (condition == "!=") notEqualCase = true;
      if (arg.includes(',')) {
        orCase = true;
        arg = arg.split(',');
        var value: any;
        var numericArgs = this.numericArgs;
        _.each(arg, function (val) {
          s = "";
          value = val.toString().trim();
          if (_.contains(numericArgs, selector)) {
            value = parseFloat(value);
          } else {
            value = '"' + value + '"';
          }
          s = s.concat('[ ' + selector + ' ' + condition + ' ' + value + ']');
          arr.push(s);
        });
        listOfAllSubSelectors.push(arr);

      } else {
        //   console.log("simple");
        if (_.contains(this.numericArgs, selector)) {
          arg = parseFloat(arg);
        } else {
          arg = '"' + arg + '"';
        }
        s = s.concat('[ ' + selector + ' ' + condition + ' ' + arg + ']');
        //   console.log(s);
        listOfAllSubSelectors.push(s);
      }
    }
    console.log(listOfAllSubSelectors);
    if (orCase && notEqualCase) {
      // console.log("not equal")
      nodeQuery = "";
      _.each(listOfAllSubSelectors, function (val) {
        if (typeof val == 'object') {
          nodeQuery = nodeQuery + val.join("");
        } else {
          nodeQuery = nodeQuery + "" + val;
        }
      });
      nodeQuery = "node" + nodeQuery;
    } else if (orCase && !notEqualCase) {
      var finalQuery = [];
      for (var index = 0; index < query['selector'].length; index++) {
        // console.log(typeof listOfAllSubSelectors[index]);

        if (typeof listOfAllSubSelectors[index] == 'string' && typeof listOfAllSubSelectors[index + 1] == 'string') {
          // console.log("str str");
          listOfAllSubSelectors[index + 1] = listOfAllSubSelectors[index].concat(listOfAllSubSelectors[index + 1]);
        } else if (typeof listOfAllSubSelectors[index] == 'string' && typeof listOfAllSubSelectors[index + 1] == 'object' || typeof listOfAllSubSelectors[index] == 'object' && typeof listOfAllSubSelectors[index + 1] == 'string') {
          var loopOn;
          var strToConcat;
          if (typeof listOfAllSubSelectors[index] == 'object') {
            loopOn = listOfAllSubSelectors[index];
            strToConcat = listOfAllSubSelectors[index + 1];
          } else {
            loopOn = listOfAllSubSelectors[index + 1];
            strToConcat = listOfAllSubSelectors[index];
          }
          for (var i = 0; i < loopOn.length; i++) {
            finalQuery.push(loopOn[i] + "" + strToConcat);
          }
          // console.log("running str obj");
          listOfAllSubSelectors[index + 1] = finalQuery;
          finalQuery = [];

        } else if (typeof listOfAllSubSelectors[index] == 'object' && typeof listOfAllSubSelectors[index + 1] == 'object') {
          // console.log("running obj obj");
          _.forEach(listOfAllSubSelectors[index], function (val1: string) {
            _.forEach(listOfAllSubSelectors[index + 1], function (val2: string) {
              // console.log(val1 + " : " + val2);
              val1 = val1.concat(val2);
            });
            finalQuery.push("node" + val1);
          });
          listOfAllSubSelectors[index + 1] = finalQuery;
          finalQuery = [];
        }

      }
      // console.log(_.last(listOfAllSubSelectors));


      listOfAllSubSelectors = _.last(listOfAllSubSelectors);
      listOfAllSubSelectors = _.map(listOfAllSubSelectors, function (val) {
        return "node" + val;
      });
      console.log(listOfAllSubSelectors);

      nodeQuery = listOfAllSubSelectors.join(",");
    } else {
      nodeQuery = "node" + listOfAllSubSelectors.join("");
    }
    // query that cy knows
    // var test = '[label="abhijeet maheshwari"],node[label="sanjay dutt"]';
    // nodeQuery = 'node[ label="reena wahi"][ dc!=2.42][ dc!=0.62],node[ label="debasis panigrahi"][ dc!=2.42][ dc!=0.62],node[ label="rita misra"][ dc!=2.42][ dc!=0.62],node[ label="simantika mukherjee"][ dc!=2.42][ dc!=0.62],node[ label="amit joshi"][ dc!=2.42][ dc!=0.62],node[ label="vipin malik"][ dc!=2.42][ dc!=0.62]';
    console.log(nodeQuery);
    // "=" case : select gender = male and label = sanjay dutt , sanjay sharma
    // node[ label = "sanjay dutt"][ gender = "male"],node[ label = "sanjay sharma"][ gender = "male"]
    // "!="
    // node[ label != "sanjay dutt"][ gender = "male"],node[ label != "sanjay sharma"][ gender = "male"]
    // nodeQuery='node[ label != "sanjay dutt"][ label != "sanjay sharma"][ gender = "male"][ dc != 1.32][ dc != 2.42  ]';
    // for select label = reena wahi,debasis panigrahi,rita misra,simantika mukherjee,amit joshi,vipin malik and dept *= hu and dc != 2.42
    // nodeQuery='node[ label = "reena wahi"][ dept *= "hu"][ dc != 2.42],node[ label = "debasis panigrahi"][ dept *= "hu"][ dc != 2.42],node[ label = "rita misra"][ dept *= "hu"][ dc != 2.42],node[ label = "simantika mukherjee"][ dept *= "hu"][ dc != 2.42],node[ label = "amit joshi"][ dept *= "hu"][ dc != 2.42],node[ label = "vipin malik"][ dept *= "hu"][ dc != 2.42]';
    //
    // nodeQuery='node[ label = "debasis panigrahi"][ lvl != "md&ceo"],node[ label = "rita misra"][ lvl != "md&ceo"]';

    // query now holds the result
    query = this.cy.filter(nodeQuery);
    this.lastSelection = query;
    console.log(this.lastSelection);

    // highlight
    // this.lastSelection.flashClass('querySelect', 5000);

    // query now holds the result [output]
    return query;
  }


  setStyle(query) {
    console.log('setting style...');

    // quick fix
    query['arg'] = query['arg'][0];
    //

    if (query['selector'] == 'node_color') {
      this.value = this.message["success"] + ' Colored selected nodes to : ' + query['arg'];
      this.cy.filter(this.lastSelection).style({
        'background-color': query['arg']
      });
    }
    if (query['selector'] == 'rank_by') {
      this.value = this.message["success"] + ' rank by nodes to : ' + query['arg'];
      this.style_for_graph['rank_by'] = query['arg'];
    }
    if (query['selector'] == 'label_size') {
      this.value = this.message["success"] + ' Setting label size : ' + query['arg'];
      this.style_for_graph["label_size"] = query['arg'];
      this.cy.filter(this.lastSelection).style({

        "font-size": this.style_for_graph["label_size"],
      });
    }
    if (query['selector'] == '_node_label') {
      if (_.contains(this.querySelectors, query['arg'])) {
        this.value = this.message["success"] + ' Setting node label as : ' + query['arg'] + " for next load";
        this.style_for_graph["_node_label"] = query['arg'];
        // console.log(this.style_for_graph["_node_label"]);
        this.cy.filter(this.lastSelection).addClass('label');
      } else {
        this.value = this.message["error"] + " " + query['arg'] + ' is not valid, setting node label as : id';
        this.style_for_graph["_node_label"] = 'data(' + query['id'] + ')';
        this.cy.filter(this.lastSelection).addClass('label');
      }

    }
    if (query['selector'] == '_edge_label') {
      if (_.contains(this.edgeQuerySelectors, query['arg'])) {
        this.value = this.message["success"] + ' Setting edge label as : ' + query['arg'] + " for next load";
        this.style_for_graph["_edge_label"] = query['arg'];
        // console.log(this.style_for_graph["_node_label"]);
        this.cy.filter(this.lastEdgeSelection).addClass('label');
      } else {
        this.value = this.message["error"] + " " + query['arg'] + ' is not valid, setting edge label as : frequency';
        this.style_for_graph["_edge_label"] = 'data(' + query['frequency'] + ')';
        this.cy.filter(this.lastEdgeSelection).addClass('label');
      }

    }
    if (query['selector'] == 'node_size') {
      this.value = this.message["success"] + ' setting size of selected nodes to : ' + query['arg'];
      if (_.contains(this.numericArgs, query['arg'])) {

        //
        if ((query['arg']).includes('rank')) {
          let number_of_nodes = this.style_for_graph["graphSize"] + 1;
          this.cy.filter(this.lastSelection).forEach(function (n) {
            var defaultHeight = n.height();
            var size = (number_of_nodes - n._private.data[query['arg']]) / 3 + defaultHeight;
            n.style({
              'width': size,
              'height': size
            });

          });

        } else {
          this.cy.filter(this.lastSelection).forEach(function (n) {
            var defaultHeight = n.height();
            var size = n._private.data[query['arg']] + defaultHeight;
            n.style({
              'width': size,
              'height': size
            });

          });
        }

        //


      } else {
        this.cy.filter(this.lastSelection).style({
          'width': query['arg'],
          'height': query['arg']
        });
      }
    }


  }

  getCutForPercent(num, attr) {
    // let graphSize = this.style_for_graph['graphSize'];
    console.log('num : ' + num + " attr : " + attr);
    let graphSize = (this.cy.nodes()).length;
    let percent = (num / 100) * graphSize

    let values = []
    _.each(this.cy.nodes(), function (data) {
      console.log(data);

    });


  }

  sort(query) {
    console.log(query);
    console.log({ "type": "sort", "selector": ["selected"], "condition": ["<"], "arg": ["rank_go_to_score"] });
    try {
      if (query['selector'] == null && query['condition'] == null && query['arg'] == null) {
        this.value = this.message["error"] + " Invalid query, use valid sytax like : syntax 1 :  sort [> or <] dc  , syntax 2 : sort selected [> or <] dc";
        // This can cause issue in script, only using for display data even if sort syntax fail
        // this.result=this.lastResultCopy;
        return;
      }

      if (query['selector'] == null && query['condition'][0] != null && query['arg'][0] != null) {
        console.log('case 1');
        var value = query['arg'][0];
        var condition = query['condition'][0];
        if (_.contains(this.numericArgs, value)) {
          var nodes = this.cy.nodes().sort(function (a, b) {
            if (condition == '>') {
              return b.data(value) - a.data(value);
            } else {
              return a.data(value) - b.data(value);
            }
          });
        }

      } else if (query['selector'] != null && query['arg'][0] != null && query['condition'][0] != null) {
        console.log('case 2');
        if (query['selector'][0] == 'selected') {
          if (typeof this.lastSelection == 'undefined') {
            this.value = this.message["error"] + " No previous selection ";
            return;
          }
          var value = query['arg'][0];
          var condition = query['condition'][0];
          // console.log(this.lastSelection);
          if (_.contains(this.numericArgs, value)) {
            var nodes = this.lastSelection.sort(function (a, b) {

              if (condition == '>') {
                return b.data(value) - a.data(value);
              } else {
                return a.data(value) - b.data(value);
              }
            });
          }

        }



      }
      if (typeof nodes == 'undefined')
        return;
      this.lastSelection = nodes;
      this.showResult(nodes);
      // console.log('Animating nodes to show sorted order');
      // this.animateNodes(nodes);

    } catch (err) {
      this.value = this.message["error"] + " Invalid query";
      this.value = err;
    }

  }

  animateNodes(nodes) {
    // show order via animations
    this.cy.nodes().removeStyle();
    var duration = 1000;
    nodes.removeStyle().forEach(function (node, i) {
      var defaultHeight = node.height();
      var size = node.data('dc') * 10 + defaultHeight;
      node.delay(i * duration).animate({
        style: {
          'border-width': 4,
          'border-color': '#fff',
          'color': '#ff80ab',
          'background-color': '#00c4b4',
          'font-size': 30,
          'font-weight': 200,
          'width': size,
          'height': size
        }
      }, {
        duration: duration
      }).removeStyle();


    });

  }

  getTop(query) {
    // First sort then call top
    try {
      if (query['selector'] != null && query['condition'] == null && query['arg'] == null)
        var range = query['selector'][0];
      if (range > this.lastSelection.length) {
        range = this.lastSelection.length;

      }
      this.lastSelection = this.lastSelection.slice(0, range);
      this.showResult(this.lastSelection);
      // this.animateNodes(this.lastSelection);
    } catch (err) {
      this.value = this.message["error"] + " Invalid Top query";
    }


  }
  // temp
  log(val) { console.log(val); }


  onRowClick(event, val: string) {
    var id = "#" + val;
    console.log(event.target.tagName + " : " + val);
    if (this.collectionOnRowClick.has(id)) {
      event.target.tagName == 'TD' ? event.target.parentElement.classList.remove('jsonResultRowSelected') : event.target.classList.remove('jsonResultRowSelected');
      this.collectionOnRowClick.delete(id);
    } else {
      event.target.tagName == 'TD' ? event.target.parentElement.classList.add('jsonResultRowSelected') : event.target.classList.add('jsonResultRowSelected');
      this.collectionOnRowClick.add(id);
    }

    console.log(this.collectionOnRowClick);

    var listOfIdSelected = Array.from(this.collectionOnRowClick);

    if (listOfIdSelected.length >= 1) {
      this.cy.elements(listOfIdSelected.join(',')).flashClass('selected outline', 5000);
      if (listOfIdSelected.length > 1)
        this.internalNetworkOf(listOfIdSelected.join(','));
    } else if (listOfIdSelected.length == 0) {
      this.cy.elements().edges().removeClass('hideE0 highlightE');
      this.cy.elements().nodes().removeClass('hideN0');
      this.cy.fit(this.cy.elements().nodes());
    }
  }

  internalNetworkOf(nodeIds) {
    var cy = this.cy;
    var j = cy.elements(nodeIds);
    this.cy.elements().edges().removeClass('hideE0 highlightE');
    this.cy.elements().nodes().removeClass('hideN0');
    cy.elements().not(j).nodes().addClass('hideN0');
    cy.elements().not(j.edgesWith(j)).edges().addClass('hideE0');
    j.edgesWith(j).addClass('highlightE');
    j.addClass('selected');
    // cy.center(j);
    cy.fit(j, 50);
    if (j.edgesWith(j).length === 0)
      alert("Not internal Network Found");
  }
  setStyleFlag(flag: string) {
    if (flag in this.style_flag) {
      if (this.style_flag[flag] == false) {
        console.log("setting style flag for " + flag + " in : ");
        console.log(this.style_flag);
        _.each(this.style_flag, function (value, key, dict) {
          if (key == flag) {
            dict[key] = true;
          } else {
            dict[key] = false;
          }
        });
        console.log("updated to : ");
        console.log(this.style_flag);
      }
    } else {
      console.log("Not a valid flag");
    }
  }

  setNodeShapeStyle(key: string, shape: string) {
    if (_.contains(this.possible_node_shape, shape)) {
      console.log("setting node shape");
      this.style_node_shape_type[key] = shape;
    }
  }

  // this function result is not matching to python only tested once in hurry
  genrateSingleEdgeGraph(data) {
    var nodes = this.cy.elements("nodes");
    var edges = this.cy.elements("edges");
    // console.log(data);
    // console.log();
    var cy = this.cy;
    var newEdges = [];
    // remove all edge
    // cy.remove(edges);

    cy.nodes().forEach(function (ele) {
      var nodeId = ele.id()
      var node = cy.$('#' + nodeId);
      var neighbor = node.neighborhood();

      // console.log("----------------");
      // console.log("source : " +nodeId );
      _.each(neighbor, function (neighborNodeData) {
        // console.log("==============");
        var target = null;
        // if data is of node and not edge, get the id
        if (neighborNodeData[0]._private["group"] == "nodes") {
          target = neighborNodeData[0]._private["data"]["id"];
          // console.log("target : " +neighborNodeData[0]._private["data"]["id"]);

          var maxFrequency = 0;
          var edgeCount = 0;
          var connectedEdges = node.edgesWith(neighborNodeData);
          _.each(connectedEdges, function (edgeData: any) {
            // edgeDataDict=>{network: "trust", frequency: 2, source: "1475", target: "1490", key: 0, …}
            var edgeDataDict = edgeData[0]._private["data"];
            var frequency = edgeDataDict["frequency"];
            var source = edgeDataDict["source"];
            if (source == nodeId) {
              // console.log(edgeDataDict);
              maxFrequency = maxFrequency < frequency ? frequency : maxFrequency;
              edgeCount++;
            } else {
              target = null;
            }
          });

          maxFrequency = maxFrequency * edgeCount;
        }



        if (target != null) {

          if (1 <= maxFrequency && maxFrequency <= 5) {
            maxFrequency = 1;
          } else if (6 <= maxFrequency && maxFrequency <= 9) {
            maxFrequency = 2;
          }
          if (10 <= maxFrequency) {
            maxFrequency = 3;
          }


          newEdges.push({
            group: 'edges',
            data: {
              source: nodeId,
              target: target,
              frequency: maxFrequency

            }
          });
        }

        // console.log("==============");

      });

      // console.log("----------------");

    });
    cy.remove(edges);
    //   console.log(newEdges);
    cy.add(newEdges);
    cytoscape.use(cise);
    cy.layout({
      name: 'cise',

    }).run();
    // cy.layout({
    //     name: 'spread'
    // }).run();
    this.spreadLayout();
    //   cy.add(newEdges);

  }

  toogleLabel() {
    this.cy.nodes().toggleClass("label");
  }
  two_entities_graph() {
    var cy_nodes = this.cy.nodes();
    //create two empty collections
    var team1 = this.cy.collection();
    var team2 = this.cy.collection();
    for (let node in cy_nodes) {
      if (cy_nodes[node]["_private"]) {


        //create collection of nodes for team 1
        if (cy_nodes[node]["_private"]["data"][$("#team_type_2").children("option:selected").attr("childClassValue")] == $("#team_dd_1").children("option:selected").text()) {
          console.log(cy_nodes[node]["_private"]["data"][$("#team_type_2").children("option:selected").attr("childClassValue")]);
          console.log($("#team_dd_1").children("option:selected").text());

          team1 = team1.union(cy_nodes[node])
        }
        //create collection fo nodes for team 2
        else if (cy_nodes[node]["_private"]["data"][$("#team_type_2").children("option:selected").attr("childClassValue")] == $("#team_dd_2").children("option:selected").text()) {
          console.log(cy_nodes[node]["_private"]["data"][$("#team_type_2").children("option:selected").attr("childClassValue")]);
          console.log($("#team_dd_2").children("option:selected").text());
          team2 = team2.union(cy_nodes[node])
        }
        //remove rest of the nodes
        else {
          this.cy.remove('#' + cy_nodes[node]["_private"]["data"]["id"])
        }
      }
    }
    console.log("two entities graph 1");
    // if(this.update_node_slider_two_entities ){
    //  this.update_node_slider(this.cy.nodes().length,this.cy.nodes().length)
    //   this.update_node_slider_two_entities = false;
    // }
    var file_name_1 = $("#team_dd_1").children("option:selected").val() + "_" + $("#team_type_2").children("option:selected").attr("value") + "_org_combined";
    var file_name_2 = $("#team_dd_2").children("option:selected").val() + "_" + $("#team_type_2").children("option:selected").attr("value") + "_org_combined";
    try {
      this.appservice.getGraphPositions(file_name_1, this.unique_hash, $("#layout_dd").children("option:selected").text()).then((data) => {
        var max_x = 0;
        var max_y = 0;
        for (let i in team1) {
          if (team1[i]["_private"]) {
            team1[i].position('x', data[team1[i]["_private"]["data"]["id"]][0]);
            team1[i].position('y', data[team1[i]["_private"]["data"]["id"]][1]);
            if (max_x < data[team1[i]["_private"]["data"]["id"]][0]) {
              max_x = data[team1[i]["_private"]["data"]["id"]][0]
            }
            if (max_y < data[team1[i]["_private"]["data"]["id"]][1]) {
              max_y = data[team1[i]["_private"]["data"]["id"]][1]

            }
          }

        }


        // var max = 12500, min = -12500;
        // var x = Math.round(Math.random() * (max - min + 1) + min)
        // var y = Math.round(Math.random() * (max - min + 1) + min)
        // team1.shift({x:x,y:y})
        // this.cy.resize();
        // this.cy.fit();
        try {
          this.appservice.getGraphPositions(file_name_2, this.uid, $("#layout_dd").children("option:selected").text()).then((data) => {
            for (let i in team2) {
              if (team2[i]["_private"]) {
                team2[i].position('x', max_x + data[team2[i]["_private"]["data"]["id"]][0]);
                team2[i].position('y', data[team2[i]["_private"]["data"]["id"]][1])
              }

            }
            // var max = 12500, min = -12500;
            // var x = Math.round(Math.random() * (max - min + 1) + min)
            // var y = Math.round(Math.random() * (max - min + 1) + min)
            // team2.shift({x:x,y:y})
            this.cy.resize();
            this.cy.fit();
          });
        }
        catch {
          team2.layout({
            name: 'circle'
          })
        }

      },
        (error) => {
          team1.layout({
            name: 'circle'
          })
        });
    }
    catch {


    }


    //set team 1 in a cricle
    // team1.layout({
    //   name: 'circle',
    //   radius: team1.length
    // }).run();
    // //set team2 in a circle
    // team2.layout({
    //   name: 'circle',
    //   radius: team2.length

    // }).run()

    //shift positions of teams. TODO: recalibrate this
    // team1.shift({ x: 20 * team2.length, y: 20 * team2.length });
    // team2.shift({ x: -20 * team1.length, y: -20 * team1.length });


    console.log("this.cy ", this.cy);
    setTimeout(() => {
      var between_entity_edges = team1.edgesWith(team2)
      var within_entity_edges = this.cy.edges().difference(between_entity_edges)
      within_entity_edges.style("line-color", 'gray')
      within_entity_edges.style("target-arrow-color", 'gray')
      this.cy.resize();
      this.cy.fit();
    }, 1000)

    // this.cy.fit();
    return;
  }
  net_stat_load_perc: number = 0;
  graph_stats_calculation() {
    $("#net_stat_load_perc").html("0%")
    this.net_stat_load_perc = 0;
    //almost all metrics are calculated assuming graph as undirected
    var nodes = this.cy.nodes();
    var edges = this.cy.edges();
    var node_ids = [];
    //normalized centrality metrics
    var degree_centrality_normalized = [];
    var closeness_centrality_normalized = [];
    var betweennness_centrality_normalized = [];
    // var dcn = this.cy.$().dcn();
    // var ccn = this.cy.$().ccn();
    // var bc = this.cy.elements().bc();
    var isolated_nodes = [];
    var no_of_isolated_nodes = 0;
    $("#net_stat_load_perc").html("5%")

    //nodes ranked according to page_rank algorithm
    // var nodes_page_rank_func = this.cy.elements().pageRank();
    var nodes_page_rank = [];
    // var dijkstra_func, floydWarshall = this.cy.elements().floydWarshall(true);
    var shortest_distance_list = [];
    var shortest_distance_w_source_target = [];
    var directed_graph_diameter;
    var node_matrix = [] //a 2D matrix to store source and target

    var average_degree = nodes.totalDegree() / nodes.length
    var sum_indegree = 0;
    var sum_outdegree = 0;
    $("#net_stat_load_perc").html("10%")
    setTimeout(() => {

      //creating array with node ids
      nodes.forEach((element) => {
        node_ids.push(element.id());
        sum_indegree += element.indegree();
        sum_outdegree += element.outdegree();
        // degree_centrality_normalized.push({'id': element.id(),'dcn':dcn.degree('#'+element.id())});
        // closeness_centrality_normalized.push({'id': element.id(),'ccn':ccn.closeness('#'+element.id())});
        // betweennness_centrality_normalized.push({'id':element.id(),'bcn':bc.betweennessNormalized('#'+element.id())});
        // nodes_page_rank.push({'id': element.id(),'page_rank':nodes_page_rank_func.rank('#'+element.id())});
        if (element.degree() == 0) {
          isolated_nodes.push(element);
          no_of_isolated_nodes += 1;
        }
        if (element.degree() != 0) {
          // console.log(element.degree());
          // dijkstra_func = this.cy.elements().dijkstra('#'+element.id(),(edge)=>{return edge}, false); //directed true

          // for(let i in nodes){
          //   var element_inner = nodes[i];
          //   if(element_inner["_private"]){
          //     console.log("inner element ",element_inner);    
          //     if(dijkstra_func["distanceTo"])  {
          //       if(element_inner.degree() != 0 && element_inner != element){
          //         shortest_distance_list.push(floydWarshall.distance('#'+element.id(),'#'+element_inner.id()))
          //       // shortest_distance_list.push(dijkstra_func.distanceTo("#"+element_inner.id()));
          //       //  shortest_distance_w_source_target.push([element.id(),element_inner.id(),dijkstra_func.distanceTo("#"+element_inner.id())])
          //       }
          //     }
          //   }

          // }

        }



      });
      $("#net_stat_load_perc").html("30%")


      console.log("list of shortest distance ", shortest_distance_list);
      directed_graph_diameter = Math.max.apply(Math, shortest_distance_list)
      var no_of_nodes = nodes.length;
      var no_of_edges = edges.length;

      //array of collection of connected component
      var connected_component_collection = this.cy.elements().components();

      //number of connected components
      var no_of_connected_components = connected_component_collection.length - no_of_isolated_nodes;

      //max degree
      var max_degree = nodes.maxDegree();
      //max indegree
      var max_indegree = nodes.maxIndegree();
      //max outdegree
      var max_outdegree = nodes.maxOutdegree();
      //min degree
      var min_degree = nodes.minDegree();
      //min indegree
      var min_indegree = nodes.minIndegree();
      //min outdegree 
      var min_outdegree = nodes.minOutdegree();
      $("#net_stat_load_perc").html("50%")

      //density for directed graph
      var density_directed = no_of_edges / (no_of_nodes * (no_of_nodes - 1))


      this.graph_stats_obj["no_of_nodes"] = no_of_nodes;
      this.graph_stats_obj["no_of_edges"] = no_of_edges;
      // this.graph_stats_obj["degree_centrality_normalized"] = degree_centrality_normalized;
      // this.graph_stats_obj["closeness_centrality_normalized"] = closeness_centrality_normalized;
      // this.graph_stats_obj["betweenness_centrality_normalized"] = betweennness_centrality_normalized;
      // this.graph_stats_obj["nodes_page_rank"] = nodes_page_rank;
      this.graph_stats_obj["density_directed"] = density_directed;
      this.graph_stats_obj["max_degree"] = max_degree;
      this.graph_stats_obj["min_degree"] = min_degree;
      this.graph_stats_obj["min_indegree"] = min_indegree;
      this.graph_stats_obj["max_indegree"] = max_indegree;
      this.graph_stats_obj["min_outdegree"] = min_outdegree;
      this.graph_stats_obj["max_outdegree"] = max_outdegree;
      this.graph_stats_obj["no_of_connected_components"] = no_of_connected_components;
      this.graph_stats_obj["isolated_nodes"] = isolated_nodes;
      this.graph_stats_obj["no_of_isolated_nodes"] = no_of_isolated_nodes;
      $("#net_stat_load_perc").html("70%")

      // this.graph_stats_obj["directed_graph_diameter"] = directed_graph_diameter;
      this.graph_stats_obj["average_degree"] = average_degree;
      $("#stats_no_of_nodes").html(no_of_nodes);
      $("#stats_no_of_edges").html(no_of_edges);
      $("#stats_density").html(String(density_directed.toFixed(2)));

      $("#stats_degree").html(" " + String(average_degree.toFixed(2)));
      $("#stats_min_degree").html("Min: " + String(min_degree));
      $("#stats_max_degree").html("Max: " + String(max_degree));
      $("#stats_indegree").html(" " + String((sum_indegree / no_of_nodes).toFixed(2)));
      $("#stats_min_indegree").html("Min: " + String(min_indegree));
      $("#stats_max_indegree").html("Max: " + String(max_indegree));
      $("#stats_outdegree").html(" " + String((sum_outdegree / no_of_nodes).toFixed(2)));
      $("#stats_min_outdegree").html("Min: " + String(min_outdegree));
      $("#stats_max_outdegree").html("Max: " + String(max_outdegree));
      $("#stats_conn_components").html(String(no_of_connected_components));
      $("#stats_iso_nodes").html(String(no_of_isolated_nodes));
      $("#net_stat_load_perc").html("100%")


      this.net_stat_load_screen = false;
      console.log("Graph stats object", this.graph_stats_obj);

    }, 500);

  }
  main_networks = ['work', 'innovation', 'expertise', 'trust', 'energy']
  hideEdges(networkid) {
    if (this.main_networks.includes(networkid)) this.main_networks = this.main_networks.filter((e, i) => e != networkid)
    else this.main_networks.push(networkid)
    var gEdges = this.cy.edges();
    // console.log("global edges",gEdges);
    if (networkid == "work") {
      $("#work").toggleClass("border-2px")
    }
    if (networkid == "innovation") {
      $("#innovation").toggleClass("border-2px")
    }
    if (networkid == "expertise") {
      $("#expertise").toggleClass("border-2px")
    }
    if (networkid == "trust") {
      $("#trust").toggleClass("border-2px")
    }
    if (networkid == "energy") {
      $("#energy").toggleClass("border-2px")
    }


    for (let i in gEdges) {


      if (gEdges[i]["_private"]) {
        gEdges[i].addClass('hidden');


        if (this.main_networks.includes(gEdges[i]["_private"]["data"]["network"])) {


          if (gEdges[i]["_private"]["data"]["network"] == 'work') {
            gEdges[i].style('line-color', '#ec700b');
            gEdges[i].style('target-arrow-color', '#ec700b');
            gEdges[i].removeClass('hidden');
          }


          else if (gEdges[i]["_private"]["data"]["network"] == 'innovation') {
            gEdges[i].style('line-color', '#0b47ec ');
            gEdges[i].style('target-arrow-color', '#0b47ec');
            gEdges[i].removeClass('hidden');
          }
          else if (gEdges[i]["_private"]["data"]["network"] == 'expertise') {
            gEdges[i].style('line-color', '#8aec0b');
            gEdges[i].style('target-arrow-color', '#8aec0b');
            gEdges[i].removeClass('hidden');
          }
          else if (gEdges[i]["_private"]["data"]["network"] == 'trust') {
            gEdges[i].style('line-color', '#9a07c7');
            gEdges[i].style('target-arrow-color', '#9a07c7');
            gEdges[i].removeClass('hidden');


          }
          else if (gEdges[i]["_private"]["data"]["network"] == 'energy') {
            gEdges[i].style('line-color', '#e7e41d');
            gEdges[i].style('target-arrow-color', '#e7e41d');
            gEdges[i].removeClass('hidden');
          }


        }
      }




    }




  }



  graph_manipulation() {
    var timestamp_start = new Date().getTime()
    var hl_order = this.config_json_data["settings"]["hl_order"]
    if (this.config_json_data["report_filter"]["hl"]) {
      var no_of_hl = this.config_json_data["report_filter"]["hl"].length;
      var leadership_count = parseInt(this.config_json_data["settings"]["hl_count"]);
      var leadership_levels = []
      if (this.config_json_data["settings"]["hl_order"] == "ASC") {
        var highest_level = 1;
        leadership_levels = [1]
        for (let i = 1; i < leadership_count; i++) {
          highest_level += 1

          leadership_levels.push(highest_level);
        }
      }
      else if (this.config_json_data["settings"]["hl_order"] == "DESC") {
        var highest_level = parseInt(no_of_hl);
        leadership_levels = [highest_level];
        for (let i = 1; i < leadership_count; i++) {
          highest_level -= 1

          leadership_levels.push(highest_level);
        }
      }
    }

    var nodes = this.cy.nodes()
    console.log("graph positions", graph_positions);
    //condition for nodes less than 20
    //TODO: fetch this number '20' from a single place maybe a config file
    // if (nodes.length <= 30) {
    //   nodes.layout({
    //     name: "euler"
    //   }).run();
    // }
    //condition for nodes grouped by a team type
    if ($("#team_dd").children("option:selected").text() != "None" && $("#team_type_dd").children("option:selected").val() == 'org') {
      nodes.layout({
        name: "euler"
      }).run();
    }
    if (this.graph_positions_found) {
      //assign positions to nodes
      console.log("assigning graph positions ")
      for (let i in nodes) {
        if (nodes[i]["_private"]) {
          if (graph_positions[nodes[i]["_private"]["data"]["id"]]) {
            console.log("assigning graph positions ")

            nodes[i].position('x', graph_positions[nodes[i]["_private"]["data"]["id"]][0]);
            nodes[i].position('y', graph_positions[nodes[i]["_private"]["data"]["id"]][1]);
          }


        }
      }
      //   const params = {
      //     iterations: 1000,                        // Number of layout iterations
      //     gravity: 1,                           // Strength of gravity (attraction to center)
      //     scalingRatio: 30.0,                      // Controls repulsion force (higher = more spacing)
      //     jitterTolerance: 0.1,                   // Tolerance for jitter during updates
      //     strongGravityMode: false,               // Use strong gravity mode
      //     barnesHutTheta: 1.2,                    // Barnes-Hut approximation parameter (precision)
      //     barnesHutSplits: 20,                     // Number of Barnes-Hut subdivisions
      //     outboundAttractionDistribution: false,   // Spread attraction force based on outbound degree
      //     linLogMode: false,                      // Logarithmic attraction mode for tighter clusters
      //     updateCenter: false,                     // Recalculate center of the Barnes-Hut tree
      //     targetChangePerNode: 0.01,              // Early stopping threshold (average displacement/node)
      //     directed: false,                        // Treat graph as directed (adjust attraction forces)
      //     "2d": false                              // Force the layout to run in 2D (ignore z-axis)
      //   };


      // this.fa2Service.applyLayout(this.cy, params).then((data)=>{
      //    console.log("pos ",this.cy.nodes().position())
      this.isNoverlapLoading = true

      setTimeout(() => {
        if(this.cy.nodes().length <= 700) 
          {       
            console.log("Noverlap icon ",this.isNoverlapLoading)
            this.noverlapLayout(this.cy)
          }

      }, 1000)

      // });

    }
    else if (this.getUrlParameter('layout') == "formalstructure") {
      var hl_buckets = {}
      for (let i in nodes) {
        if (nodes[i]["_private"]) {
          if (nodes[i]["_private"]["data"]["hierarchy_level"]) {
            console.log("show formal structure hl")
            if (hl_buckets[nodes[i]["_private"]["data"]["hierarchy_level"]]) {
              hl_buckets[nodes[i]["_private"]["data"]["hierarchy_level"]] = hl_buckets[nodes[i]["_private"]["data"]["hierarchy_level"]].union(nodes[i])
            }
            else {
              hl_buckets[nodes[i]["_private"]["data"]["hierarchy_level"]] = this.cy.collection()
              hl_buckets[nodes[i]["_private"]["data"]["hierarchy_level"]] = hl_buckets[nodes[i]["_private"]["data"]["hierarchy_level"]].union(nodes[i])
            }
          }



        }
      }
      var hls = Object.keys(hl_buckets)

      if (this.config_json_data["settings"]["hl_order"] == "ASC") hls.sort()
      else hls.sort().reverse()
      console.log("show formal structure", hl_buckets)

      console.log("show formal structure", hls)

      var radius = 10;
      var pan = 0;
      for (let i of hls) {
        var collections = this.cy.collection()
        collections = hl_buckets[i]
        console.log("show formal structure", hl_buckets[i])
        hl_buckets[i].layout({
          name: "grid",
          rows: 1,
        }).run();
        hl_buckets[i].shift({
          y: pan
        })
        pan += 200


      }
      this.cy.fit()
      this.cy.center()
    }
    else {
      console.log("euler_layout")


      if (!this.individual_tab_active) {

        nodes.layout({
          name: "euler",
          randomize: true,
          animate: true,
          avoidOverlap: true,
          nodeDimensionsIncludeLabels: true,

        }).run();
      }
      else {
        // nodes.layout({
        //   name: "euler",
        //   randomize: true,
        //   animate: true,
        //   avoidOverlap: true,
        //   nodeDimensionsIncludeLabels: true,
        //   springLength: function (edge) {
        //     return Math.floor(Math.random() * 200) + 100;
        //     // return 200;
        //   },
        //   springCoeff: edge => 0.0008,

        //   // gravity: -20,
        //   // spreads the node but makes small
        //   pull: 0.001,
        //   // Padding in rendered co-ordinates around the layout
        //   padding: 10,
        // }).run();
      }
      if (!this.teams_tab_active) {
        if (this.cy.nodes().length < 8) {
          setTimeout(() => {
            nodes.layout({
              name: "circle",
            }).run();

          }, 600)
        }
      }


      // if (this.teams_tab_active) {
      //   setTimeout(()=>{
      //     nodes.layout({
      //       name: "euler"
      //     }).run();
      //   },300) 
      // }
      // else {
      //   nodes.layout({
      //     name: "euler"
      //   }).run();
      // }

    }
    console.log("parallel edges ", this.cy.edges().parallelEdges())
    //making reciprocal edges thicker for collaboration graph
    if (this.show_leadership_team || this.g_type_url_global == "collaboration") {

      for (let edge of this.cy.edges()) {
        var parallel_edge = edge.parallelEdges()
        console.log("parallel edge ", parallel_edge)
        if (parallel_edge.length == 2) {
          edge.style("width", 2)

        }

      }
    }





    //calculate isolates for disc_emp 
    if ((this.g_type_url_global == "disc_emp" || this.show_isoNodes == 1) && !this.teams_tab_active && !this.individual_tab_active) {
      var main_graph_nodes = this.cy.filter('node[degree>0]');
      var silos = this.cy.filter('node[component_id > 1]');

      var main_graph_edges: any = []
      for (let i of main_graph_nodes) {
        var edges = i.incomers('edge')
        for (let edge of edges) main_graph_edges.push(edge)
      }
      var positions_temp: any = {};
      for (let i of this.cy.nodes()) {
        positions_temp[i["_private"]["data"]["id"]] = i.position()

      }
      if ($("#network_dd").children("option:selected").val() != 'main' && $("#network_dd").children("option:selected").val() != 'personal' && $("#network_dd").children("option:selected").val() != 'professional') {
        this.cy.remove('node[component_id = 1]')

        this.cy.layout({
          name: 'grid'
        }).run()
      }
      this.cy.nodes().style('font-size', "5px")
      //creating an empty collection
      var isolates = this.cy.filter('node[degree=0]');


      // TODO: recalibrate acc. to v2
      if (isolates.length > 2) {
        var isolateLayout = isolates.layout({
          name: 'grid',
          spacingFactor: 0.2
        });
        isolateLayout.run();
        this.cy.panBy({ x: 100, y: 0 })
      }

      if (isolates.length != 0) {
        // this.cy.zoom({
        //   level: 0.5
        // });
        var max_x = 0, max_y = 0;

        for (let i of main_graph_nodes) {
          var positions_temp = i.position()
          if (positions_temp['x'] > max_x) max_x = positions_temp['x']
          if (positions_temp['y'] > max_y) max_y = positions_temp['y']
        }
        var max_x_silos: any = 0; var max_y_silos: any = 0;
        for (let i of silos) {
          var positions_temp = i.position()
          if (positions_temp['x'] > max_x_silos) max_x_silos = positions_temp['x']
          if (positions_temp['y'] > max_y_silos) max_y_silos = positions_temp['y']
        }

        for (let i of silos) {
          var positions_temp = i.position()
          var diff_x = max_x_silos - positions_temp['x']
          i.position({ 'x': max_x + diff_x + 100 })
          // if(positions_temp['y'] < max_y) i.position({'y':max_y+positions_temp['y']})
        }
        for (let i of silos) {
          var positions_temp = i.position()
          if (positions_temp['x'] > max_x) max_x = positions_temp['x']
          if (positions_temp['y'] > max_y) max_y = positions_temp['y']
        }
        var max_x_isolates: any = 0; var max_y_isolates: any = 0;
        for (let i of isolates) {
          var positions_temp = i.position()
          if (positions_temp['x'] > max_x_isolates) max_x_isolates = positions_temp['x']
          if (positions_temp['y'] > max_y_isolates) max_y_isolates = positions_temp['y']
        }
        for (let i of isolates) {
          var positions_temp = i.position()
          var diff_x = max_x_isolates - positions_temp['x']
          i.position({ 'x': max_x + diff_x + 40 })
          // if(positions_temp['y'] < max_y) i.position({'y':max_y+positions_temp['y']})
        }
        // if (isolates.length >= 3) {

        //   this.cy.panBy({ x: 150, y: 150 });
        //   isolates.pan({ x: max_x, y: -100 });
        // }

      }
      this.cy.resize();
      this.cy.fit();
      for (let node of main_graph_nodes) {
        this.cy.add(node)
      }
      for (let edge of main_graph_edges) {
        this.cy.add(edge)

      }
      this.cy.resize();
      this.cy.fit();
    }
    // if($("#network_dd option:selected").val() == "main"){
    //   for(let i of this.cy.edges()){
    //     i.style("line-fill","linear-gradient");
    //     i.style("line-gradient-stop-colors","red green blue yellow purple")
    //     i.style("line-gradient-stop-positions","20 40 60 80 100")

    //   }
    // }
    //removing isolates otherwise
    else if (this.show_isoNodes == 0) {
      this.cy.remove('[degree=0]');

    }
    $("#main_network_selection").css("display", "none")

    if ($("#team_type_dd option:selected").val() != "org" && $("#network_dd option:selected").val() == "main") {
      $("#main_network_selection").css("display", "flex")


      // this.update_node_slider(this.cy.nodes().length, this.cy.nodes().length)
      var edges = this.cy.edges()
      for (let i in edges) {
        // console.log(i)
        // if(edges[i]["_private"]["data"]["network_id"] == 1){
        // edges[i].style('line-color','#2AFD04');
        // edges[i].style('target-arrow-color','#2AFD04');
        //   }
        // console.log("data ",edges[i]["_private"]["data"]);
        if (edges[i]["_private"]) {
          if (edges[i]["_private"]["data"]["network"] == "work") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#ec700b');
            edges[i].style('target-arrow-color', '#ec700b');

          }
          else if (edges[i]["_private"]["data"]["network"] == 7) {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#01611A ');
            edges[i].style('target-arrow-color', '#01611A');

          }
          else if (edges[i]["_private"]["data"]["network"] == 4) {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#ad0163');
            edges[i].style('target-arrow-color', '#ad0163');

          }

          else if (edges[i]["_private"]["data"]["network"] == "innovation") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#0bb7ec ');
            edges[i].style('target-arrow-color', '#0bb7ec');

          }
          else if (edges[i]["_private"]["data"]["network"] == "expertise") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#8aec0b');
            edges[i].style('target-arrow-color', '#8aec0b');

          }
          else if (edges[i]["_private"]["data"]["network"] == "trust") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#9a07c7');
            edges[i].style('target-arrow-color', '#9a07c7');

          }
          else if (edges[i]["_private"]["data"]["network"] == "energy") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#e7e41d');
            edges[i].style('target-arrow-color', '#e7e41d');

          }
          // else if(edges[i]["_private"]["data"]["network"] == 9){
          //   edges[i].style('line-color','#45010D');
          //   edges[i].style('target-arrow-color','#45010D');

          //   }   
        }




      }




    }
    else if ($("#team_type_dd option:selected").val() == "org" && $("#network_dd option:selected").val() == "main") {
      $("#main_network_selection").css("display", "flex")


      var edges = this.cy.edges()
      for (let i in edges) {
        // console.log(i)
        // if(edges[i]["_private"]["data"]["network_id"] == 1){
        // edges[i].style('line-color','#2AFD04');
        // edges[i].style('target-arrow-color','#2AFD04');
        //   }
        // console.log("data ",edges[i]["_private"]["data"]);
        if (edges[i]["_private"]) {
          if (edges[i]["_private"]["data"]["network"] == "work") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#ec700b');
            edges[i].style('target-arrow-color', '#ec700b');

          }
          else if (edges[i]["_private"]["data"]["network"] == 7) {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#01611A ');
            edges[i].style('target-arrow-color', '#01611A');

          }
          else if (edges[i]["_private"]["data"]["network"] == 4) {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#ad0163');
            edges[i].style('target-arrow-color', '#ad0163');

          }

          else if (edges[i]["_private"]["data"]["network"] == "innovation") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#0bb7ec ');
            edges[i].style('target-arrow-color', '#0bb7ec');

          }
          else if (edges[i]["_private"]["data"]["network"] == "expertise") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#8aec0b');
            edges[i].style('target-arrow-color', '#8aec0b');

          }
          else if (edges[i]["_private"]["data"]["network"] == "trust") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#9a07c7');
            edges[i].style('target-arrow-color', '#9a07c7');

          }
          else if (edges[i]["_private"]["data"]["network"] == "energy") {
            // console.log("id ",edges[i]["_private"]["data"]["network"]);
            edges[i].style('line-color', '#e7e41d');
            edges[i].style('target-arrow-color', '#e7e41d');

          }
          // else if(edges[i]["_private"]["data"]["network"] == 9){
          //   edges[i].style('line-color','#45010D');
          //   edges[i].style('target-arrow-color','#45010D');

          //   }   
        }




      }


    }
    this.cy.resize();
    this.cy.fit();
    //if grouping of nodes is not to be shown as a single node
    if (this.group_as_single_node == 0 && $("#team_type_dd").children("option:selected").val() == 'org' &&
      $("#team_dd").children("option:selected").val() == 'none' || this.g_type_url_global == 'informal-communities') {

      this.cy.remove('[degree = 0]')
      this.appservice.getInsightJson(this.uid, "full_community").then((comm_detect_data) => {
        console.log("getting community detect")
        comm_detect_data = comm_detect_data["Graph"]
        console.log("comm_detect_data", comm_detect_data)
        var objectforciselayout = {};
        var arrayforciselayout = [];
        for (let i in comm_detect_data['elements']['nodes']) {
          var nodess = this.cy.nodes();
          for (let node in nodess) {
            if (nodess[node]["_private"]) {
              if (nodess[node]["_private"]["data"]["value"] == comm_detect_data["elements"]["nodes"][i]["data"]["value"]) {
                nodess[node]["_private"]["data"]["Community"] = comm_detect_data["elements"]["nodes"][i]["data"]["Community"]
              }
            }
          }
          // if (objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]] && comm_detect_data['elements']['nodes'][i]['data']['component_id'] == 1) {
          //   objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))
          // }
          // else {
          //   objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]] = []
          //   objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))

          // }
          if (objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']["Community"]] && comm_detect_data['elements']['nodes'][i]['data']['component_id'] == 1) {
            objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']["Community"]].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))
          }
          else {
            objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']["Community"]] = []
            objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']["Community"]].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))

          }
        }

        for (let j in Object.keys(objectforciselayout)) {
          arrayforciselayout.push(objectforciselayout[Object.keys(objectforciselayout)[j]])
        }
        console.log(arrayforciselayout)
        // var cise_layout = this.cy.layout({
        //   name: 'cise',
        //   animate: true,
        //   clusters: arrayforciselayout,
        //   nodeRepulsion: 100000
        // })

        // cise_layout.run();
        var comm_wise_object = {}
        for (let key in Object.keys(objectforciselayout)) {
          console.log("ki 0", Object.keys(objectforciselayout)[key]);

          comm_wise_object[Object.keys(objectforciselayout)[key]] = this.cy.collection();

          this.cy.filter(function (node, i) {
            if (node["_private"]) {

              if (String(node["_private"]["data"]["Community"]) == String(Object.keys(objectforciselayout)[key])) {
                comm_wise_object[Object.keys(comm_wise_object)[key]] = comm_wise_object[Object.keys(comm_wise_object)[key]].union(node);

              }
            }
          })
          // console.log("key object",comm_wise_object[key]);

        }
        console.log("comm wise ", comm_wise_object);
        var cluster_layouts_obj = {}
        var cluster_num = 0;
        var x = 0, y = 0;
        var shift_pos_arr = []
        console.log(" keys comm ", Object.keys(comm_wise_object))
        var max_min_x_y_arr = []

        for (let key in Object.keys(comm_wise_object)) {
          console.log("key 0", key);
          var cluster_length = comm_wise_object[Object.keys(comm_wise_object)[key]].length;
          var radius = cluster_length;
          var list_entities = this.config_json_data["report_filter"][String($("#team_dd").children("option:selected").val())];
          var entity_short_val = '';
          for (let entity_index in list_entities) {
            console.log(list_entities[entity_index]["entity"]["name"], Object.keys(comm_wise_object)[key])
            if (list_entities[entity_index]["entity"]["name"] == Object.keys(comm_wise_object)[key]) {
              entity_short_val = list_entities[entity_index]["entity"]["value"]
              break;
            }
          }
          var pos_filename = entity_short_val + "_" + $("#team_dd").children("option:selected").val() + "_org_" + $("#network_dd").children("option:selected").val();

          console.log("pos filename ", pos_filename);

          this.appservice.getGraphPositions(pos_filename, this.uid, $("#layout_dd").children("option:selected").text()).then((data) => {
            console.log("position data ", data);
            var node_object = comm_wise_object[Object.keys(comm_wise_object)[key]];
            var node_object_keys = Object.keys(node_object);
            var max_y = 0, max_x = 0, min_y = 10000000, min_x = 10000000;
            for (let i in node_object_keys) {

              if (node_object[node_object_keys[i]]["_private"]) {

                if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]]) {
                  if (parseFloat(data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1]) < min_y) {
                    min_y = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1];
                  }
                  if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1] > max_y) {
                    max_y = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1];
                  }
                  if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0] < min_x) {
                    min_x = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0];
                  }
                  if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0] > max_y) {
                    max_x = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0];
                  }
                  max_min_x_y_arr.push([max_x, max_y, min_x, min_y]);
                  node_object[node_object_keys[i]].position('x', data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0]);
                  node_object[node_object_keys[i]].position('y', data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1]);

                }
              }



            }

            console.log("ran layout for ", cluster_num);
            cluster_num += 1;
            var max = 12500, min = -12500;
            x = Math.round(Math.random() * (max - min + 1) + min)
            y = Math.round(Math.random() * (max - min + 1) + min)
            var pos_found = 0;
            for (let pos in shift_pos_arr) {
              if (x == shift_pos_arr[pos][0] || y == shift_pos_arr[pos][1]) {
                pos_found = 1;
              }
            }

            if (pos_found == 0) {
              shift_pos_arr.push([x, y])
            }
            else if (pos_found == 1) {
              x = Math.round(Math.random() * (max - min + 1) + min);
              y = Math.round(Math.random() * (max - min + 1) + min);
              shift_pos_arr.push([x, y])

            }
            comm_wise_object[Object.keys(comm_wise_object)[key]].shift({ x: x, y: y });
            this.cy.resize();
            this.cy.fit();

          },
            (error) => {
              comm_wise_object[Object.keys(comm_wise_object)[key]].layout({
                name: "circle",
                spacingFactor: 0.6,
                radius: radius
              }).run();
              console.log("ran layout for ", cluster_num);
              cluster_num += 1;
              var max = 12500, min = -12500;
              x = Math.round(Math.random() * (max - min + 1) + min)
              y = Math.round(Math.random() * (max - min + 1) + min)
              var pos_found = 0;
              for (let pos in shift_pos_arr) {
                if (x == shift_pos_arr[pos][0] || y == shift_pos_arr[pos][1]) {
                  pos_found = 1;
                }
              }

              if (pos_found == 0) {
                shift_pos_arr.push([x, y])
              }
              else if (pos_found == 1) {
                x = Math.round(Math.random() * (max - min + 1) + min);
                y = Math.round(Math.random() * (max - min + 1) + min);
                shift_pos_arr.push([x, y])

              }
              comm_wise_object[Object.keys(comm_wise_object)[key]].shift({ x: x, y: y });
              this.cy.resize();
              this.cy.fit();

            });

          cluster_layouts_obj[cluster_num] = comm_wise_object[Object.keys(comm_wise_object)[key]].layout({
            name: "circle",
            spacingFactor: 0.6,
            radius: radius
          });
          // cluster_layouts_obj[cluster_num].run()


        }
        // comm_wise_object["9"].layout({name: 'circle',spacingFactor:0.5}).run()

        console.log("cise layout run , " + comm_wise_object)

        this.cy.resize();
        this.cy.fit();


        // this.cy.layout({  
        //   name: 'cola',
        //   animate: true,
        //   maxSimulationTime: 500,
        //   stop: () => {

        //   }
        // }).run()



      })

      return;
    }
    var timestamp_end = new Date().getTime()
    console.log("Time taken for graph manipulation ", (timestamp_end - timestamp_start))
  }
  change_label_size(increment_decrement: any) {
    console.log(" font-size ", this.cy.nodes().style('font-size'))
    if (increment_decrement == "increment") {
      this.cy.nodes().forEach((node: any) => {
        const currentFontSize = parseInt(node.style('font-size')) || 12; // Default to 12px
        const newFontSize = currentFontSize + 1;

        node.style({
          'font-size': `${newFontSize}px`,
        });
      });
    }
    else if (increment_decrement == "decrement") {
      this.cy.nodes().forEach((node: any) => {
        const currentFontSize = parseInt(node.style('font-size')) || 12; // Default to 12px
        const newFontSize = currentFontSize - 1;

        node.style({
          'font-size': `${newFontSize}px`,
        });
      });
    }
  }
  onLabelSizeInputChange(value: string) {
    console.log(" font-size ", this.cy.nodes().style('font-size'))
    this.cy.nodes().style('font-size', parseInt(value))

  }
  onlyAllowNumbers(event: KeyboardEvent) {
    console.log(" font-size ", this.cy.nodes().style('font-size'))

    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (!allowedKeys.includes(event.key) && isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  communities(same_node_color) {
    var timestamp_start = new Date().getTime()

    this.cy.remove('[degree = 0]')
    this.cy.remove('node[component_id > 1]')
    var filename_informal = "full_community"
    // if (this.getUrlParameter('new-informal') == 'true'){
    //   filename_informal = "full_community"
    // }
    this.appservice.getInsightJson(this.uid, filename_informal).then((comm_detect_data) => {
      console.log("getting community detect")
      var influencer_data = comm_detect_data["table"]
      console.log("comm_detect_data", comm_detect_data)

      // comm_detect_data = comm_detect_data["Graph"]
      console.log("comm_detect_data", comm_detect_data)
      var objectforciselayout = {};
      var arrayforciselayout = [];
      var nodess = this.cy.nodes();

      console.log("nodess", nodess)
      for (let i in comm_detect_data['elements']['nodes']) {

        for (let node in nodess) {
          if (nodess[node]["_private"]) {

            if (nodess[node]["_private"]["data"]["value"] == comm_detect_data["elements"]["nodes"][i]["data"]["value"]) {
              nodess[node]["_private"]["data"]["Community"] = comm_detect_data["elements"]["nodes"][i]["data"]["Community"]
            }


          }
        }

        if (objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']['Community']] && comm_detect_data['elements']['nodes'][i]['data']['component_id'] == 1) {
          objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']['Community']].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))
        }
        else {
          objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']['Community']] = []
          objectforciselayout[comm_detect_data['elements']['nodes'][i]['data']['Community']].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))

        }

      }
      console.log("objectforciselayout", objectforciselayout)
      if (this.getUrlParameter('show_single_community') == "true") {
        objectforciselayout = {}
        nodess = _.filter(nodess, (obj) => {

          if (obj["_private"]["data"]["Community"] == this.getUrlParameter("community")) {

            return _.contains(nodess, obj)

          }
          else {
            this.cy.remove("#" + obj["_private"]["data"]["id"])
          }

        })
        for (let node in nodess) {


          if (objectforciselayout[nodess[node]['data']["Community"]] && nodess[node]['data']['component_id'] == 1) {
            objectforciselayout[nodess[node]['data']["Community"]].push(String(nodess[node]['data']['id']))
          }
          else {
            objectforciselayout[nodess[node]['data']["Community"]] = []
            objectforciselayout[nodess[node]['data']["Community"]].push(String(nodess[node]['data']['id']))

          }

        }

      }


      for (let j in Object.keys(objectforciselayout)) {
        arrayforciselayout.push(objectforciselayout[Object.keys(objectforciselayout)[j]])
      }
      console.log(arrayforciselayout)
      // var cise_layout = this.cy.layout({
      //   name: 'cise',
      //   animate: true,
      //   clusters: arrayforciselayout,
      //   nodeRepulsion: 100000
      // })

      // cise_layout.run();
      var comm_wise_object = {}
      for (let key in Object.keys(objectforciselayout)) {
        console.log("ki 0", Object.keys(objectforciselayout)[key]);

        comm_wise_object[Object.keys(objectforciselayout)[key]] = this.cy.collection();

        this.cy.filter(function (node, i) {
          if (node["_private"]) {

            if (String(node["_private"]["data"]["Community"]) == String(Object.keys(objectforciselayout)[key])) {
              comm_wise_object[Object.keys(comm_wise_object)[key]] = comm_wise_object[Object.keys(comm_wise_object)[key]].union(node);

            }
          }
        })
        // console.log("key object",comm_wise_object[key]);

      }
      console.log("comm wise ", comm_wise_object);
      var cluster_layouts_obj = {}
      var cluster_num = 0;
      var x = 0, y = 0;
      var shift_pos_arr = []
      console.log(" keys comm ", Object.keys(comm_wise_object))
      var max_min_x_y_arr = []

      for (let key in Object.keys(comm_wise_object)) {
        console.log("key 0", key);
        var cluster_length = comm_wise_object[Object.keys(comm_wise_object)[key]].length;
        var radius = cluster_length;
        var list_entities = this.config_json_data["report_filter"][String($("#team_dd").children("option:selected").val())];
        var entity_short_val = '';
        for (let entity_index in list_entities) {
          console.log(list_entities[entity_index]["entity"]["name"], Object.keys(comm_wise_object)[key])
          if (list_entities[entity_index]["entity"]["name"] == Object.keys(comm_wise_object)[key]) {
            entity_short_val = list_entities[entity_index]["entity"]["value"]
            break;
          }
        }
        var pos_filename = entity_short_val + "_" + $("#team_dd").children("option:selected").val() + "_org_" + $("#network_dd").children("option:selected").val();

        console.log("pos filename ", pos_filename);

        this.appservice.getGraphPositions(pos_filename, this.uid, $("#layout_dd").children("option:selected").text()).then((data) => {
          console.log("position data ", data);
          var node_object = comm_wise_object[Object.keys(comm_wise_object)[key]];
          var node_object_keys = Object.keys(node_object);
          var max_y = 0, max_x = 0, min_y = 10000000, min_x = 10000000;
          for (let i in node_object_keys) {

            if (node_object[node_object_keys[i]]["_private"]) {

              if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]]) {
                if (parseFloat(data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1]) < min_y) {
                  min_y = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1];
                }
                if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1] > max_y) {
                  max_y = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1];
                }
                if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0] < min_x) {
                  min_x = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0];
                }
                if (data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0] > max_y) {
                  max_x = data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0];
                }
                max_min_x_y_arr.push([max_x, max_y, min_x, min_y]);
                for (let i of Object.keys(influencer_data)) {
                  for (let j of influencer_data[i]) {
                    if (j["Influencer"] == "Yes" && j["id"] == node_object[node_object_keys[i]]["_private"]["data"]["id"]) {
                      node_object[node_object_keys[i]].style('label', node_object[node_object_keys[i]]["_private"]["data"]["first_name"]);
                      node_object[node_object_keys[i]].style('height', '30px');
                      node_object[node_object_keys[i]].style('width', '30px');

                    }
                  }

                }
                node_object[node_object_keys[i]].position('x', data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][0]);
                node_object[node_object_keys[i]].position('y', data[node_object[node_object_keys[i]]["_private"]["data"]["id"]][1]);

              }
            }



          }

          console.log("ran layout for ", cluster_num);
          cluster_num += 1;
          var max = 8000, min = -8000;
          if (this.cy.nodes().length < 500 && this.cy.nodes().length >= 300) {
            max = 3000, min = -3000;

          }
          else if (this.cy.nodes().length < 300 && this.cy.nodes().length >= 200) {
            max = 2000, min = -2000;

          }
          else if (this.cy.nodes().length < 200 && this.cy.nodes().length >= 100) {
            max = 1000, min = -1000;

          }
          else if (this.cy.nodes().length < 100 && this.cy.nodes().length >= 50) {
            max = 500, min = -500;

          }
          else if (this.cy.nodes().length < 50) {
            max = 250, min = -250;

          }
          x = Math.round(Math.random() * (max - min + 1) + min)
          y = Math.round(Math.random() * (max - min + 1) + min)
          var pos_found = 0;
          for (let pos in shift_pos_arr) {
            if (x == shift_pos_arr[pos][0] || y == shift_pos_arr[pos][1]) {
              pos_found = 1;
            }
          }

          if (pos_found == 0) {
            shift_pos_arr.push([x, y])
          }
          else if (pos_found == 1) {
            x = Math.round(Math.random() * (max - min + 1) + min);
            y = Math.round(Math.random() * (max - min + 1) + min);
            shift_pos_arr.push([x, y])

          }
          comm_wise_object[Object.keys(comm_wise_object)[key]].shift({ x: x, y: y });
          this.cy.resize();
          this.cy.fit();

        },
          (error) => {
            comm_wise_object[Object.keys(comm_wise_object)[key]].layout({
              name: "circle",
              spacingFactor: 0.6,
              radius: radius
            }).run();
            console.log("ran layout for ", cluster_num);
            cluster_num += 1;
            var max = 6000, min = -6000;
            if (this.cy.nodes().length < 500 && this.cy.nodes().length >= 300) {
              max = 3000, min = -3000;

            }
            else if (this.cy.nodes().length < 300 && this.cy.nodes().length >= 200) {
              max = 2000, min = -2000;

            }
            else if (this.cy.nodes().length < 200 && this.cy.nodes().length >= 100) {
              max = 1000, min = -1000;

            }
            else if (this.cy.nodes().length < 100 && this.cy.nodes().length >= 50) {
              max = 500, min = -500;

            }
            else if (this.cy.nodes().length < 50) {
              max = 250, min = -250;

            }
            x = Math.round(Math.random() * (max - min + 1) + min)
            y = Math.round(Math.random() * (max - min + 1) + min)
            var pos_found = 0;
            for (let pos in shift_pos_arr) {
              if (x == shift_pos_arr[pos][0] || y == shift_pos_arr[pos][1]) {
                pos_found = 1;
              }
            }

            if (pos_found == 0) {
              shift_pos_arr.push([x, y])
            }
            else if (pos_found == 1) {
              x = Math.round(Math.random() * (max - min + 1) + min);
              y = Math.round(Math.random() * (max - min + 1) + min);
              shift_pos_arr.push([x, y])

            }
            comm_wise_object[Object.keys(comm_wise_object)[key]].shift({ x: x, y: y });
            if (this.getUrlParameter('show_single_community') == "true") {
              this.appservice.getGraphPositions("main_combined", this.uid, $("#layout_dd").children("option:selected").text()).then((data) => {
                for (let i of this.cy.nodes()) {
                  if (i["_private"]) {
                    for (let m of Object.keys(influencer_data)) {
                      for (let j of influencer_data[m]) {
                        if (j["Influencer"] == "Yes" && j["id"] == i["_private"]["data"]["id"]) {
                          console.log("influencer ", i["_private"]["data"]["first_name"])
                          i.style('label', i["_private"]["data"]["first_name"]);
                          i.style('shape', 'triangle');

                          i.style('height', '50px');
                          i.style('width', '50px');

                        }
                      }

                    }
                    i.position('x', data[i["_private"]["data"]["id"]][0]);
                    i.position('y', data[i["_private"]["data"]["id"]][1]);

                  }
                }
                this.cy.resize();
                this.cy.fit();

              })

            }
            this.cy.resize();
            this.cy.fit();

          });

        cluster_layouts_obj[cluster_num] = comm_wise_object[Object.keys(comm_wise_object)[key]].layout({
          name: "circle",
          spacingFactor: 0.6,
          radius: radius
        });
        // cluster_layouts_obj[cluster_num].run()


      }
      // comm_wise_object["9"].layout({name: 'circle',spacingFactor:0.5}).run()

      console.log("cise layout run , " + comm_wise_object)

      this.cy.resize();
      this.cy.fit();


      // this.cy.layout({  
      //   name: 'cola',
      //   animate: true,
      //   maxSimulationTime: 500,
      //   stop: () => {

      //   }
      // }).run()


      this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", same_node_color)

      var timestamp_end = new Date().getTime()
      console.log("Time taken for communities graph ", (timestamp_end - timestamp_start))
    })


  }

  group_nodes_as_teams() {

    // this.cy.remove('[degree = 0]')
    this.appservice.getInsightJson(this.uid, "community_detect").then((comm_detect_data) => {
      var objectforciselayout = {};
      var arrayforciselayout = [];
      for (let i in comm_detect_data['elements']['nodes']) {
        var nodess = this.cy.nodes();
        for (let node in nodess) {
          if (nodess[node]["_private"]) {
            if (nodess[node]["_private"]["data"]["value"] == comm_detect_data["elements"]["nodes"][i]["data"]["value"]) {
              nodess[node]["_private"]["data"]["Community"] = comm_detect_data["elements"]["nodes"][i]["data"]["Community"]
            }
          }
        }
        if (objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]] && comm_detect_data['elements']['nodes'][i]['data']['component_id'] == 1) {
          objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))
        }
        else {
          objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]] = []
          objectforciselayout[comm_detect_data['elements']['nodes'][i]['data'][$("#team_dd").children("option:selected").attr("childClassValue")]].push(String(comm_detect_data['elements']['nodes'][i]['data']['id']))

        }
      }

      for (let j in Object.keys(objectforciselayout)) {
        arrayforciselayout.push(objectforciselayout[Object.keys(objectforciselayout)[j]])
      }
      console.log(arrayforciselayout)
      var cise_layout = this.cy.layout({
        name: 'cise',
        animate: true,
        clusters: arrayforciselayout,
        nodeRepulsion: 100000
      })

      // cise_layout.run();


      this.cy.layout({
        name: 'cola',
        animate: true,
        maxSimulationTime: 500,
        stop: () => {
          var comm_wise_object = {}
          for (let key in Object.keys(objectforciselayout)) {
            console.log("ki 1", Object.keys(objectforciselayout)[key]);

            comm_wise_object[key] = this.cy.collection();

            this.cy.filter(function (node, i) {
              if (node["_private"]) {

                if (String(node["_private"]["data"][$("#team_dd").children("option:selected").attr("childClassValue")]) == String(Object.keys(objectforciselayout)[key])) {
                  comm_wise_object[Object.keys(comm_wise_object)[key]] = comm_wise_object[Object.keys(comm_wise_object)[key]].union(node);

                }
              }
            })
            // console.log("key object",comm_wise_object[key]);

          }
          var cluster_layouts_obj = {}
          var cluster_num = 0;
          var x = 0, y = 0;
          var shift_pos_arr = []
          console.log(" keys comm ", Object.keys(comm_wise_object))
          for (let key in Object.keys(comm_wise_object)) {
            console.log("key 1", key);
            var cluster_length = comm_wise_object[Object.keys(comm_wise_object)[key]].length;
            var radius = cluster_length;
            var pos_filename = key + "_" + $("#team_dd").children("option:selected").val() + "_org_" + $("#network_dd").children("option:selected").val() + "_positions.json";
            console.log("pos filename ", pos_filename);
            this.appservice.getGraphPositions(pos_filename, this.uid, $("#layout_dd").children("option:selected").text()).then((data) => {
              console.log("position data ", data);
              var node_collection = comm_wise_object[Object.keys(comm_wise_object)[key]];
              for (let i in node_collection) {
                if (data[node_collection[i]["_private"]["data"]["id"]]) {
                  node_collection[i].position('x', data[node_collection[i]["_private"]["data"]["id"]][0]);
                  node_collection[i].position('y', data[node_collection[i]["_private"]["data"]["id"]][1])

                }
              }
            });
            cluster_layouts_obj[cluster_num] = comm_wise_object[Object.keys(comm_wise_object)[key]].layout({
              name: "circle",
              spacingFactor: 0.6,
              radius: radius
            });
            // cluster_layouts_obj[cluster_num].run()
            console.log("ran layout for ", cluster_num);
            cluster_num += 1;
            var max = 2500, min = -2500;
            x = Math.round(Math.random() * (max - min + 1) + min)
            y = Math.round(Math.random() * (max - min + 1) + min)
            var pos_found = 0;
            for (let pos in shift_pos_arr) {
              if (x == shift_pos_arr[pos][0] || y == shift_pos_arr[pos][1]) {
                pos_found = 1;
              }
            }

            if (pos_found == 0) {
              shift_pos_arr.push([x, y])
            }
            else if (pos_found == 1) {
              x = Math.round(Math.random() * (max - min + 1) + min);
              y = Math.round(Math.random() * (max - min + 1) + min);
              shift_pos_arr.push([x, y])

            }
            comm_wise_object[Object.keys(comm_wise_object)[key]].shift({ x: x, y: y });



          }
          // comm_wise_object["9"].layout({name: 'circle',spacingFactor:0.5}).run()

          console.log("cise layout run , " + comm_wise_object)

          this.cy.resize();
          this.cy.fit();

        }
      }).run()



      // cy.panBy({ x: 150, y: 150 });


    })

    return;
  }
  change_network_dd() {
    if ($("#network_dd option:selected").val() == "combined") {
      $("#daily").prop("disabled", true);
      $("#weekly").prop("disabled", true);
      $("#monthly").prop("disabled", true);
      $("#quarterly").prop("disabled", true);
      $("#edge_freq_apply").prop("disabled", true);
      $("#connection_frequency_label_check").prop("disabled", true);
      $("#apply_edge_settings").prop("disabled", false)
      $("#apply_edge_settings").html("Apply")
      if (this.teams_tab_active || this.individual_tab_active) {
        $("#apply_edge_settings").prop("disabled", true)
        $("#apply_edge_settings").html("Not Applicable")

      }

    }
    else {
      $("#daily").prop("disabled", false);
      $("#weekly").prop("disabled", false);
      $("#monthly").prop("disabled", false);
      $("#quarterly").prop("disabled", false);
      $("#edge_freq_apply").prop("disabled", false);
      $("#connection_frequency_label_check").prop("disabled", false);
      $("#apply_edge_settings").prop("disabled", false)
      $("#apply_edge_settings").html("Apply Settings")


    }

  }
  sortedNodesListIds = []
  timer: any;
  euler_layout_settins(data) {
    var euler_settings = { "maxIterations": 1000, "maxSimulationTime": 4000, "mass": 4, "gravity": -1.2, "springlength": 80, "springcoeff": 0.0008, "pull": 0.001 };
    if (data.nodes.length < 100) {
      euler_settings["mass"] = 4;
      euler_settings["gravity"] = -1.2;
      euler_settings["springlength"] = 80;
      euler_settings["springcoeff"] = 0.0008;
      euler_settings["pull"] = 0.001;
      euler_settings["maxIterations"] = 1000;
      euler_settings["maxSimulationTime"] = 2000;
    }
    else if (data.nodes.length >= 100 && data.nodes.length < 200) {
      euler_settings["mass"] = 7;
      euler_settings["gravity"] = -1.5;
      euler_settings["springlength"] = 80;
      euler_settings["springcoeff"] = 0.0008;
      euler_settings["pull"] = 0.009;
      euler_settings["maxIterations"] = 3000;
      euler_settings["maxSimulationTime"] = 4000;

    }
    else if (data.nodes.length >= 200 && data.nodes.length < 400) {
      euler_settings["mass"] = 7;
      euler_settings["gravity"] = -1.5;
      euler_settings["springlength"] = 80;
      euler_settings["springcoeff"] = 0.0008;
      euler_settings["pull"] = 0.01;
      euler_settings["maxIterations"] = 5000;
      euler_settings["maxSimulationTime"] = 4000;

    }
    else if (data.nodes.length >= 400 && data.nodes.length < 600) {
      euler_settings["mass"] = 7;
      euler_settings["gravity"] = -1.5;
      euler_settings["springlength"] = 80;
      euler_settings["springcoeff"] = 0.0008;
      euler_settings["pull"] = 0.01;
      euler_settings["maxIterations"] = 7000;
      euler_settings["maxSimulationTime"] = 7000;

    }
    else if (data.nodes.length > 600) {
      euler_settings["mass"] = 7;
      euler_settings["gravity"] = -2.5;
      euler_settings["springlength"] = 80;
      euler_settings["springcoeff"] = 0.0003;
      euler_settings["pull"] = 0.01;
      euler_settings["maxIterations"] = 9000;
      euler_settings["maxSimulationTime"] = 8000;

    }
    return euler_settings;
  }
  load(noOfNodesConsidered: any = this.noOfNodesConsidered, sortedNodesList = this.sortedNodesList, edgeList = this.edgeList, max_degree = this.max_degree, min_degree = this.min_degree, same_node_color: any = false) {
    var timestamp_start = new Date().getTime()
    var sortedNodesListDynamic;
    console.log("edgelist", edgeList)
    //k-core
    if ($("#nodes_sort_advance").children("option:selected").val() == "k-core") {
      //filtering the nodes with k-core = slider value
      sortedNodesListDynamic = _.filter(sortedNodesList, (obj) => {

        console.log("node slider value", String($("#_nodeSlider").val()));
        console.log("k-core data ", this.k_core_data);
        if (this.k_core_data["kcore_nodes"][obj["data"]["id"]]["k-core"][String($("#_nodeSlider").val())] == $("#_nodeSlider").val()) {

          return _.contains(sortedNodesList, obj)

        }

      })
    }
    // if k-core is not chosen we select a sub-list of nodes from the sorted list of nodes
    else {
      console.log("k-core not chosen")
      sortedNodesListDynamic = sortedNodesList.slice(0, noOfNodesConsidered);

    }
    //creating sorted list of node-ids
    var sortedNodesListIds = [];

    console.log("sortedNodesListDynamic ", sortedNodesListDynamic)

    for (let i in sortedNodesListDynamic) {
      if ($("#team_type_dd").val() == "org" && $("#team_dd").val() != "none") {
        sortedNodesListIds.push(sortedNodesListDynamic[i]["data"]["id"]);

      }
      else {
        sortedNodesListIds.push(parseInt(sortedNodesListDynamic[i]["data"]["id"]));

      }
    }
    if (this.collab_within_entity_highlight) {
      sortedNodesListIds = [];
      for (let i in sortedNodesListDynamic) {
        console.log(String(sortedNodesListDynamic[i]["data"][this.entity_type_highlight]));
        console.log(this.entity_highlight_name);

        if (String(sortedNodesListDynamic[i]["data"][this.entity_type_highlight]) == this.entity_highlight_name) {
          sortedNodesListIds.push(sortedNodesListDynamic[i]["data"]["id"]);

        }

      }
    }
    console.log("sorted node ids", sortedNodesListIds)
    var temp_edgeList = edgeList;
    if (String($("#network_dd option:selected").val()) == "combined"
      || String($("#network_dd option:selected").val()) == "professional"
      || String($("#network_dd option:selected").val()) == "personal") {
      $("#daily").prop("disabled", true);
      $("#weekly").prop("disabled", true);
      $("#monthly").prop("disabled", true);
      $("#quarterly").prop("disabled", true);
      $("#edge_freq_apply").prop("disabled", true);
      $("#connection_frequency_label_check").prop("disabled", true);
      $("#daily").prop("checked", true);
      $("#weekly").prop("checked", true);
      $("#monthly").prop("checked", true);
      $("#quarterly").prop("checked", true);
      $("#connection_frequency_label_check").prop("checked", false);



    }
    else {
      $("#daily").prop("disabled", false);
      $("#weekly").prop("disabled", false);
      $("#monthly").prop("disabled", false);
      $("#quarterly").prop("disabled", false);
      $("#edge_freq_apply").prop("disabled", false);
      $("#connection_frequency_label_check").prop("disabled", false);

      temp_edgeList = [];
      if (edgeList.length < 80000) {
        for (let i in edgeList) {

          if ($("#daily").prop("checked") == true && Math.round(edgeList[i]["data"]["frequency"]) == 4) {

            temp_edgeList.push(edgeList[i])
          }
          if ($("#daily").prop("checked") == false && Math.round(edgeList[i]["data"]["frequency"]) == 4) {
            console.log("removing ", edgeList[i]["data"])

          }
          if ($("#weekly").prop("checked") == true && Math.round(edgeList[i]["data"]["frequency"]) == 3) {

            temp_edgeList.push(edgeList[i])

          }
          if ($("#weekly").prop("checked") == false && Math.round(edgeList[i]["data"]["frequency"]) == 3) {
            console.log("removing ", edgeList[i]["data"])


          }
          if ($("#monthly").prop("checked") == true && Math.round(edgeList[i]["data"]["frequency"]) == 2) {

            temp_edgeList.push(edgeList[i])

          }
          if ($("#monthly").prop("checked") == false && Math.round(edgeList[i]["data"]["frequency"]) == 2) {
            console.log("removing ", edgeList[i]["data"])


          }
          if ($("#quarterly").prop("checked") == true && Math.round(edgeList[i]["data"]["frequency"]) == 1) {

            temp_edgeList.push(edgeList[i])

          }
          if ($("#quarterly").prop("checked") == false && Math.round(edgeList[i]["data"]["frequency"]) == 1) {
            console.log("removing ", edgeList[i]["data"])


          }

        }
        console.log("temp_Edge_list", temp_edgeList)

      }

    }
    console.log("temp edgelist", temp_edgeList)


    this.sortedNodesListIds = sortedNodesListIds
    //creating this structure to quickly access edges
    var edgelist_objectform = {}
    for (let i in temp_edgeList) {
      if (edgelist_objectform[temp_edgeList[i]["data"]["source"]]) {
        edgelist_objectform[temp_edgeList[i]["data"]["source"]][temp_edgeList[i]["data"]["target"]] = temp_edgeList[i]
      }
      else {
        edgelist_objectform[temp_edgeList[i]["data"]["source"]] = {}
        edgelist_objectform[temp_edgeList[i]["data"]["source"]][temp_edgeList[i]["data"]["target"]] = temp_edgeList[i]

      }
    }

    // use sorted list of node ids to create list of edges for those nodes

    var edgeListDynamic = _.filter(temp_edgeList, function (obj) {
      var sourcepresent = _.indexOf(sortedNodesListIds, parseInt(obj["data"]["source"]));

      var targetpresent = _.indexOf(sortedNodesListIds, parseInt(obj["data"]["target"]));
      if (sourcepresent == -1 || targetpresent == -1) {
      }
      if (sourcepresent != -1 && targetpresent != -1) {

        return _.contains(temp_edgeList, obj);
      }
    });
    if (this.collab_within_entity_highlight) {
      console.log("within entity high node ids ", sortedNodesListIds)
      edgeListDynamic = _.filter(temp_edgeList, function (obj) {
        var sourcepresent = _.indexOf(sortedNodesListIds, parseInt(obj["data"]["source"]));

        var targetpresent = _.indexOf(sortedNodesListIds, parseInt(obj["data"]["target"]));
        // if (sourcepresent == -1 || targetpresent == -1) {
        // }

        if (sourcepresent != -1 || targetpresent != -1) {
          return _.contains(temp_edgeList, obj);
        }
      });
    }

    if ($("#team_type_dd").val() == "org" && $("#team_dd").val() != "none") {
      console.log("this.collab_bet_entity_ highlight", this.collab_bet_entity_highlight)
      var collab_bet_entity_highlight_local = this.collab_bet_entity_highlight
      var entity_highlight_name_local = this.entity_highlight_name
      console.log("entity_highlight_name_local", entity_highlight_name_local)

      edgeListDynamic = _.filter(temp_edgeList, function (obj) {
        var sourcepresent = _.indexOf(sortedNodesListIds, String(obj["data"]["source"]));

        var targetpresent = _.indexOf(sortedNodesListIds, String(obj["data"]["target"]));
        if (sourcepresent == -1 || targetpresent == -1) {
        }
        else if (sourcepresent != -1 && targetpresent != -1) {
          //  if (collab_bet_entity_highlight_local) {
          //    if (obj["data"]["source"] == entity_highlight_name_local || obj["data"]["target"] == entity_highlight_name_local) {
          //      return _.contains(temp_edgeList, obj);

          //    }
          //  }
          //  else {
          return _.contains(temp_edgeList, obj);

          //}
        }
      });
    }





    //object to store in cytoscape format
    var elements_dynamic = {
      "nodes": sortedNodesListDynamic,
      "edges": edgeListDynamic

    }

    console.log("edgelist_objectform", edgelist_objectform);
    console.log(sortedNodesListIds);
    if ($("#reciprocal_nodes").prop("checked") == true) {

      edgeListDynamic = []
      for (let node in sortedNodesListDynamic) {
        console.log("sortedNodesListDynamic[node]['data']['id']", sortedNodesListDynamic[node]["data"]["id"]);
        if (sortedNodesListDynamic[node]["data"]["in_neighbors"]) {
          var in_neighbors_ids = Object.keys(sortedNodesListDynamic[node]["data"]["in_neighbors"][sortedNodesListDynamic[node]["data"]["id"]]);
          // in_neighbors_ids = this.cy.$('#' + sortedNodesListDynamic[node]["data"]["id"])
          console.log("in_neighbors ", in_neighbors_ids)
          if (sortedNodesListDynamic[node]["data"]["out_neighbors"])
            var out_neighbors_ids = Object.keys(sortedNodesListDynamic[node]["data"]["out_neighbors"][sortedNodesListDynamic[node]["data"]["id"]]);

          var reciprocating_nodes = _.intersection(in_neighbors_ids, out_neighbors_ids)

          if (!edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]]) {
            edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]] = {}
          }
          // if(reciprocating_nodes.length == 0){
          //   sortedNodesListDynamic.splice(node,1);
          // }
          // console.log(" object edge ",edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]]);

          for (let index in reciprocating_nodes) {
            console.log("reciprocating node", reciprocating_nodes[index])


            var targetpresent = _.indexOf(sortedNodesListIds, parseInt(reciprocating_nodes[index]));

            if (targetpresent != -1) {
              console.log(" object edge 2", edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]][reciprocating_nodes[index]]);

              if (edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]] && edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]][reciprocating_nodes[index]]) {

                console.log("source present");

                if (edgelist_objectform[reciprocating_nodes[index]] && edgelist_objectform[reciprocating_nodes[index]][sortedNodesListDynamic[node]["data"]["id"]]) {
                  console.log("target present");

                  edgeListDynamic.push(edgelist_objectform[sortedNodesListDynamic[node]["data"]["id"]][reciprocating_nodes[index]])
                  edgeListDynamic.push(edgelist_objectform[reciprocating_nodes[index]][sortedNodesListDynamic[node]["data"]["id"]])
                }



              }
            }


          }
        }
        else {
          // Example raw data (elements array)

          // Initialize edge list to store reciprocal edges
          edgeListDynamic = [];
          const visitedEdges = new Set(); // To avoid duplicates
          console.log('elements_dynamic', elements_dynamic)
          // Extract only edges from the elements array
          const edges = elements_dynamic.edges.filter((el: any) => el.data.source && el.data.target);

          edges.forEach((edge: any) => {
            const source = edge.data.source;
            const target = edge.data.target;

            // Create edge keys to identify forward and reverse edges
            const edgeKey = `${source}-${target}`;
            const reverseKey = `${target}-${source}`;

            // Check if the reverse edge exists in the data
            const reverseEdge = edges.find((e: any) =>
              e.data.source === target && e.data.target === source
            );

            // If reverse edge exists and hasn't been added yet
            if (reverseEdge && !visitedEdges.has(reverseKey)) {
              edgeListDynamic.push(edge);
              edgeListDynamic.push(reverseEdge);

              // Mark both edges as visited
              visitedEdges.add(edgeKey);
              visitedEdges.add(reverseKey);
            }
          });

          console.log("Reciprocal Edges:", edgeListDynamic);

        }

      }
    }


    elements_dynamic = {
      "nodes": sortedNodesListDynamic,
      "edges": edgeListDynamic

    }





    console.log("dynamic elements ", elements_dynamic);
    console.log("g type ", this.g_type_url_global)
    console.log("n type ", this.n_type_url_global);

    //remove loading icon

    // $("#loadingicon").css("display", "none")

    // $(".lds-hourglass").css("display", "none");
    $(".hide_before_load").css("display", "block");
    $("#top_heading_container").css("display", "block");
    $(".action").css("display", "block");
    $("#nodeSliderCont").css("display", "flex");


    var _top_id = this._top_id;
    var style_flag = this.style_flag;
    var style_node_shape_type = this.style_node_shape_type;
    var style_for_graph = this.style_for_graph;

    $("#open_popup").css("display", "block");
    var layout: any = {}
    var layoutSettings = this.euler_layout_settins(elements_dynamic)

    if (this.individual_tab_active) {
      layout = {
        name: 'euler',
        randomize: true,
        animate: true,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true
        //animate: false,
        // name: 'preset'

        //name: 'spread',
        // minDist: 40

        // some more options here...
      }
    }
    else if (this.teams_tab_active) {
      layout = {
        name: 'euler',
        randomize: true,
        animate: true,
        springLength: edge => layoutSettings["springlength"],
        springCoeff: edge => layoutSettings["springcoeff"], //higher value for tighter springs
        mass: node => layoutSettings["mass"],
        gravity: layoutSettings["gravity"],
        pull: layoutSettings["pull"],
        maxIterations: layoutSettings["maxIterations"],
        maxSimulationTime: layoutSettings["maxSimulationTime"],
        ready: function () { },
        stop: function () { },

      }
    }


    //call cytoscape function to generate core of the graph
    var cy = cytoscape({

      container: document.getElementById('cy'), // container to render in
      // container: null, // container to render in

      elements: elements_dynamic,
      layout: layout,      // hide edge
      // hideEdgesOnViewport: true,


      style: [{
        selector: 'node',
        style: {
          // [] => apply to those that have label
          // ['label']: 'data(first_name)',
          // allign
          "animate": false,
          "height": (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['height']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['height']

            }
            else {
              return style_for_graph["node_size"];

            }
          },
          "width": (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['height']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['width']

            }
            else {
              return style_for_graph["node_size"];

            }

          },


          // set Node color
          'background-color': (ele) => {
            if (this.n_type_url_global != '') {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['background-color']
            }
            else {
              return "#00bfff"
            }
            // if(ele._private.data['grey'] == true) {
            //   return "#808080";
            // }
            // if (style_flag["all"]) {

            //   if (_.contains(_top_id, ele._private.data["id"])) {
            //     console.log("node color for special");
            //     switch (_.indexOf(_top_id, ele._private.data["id"])) {
            //       case 0: {
            //         return "#1E3F66";
            //         break;
            //       }
            //       case 1: {
            //         return "#2E5984";
            //         break;
            //       }
            //       case 2: {
            //         return "#528AAE";
            //         break;
            //       }
            //       case 3: {
            //         return "#73A5C6";
            //         break;
            //       }
            //       case 4: {
            //         return "#91BAD6";
            //         break;
            //       }
            //     }
            //   }
            // }

            // // setting different color for special nodes
            // if (_.contains(_top_id, ele._private.data["id"])) {
            //   return "#FED766";
            // } else
            //   return '#4eb3d3'
          },
          'border-width': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['border-width']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['border-width']

            }
            else {
              return 2;

            }

          },
          'border-color': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['border-color']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['border-color']

            }
            else {
              return '#888';

            }

          },
          'border-opacity': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['border-opacity']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_style']['border-opacity']

            }
            else {
              return 0.7;

            }

          },
          "shape": (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['shape']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['shape']
            }
            // console.log(ele._private.data["first_name"])
            else {
              if (!style_flag["all"]) {
                // if(ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && !style_flag["reduced_graph"]){
                //     return "star"
                // }
                if (_.contains(_top_id, ele._private.data["id"])) {
                  // return "star";
                  return style_node_shape_type["special"];
                } else
                  // return "round-triangle"
                  return style_node_shape_type["default"];
              } else

                return "ellipse"
            }

          },
          "font-size": (ele) => {
            return 15
          }


          // To create PIE chart on node
          // 'content': 'data(dc)',
          // 'pie-size': '80%',
          // 'pie-1-background-color': '#E8747C',
          // 'pie-1-background-size': 'mapData(dc, 0, 3, 0, 100)',
          // 'pie-2-background-color': '#FFCC00',
          // 'pie-2-background-size': 'mapData(bc, 0, 1, 0, 100)',
          // 'pie-3-background-color': '#74E883',
          // 'pie-3-background-size': 'mapData(cc, 0, 1, 0, 100)',
        }
      },
      {
        // 'curve-style': 'bezier' for multy edge
        selector: 'edge',
        style: {
          // 'width': 'data(frequency)',
          //   'label':'data(frequency)',
          // ["label"]:  (ele)=> {
          //     if (style_flag["reduced_graph"]) {
          //         return ele._private.data["frequency"];
          //     }
          //     else
          //         return "";
          // },
          'width': (ele) => {

            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['width']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['width']
            }
            else {
              if ($("#reciprocal_nodes").prop("checked") == true) {
                return 1.5

              }
              if (style_flag["all_single_edge"]) {
                if (ele._private.data["frequency"] >= 1 && ele._private.data["frequency"] < 2)
                  return 0.5;
                if (ele._private.data["frequency"] >= 2 && ele._private.data["frequency"] < 3)
                  return 0.8;
                if (ele._private.data["frequency"] >= 3 && ele._private.data["frequency"] < 4)
                  return 1.2;
                else if (ele._private.data["frequency"] >= 4 && ele._private.data["frequency"] < 5)
                  return 1.5;
                else return 1.5;
              } else if (style_flag["network_7"]) {
                if (ele._private.data["frequency"] == 3)
                  //   return 0.6 * ele._private.data["frequency"];
                  return 1.5;
                else if (ele._private.data["frequency"] == 4)
                  //   return 0.5 + ele._private.data["frequency"];
                  return 3.3;
                else
                  //   return 0.9 * ele._private.data["frequency"];
                  return 1.5;
              } else if (style_flag["all"]) {
                //   if (ele._private.data["frequency"] == 1)
                //   return 0.8;
                // if (ele._private.data["frequency"] == 2)
                //   return 1;
                // if (ele._private.data["frequency"] == 3)
                //   return 1.2;
                // else if (ele._private.data["frequency"] == 4)
                //   return 1.4;
                return 0.8;
              } else if (style_flag["formal_informal_graph"]) {
                return 2;
              } else {
                return 1;
              }
            }


          },
          ["label"]: (ele) => {
            if ($("#connection_frequency_label_check").prop("checked") == true) {
              if (ele["_private"]["data"]["frequency_str"]) return ele["_private"]["data"]["frequency_str"];
              else if (ele["_private"]["data"]["frequency"]) {
                return this.frequency_str_map[String(Math.round(ele["_private"]["data"]["frequency"]))]
              }
              else {
                return ""
              }
            }
            else {
              return ""
            }

          },

          'line-color': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['line-color']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['line-color']
            }
            else {
              if (style_flag["all_single_edge"]) {
                if (ele._private.data["frequency"] == 3) {
                  //   return "#605052";
                  return "#2B2B2F";
                } else
                  return "#787878"
              } else if (style_flag["network_7"] || style_flag["all"]) {


                switch (ele._private.data["network"]) {

                  case "work": {
                    return "#3FBD98";
                    break;
                  }
                  case "trust": {
                    return "#ff764a";
                    break;
                  }
                  case "decision_making": {
                    return "#ef5675";
                    break;
                  }
                  //
                  case "career_advice": {
                    return "brown";
                    break;
                  }
                  case "innovation": {
                    return "#7a5195";
                    break;
                  }
                  //
                  case "awareness": {
                    return "#36413E";
                    break;
                  }
                  case "expertise": {
                    return "#003f5c";
                    break;
                  }
                }
              } else if (style_flag["reduced_graph"] || style_flag["formal_informal_graph"]) {
                console.log(ele);
                if (style_for_graph["formal"] || ele._private.data["edge_is"] == "formal") {
                  return "#77DD76";
                } else if (style_for_graph["informal"] || ele._private.data["edge_is"] == "informal") {
                  return "#FE7E03";
                }
              } else {
                return "#454545";
              }
            }

          },

          'opacity': () => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['opacity']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['opacity']
            }
            else {
              if (style_flag["network_7"] || style_flag["all_single_edge"]) {
                return 0.9;
              } else if (style_flag["all"]) {
                return 1;
              } else {
                return 0.6;
              }
            }
          },
          'arrow-scale': () => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['arrow-scale']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['arrow-scale']
            }
            else {
              return 1
            }
          },
          'padding':
            () => {
              if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['padding']) {
                return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['adding']
              }
              else {
                return "1em"
              }
            },
          'target-arrow-shape': () => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['target-arrow-shape']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['target-arrow-shape']
            }
            else {
              return 'triangle'
            }
          },
          'source-arrow-shape': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['source-arrow-shape']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['source-arrow-shape']
            }
            else {
              if (style_flag["reduced_graph"] || style_flag["formal_informal_graph"]) {
                if (ele._private.data["bidirectional"] > 0) {
                  return 'triangle';

                } else
                  return "tee";
              } else
                return "tee";
            }
          },
          //   'source-arrow-shape': 'tee',
          'source-arrow-fill': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['source-arrow-fill']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['source-arrow-fill']
            }
            else {
              if ((style_flag["reduced_graph"] || style_flag["formal_informal_graph"]) && ele._private.data["bidirectional"] > 0) {
                return "filled";
              } else return "hollow"
            };
          },
          'source-distance-from-node': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['source-distance-from-node']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['source-distance-from-node']
            }
            else {
              return '5px'
            }
          },
          'target-distance-from-node': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['target-distance-from-node']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['target-distance-from-node']
            }
            else {
              return '5px'
            }
          },
          //   'line-cap':'round',
          'target-arrow-color': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['target-arrow-color']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['target-arrow-color']
            }
            else {
              return '#58666E'
            }
          },
          'source-arrow-color': (ele) => {
            if ((style_flag["reduced_graph"] || style_flag["formal_informal_graph"]) && ele._private.data["bidirectional"] > 0) {
              return "#58666E";
            } else {
              return "#848482"
            }
          },
          //   'line-dotted-pattern': [4, 4],
          //   'target-arrow-color': (ele)=>{

          //     switch(ele._private.data["network"]){
          //         case "work": {
          //             return "#2EB67D";
          //             break;
          //         }
          //         case "trust": {
          //             return "#ff764a";
          //             break;
          //         }
          //         case "decision_making": {
          //             return "#ef5675";
          //             break;
          //         }
          //         //
          //         case "career_advice": {
          //             return "brown";
          //             break;
          //         }
          //         case "innovation": {
          //             return "#7a5195";
          //             break;
          //         }
          //         //
          //         case "awareness": {
          //             return "#36413E";
          //             break;
          //         }
          //         case "expertise": {
          //             return "#003f5c";
          //             break;
          //         }
          //     }


          //   },
          //
          'line-style': (ele) => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['line-style']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['line-style']
            }
            else {
              if (style_for_graph["graphSize"] <= style_for_graph["maxGraphSizeForSmallGraph"]) {
                // console.log(ele);
                if (style_flag["all_single_edge"]) {

                  // console.log(ele._private.data["frequency"])
                  if (ele._private.data["frequency"] == 1)
                    return "solid";
                  // return "dashed";
                  //   if (ele._private.data["frequency"] == 2)
                  //       return "solid";
                  //   // return "dotted";
                  //   if (ele._private.data["frequency"] == 3)
                  //       return "solid";
                  //   if (ele._private.data["frequency"] == 4)
                  //      return "solid";
                  else {
                    return "solid";
                  }
                } else if (style_flag["network_7"]) {

                  // console.log(ele._private.data["frequency"])
                  if (ele._private.data["frequency"] == 1) {
                    // return "dashed";
                    return "solid";
                  }
                  if (ele._private.data["frequency"] == 2) {
                    // return "dotted";
                    return "solid";
                  } else {
                    // console.log(ele);
                    return "solid";
                  }

                } else {
                  return "solid";
                }
              } else {
                return "solid";
              }
            }
          },
          "curve-style": () => {
            if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['curve-style']) {
              return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style'][' curve-style']

            }
            else {
              if (style_for_graph["graphSize"] <= style_for_graph["maxGraphSizeForSmallGraph"]) {
                // if(style_flag["reduced_graph"] || style_flag["formal_informal_graph"]){
                //     return "bezier";
                // }
                // else
                return "unbundled-bezier";
              } else {
                return "unbundled-bezier";
              }
            }
          },
          "control-point-distance": (ele) => {
            if (this.general_tab_active) {
              if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['control-point-distance']) {
                return this.graph_spec[this.g_type_url_global][this.n_type_url_global]['edge_style']['control-point-distance']

              }
              else {
                if (!style_flag["reduced_graph"] && !style_flag["formal_informal_graph"]) {
                  if (ele._private.data["frequency"] > 20) {
                    // Random betwwen 5-15
                    return Math.floor(Math.random() * 5) + 1;
                  } else
                    return ele._private.data["frequency"] * 15;
                } else
                  return Math.floor(Math.random() * 25) + 15;
              }
            }
            else return Math.floor(Math.random() * 25) + 15;
          }


          // "curve-style": "unbundled-bezier",
          // "control-point-distance": 30,
          // "control-point-weight": 0.5,

          // "flipLabel": true,
          // "control-point-step-size": 40,
          // 'curve-style': 'haystack',
          // 'haystack-radius': 0,
        }
      },
      {
        selector: ".hidden",
        style: {
          'display': 'none',
        }
      },

      {
        selector: 'node.selected',
        style: {
          // 'background-color': '#ef3b2c',
          'background-color': '#ffcc00',
          'border-width': 3,
          'border-style': 'solid',
          'border-color': '#c51b7d',
          'opacity': 1,
          'label': style_for_graph["_node_label"],

        }
      },
      {
        selector: 'node.rank',
        style: {
          "text-background-opacity": 0.1,
          "height": (ele) => {
            console.log(ele);
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top']) {
              return style_for_graph["node_size"] + 5
            }
            return style_for_graph["node_size"];
          },
          "width": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top']) {
              return style_for_graph["node_size"] + 5
            }
            return style_for_graph["node_size"];
          },

          "shape": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top']) {
              return "star"
            } else {
              return 'ellipse';
            }
          },
          "background-color": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top']) {
              return "#FED766";
            } else {
              return '#4eb3d3';
            }

          }

        }
      },

      {
        selector: 'node.label',
        style: {
          "text-valign": 'center',
          "text-halign": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
              return 'center';
            }
            return 'center';
          },
          //  "text-background-opacity":  (ele)=> {

          //   if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
          //     return 0.1;
          //   }
          //   return 1;

          // },
          "text-background-padding": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
              return 5;
            } else
              return 1;
          },
          // "text-border-color":  (ele)=> {
          //   if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
          //     return "";
          //   } else {
          //     return "";
          //   }
          // }, 
          "text-margin-y": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
              return -10;
            }
            return 0;
          },
          // <----style text back
          "color": (ele) => {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
              return '#000';
            }
            return "#000";
          },
          // "text-background-color":  (ele)=> {
          //   if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
          //     return "#fff";
          //   }
          //   return "#fff";
          // },
          // "text-background-opacity":1,

          "text-background-shape": "roundrectangle",
          // "text-border-color":"#000",

          // "text-border-width": 1,
          // "text-border-opacity": 1,
          // ---->
          'font-family': 'FontAwesome, Arial, sans-serif',
          "font-size": style_for_graph["label_size"],
          ['label']: function (ele) {
            if (ele._private.data[style_for_graph['rank_by']] <= style_for_graph['rank_top'] && style_for_graph['show_rank']) {
              let str = String(ele._private.data[style_for_graph["_node_label"]]);
              console.log(str)
              let upercase = str.charAt(0).toUpperCase() + str.slice(1);

              // return upercase + ' [ ' + ele._private.data[style_for_graph['rank_by']] + ' ]';
              return '';
            }
            let str = String(ele._private.data[style_for_graph["_node_label"]]);
            console.log(str)

            let upercase = str.charAt(0).toUpperCase() + str.slice(1);
            // return upercase;
            return '';
          },
          // performance label
          "min-zoomed-font-size": style_for_graph["min-zoomed-font-size"],
          'z-index': 12,
          // "text-transform": "capitalize"
        }
      },
      {
        selector: 'node.imageF',
        style: {
          // 'background-image': 'assets/f.jpg',
          'background-color': 'salmon',
          'label': 'data(gender)',


        }
      },
      {
        selector: 'node.imageM',
        style: {
          // 'background-image': 'assets/m.jpg',
          'background-color': '#1abc9c',
          'label': 'data(gender)',
        }
      },
      {
        selector: 'node.defaulter',
        style: {
          'label': 'data(first_name)',
          'background-color': '#808080'

        }
      },
      {
        selector: 'node.highlightN',
        style: {
          // <---
          // 'background-color': '#D6ED17',
          // 'border-width': 2,
          // --->
          // 'border-style': 'dashed',
          // 'border-color': '#ef3b2c',
          // 'label': 'data(first_name)',
          'opacity': 1,
          'z-index': 10
        }
      },
      {
        selector: 'edge.highlightE',
        style: {
          // 'width': 2,
          // 'line-color': '#F2AA4C',
          // 'target-arrow-shape': 'triangle',
          // 'target-arrow-color': '#737373',
          'curve-style': 'unbundled-bezier',
          'opacity': 1,
          // "edge-text-rotation": "autorotate",
          // 'label': 'data(frequency)',
          'z-index': 10

        }
      },
      {
        selector: 'edge.parallel',
        style: {
          'line-color': '#b6200f',
          // 'width': 2,
          // 'line-color': '#F2AA4C',
          // 'target-arrow-shape': 'triangle',
          // 'target-arrow-color': '#737373',
          // 'curve-style': 'bezier',
          'opacity': 1,
          // "edge-text-rotation": "autorotate",
          // 'label': 'data(frequency)',
          // 'z-index': 10

        }
      },
      {
        selector: 'node.hideN',
        style: {
          'opacity': 0.1,
          'label': '',
          // "font-size": 8,
          // "height": 10,
          // "width": 10,

        }
      },
      {
        selector: 'edge.hideE',
        style: {
          // 'opacity': 0,
          'display': 'none'


        }
      },
      {
        selector: 'edge.label',
        style: {
          ["label"]: function (ele) {
            if ($("#connection_frequency_label_check").prop("checked") == true) {
              if (ele["_private"]["data"]["frequency_str"]) return ele["_private"]["data"]["frequency_str"];
              else if (ele["_private"]["data"]["frequency"]) {
                return this.frequency_str_map[String(Math.round(ele["_private"]["data"]["frequency"]))]
              }
              else {
                return ""
              }
            }
            else {
              if (!style_flag['reduced_graph'] && !style_flag["formal_informal_graph"]) {
                let additional_info = "";
                if (style_for_graph["_edge_label"] == "network") {
                  additional_info = ele._private.data["frequency"];

                } else if (style_for_graph["_edge_label"] == "frequency") {
                  additional_info = ele._private.data["network"];
                }
                return ele._private.data[style_for_graph["_edge_label"]] + " : " + additional_info;
              } else
                return "";
            }

          },
          "text-rotation": function () {
            if (!style_flag['reduced_graph'] && !style_flag["formal_informal_graph"]) {
              return "autorotate";
            } else
              return 0;
          },
          ["source-label"]: function (ele) {
            if (style_flag['reduced_graph'] || style_flag["formal_informal_graph"]) {
              if (ele._private.data["bidirectional"] > 0) {
                if (style_for_graph["_edge_label"] == "count") {
                  return ele._private.data["in_count"];
                } else
                  return ele._private.data["bidirectional"];
              } else return "";
            } else return "";
          },
          ["target-label"]: function (ele) {
            if (style_flag['reduced_graph'] || style_flag["formal_informal_graph"]) {
              if (style_for_graph["_edge_label"] == "count") {
                return ele._private.data["out_count"];
              } else
                return ele._private.data["frequency"];

            } else "";
          },
          "target-text-offset": function (ele) {
            return 50;
          },
          "font-size": function () {
            if (!style_flag['reduced_graph'] && !style_flag["formal_informal_graph"]) {
              return style_for_graph["label_size"] - 5;
            } else
              return style_for_graph["label_size"];
          },
          "source-text-offset": function (ele) {

            return 50;

          },
          'color': '#000000',
          'text-background-color': '#fff',
          'text-background-opacity': 1,
          'text-background-shape': 'roundrectangle'


        }
      },
      {
        selector: 'node.hideN0',
        style: {
          'opacity': 0,
          'visibility': "hidden",

        }
      },
      { // used in internal network
        selector: 'edge.hideE0',
        style: {
          'opacity': 0,
          'visibility': "hidden"

        }
      },
      {
        selector: 'node.querySelect',
        style: {
          // 'background-color': '#ec7014',
          'border-width': 2,
          // 'border-style': 'solid',
          'border-color': '#fff'
        }
      },
      {
        "selector": "node[label].outline",
        "style": {
          "color": "#fff",
          "text-outline-color": "#888",
          "text-outline-width": 3
        }
      }
      ],



    });


    setTimeout(() => {
      $("#loadingicon").css("display", "none")
      $(".lds-hourglass").css("display", "none");

      ;
    }, 200)
    //assign core graph as class variable
    this.cy = cy
    this.cy.resize()
    this.cy.fit()
    console.log("cy", this.cy)
    if (this.show_collab_within_entity) {
      var rm_email_list_temp: any = []
      for (let i of this.cy.nodes()) {
        if (i["_private"]) {
          if (rm_email_list_temp.includes(i["_private"]["data"]["reporting_manager"])) continue;
          else rm_email_list_temp.push(i["_private"]["data"]["reporting_manager"])
        }
      }
      for (let rm_email of rm_email_list_temp) {
        for (let node of this.cy.nodes()) {
          if (node["_private"]) {
            if (node["_private"]["data"]["email"] == rm_email) {
              for (let i of node.neighborhood('node')) {
                if (i["_private"]["data"]["reporting_manager"] == rm_email) {
                  this.cy.remove(node.edgesWith(i))
                }
              }
            }
          }
        }

      }

    }

    console.log("---------------------------");
    console.log("JSON before removing -1");
    console.log(cy.json());

    //change node size by checking node_size_by 
    if (this.graph_spec[this.g_type_url_global][this.n_type_url_global]['node_size_by']) {
      if ($("#node_size_dd").children("option:selected").val() == 'indegree') {
        this.NodeSize('indegree', cy.nodes()).then(() => {
          // if (this.colorsingraphvalue.length != 0)
          // this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", true)
          // else {
          // this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", same_node_color)

          // }
        });

      }
      else if ($("#node_size_dd").children("option:selected").val() == 'outdegree') {
        this.NodeSize('outdegree', cy.nodes()).then(() => {
          // if (this.colorsingraphvalue.length != 0)
          //   this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", true)
          // else {
          //   this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", same_node_color)

          // }
        });

      }
      if ($("#node_size_dd").children("option:selected").val() == 'collab_score' || this.show_collab_bet_entity) {
        $("#node_size_dd").val('collab_score').trigger('change')
        this.NodeSize('collab_score', cy.nodes()).then(() => {
          //change node color

          // if (this.colorsingraphvalue.length != 0)
          //   this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", true)
          // else {
          //   this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", same_node_color)

          // }
        });

      }
      if ($("#node_size_dd").children("option:selected").val() == 'engagement' || this.g_type_url_global == "engagement") {
        $("#node_size_dd").val('engagement').trigger('change')
        this.NodeSize('engagement', cy.nodes()).then(() => {
          //change node color

          // if (this.colorsingraphvalue.length != 0)
          //   this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", true)
          // else {
          //   this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", same_node_color)

          // }
        });

      }



    }
    if (this.colorsingraphvalue.length != 0)
      this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", true)
    else {
      this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, "", same_node_color)

    }
    this.change_node_label();

    console.log("---------------------------");
    var j = cy.$('#-1');
    cy.remove(j);
    // cy.remove('node[id = -1]');
    console.log("JSON after removing -1")
    console.log(cy.json());

    console.log("---------------------------");
    let defaulter_nodes = cy.collection();
    _.each(this.survey_defaulter, function (id) {
      var j = cy.$('#' + id);
      defaulter_nodes = defaulter_nodes.union(j);
    });
    if (this.style_for_graph["remove_defaulter_nodes"]) {
      console.log("Removing survey defaulters:");
      cy.remove(defaulter_nodes);
    } else {
      console.log("color survey defaulters:");
      defaulter_nodes.toggleClass("defaulter");
    }

    console.log("JSON after removing defaulters:")
    console.log(cy.json());
    console.log("---------------------------");

    //if graph between two specific entities is to be shown
    if (this.two_specific_entities_boolean) {
      setTimeout(() => {
        this.two_entities_graph();

      }, 300)
    }

    if (this.g_type_url_global != 'informal-communities') {
      this.graph_manipulation();


    }
    else if (this.g_type_url_global == 'informal-communities') {
      this.communities(same_node_color);
    }
    else if (this.group_as_single_node == 0 && $("#team_type_dd").children("option:selected").val() == 'org' &&
      $("#team_dd").children("option:selected").val() != 'none') {

      this.group_nodes_as_teams;
    }
    else if (style_flag['all']) {

      var layout = cy.layout({
        name: 'concentric',
        // minDist: 100,

        // attribute to use
        // concentric: function (node) {
        //     console.log("concentric layout setting");
        //     if (style_flag["all"]) {
        //         return 1000 * node._private.data["PageRank"];
        //     } else return node.degree();
        // }, levelWidth: function (nodes) {
        //     return 2;
        // }

      });


      layout.run();

    } else if (style_flag["reduced_graph"] || style_flag["formal_informal_graph"] || style_flag["sub_graph"]) {

      let style_for_graph = this.style_for_graph;
      cytoscape.use(euler);
      cy.layout({
        name: 'euler',
        fit: true,
        animate: true,
        springLength: function (edge) {
          return Math.floor(Math.random() * 200) + 100;
          // return 200;
        },
        springCoeff: edge => 0.0008,
        mass: function (node) {
          if ((node._private['edges']).length > 0) {

            // console.log(node._private.data);
            if (style_for_graph['rank_by'] in node._private.data) {
              return 100 / node._private.data[style_for_graph['rank_by']]
            }
            let deg = node.degree();
            // console.log(deg)
            if (deg > 5) {
              return Math.floor(Math.random() * 10) + 5;
            } else
              return deg;
          } else {
            return 1;
          }
        },
        // gravity: -20,
        // spreads the node but makes small
        pull: 0.001,
        // Padding in rendered co-ordinates around the layout
        padding: 10,
      }).run();
    } else {
      cytoscape.use(euler);
      // pagerank
      console.log("------------");
      console.log("Page Rank");
      var pr = cy.elements().pageRank()
      // cy.nodes().forEach(function( ele ){
      //     console.log();
      //     console.log( ele._private.data["first_name"]+" : "+pr.rank('#'+ele.id()));
      // });
      console.log("------------");

      var nodesWithConnection = [];
      var maxPr = 1;
      cy.layout({
        name: 'euler',
        // fit: true,
        // animate: true,
        springLength: function (edge) {
          // console.log(edge);
          // return 200;
          if (edge._private.data.frequency > 8) {
            // Random betwwen 5-15
            return Math.floor(Math.random() * 10) + 1;
          } else
            return edge._private.data.frequency * 200;
        },
        springCoeff: edge => 0.0008,
        // mass: node => 4,
        mass: function (node) {

          var neighbor = cy.$('#' + node._private.data.id).neighborhood();
          // if(node._private.data.id == 1494)
          // console.log(neighbor);

          if (neighbor["length"] >= 1) {
            nodesWithConnection.push('#' + node._private.data.id);
            var pageRank = pr.rank('#' + node._private.data.id);
            maxPr = pageRank > maxPr ? pageRank : maxPr;
            return pageRank * 1000;
          } else {
            return maxPr * 20;
          }
        },
        gravity: -1.2,
        // A force that pulls nodes towards the origin (0, 0)
        // Higher values keep the components less spread out
        pull: 0.001,
        // theta: 0.666,
        // dragCoeff: 0.009,
        // maxIterations: 1000,
        // maxSimulationTime: 4000,
      }).run();
      this.nodesWithConnection = nodesWithConnection.join(",");
      console.log(this.nodesWithConnection);
      cy.fit(cy.$(this.nodesWithConnection));


    }


    this.grphLoaded = true;
    this.cy = cy;
    this.lastSelection = cy.nodes();

    // pan zoom
    cy.ready(this.panZoom());
    cy.ready(this.node_cxtmenu());
    cy.ready(this.core_cxtmenu());
    cy.ready(this.edge_cxtmenu());

    cy.ready(this.edgeEdit());

    cy.ready(this.toogleLabel());
    cy.ready(this.createNotification("info", "Graph nodes : " + this.lastSelection.length));


    var querySelectors = this.querySelectors;
    var isVisible = this.isVisible;
    //tapping anywhere on the 
    cy.on('tap', (event) => {

      // target holds a reference to the originator
      // of the event (core or element)
      var evtTarget = event.target;
      var nodeData = evtTarget['_private']['data'];
      var rows = "";
      _.each(querySelectors, function (val, index) {
        if (val != "id" && val != "value" && val != "name" && (val == "first_name" || val == "last_name" || val == "designation" || val == "department" || val == "hierarchy_level")) {
          var rowData = val == 'label' ? nodeData['gender'] == 'male' ? `<td style=' color: #4eb3d3;'>` + nodeData['gender'] + `</td>` : `<td style='  color: salmon;'>` + nodeData[val] + `</td>` : (typeof nodeData[val]) === "object" ? `<td>` + JSON.stringify(nodeData[val]) + `</td>` : `<td>` + nodeData[val] + `</td>`;
          //       rows = rows +
          //         `
          // <tr>
          // <th scope="row">` + (index + 1) + `</th>
          // <td>` + val + `</td>` +
          //         rowData +
          //         `
          // </tr>

          //   `;
          val = val == "first_name" ? "First Name" : val == "last_name" ? "Last Name" : val == "designation" ? "Designation" : val == "department" ? "Deparment" : val == "hierarchy_level" ? "Hierarchy Level" : val
          rows = rows +
            `
<tr>
<td>` + val + `</td>` +
            rowData +
            `
</tr>

`;
        }

      });
      var detailsTable = String.raw`
    <table height="60%" class="table" style="color: #fff;font-size: .8em;">
    <caption style="caption-side: top;color:#fff">Node Info</caption>
      <thead style="background-color: #6c7ae0">
        <tr>
          <th scope="col">Label</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
      ` +
        rows +
        `
      </tbody>
    </table>

    `;
      try {

        // ----
        if (evtTarget == this.selected_target) {
          console.log("evttarget ", evtTarget);
          // console.log('show');
          let element = document.getElementById('details');
          element.innerHTML = detailsTable;
          element.className = 'show';
          // document.getElementById('result').className = 'hide';
        } else {
          this.selected_target = evtTarget;
          console.log('hide');

          let element = document.getElementById('details');
          element.innerHTML = "";
          element.className = 'hide';
          if (all_legends_selected.length == 0) {

            console.log("node clicked -> legends selected ", all_legends_selected);
            console.log("node clicked ", nodeClicked, " node ", this.selected_target);
            $("#firstDiv").css("display", "none");
            $("#secondDiv").css("width", "100%");
            // setTimeout(() => {
            //   cy.fit();
            //   cy.center();
            // }, 200);
            selected_node = '';
            console.log("selected id 0.5", selected_node);
            var neighbourOfSelected = cy.$(this.selected_target).neighborhood();
            cy.elements().not(neighbourOfSelected).removeClass('hideN hideE');
            neighbourOfSelected.removeClass('highlightN outline');
            cy.$(this.selected_target).removeClass('selected');
            cy.$(this.selected_target).edgesWith(neighbourOfSelected).removeClass('highlightE');
            if (style_for_graph["graphSize"] <= style_for_graph["maxGraphSizeForSmallGraph"]) {
              cy.fit(cy.elements());
            } else {
              cy.fit(cy.$(nodesWithConnection));
            }
            nodeClicked = 0;

            // Hide details
            let element = document.getElementById('details');
            element.innerHTML = "";
            element.className = 'hide';
            document.getElementById('result').className = 'show';
          }

          else {
            $("#firstDiv").css("display", "none");
            $("#secondDiv").css("width", "100%");
            // cy.fit();
            // setTimeout(() => {
            //   cy.fit();
            //   cy.center();
            // }, 200);
            selected_node = '';

            nodeClicked = 0;

            console.log("inside tap else");
            cy.nodes().removeClass("hideN");
            cy.edges().removeClass("hideE");
            cy.nodes().addClass("hideN");
            cy.edges().addClass("hideE");

            let graph_nodes_legend_click = cy.nodes();
            let graph_edges_legend_click = cy.edges();
            for (let i in graph_nodes_legend_click) {
              if (graph_nodes_legend_click[i]["_private"]) {
                console.log("all legends selected ", all_legends_selected);
                for (let j in all_legends_selected) {
                  console.log("nde dd", $("#node_color_dd").children("option:selected").val());
                  console.log("g_Type", this.g_type_url_global);

                  if ($("#node_color_dd").children("option:selected").val() == this.g_type_url_global) {
                    console.log("default node selected");
                    for (let k in this.module_specific_nodes["people_details"]) {
                      var network = this.n_type_url_global;
                      if (this.n_type_url_global == "combined") {
                        network = "main_combined"
                      }
                      if (this.n_type_url_global == "professional") {
                        network = "main_professional"
                      }
                      if (this.n_type_url_global == "personal") {
                        network = "main_personal"
                      }
                      if (graph_nodes_legend_click[i]["_private"]["data"]["value"] == this.module_specific_nodes["people_details"][k]["id"] && this.module_specific_nodes["people_details"][k][network] == 1) {
                        graph_nodes_legend_click[i].removeClass("hideN");
                        graph_nodes_legend_click[i].addClass("highlightN outline");


                        selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                      }
                    }
                  }

                  else {
                    if (graph_nodes_legend_click[i]["_private"]["data"][$("#node_color_dd").children("option:selected").val()].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == all_legends_selected[j]) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }
                  }

                }
              }
            }
            for (let i in graph_edges_legend_click) {
              if (graph_edges_legend_click[i]["_private"]) {
                let sourcepresent = _.indexOf(selected_nodes_legend, parseInt(graph_edges_legend_click[i]["_private"]["data"]["source"]));

                let targetpresent = _.indexOf(selected_nodes_legend, parseInt(graph_edges_legend_click[i]["_private"]["data"]["target"]));
                if (sourcepresent != -1 && targetpresent != -1) {
                  graph_edges_legend_click[i].removeClass("hideE");
                  graph_edges_legend_click[i].addClass("highlightE");


                }
                // for(let j in all_legends_selected){
                //   console.log()
                //   if(graph_edges_legend_click[i]["_private"]["data"][this.graph_spec[this.g_type_url_global][this.n_type_url_global]["node_color_by"]].replace(/\s/g).toLowerCase() == all_legends_selected[j]){
                //     graph_edges_legend_click[i].style("opacity","1");
                //   }

                // }
              }
            }

            if (style_for_graph["graphSize"] <= style_for_graph["maxGraphSizeForSmallGraph"]) {
              cy.fit(cy.elements());
            } else {
              cy.fit(cy.$(nodesWithConnection));
            }





          }

        }

        // --
      } catch (err) {
        console.log(err);
      }

    });

    var nodeClicked = 0;
    cy.on('mouseover', 'node', (evt) => {
      if (this.g_type_url_global == "onps") {
        return
      }

      var collab_nodeData = evt.target.data();

      var remainingElements = cy.elements().difference(evt.target.outgoers().union(evt.target.incomers()));
      this.nodeHoverEvent("_nodeInfo", collab_nodeData, evt, remainingElements);





    });
    var close_color_legend = 0

    $("#graph_node_legend > div > div").on("mouseenter", (element) => {
      clearTimeout(this.timer)

      console.log("element ", element.relatedTarget["id"])
      this.dynamic_change_color(element.relatedTarget["id"], $("#" + element.relatedTarget['id'] + " > span").text())
      this.timer = setTimeout(() => {
        $("#legends_color_menu").remove()
        $("#node_color_menu").css("display", "none")

      }, 7000);
    })






    // $(document).on('click','#viewgraphscreen',function () {
    //   setTimeout(() => {
    //     // $('body').addClass('graphscreen-active');
    //   }, 100)


    // });
    // $(document).on('click','#closegraphscreen',function () {

    //   $('body').removeClass('modal-active');

    // })
    cy.on('mouseout', 'node', (evt) => {

      var remainingElements = cy.elements().difference(evt.target.outgoers().union(evt.target.incomers()));

      this.nodeHoverEventOut("_nodeInfo", evt, remainingElements);
    });
    cy.on('tap', 'node', (evt) => {
      if (this.g_type_url_global == "onps") {
        return
      }
      console.log('+++++++++++');
      console.log("clicked");
      console.log("clicked node id ", evt.target.data.id);
      // cy.$(node).position({
      //     x: 123,
      //     y: 200
      //   });
      var node = evt.target;
      var node_presentin_legend = _.indexOf(all_legends_selected, (ele) => { return node.data[String($("#node_color_dd").children("option:selected").val())] })

      // var selected = cy.$('#' + node.id());
      var selected = cy.$(node);
      this.selected_target = evt.target;
      selected_node = selected["_private"]["map"].keys().next().value;

      console.log("selected id 0", selected_node);
      console.log("legend selected ", all_legends_selected);

      var neighbourOfSelected = selected.neighborhood();

      if (all_legends_selected.length == 0) {
        console.log("node clicked -> legends selected ", all_legends_selected);
        console.log("node clicked ", nodeClicked, " node ", node);
        if (nodeClicked == node) {
          $("#firstDiv").css("display", "none");
          $("#secondDiv").css("width", "100%");
          // setTimeout(() => {
          //   cy.fit();
          //   cy.center();
          // }, 200);
          selected_node = '';
          console.log("selected id 0.5", selected_node);
          // cy.elements().removeClass('hideN hideE highlightN outline selected highlightE');

          cy.elements().not(neighbourOfSelected).removeClass('hideN hideE');
          neighbourOfSelected.removeClass('highlightN outline');

          selected.removeClass('selected highlightN outline');
          selected.edgesWith(neighbourOfSelected).removeClass('highlightE');
          // cy.elements().style("opacity","1");
          if (style_for_graph["graphSize"] <= style_for_graph["maxGraphSizeForSmallGraph"]) {
            cy.fit(cy.elements());
          } else {
            cy.fit(cy.$(nodesWithConnection));
          }
          nodeClicked = 0;

          // Hide details
          let element = document.getElementById('details');
          element.innerHTML = "";
          element.className = 'hide';
          document.getElementById('result').className = 'show';





        } else if (nodeClicked != node) {
          // $("#firstDiv").css("display", "inline-block");
          // $("#secondDiv").css("width", "70%");

          nodeClicked = node;
          selected_node = selected["_private"]["map"].keys().next().value;;

          cy.elements().removeClass('hideN hideE highlightN outline selected highlightE');

          // Hide remaining
          cy.elements().not(neighbourOfSelected.merge(selected)).addClass('hideN hideE');

          // Highlight neighbourOfSelected
          neighbourOfSelected.addClass('highlightN outline');

          selected.addClass('selected');
          // Highlight edges between selected and neighbourOfSelected
          selected.edgesWith(neighbourOfSelected).addClass('highlightE');
          // Fit slected to view
          // cy.center(selected);
          cy.fit(neighbourOfSelected, 20);
          //
          console.log('----------');
          // _.each(neighbourOfSelected, function (node) {
          //   console.log(node);
          //   console.log(cy.$(node).position());
          // });
          console.log('+++++++++++');


        }

      }
      else {
        if (nodeClicked == node) {
          $("#firstDiv").css("display", "none");
          $("#secondDiv").css("width", "100%");
          // cy.fit();
          // setTimeout(() => {
          //   cy.fit();
          //   cy.center();
          // }, 200);
          selected_node = '';

          nodeClicked = 0;

          console.log("inside tap else");
          cy.nodes().removeClass("hideN");
          cy.edges().removeClass("hideE");
          cy.nodes().addClass("hideN");
          cy.edges().addClass("hideE");
          selected.removeClass('selected highlightN outline');


          let graph_nodes_legend_click = cy.nodes();
          let graph_edges_legend_click = cy.edges();
          for (let i in graph_nodes_legend_click) {
            if (graph_nodes_legend_click[i]["_private"]) {
              for (let j in all_legends_selected) {
                if ($("#node_color_dd").children("option:selected").val() == this.g_type_url_global) {
                  console.log("default node selected");
                  if (this.g_type_url_global == "engagement") {
                    if (graph_nodes_legend_click[i]["_private"]["data"][all_legends_selected[j]]) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }

                  }
                  else if (this.g_type_url_global == "informal-communities") {
                    if (graph_nodes_legend_click[i]["_private"]["data"]["Community"] == all_legends_selected[j]) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }
                  }
                  else if (this.g_type_url_global == "collaboration" && this.collab_bet_entity_highlight) {
                    if (graph_nodes_legend_click[i]["_private"]["data"]["id"].toLowerCase() == all_legends_selected[j].toLowerCase) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }
                  }
                  else if (this.g_type_url_global == "disc_emp") {
                    if (graph_nodes_legend_click[i]["_private"]["data"][all_legends_selected[j]] == all_legends_selected[j]) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }
                  }
                  else if (this.g_type_url_global == "collab_overload") {
                    if (graph_nodes_legend_click[i]["_private"]["data"][all_legends_selected[j]] == 1) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }
                  }
                  else {
                    for (let k in this.module_specific_nodes["people_details"]) {
                      var network = this.n_type_url_global;
                      if (this.n_type_url_global == "combined") {
                        network = "main_combined"
                      }
                      if (this.n_type_url_global == "professional") {
                        network = "main_professional"
                      }
                      if (this.n_type_url_global == "personal") {
                        network = "main_personal"
                      }
                      if (graph_nodes_legend_click[i]["_private"]["data"]["value"] == this.module_specific_nodes["people_details"][k]["id"] && this.module_specific_nodes["people_details"][k][network] == 1) {
                        graph_nodes_legend_click[i].removeClass("hideN");
                        graph_nodes_legend_click[i].addClass("highlightN outline");


                        selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                      }
                    }
                  }

                }

                else {
                  if (graph_nodes_legend_click[i]["_private"]["data"][$("#node_color_dd").children("option:selected").val()].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == all_legends_selected[j]) {
                    graph_nodes_legend_click[i].removeClass("hideN");
                    graph_nodes_legend_click[i].addClass("highlightN outline");


                    selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                  }
                }

              }
            }
          }
          for (let i in graph_edges_legend_click) {
            if (graph_edges_legend_click[i]["_private"]) {
              let sourcepresent = _.indexOf(selected_nodes_legend, parseInt(graph_edges_legend_click[i]["_private"]["data"]["source"]));

              let targetpresent = _.indexOf(selected_nodes_legend, parseInt(graph_edges_legend_click[i]["_private"]["data"]["target"]));
              if (sourcepresent != -1 && targetpresent != -1) {
                graph_edges_legend_click[i].removeClass("hideE");
                graph_edges_legend_click[i].addClass("highlightE");


              }
              // for(let j in all_legends_selected){
              //   console.log()
              //   if(graph_edges_legend_click[i]["_private"]["data"][this.graph_spec[this.g_type_url_global][this.n_type_url_global]["node_color_by"]].replace(/\s/g).toLowerCase() == all_legends_selected[j]){
              //     graph_edges_legend_click[i].style("opacity","1");
              //   }

              // }
            }
          }

          if (style_for_graph["graphSize"] <= style_for_graph["maxGraphSizeForSmallGraph"]) {
            cy.fit(cy.elements());
          } else {
            cy.fit(cy.$(nodesWithConnection));
          }




        } else if (nodeClicked != node) {
          // $("#firstDiv").css("display", "inline-block");
          // $("#secondDiv").css("width", "70%");
          nodeClicked = node;
          selected_node = selected["_private"]["map"].keys().next().value;;

          cy.elements().removeClass('hideN hideE highlightN outline selected highlightE');
          selected.addClass('selected');
          // console.log(neighbourOfSelected);
          // // Hide remaining
          cy.elements().not(neighbourOfSelected.merge(selected)).addClass('hideN hideE');

          // // Highlight neighbourOfSelected
          // neighbourOfSelected.addClass('highlightN outline');

          // // Highlight edges between selected and neighbourOfSelected
          // selected.edgesWith(neighbourOfSelected).addClass('highlightE');
          // Fit slected to view
          // cy.center(selected);
          cy.fit(neighbourOfSelected, 20);
          //
          // console.log('----------');
          // // _.each(neighbourOfSelected, function (node) {
          // //   console.log(node);
          // //   console.log(cy.$(node).position());
          // // });
          // console.log('+++++++++++');
          let neighbourOfSelected_edges = selected.neighborhood('edge');
          let neighbourOfSelected_nodes = selected.neighborhood('node');
          for (let i in neighbourOfSelected_nodes) {
            if (neighbourOfSelected_nodes[i]["_private"]) {
              // console.log(" neight ",neighbourOfSelected_nodes[i]["_private"]["data"][this.graph_spec[this.g_type_url_global][this.n_type_url_global]["node_color_by"]].replace(/\s/g).toLowerCase().trim())

              let neighbour_node_in_legend = 0;
              // _.indexOf(all_legends_selected,(ele)=>{
              //   return String(neighbourOfSelected_nodes[i]["_private"]["data"][this.graph_spec[this.g_type_url_global][this.n_type_url_global]["node_color_by"]].replace(/\s/g).toLowerCase())})
              // console.log("index neighbour ",neighbour_node_in_legend);
              // if(neighbour_node_in_legend == -1 ){
              //   neighbourOfSelected_nodes[i].addClass("hideN");
              //   neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");

              //   neighbourOfSelected_nodes[i].incomers('edge').addClass("hideE");
              // }

              for (let j in all_legends_selected) {
                console.log("val ", $("#node_color_dd").children("option:selected").val(), " g type ", this.g_type_url_global);

                if (String($("#node_color_dd").children("option:selected").val()) == this.g_type_url_global) {
                  console.log("default node selected");
                  if (this.g_type_url_global == "engagement") {
                    if (neighbourOfSelected_nodes[i]["_private"]["data"][all_legends_selected[j]]) {
                      neighbour_node_in_legend = 1;
                      neighbourOfSelected_nodes[i].removeClass("hideN");
                      // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                      neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                    }

                  }
                  else if (this.g_type_url_global == "informal-communities") {
                    if (neighbourOfSelected_nodes[i]["_private"]["data"]["Community"] == all_legends_selected[j]) {
                      neighbour_node_in_legend = 1;
                      neighbourOfSelected_nodes[i].removeClass("hideN");
                      // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                      neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                    }
                  }
                  else if (this.g_type_url_global == "collaboration" && this.collab_bet_entity_highlight) {
                    if (neighbourOfSelected_nodes[i]["_private"]["data"]["id"].toLowerCase() == all_legends_selected[j].toLowerCase) {
                      neighbour_node_in_legend = 1;
                      neighbourOfSelected_nodes[i].removeClass("hideN");
                      // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                      neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                    }
                  }
                  else if (this.g_type_url_global == "disc_emp") {
                    if (neighbourOfSelected_nodes[i]["_private"]["data"][all_legends_selected[j]] == all_legends_selected[j]) {
                      neighbour_node_in_legend = 1;
                      neighbourOfSelected_nodes[i].removeClass("hideN");
                      // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                      neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                    }
                  }
                  else if (this.g_type_url_global == "collab_overload") {
                    if (neighbourOfSelected_nodes[i]["_private"]["data"][all_legends_selected[j]] == 1) {
                      neighbour_node_in_legend = 1;
                      neighbourOfSelected_nodes[i].removeClass("hideN");
                      // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                      neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                    }
                  }
                  else {
                    for (let k in this.module_specific_nodes["people_details"]) {
                      var network = this.n_type_url_global;
                      if (this.n_type_url_global == "combined") {
                        network = "main_combined"
                      }
                      if (this.n_type_url_global == "professional") {
                        network = "main_professional"
                      }
                      if (this.n_type_url_global == "personal") {
                        network = "main_personal"
                      }
                      if (neighbourOfSelected_nodes[i]["_private"]["data"]["value"] == this.module_specific_nodes["people_details"][k]["id"] && this.module_specific_nodes["people_details"][k][network] == 1) {
                        neighbour_node_in_legend = 1;
                        neighbourOfSelected_nodes[i].removeClass("hideN");
                        // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                        neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                      }
                    }
                  }

                }
                else {
                  if (neighbourOfSelected_nodes[i]["_private"]["data"][$("#node_color_dd").children("option:selected").val()].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == all_legends_selected[j]) {
                    console.log(" this.graph_spec ", neighbourOfSelected_nodes[i]["_private"]["data"][$("#node_color_dd").children("option:selected").val()].replace(/[^a-zA-Z0-9]/g, '').toLowerCase())
                    neighbour_node_in_legend = 1;
                    neighbourOfSelected_nodes[i].removeClass("hideN");
                    // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                    neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")


                  }
                }
              }

              if (neighbour_node_in_legend == 0) {
                neighbourOfSelected_nodes[i].removeClass("hideN");

                neighbourOfSelected_nodes[i].addClass("hideN");
                neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                neighbourOfSelected_nodes[i].edgesWith(selected).addClass("hideE")

                // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");

                // neighbourOfSelected_nodes[i].incomers('edge').addClass("hideE");
              }
            }
          }



        }


      }



    });
    if (cy.nodes().length < 10) {
      // console.log(" length less 10 length: " + cy.nodes().length)
      setTimeout(() => {
        if (cy.nodes().length == 1) {
          cy.zoom(cy.zoom() / 4);
          cy.center();

          return;
        }
        // console.log("zoom level", cy.zoom())
        cy.zoom(cy.zoom() / 2)
        cy.center();
        // console.log("zoom level", cy.zoom())


      }, 1000)
    }
    else {
      setTimeout(() => {

        // console.log("zoom level", cy.zoom())
        // cy.zoom(cy.zoom() - 0.1)
        cy.center();
        // console.log("zoom level", cy.zoom())


      }, 1000)
    }
    var timestamp_end = new Date().getTime()
    console.log("Time taken for loading graph function ", (timestamp_end - timestamp_start))


  }

  n_entities_graph(entities_array: any, entity_type: string) {
    this.start_graph_generation();

    var nodes = this.cy.nodes();

    for (let node in nodes) {
      for (let entity_index in entities_array) {
        var entity_found = 0;

      }
    }

  }
  open_cag_modal() {
    console.log("opening 'configure a graph'")
    $('#cag_modal_container').removeAttr('class').addClass('modalscreen-container one');

  }
  close_cag_modal() {
    $("#cag_modal_container").addClass('modalscreen-container out');

  }
  submit_cag(config: any) {
    this.appservice.pushConfig(this.uid, config).subscribe(response => {
      console.log(response);

    });
  }

  nodeHoverEvent(nodeInfoContainerId, nodeInfo, evt, remainingElements) {
    $("#" + nodeInfoContainerId).html("")
    //details to be shown when hovering over a node
    if (this.g_type_url_global != "engagement") {
      if (nodeInfo['first_name'] && nodeInfo['last_name']) {
        $("#" + nodeInfoContainerId).html("<span> Name : " + nodeInfo['first_name'] + " " + nodeInfo['last_name'] + "<span>");
      }
      else if (nodeInfo['first_name']) {
        $("#" + nodeInfoContainerId).html("<span> Name : " + nodeInfo['first_name'] + "<span>");
      }

      else if (nodeInfo['name']) {
        $("#" + nodeInfoContainerId).html("<span> Name : " + nodeInfo['name'] + "<span>");
      }
    }

    if (nodeInfo['gender']) {
      $("#" + nodeInfoContainerId).append("<span> Gender : " + nodeInfo['gender'] + "<span>");

    }
    if (nodeInfo['designation']) {
      $("#" + nodeInfoContainerId).append("<span> Designation : " + nodeInfo['designation'] + "<span>");
    }
    for (let i of this.entity_class_map) {
      if (nodeInfo[i[0]] && nodeInfo[i[1]] != "org")
        $("#" + nodeInfoContainerId).append("<span> " + i[2] + " : " + nodeInfo[i[0]] + "<span>");


    }
    // if (nodeInfo['department']) {
    //   $("#" + nodeInfoContainerId).append("<span> Department : " + nodeInfo['department'] + "<span>");
    // }
    // if (nodeInfo['hierarchy_level']) {
    //   $("#" + nodeInfoContainerId).append("<span> Hierarchy Level : " + nodeInfo['hierarchy_level'] + "<span>");
    // }
    // if (nodeInfo['location']) {
    //   $("#" + nodeInfoContainerId).append("<span> Location : " + nodeInfo['location'] + "<span>");

    // }
    if ($("#team_dd").children("option:selected").val() != "none" && $("#team_type_dd").children("option:selected").val() == "org") {
      console.log("goup by incoming check")
      for (let i of this.entity_class_map) {
        if (i[1] == $("#team_dd").children("option:selected").val()) {
          $("#" + nodeInfoContainerId).append("<span> Incoming Connections : " + evt.target.indegree() + " " + $("#team_dd").children("option:selected").text() + "(s)<span>");
          $("#" + nodeInfoContainerId).append("<span> Outgoing Connections : " + evt.target.outdegree() + " " + $("#team_dd").children("option:selected").text() + "(s))<span>");

        }

      }
    }
    else {
      $("#" + nodeInfoContainerId).append("<span> Incoming Connections : " + evt.target.indegree() + "<span>");
      $("#" + nodeInfoContainerId).append("<span> Outgoing Connections : " + evt.target.outdegree() + "<span>");
    }


    $("#" + nodeInfoContainerId).append("<span> Total Connections : " + evt.target.degree() + "<span>");

    if (this.g_type_url_global == "collaboration") {

      if (this.team == "none") {
        $("#" + nodeInfoContainerId).append("<span> Mutually Connected Employees " + evt.target.outgoers('node').intersection(evt.target.incomers('node')).length + "<span>");
      }
      else if (this.team != "none") {
        $("#" + nodeInfoContainerId).append("<span> Mutually Connected " + $("#team_dd").children('option:selected').text()
          + "s : " + evt.target.outgoers('node').intersection(evt.target.incomers('node')).length + "<span>");
        $("#" + nodeInfoContainerId).append("<span> Disconnected " + $("#team_dd").children('option:selected').text()
          + "s : " + (this.cy.nodes().length - evt.target.neighborhood('node').length) + "<span>");
      }


    }

    $("#" + nodeInfoContainerId).css("display", "flex");
    // remainingElements.style("opacity", "0.2");
    // evt.target.style("opacity", "1");
  }
  nodeHoverEventOut(nodeInfoContainerId, evt, remainingElements) {
    $("#" + nodeInfoContainerId).hide();
    // remainingElements.style("opacity", "1");
  }

  async NodeSize(optionselected: string, nodes_collection: any) {
    var timestamp_start = new Date().getTime()
    var graphnodes = nodes_collection;

    var maxIncoming = 0, maxOutgoing = 0, maxCollabScore = 0, min_collab_score = 0, maxengscore = 0;
    //the array starts with a darker shade and goes to a lighter shade
    var collab_colors = ['#56a5f5', '#0f84fa', '#0075eb', '#0063c7', '#00458a']
    var no_of_buckets_for_color = collab_colors.length
    if ($("#network_dd option:selected").val() == "main") {
      graphnodes = this.cy.nodes()
    }
    if (optionselected == 'indegree') {
      console.log("Sizing according to indegree")
      for (let node in graphnodes) {

        if ($("#network_dd option:selected").val() == "main") {
          graphnodes = this.cy.nodes()
          maxIncoming = this.cy.nodes().maxIndegree()
        }
        else {
          if (graphnodes[node]["_private"]) {

            if (graphnodes[node]["_private"]["data"]["indegree"] > maxIncoming) {
              maxIncoming = graphnodes[node]["_private"]["data"]["indegree"];

            }
          }
        }
      }
      let nodei = 1;
      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {
          if ($("#network_dd option:selected").val() == "main") {

            var newSize = ((graphnodes[node].indegree() / maxIncoming) * (15 * (Math.log(graphnodes.length)))) + 20 + "px";
            graphnodes[node].style("height", newSize);
            graphnodes[node].style("width", newSize);

            nodei += 1;

          }
          else {

            var newSize = ((graphnodes[node]["_private"]["data"]["indegree"] / maxIncoming) * (15 * (Math.log(graphnodes.length)))) + 20 + "px";
            graphnodes[node].style("height", newSize);
            graphnodes[node].style("width", newSize);

            nodei += 1;

          }



        }

      }

    }
    else if (optionselected == 'outdegree') {
      console.log("Sizing according to outdegree")
      for (let node in graphnodes) {
        if ($("#network_dd option:selected").val() == "main") {
          graphnodes = this.cy.nodes()
          maxIncoming = this.cy.nodes().maxOutdegree()
        }
        else {
          if (graphnodes[node]["_private"]) {
            if (graphnodes[node]["_private"]["data"]["out_degree"] > maxOutgoing) {
              maxOutgoing = graphnodes[node]["_private"]["data"]["out_degree"];

            }
          }
        }

      }
      let nodei = 1;

      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {
          if ($("#network_dd option:selected").val() == "main") {
            var newSize = ((graphnodes[node]["_private"]["data"]["out_degree"] / maxOutgoing) * (15 * Math.log(graphnodes.length))) + 20 + "px";
            graphnodes[node].style("height", newSize);
            graphnodes[node].style("width", newSize);
            // if (graphnodes[node]["_private"]["data"]["out_degree"] > (0.5 * maxOutgoing)) {
            // graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["first_name"]);
            nodei += 1;

            // }
          }
          var newSize = ((graphnodes[node]["_private"]["data"]["out_degree"] / maxOutgoing) * (15 * Math.log(graphnodes.length))) + 20 + "px";
          graphnodes[node].style("height", newSize);
          graphnodes[node].style("width", newSize);
          // if (graphnodes[node]["_private"]["data"]["out_degree"] > (0.5 * maxOutgoing)) {
          // graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["first_name"]);
          nodei += 1;

          // }


        }
      }
    }
    else if (optionselected == 'default') {
      console.log("default size nodes gen ona");
      for (let k in graphnodes) {
        if (graphnodes[k]["_private"]) {


          // graphnodes[k].style("height",((globalNodeSizeData[l]/g2maxnodesize)*20).toString()+"px");
          // graphnodes[k].style("width",((globalNodeSizeData[l]/g2maxnodesize)*20).toString()+"px");
          graphnodes[k].style("height", "0px");
          graphnodes[k].style("width", "0px");





        }


      }

    }
    else if (optionselected == 'collab_score') {
      console.log('Sizing according to collab_score');
      var filename = ''
      if (this.show_leadership_team) {
        filename = 'null_leader_collab'
        this.appservice.getv3InsightJson(this.uid, 'null_leader_collab').then((leader_collab_json) => {
          var network_value = String($("#network_dd option:selected").val())
          var leader_collab_score = leader_collab_json[network_value]["Score"]
          for (let i of Object.keys(leader_collab_score)) {
            if (leader_collab_json[network_value]["Score"][i] > maxCollabScore) maxCollabScore = leader_collab_json[network_value]["Score"][i]

          }
          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {
              var node_score = leader_collab_json[network_value]["Score"][graphnodes[node]["_private"]["data"]["id"]]
              var newSize = ((node_score / maxCollabScore) * (15 * Math.log(graphnodes.length))) + 20 + "px";
              graphnodes[node].style("height", newSize);
              graphnodes[node].style("width", newSize);
              // if (graphnodes[node]["_private"]["data"]["indegree"] > (0 ) {
              graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["first_name"]);

              // }
              for (let i in collab_colors) {
                if (node_score >= parseInt(i) * (maxCollabScore / no_of_buckets_for_color)
                  && node_score <= (parseInt(i) + 1) * (maxCollabScore / no_of_buckets_for_color)) graphnodes[node].style("background-color", collab_colors[i]);

              }

            }

          }
        });
      }
      if (this.team_type == 'org' && this.team != 'none') {
        var max_size = 0;
        for (let node in graphnodes) {
          if (graphnodes[node]["_private"]) {
            if (graphnodes[node]["_private"]["data"]["size"]) {
              if (graphnodes[node]["_private"]["data"]["size"] > max_size) max_size = graphnodes[node]["_private"]["data"]["size"]
            }



          }

        }
        for (let node in graphnodes) {
          if (graphnodes[node]["_private"]) {

            var newSize = ((graphnodes[node]["_private"]["data"]["size"] / (max_size)) * (30 * Math.log(graphnodes[node]["_private"]["data"]["size"]))) + 20 + "px";
            graphnodes[node].style("height", newSize);
            graphnodes[node].style("width", newSize);



          }

        }
        var edges = this.cy.edges()
        var added_edges: any = []
        this.cy.remove(edges)
        for (let edge of edges) {
          if (edge["_private"]) {
            for (let edge2 of edges) {
              if (edge2["_private"]) {
                if (edge["_private"]["data"]["source"] == edge2["_private"]["data"]["target"] && edge2["_private"]["data"]["source"] == edge["_private"]["data"]["target"]) {
                  console.log("adding edge")
                  var edge_already_added = 0
                  for (let edge_added of added_edges) {
                    if ((edge["_private"]["data"]["source"] == edge_added["_private"]["data"]["source"]
                      && edge["_private"]["data"]["target"] == edge_added["_private"]["data"]["target"]) ||
                      (edge2["_private"]["data"]["source"] == edge_added["_private"]["data"]["source"]
                        && edge2["_private"]["data"]["target"] == edge_added["_private"]["data"]["target"])
                    ) {
                      edge_already_added = 1
                    }
                  }
                  if (edge_already_added == 0) {
                    this.cy.add(edge)
                    added_edges.push(edge)

                  }
                  break;
                }
              }
            }
          }
        }

        for (let edge of this.cy.edges()) {
          edge.style("target-arrow-shape", "none");
          edge.style("source-arrow-shape", "none");
          edge.style("line-color", "gray");

          edge.style("width", "0.5")
        }


        filename = this.team + "_collab_between";
        console.log("collab bet filename ", filename)
        this.appservice.getInsightJson(this.uid, filename).then((between_collab_json) => {
          var network_value = String($("#network_dd").children("option:selected").val())
          if (network_value == "personal" || network_value == "professional")
            network_value += "main_"
          if (network_value == "combined") network_value = "Combined"
          console.log("between_collab_json", between_collab_json)
          var leader_collab_score = between_collab_json[network_value]["Score"]
          for (let i of Object.keys(leader_collab_score)) {
            if (between_collab_json[network_value]["Score"][i] > maxCollabScore) maxCollabScore = between_collab_json[network_value]["Score"][i]
          }
          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {

              var node_score = between_collab_json[network_value]["Score"][graphnodes[node]["_private"]["data"]["id"]]
              var newSize = ((node_score / maxCollabScore) * (15 * Math.log(graphnodes.length))) + 20 + "px";
              graphnodes[node].style("height", newSize);
              graphnodes[node].style("width", newSize);
              // if (graphnodes[node]["_private"]["data"]["indegree"] > (0 ) {

              // }
              for (let i in collab_colors) {
                if (node_score >= parseInt(i) * (maxCollabScore / no_of_buckets_for_color)
                  && node_score <= (parseInt(i) + 1) * (maxCollabScore / no_of_buckets_for_color)) graphnodes[node]["_private"]["data"]["color"] = collab_colors[i];

              }

            }

          }
          this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), false, [,])
        });
        console.log("resized graph node size collab score ", graphnodes)
        this.cy.resize();

        this.cy.fit();
      }
      else if (this.team_type == 'org' && this.team == 'none' && this.two_specific_entities_boolean) {
        filename = $("#team_dd_1 option:selected").val() + "_" + $("#team_type_2 option:selected").val() + "_collab_within"
        this.appservice.getv3InsightJson(this.uid, filename).then((between_collab_json) => {
          var leader_collab_score = between_collab_json[$("#team_dd_1 option:selected").text()][$("#network_dd option:selected").val()]["Score"]
          for (let i of Object.keys(leader_collab_score)) {
            if (between_collab_json[$("#team_dd_1 option:selected").text()][$("#network_dd option:selected").val()]["Score"][i] > maxCollabScore) maxCollabScore = between_collab_json[$("#team_dd_1 option:selected").text()][$("#network_dd option:selected").val()]["Score"][i]

          }
          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {

              var newSize = ((between_collab_json[$("#team_dd_1 option:selected").text()][$("#network_dd option:selected").val()]["Score"][graphnodes[node]["_private"]["data"]["id"]]
                / maxCollabScore) * (15 * Math.log(graphnodes.length))) + 20 + "px";
              graphnodes[node].style("height", newSize);
              graphnodes[node].style("width", newSize);
              // if (graphnodes[node]["_private"]["data"]["indegree"] > (0 ) {
              // graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["first_name"]);

              // }


            }

          }
          this.cy.resize();

          this.cy.fit();
        });
        filename = $("#team_dd_2 option:selected").val() + "_" + $("#team_type_2 option:selected").val() + "_collab_within"
        this.appservice.getv3InsightJson(this.uid, filename).then((between_collab_json) => {
          var leader_collab_score = between_collab_json[$("#team_dd_2 option:selected").text()][$("#network_dd option:selected").val()]["Score"]
          for (let i of Object.keys(leader_collab_score)) {
            if (between_collab_json[$("#team_dd_2 option:selected").text()][$("#network_dd option:selected").val()]["Score"][i] > maxCollabScore) maxCollabScore = between_collab_json[$("#team_dd_2 option:selected").text()][$("#network_dd option:selected").val()]["Score"][i]

          }
          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {

              var newSize = ((between_collab_json[$("#team_dd_2 option:selected").text()][$("#network_dd option:selected").val()]["Score"][graphnodes[node]["_private"]["data"]["id"]]
                / maxCollabScore) * (15 * Math.log(graphnodes.length))) + 20 + "px";
              graphnodes[node].style("height", newSize);
              graphnodes[node].style("width", newSize);
              // if (graphnodes[node]["_private"]["data"]["indegree"] > (0 ) {
              // graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["first_name"]);

              // }


            }

          }
          this.cy.resize();

          this.cy.fit();
        });

      }
      if (this.team_type != 'org') {
        filename = this.team + "_" + this.team_type + "_collab_within";
        this.appservice.getv3InsightJson(this.uid, filename).then((within_collab_json) => {
          var leader_collab_score = within_collab_json[$("#team_dd option:selected").text()][$("#network_dd option:selected").val()]["Score"]
          for (let i of Object.keys(leader_collab_score)) {
            if (within_collab_json[$("#team_dd option:selected").text()][$("#network_dd option:selected").val()]["Score"][i] > maxCollabScore) maxCollabScore = within_collab_json[$("#team_dd option:selected").text()][$("#network_dd option:selected").val()]["Score"][i]

          }
          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {

              var newSize = ((within_collab_json[$("#team_dd option:selected").text()][$("#network_dd option:selected").val()]["Score"][graphnodes[node]["_private"]["data"]["id"]]
                / maxCollabScore) * (15 * Math.log(graphnodes.length))) + 20 + "px";
              graphnodes[node].style("height", newSize);
              graphnodes[node].style("width", newSize);
              // if (graphnodes[node]["_private"]["data"]["indegree"] > (0 ) {
              graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["first_name"]);

              // }


            }

          }
        });
      }

      this.cy.resize();

      this.cy.fit();
    }
    else if (optionselected == 'engagement') {
      filename = 'node_categories_engagement'
      this.appservice.getInsightJson(this.uid, filename).then((enagement_scores) => {
        console.log("enagement_scores ", enagement_scores)
        var engagement_categories = Object.keys(enagement_scores)
        for (let i of engagement_categories) {
          for (let j of Object.keys(enagement_scores[i])) {
            if (enagement_scores[i][j] > maxengscore) maxengscore = enagement_scores[i][j]
          }
        }
        console.log("max eng score ", maxengscore)
        for (let node in graphnodes) {
          if (graphnodes[node]["_private"]) {
            for (let i of engagement_categories) {
              var node_score = enagement_scores[i][graphnodes[node]["_private"]["data"]["id"]]
              var newSize = ((node_score / maxengscore) * (15 * Math.log(graphnodes.length))) + 20 + "px";
              graphnodes[node].style("height", newSize);
              graphnodes[node].style("width", newSize);
              // if (graphnodes[node]["_private"]["data"]["indegree"] > (0 ) {
            }


            // }


          }

        }
      });
    }
    this.cy.resize();

    this.cy.fit();

    // var clusters = nodes_collection.kMedoids({
    //   k:6,
    //   attributes:['group_name1']
    // })
    // console.log("clusters ",clusters);
    var timestamp_end = new Date().getTime()
    console.log("Time taken for node sizing ", (timestamp_end - timestamp_start))


  }
  async fetchPeopleDetails_ModuleSpecific() {
    var resp = await this.httpClient.get("assets/module_specific_people/" + this.g_type_url_global + ".json").toPromise();
    return resp
  }

  reciprocal_frequency_apply() {
    $("#graph_node_legend").html("")
    setTimeout(() => {
      this.load(parseInt(String($("#_nodeSlider").val())), this.sortedNodesList, this.edgeList, this.max_degree, this.min_degree, true);

    }, 500)

  }
  edge_frequency_apply() {
    this.load(parseInt(String($("#_nodeSlider").val())), this.sortedNodesList, this.edgeList, this.max_degree, this.min_degree, true);

  }

  nodeSizeIVI(onpsgraphnodes) {
    var filename = $("#team_dd").children("option:selected").val() + "_" + $("#team_type_dd").children("option:selected").val() + "_onps"
    if ($("#team_dd").children("option:selected").val() == "none") {
      filename = "onps"
    }
    this.appservice.getInsightJson(this.uid, filename).then((cutData) => {
      var maxIVIScore = 0;
      var keys = Object.keys(cutData["summary"]["Department"]["table"])
      var scores: any = {}
      var max_score: any = 0;
      for (let i of keys) {
        for (let j of Object.keys(cutData["summary"]["Department"]["table"][i]["ivi_score"])) {
          scores[j] = cutData["summary"]["Department"]["table"][i]["ivi_score"][j]
          if (scores[j] > max_score) {
            max_score = scores[j]
          }
        }
      }
      console.log("max ivi score", max_score)

      var temparray = [];
      for (let i in onpsgraphnodes) {

        if (onpsgraphnodes[i]["_private"]) {
          temparray.push(onpsgraphnodes[i]["_private"]["data"]["id"]);

          var nodeId = onpsgraphnodes[i]["_private"]["data"]["id"];

          if (scores[nodeId]) {
            // console.log("hey");
            var eachnodesize = (Math.log((scores[nodeId] / 10 * Math.log(max_score)) + 6) * 20) + 7;

            onpsgraphnodes[i].style("height", eachnodesize.toString() + "px");
            onpsgraphnodes[i].style("width", eachnodesize.toString() + "px");

          };

        }
      }
      temparray.sort()
    })

    // console.log("temp ",temparray);

  }
  nodeColorAdvocacy(onpsgraphnodes) {
    for (let i in onpsgraphnodes) {
      if (onpsgraphnodes[i]["_private"]) {
        var noderating = onpsgraphnodes[i]["_private"]["data"]["rating"];
        if (noderating >= 1 && noderating <= 6) {
          onpsgraphnodes[i].style("background-color", "red");
          onpsgraphnodes[i]["_private"]["data"]["onps"] = "low"
        }
        else if (noderating >= 7 && noderating <= 8) {
          onpsgraphnodes[i].style("background-color", "#F9A602");
          onpsgraphnodes[i]["_private"]["data"]["onps"] = "medium"
        }
        else if (noderating >= 9 && noderating <= 10) {
          onpsgraphnodes[i].style("background-color", "green");
          onpsgraphnodes[i]["_private"]["data"]["onps"] = "high"
        }
        else {
          onpsgraphnodes[i].style("background-color", "gray");
          onpsgraphnodes[i]["_private"]["data"]["onps"] = "non-respondents"
        }
      }
    }
  }
  change_node_label() {
    if ($("#node_label_dd").children("option:selected").val() == "none") {
      for (let i of this.cy.nodes()) {
        if (i["_private"]) {
          i.style("label", "");
        }
      }

    }
    else {
      for (let i of this.cy.nodes()) {
        if (i["_private"]) {
          // i.style("font-size", i.width() - 7)
          // i.style("text-max-width",i.width())

          // i.style("text-wrap", 'wrap')

          i.style("label", i["_private"]["data"][String($("#node_label_dd").children("option:selected").val())]);
          if (String($("#node_label_dd").children("option:selected").val()) == 'full_name') {
            // i.style("font-size", i.style('height'))
            i.style("label", i["_private"]["data"]['first_name'] + ' ' + i["_private"]["data"]['last_name']);



          }

        }
      }
      this.fitLabelsInsideNodes(this.cy)

    }
  }
  fitLabelsInsideNodes(cy: any) {
    cy.nodes().forEach((node: any) => {
      const nodeWidth = node.width();
      const nodeHeight = node.height();
      const label = node.style('label') || ''; // Fetch the node's label text
      console.log('label', label)
      // Base font size for an average node
      let fontSize = Math.min(nodeWidth / label.length, nodeHeight * 0.5);

      // Prevent font size from being too small or large
      fontSize = Math.max(10, Math.min(fontSize, 25)); // Min 6px, Max 16px

      // Dynamically set the style for this node
      node.style({
        'label': label,
        'text-valign': 'center',
        'text-halign': 'center',
        'text-wrap': 'wrap',
        'font-size': `${fontSize}px`,
        'text-max-width': `${nodeWidth + 10}px`, // Add some padding
      });
    });
  }
  node_color_option: any = '';
  node_color_option_after_gen: any = '';
  change_node_color_option() {
    this.node_color_option = $("#node_color_dd").children("option:selected").val()
  }
  legends_array: any = []
  legend_entity_class_value: any  ='';
  changeNodecolorONA(node_collection: object, selectedOption_string: string, change_existing_colors: any = false, changed_color: any = ["", ""], use_existing_colors: any = false) {
    var timestamp_start = new Date().getTime()
    var graphnodes: any = node_collection;
    $("#filter_out_label").css("display", "block")
    $("#graph_node_legend").html("");
    $("#legend_container").css("display", "none")
    console.log("selectedOption_string ", selectedOption_string)
    if (this.teams_tab_active) {
      var peers: any;
      var reportees: any;
      for (let i of this.cy.nodes()) {
        if (i["_private"]) {
          if (String(i["_private"]["data"]["email"]).toLowerCase() == String($("#team_dd option:selected").val()).toLowerCase()) {
            i.style("background-color", "#0545ad")
            i.style("shape", "triangle")

            i["_private"]["data"]["rm"] = 1
            peers = this.cy.nodes().difference(i.neighborhood('node'))

            reportees = i.neighborhood('node')
          }

        }
      }

      for (let i of reportees) {

        if (String(i["_private"]["data"]["email"]).toLowerCase() != String($("#team_dd option:selected").val()).toLowerCase()) {
          i.style("background-color", "#05e648")
          i["_private"]["data"]["reportee"] = 1


        }


      }
      for (let i of peers) {
        i.style("background-color", "gray")
        i["_private"]["data"]["peer"] = 1



      }
      var colorsingraphvalue: any = [['rm', 1, '#0545ad'], ['reportee', 1, '#05e648'], ['peer', 1, 'gray']];
      if (change_existing_colors) {
        colorsingraphvalue = this.colorsingraphvalue
        for (let i in colorsingraphvalue) {
          if (changed_color[0] == colorsingraphvalue[i][0]) {
            console.log("change color of", colorsingraphvalue[i])

            colorsingraphvalue[i][2] = changed_color[1]
            console.log("changed color of", colorsingraphvalue[i])
          }
        }
      }
      this.colorsingraphvalue = colorsingraphvalue

      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {

          if (graphnodes[node]["_private"]["data"]["rm"] == 1) {
            graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
          }
          if (graphnodes[node]["_private"]["data"]["reportee"] == 1) {
            graphnodes[node].style("background-color", colorsingraphvalue[1][2]);
          }

        }
      }
      $("#legend_container").css("display", "block")

      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='rm'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[0][2] + "' (click)='dynamic_change_color('rm')></div><span style='margin:5px;'>Reporting Manager</span></div>")
      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='reportee'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[1][2] + "' (click)='dynamic_change_color('reportee')></div><span style='margin:5px;'>Reportees</span></div>")
      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='peer'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[2][2] + "' (click)='dynamic_change_color('peer')></div><span style='margin:5px;'>Reportees not connected to RM</span></div>")

    }
    if (this.g_type_url_global == "none" && $("#node_color_dd").children("option:selected").val() == "none") {
      $("#filter_out_label").css("display", "none");
      if (this.individual_tab_active) {
        for (let i of this.cy.nodes()) {
          if (i["_private"]) {
            if (String(i["_private"]["data"]["id"]) == String($("#team_dd option:selected").val())) {
              console.log("ego color change")
              i.style("background-color", "#ebd534")
              i["_private"]["data"]["ego"] = 1

            }
          }
        }
        var colorsingraphvalue: any = [['ego', 1, '#ebd534']];
        if (use_existing_colors && this.node_color_option == this.node_color_option_after_gen) {
          colorsingraphvalue = this.colorsingraphvalue
        }
        if (change_existing_colors) {
          colorsingraphvalue = this.colorsingraphvalue
          for (let i in colorsingraphvalue) {
            if (changed_color[0] == colorsingraphvalue[i][0]) {
              console.log("change color of", colorsingraphvalue[i])

              colorsingraphvalue[i][2] = changed_color[1]
              console.log("changed color of", colorsingraphvalue[i])
            }
          }
        }
        this.colorsingraphvalue = colorsingraphvalue

        for (let node in graphnodes) {
          if (graphnodes[node]["_private"]) {

            if (graphnodes[node]["_private"]["data"]["ego"] == 1) {
              graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
            }

          }
        }

        // $("#graph2legendONA").html("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#33b1f5;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Male</span></div>")
        // $("#graph2legendONA").append("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#fc60f7;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Female</span></div>")

        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='ego'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[0][2] + "' (click)='dynamic_change_color('ego')></div><span style='margin:5px;'>" + String($("#team_dd option:selected").text()) + "</span></div>")

      }



    }
    if (selectedOption_string == this.g_type_url_global) {
      if (selectedOption_string == "onps") {
        this.nodeColorAdvocacy(node_collection)
        //TODO: move sizing of nodes to load()
        this.nodeSizeIVI(node_collection)
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='high'> <div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:green'></div><span style='margin:5px;'>High</span></div>");
        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='medium'>                                        <div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#F9A602'></div>                                        <span style='margin:5px;'>Medium</span>                                        </div>");

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='low'>                                        <div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:red'></div>                                        <span style='margin:5px;'>Low</span>                                        </div>");

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='non-respondents'>                                        <div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:gray'></div>                                        <span style='margin:5px;'>Non-respondents</span>                                        </div>                                        ")

        return;
      }
      if (selectedOption_string != "none" && selectedOption_string != "engagement") {
        //TODO: put this function in app.service.ts
        // this.fetchPeopleDetails_ModuleSpecific().then((all_module_specific_nodes) => {
        //   this.module_specific_nodes = all_module_specific_nodes;
        //   for (let node in graphnodes) {
        //     if (graphnodes[node]["_private"]) {
        //       for (let person in all_module_specific_nodes["people_details"]) {

        //         if (all_module_specific_nodes["people_details"][person]["id"] == parseInt(graphnodes[node]["_private"]["data"]["id"])) {
        //           var network_name = this.n_type_url_global;
        //           if (network_name == "combined") network_name = "main_combined";
        //           if (network_name == "professional") network_name = "main_professional";

        //           if (all_module_specific_nodes["people_details"][person][network_name] == 1) {
        //             if (selectedOption_string == 'influencer') {
        //               graphnodes[node].style("background-color", "#ff6f00");

        //             }
        //             else if (selectedOption_string == 'hub'  ) {
        //               graphnodes[node].style("background-color", "#7ec44f");
        //             }
        //             else if(selectedOption_string == 'gate_keeper'){
        //               graphnodes[node].style("background-color", "#d14d00");

        //             }
        //             else if(selectedOption_string == 'pulse_taker'){
        //               graphnodes[node].style("background-color", "#e6a009");

        //             }
        //             else if(selectedOption_string == 'power_players'){
        //               graphnodes[node].style("background-color", "#5c5eff");

        //             }
        //             else if(selectedOption_string == 'silo'){
        //               graphnodes[node].style("background-color", "#b9015d");

        //             }
        //             else {
        //               graphnodes[node].style("background-color", "#f05443");

        //             }
        //             this.cy.center();
        //           }
        //           else {
        //             graphnodes[node].style("background-color", "#00bfff");
        //             // if (graphnodes[node]["_private"]["data"]["degree"] == 0) {
        //             //   this.cy.remove("#" + graphnodes[node]["_private"]["data"]["id"]);
        //             //   this.cy.center();
        //             // }
        //           }
        //         }
        //       }
        //     }

        //   }
        // });
      }
      if (selectedOption_string == 'engagement') {
        this.appservice.getInsightJson(this.uid, 'node_categories_engagement').then((data) => {
          var categories = Object.keys(data)
          var nodes = this.cy.nodes();
          console.log("engagement categories", categories);
          for (let index in nodes) {
            for (let i in categories) {
              if (nodes[index]["_private"]) {
                if (Object.keys(data[categories[i]]).includes(String(nodes[index]["_private"]["data"]["value"]))) {
                  if (categories[i] == "highly_engaged") {
                    console.log(parseInt(nodes[index]["_private"]["data"]["value"]), " he",)

                    nodes[index]["_private"]["data"]["highly_engaged"] = "highlyengaged";
                    nodes[index].style("background-color", "#77B63B");
                  }
                  if (categories[i] == "engaged") {
                    console.log(parseInt(nodes[index]["_private"]["data"]["value"]), " e",)

                    nodes[index]["_private"]["data"]["engaged"] = "engaged";
                    nodes[index].style("background-color", "#FFB800");
                  }
                  if (categories[i] == "less_engaged") {
                    console.log(parseInt(nodes[index]["_private"]["data"]["value"]), " le",)

                    nodes[index]["_private"]["data"]["less_engaged"] = "lessengaged";
                    nodes[index].style("background-color", "#C12727");
                  }
                }

              }
            }

          }


        })
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='highly_engaged'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#77B63B'></div><span style='margin:5px;'>Highly Engaged</span></div>")
        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='engaged'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#FFB800'></div><span style='margin:5px;'>Engaged</span></div>")
        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='less_engaged'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#C12727'></div><span style='margin:5px;'>Less Engaged</span></div>")

      }


      //TODO: make graph legend settings dynamic

      if (selectedOption_string == "disc_emp") {
        var colorsingraphvalue: any = [['silo', 'silo', 'orange'], ['disconnected', 'disconnected', 'red']];
        if (use_existing_colors && this.node_color_option == this.node_color_option_after_gen) {
          colorsingraphvalue = this.colorsingraphvalue
        }
        if (change_existing_colors) {
          colorsingraphvalue = this.colorsingraphvalue
          for (let i in colorsingraphvalue) {
            if (changed_color[0] == colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) {
              console.log("change color of", colorsingraphvalue[i])

              colorsingraphvalue[i][2] = changed_color[1]
              console.log("changed color of", colorsingraphvalue[i])
            }
          }
        }
        this.colorsingraphvalue = colorsingraphvalue




        for (let i of graphnodes) {
          if (i["_private"]["data"]["component_id"] == 1) {
            i.style("background-color", "gray")
            // this.cy.remove(i["_private"]["data"]["id"])
            // this.cy.resize();
          }
          else if (i["_private"]["data"]["component_id"] == 0) {
            i.style("background-color", colorsingraphvalue[1][2])
            i["_private"]["data"]["disconnected"] = "disconnected"

          }
          else if (i["_private"]["data"]["component_id"] > 1) {
            i.style("background-color", colorsingraphvalue[0][2])
            i["_private"]["data"]["silo"] = "silo"


          }
        }
        this.cy.resize();

        this.cy.fit()
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='disconnected'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:red'></div><span style='margin:5px;'>Disconnected Employees</span></div>")
        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='silo'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:orange'></div><span style='margin:5px;'>Silos</span></div>")

      }
      if (selectedOption_string == "gate_keeper") {
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='gatekeeper'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#ff9050'></div><span style='margin:5px;'>Gatekeeper</span></div>")

      }
      if (selectedOption_string == "hub") {
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='hub'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#7ec44f'></div><span style='margin:5px;'>Hub</span></div>")

      }

      else if (selectedOption_string == 'influencers') {

        var file_name = "influencers_org_dc2_v3"
        if ($("#team_type_dd").children("option:selected").val() != "org") file_name = "influencers_" + $("#team_dd").children("option:selected").val() + "_" + $("#team_type_dd").children("option:selected").val() + "_dc2_v3"

        this.appservice.getInsightJson(this.uid, file_name).then((influencers_data) => {
          console.log("influencers_data ", influencers_data)
          if (this.team_type == "org") {
            influencers_data = influencers_data["org"][String($("#network_dd").children("option:selected").val())]["people"];

          }
          else influencers_data = influencers_data[String($("#team_dd").children("option:selected").val())][String($("#network_dd").children("option:selected").val())]["people"];

          var max_connector_score = 0;

          for (let i in influencers_data) {
            for (let node in graphnodes) {
              if (graphnodes[node]["_private"]) {
                if (influencers_data[i]["id"] == graphnodes[node]["_private"]["data"]["id"]) {

                  graphnodes[node].style("background-color", "#db770b");
                  if ($("#node_label_dd").children("option:selected").val() == "none"
                    || $("#node_label_dd").children("option:selected").val() == "full_name") {
                    graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["full_name"]);

                  }

                  graphnodes[node].outgoers('edge').style('line-color', "#db770b")
                  graphnodes[node].outgoers('edge').style('target-arrow-color', "#db770b")
                  graphnodes[node].outgoers('edge').style('width', "2")

                  graphnodes[node]["_private"]["data"]["influencer"] = 1

                  graphnodes[node]["_private"]["data"]["influencer_score"] = influencers_data[i]["influencer_score"]
                  if (influencers_data[i]["influencer_score"] > max_connector_score) max_connector_score = influencers_data[i]["influencer_score"]
                  break;
                }
              }
            }

          }
          var influenced_count = 0
          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {
              if (graphnodes[node]["_private"]["data"]["influencer"] == 1) {
                for (let neighbour of graphnodes[node].neighborhood('node')) {
                  if (neighbour["_private"]["data"]["influencer"] != 1) {
                    if (neighbour["_private"]["data"]["influenced"] == 1) {
                      continue
                    }
                    else {
                      neighbour["_private"]["data"]["influenced"] = 1
                      influenced_count += 1
                      // neighbour.style("background-color", "#61a303");
                    }


                  }
                }
              }
            }
          }
          console.log('influenced_count', influenced_count)
          $("#legend_container").css("display", "block")

          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {
              if (graphnodes[node]["_private"]["data"]["influencer_score"]) {
                var new_size = ((graphnodes[node]["_private"]["data"]["influencer_score"] / max_connector_score) * (15 * (Math.log(graphnodes.length)))) + 20 + "px";
                graphnodes[node].style("height", new_size)
                graphnodes[node].style("width", new_size)
              }

            }


          }
          var colorsingraphvalue: any = [['influencer', 1, '#3d0099'], ['influenced', 1, '#ff00ee']];
          if (use_existing_colors && this.node_color_option == this.node_color_option_after_gen && this.node_color_option == this.node_color_option_after_gen) {
            colorsingraphvalue = this.colorsingraphvalue
          }
          if (change_existing_colors) {
            colorsingraphvalue = this.colorsingraphvalue
            for (let i in colorsingraphvalue) {
              if (changed_color[0] == colorsingraphvalue[i][0]) {
                console.log("change color of", colorsingraphvalue[i])

                colorsingraphvalue[i][2] = changed_color[1]
                console.log("changed color of", colorsingraphvalue[i])
              }
            }
          }
          this.colorsingraphvalue = colorsingraphvalue

          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {

              if (graphnodes[node]["_private"]["data"]["influencer"] == 1) {
                graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[0][2])
                graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[0][2])
                graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
              }
              if (graphnodes[node]["_private"]["data"]["influenced"] == 1) {
                graphnodes[node].style("background-color", colorsingraphvalue[1][2]);
              }

            }
          }

          // $("#graph2legendONA").html("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#33b1f5;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Male</span></div>")
          // $("#graph2legendONA").append("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#fc60f7;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Female</span></div>")

          $("#legend_container").css("display", "block")

          $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='influencer'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[0][2] + "' (click)='dynamic_change_color(influencer)'></div><span style='margin:5px;'>Influencer</span></div>")
          $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='influenced'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[1][2] + "' (click)='dynamic_change_color(influenced)'></div><span style='margin:5px;'>Influenced Employee</span></div>")



        })


      }
      if (selectedOption_string == 'connectors') {
        var file_name = "connectors_org_dc2_v3"

        this.appservice.getInsightJson(this.uid, file_name).then((connectors_data) => {
          console.log("connectors_data ", connectors_data)
          if (this.team_type == "org") {
            connectors_data = connectors_data["org"]["people"];

          }
          var max_connector_score = 0
          for (let i in connectors_data) {
            for (let node in graphnodes) {
              if (graphnodes[node]["_private"]) {
                if (connectors_data[i]["id"] == graphnodes[node]["_private"]["data"]["id"]) {

                  graphnodes[node].style("background-color", "#029c0e");
                  graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["full_name"]);

                  graphnodes[node].outgoers('edge').style('line-color', "#029c0e")
                  graphnodes[node].outgoers('edge').style('target-arrow-color', "#029c0e")
                  graphnodes[node]["_private"]["data"]["connector"] = 1

                  graphnodes[node]["_private"]["data"]["gatekeeper_score"] = connectors_data[i]["gatekeeper_score"]
                  if (connectors_data[i]["gatekeeper_score"] > max_connector_score) max_connector_score = connectors_data[i]["gatekeeper_score"]
                  break;
                }
              }
            }

          }
          $("#legend_container").css("display", "block")

          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {
              var new_size = ((graphnodes[node]["_private"]["data"]["gatekeeper_score"] / max_connector_score) * (15 * (Math.log(graphnodes.length)))) + 20 + "px";
              graphnodes[node].style("height", new_size)
              graphnodes[node].style("width", new_size)
            }


          }
          var colorsingraphvalue: any = [['connector', 1, '#029c0e']];
          if (use_existing_colors && this.node_color_option == this.node_color_option_after_gen && this.node_color_option == this.node_color_option_after_gen) {
            colorsingraphvalue = this.colorsingraphvalue
          }
          if (change_existing_colors) {
            colorsingraphvalue = this.colorsingraphvalue
            for (let i in colorsingraphvalue) {
              if (changed_color[0] == colorsingraphvalue[i][0]) {
                console.log("change color of", colorsingraphvalue[i])

                colorsingraphvalue[i][2] = changed_color[1]
                console.log("changed color of", colorsingraphvalue[i])
              }
            }
          }
          this.colorsingraphvalue = colorsingraphvalue

          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {

              if (graphnodes[node]["_private"]["data"]["connector"] == 1) {
                graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
              }


            }
          }
          $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='connector'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[0][2] + "' (click)='dynamic_change_color(connector)'></div><span style='margin:5px;'>Connector</span></div>")


        })


      }
      else if (selectedOption_string == 'trust') {
        var file_name = "trust_org_dc2_v3"
        if ($("#team_type_dd").children("option:selected").val() != "org") file_name = "connectors_" + $("#team_dd").children("option:selected").val() + "_" + $("#team_type_dd").children("option:selected").val() + "_dc2_v3"

        this.appservice.getInsightJson(this.uid, file_name).then((trust_data) => {
          console.log("trust_data ", trust_data)
          if (this.team_type == "org") {
            trust_data = trust_data["org"]["people"]["all"];

          }
          var max_connector_score = 0
          for (let i in trust_data) {
            for (let node in graphnodes) {
              if (graphnodes[node]["_private"]) {
                if (trust_data[i]["id"] == graphnodes[node]["_private"]["data"]["id"]) {

                  graphnodes[node].style("background-color", "#8f04b5");
                  graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["full_name"]);

                  graphnodes[node].outgoers('edge').style('line-color', "#8f04b5")
                  graphnodes[node].outgoers('edge').style('target-arrow-color', "#8f04b5")
                  graphnodes[node]["_private"]["data"]["connector"] = 1

                  graphnodes[node]["_private"]["data"]["all_value"] = trust_data[i]["all_value"]
                  if (trust_data[i]["all_value"] > max_connector_score) max_connector_score = trust_data[i]["all_value"]
                  break;
                }
              }
            }

          }
          $("#legend_container").css("display", "block")

          for (let node in graphnodes) {
            if (graphnodes[node]["_private"]) {
              var new_size = ((graphnodes[node]["_private"]["data"]["all_value"] / max_connector_score) * (15 * (Math.log(graphnodes.length)))) + 20 + "px";
              graphnodes[node].style("height", new_size)
              graphnodes[node].style("width", new_size)
            }


          }
          $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='1'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#8f04b5'></div><span style='margin:5px;'>Highly Trusted Individual</span></div>")


        })


      }
      if (selectedOption_string == "collab_overload") {
        var file_name = "collab_overload"

        this.appservice.getInsightJson(this.uid, file_name).then((collab_overload_data) => {
          console.log("collab_overload_data ", collab_overload_data)
          collab_overload_data = collab_overload_data["organization"]["people_table"];


          var max_connector_score = 0
          for (let i in collab_overload_data) {
            for (let node in graphnodes) {
              if (graphnodes[node]["_private"]) {
                if (collab_overload_data[i]["id"] == graphnodes[node]["_private"]["data"]["id"]) {

                  graphnodes[node].style("background-color", "#cc0404");
                  graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["full_name"]);
                  graphnodes[node].incomers('edge').style('line-color', "#cc0404")
                  graphnodes[node].incomers('edge').style('target-arrow-color', "#cc0404")
                  graphnodes[node].outgoers('edge').style('line-color', "#049acc")
                  graphnodes[node].outgoers('edge').style('target-arrow-color', "#049acc")
                  graphnodes[node]["_private"]["data"]["highriskindividual"] = 1


                  break;
                }
              }
            }

          }
          $("#legend_container").css("display", "block")

          $("#graph_node_legend").html("")

          $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='1'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#cc0404'></div><span style='margin:5px;'>High Risk Individual</span></div>")


        })

      }
      if (selectedOption_string == "silo") {
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='silo'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#b9015d'></div><span style='margin:5px;'>Silo</span></div>")

      }
      if (selectedOption_string == "power_players") {
        $("#legend_container").css("display", "block")

        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='power_players'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#5c5eff'></div><span style='margin:5px;'>Power Players</span></div>")

      }
      if (selectedOption_string == "informal-communities") {
        var all_shades_tints = [
          // "#b30600", 
          "#ff0800",
          "#ff6f00",
          "#337eff",
          "#ffa966",
          "#00b7ff",
          "#009954",

          "#ff9d00",
          "#ccbe00",
          "#ffee00",
          "#f50290",
          "#80034b",

          "#4dcdff",
          "#b3ff00",

          "#0042b3",
          "#6600ff",
          "#b300a7",

          "#66ff8c",

          "#6b9900",
          "#ff8c33",
          "#0080b3",
          "#caff4d",
          "#486600",
          "#009926",
          "#00ff40",
          "#99ffb3",
          "#00ff8c",
          "#4dffaf",
          "#99ffd1",
          "#ffb133",
          "#cc7e00",
          "#fff780",
          "#3d0099",
          "#944dff",
          "#c299ff",
          "#ff00ee",
          "#ffb3fa",
          "#b30053",
          "#ff0077",
          "#ff4da0",
          "#ffb3d6",
          "#ff524d",
          "#b34e00",
          "#669eff",
          "#99e2ff",
          "#ffba4d",
          "#005eff",

        ]

        if (change_existing_colors) {
          var colorsingraphvalue = this.colorsingraphvalue;
          let index_color = 0;
          let shuffeled_all_shades_tints = [];
          shuffeled_all_shades_tints = this.all_shades_tints;

          for (let i in colorsingraphvalue) {
            if (changed_color[0] == colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) {
              console.log("change color of", colorsingraphvalue[i])

              colorsingraphvalue[i][2] = changed_color[1]
              console.log("changed color of", colorsingraphvalue[i])
            }
          }
          this.colorsingraphvalue = colorsingraphvalue
          for (let k in graphnodes) {
            //for color

            for (let l in colorsingraphvalue) {
              if (graphnodes[k]["_private"]) {
                if (colorsingraphvalue[l][1] == graphnodes[k]["_private"]["data"]["Community"]) {
                  graphnodes[k].style("background-color", colorsingraphvalue[l][2]);
                  graphnodes[k].outgoers('edge').style('line-color', colorsingraphvalue[l][2])
                  graphnodes[k].outgoers('edge').style('target-arrow-color', colorsingraphvalue[l][2])
                }
              }


            }


          }
          console.log("colorsingraphvalue", colorsingraphvalue);
          $("#legend_container").css("display", "block")

          for (let i in colorsingraphvalue) {
            if (this.two_specific_entities_boolean) {
              if (colorsingraphvalue[i][1] == String($("#team_dd_1 option:selected").text()) || colorsingraphvalue[i][1] == String($("#team_dd_2 option:selected").text())) {
                $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[i][2] + "' (click)='dynamic_change_color(" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>Community - " + colorsingraphvalue[i][1] + "</span></div>")

              }

            }
            else {
              $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[i][2] + "' (click)='dynamic_change_color(" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>Community - " + colorsingraphvalue[i][1] + "</span></div>")

            }
            //  /\s/g is regex to remove all whitespaces
          }

        }
        else {
          var colorsingraphvalue: any = [];
          if (use_existing_colors && this.node_color_option == this.node_color_option_after_gen) {
            colorsingraphvalue = this.colorsingraphvalue
          }
          else {
            colorsingraphvalue
          }
          let index_color = 0;
          let shuffeled_all_shades_tints = [];
          shuffeled_all_shades_tints = this.all_shades_tints;
          for (let i in node_collection) {
            if (graphnodes[i]["_private"]) {
              for (let o in graphnodes[i]["_private"]["data"]) {
                if (o == "Community") {
                  var appendtuple = [o];
                  appendtuple.push(String(graphnodes[i]["_private"]["data"][o]));
                  appendtuple.push(shuffeled_all_shades_tints[index_color]);
                  var color_exists = 0;
                  for (let j in colorsingraphvalue) {

                    if (colorsingraphvalue[j][1] == graphnodes[i]["_private"]["data"][o]) {
                      // console.log("colour exists 1",colorsingraphvalue[j][1])
                      color_exists = 1;
                      break;
                      // console.log("color exists",color_exists);
                    }
                  }
                  if (color_exists == 0) {

                    colorsingraphvalue.push(appendtuple);
                    index_color += 1;

                  }
                }
              }
            }


          }

          colorsingraphvalue = _.sortBy(colorsingraphvalue, (element) => { return parseInt(element[1]) })
          this.colorsingraphvalue = colorsingraphvalue

          for (let k in graphnodes) {
            //for color

            for (let l in colorsingraphvalue) {
              if (graphnodes[k]["_private"]) {
                if (colorsingraphvalue[l][1] == graphnodes[k]["_private"]["data"]["Community"]) {
                  graphnodes[k].style("background-color", colorsingraphvalue[l][2]);
                  graphnodes[k].outgoers('edge').style('line-color', colorsingraphvalue[l][2])
                  graphnodes[k].outgoers('edge').style('target-arrow-color', colorsingraphvalue[l][2])
                }
              }


            }


          }
          console.log("colorsingraphvalue", colorsingraphvalue);
          $("#legend_container").css("display", "block")

          for (let i in colorsingraphvalue) {
            if (this.two_specific_entities_boolean) {
              if (colorsingraphvalue[i][1] == String($("#team_dd_1 option:selected").text()) || colorsingraphvalue[i][1] == String($("#team_dd_2 option:selected").text())) {
                $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[i][2] + "' (click)='dynamic_change_color(" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>Community - " + colorsingraphvalue[i][1] + "</span></div>")

              }

            }
            else {
              $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[i][2] + "' (click)='dynamic_change_color(" + colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>Community - " + colorsingraphvalue[i][1] + "</span></div>")

            }
            //  /\s/g is regex to remove all whitespaces
          }
        }
      }

      // if ($("#team_dd").children("option:selected").val() != 'none' && $("#team_type_dd").children("option:selected").val() == 'org') {
      //   $("#graph_node_legend").html("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='silo'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:wheat'></div><span style='margin:5px;'>Not Applicable</span></div>")

      // }
      if (selectedOption_string == "collaboration") {
        
        if (this.team != "none") {
          for (let i in node_collection) {
            if (node_collection[i]["_private"]) {
              console.log(" collab bet color ", node_collection[i]["_private"]["data"])
              // for (let tuple of bfsi_det) {
              //   if (node_collection[i]["_private"]["data"]["name"] == tuple[0]) {
              //     node_collection[i].style("background-color", tuple[1])
              //     node_collection[i].incomers('edge').style("line-style", tuple[2])
              //     node_collection[i].outgoers('edge').style("line-style", tuple[2])
              //     node_collection[i].incomers('edge').style("width", 0)
              //     node_collection[i].outgoers('edge').style("width", 0)
              //     // if(tuple.length > 3){
              //     //   node_collection[i].style('border-width',4)
              //     // }

              //   }
              // }
              // node_collection[i].style("background-color", node_collection[i]["_private"]["data"]["color"])





            }
          }
          if (this.collab_bet_entity_highlight) {
            console.log(" collab bet color ")

            for (let i in node_collection) {
              if (node_collection[i]["_private"]) {
                console.log(" collab bet color ", node_collection[i]["_private"]["data"])
                node_collection[i].style("background-color", node_collection[i]["_private"]["data"]["color"])

                if (node_collection[i]["_private"]["data"]["id"] == this.entity_highlight_name) {
                  node_collection[i].style("background-color", "green")
                  node_collection[i].incomers('edge').style("line-color", "green")
                  node_collection[i].incomers('edge').style("target-arrow-color", "green")
                  node_collection[i].outgoers('edge').style("line-color", "green")

                  node_collection[i].outgoers('edge').style("target-arrow-color", "green")
                  // node_collection[i].style("shape", "triangle")
                  // node_collection[i].style("background-color", "blue")

                  // for (let tuple of bfsi_det) {

                  //   node_collection[i].incomers('edge').style("width", tuple[3])
                  //   node_collection[i].outgoers('edge').style("width", tuple[3])
                  //   // if(tuple.length > 3){
                  //   //   node_collection[i].style('border-width',4)
                  //   // }


                  // }



                }

              }
            }
            $("#legend_container").css("display", "block")

            $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + this.entity_highlight_name + "'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:green'></div><span style='margin:5px;'>" + this.entity_highlight_name.toLocaleUpperCase() + "</span></div>")

          }
          $("#legend_container").css("display", "block")

          $("#graph_node_legend").append(`
          <div style="height:min-content;display:flex">
          <div style = "width:min-content";>
          </div>
          <div style = "width: max-content;">
          <div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id=''><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#00458a' ></div><span style='margin:5px;'> Most Collaborative </span></div>
          <span class="material-symbols-outlined" style="height:min-content;width:100%;justify-content:center;display:flex;align-items:center;">south</span>

          <div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id=''><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#56a5f5' ></div><span style='margin:5px;'> Least Collaborative </span></div>
    
          </div>
          </div>
          `)
        }
      }
      if (selectedOption_string == "leadership") {
        if (this.node_color == "leadership") {
          if (this.config_json_data["settings"]["no_top_leaders_present"] == 0) {

            for (let i of graphnodes) {
              i.style("background-color", "gray")
              i.neighborhood('edge').style("line-color", "gray")
              i.neighborhood('edge').style("target-arrow-color", "gray")

            }
            for (let i of graphnodes) {

              if (i["_private"]["data"]["email"] == this.top_boss_email) {
                i.style("background-color", "#0db53f")
                i.style("shape", "triangle")
                i.data("top-most-leader", "Top Most Leader")
                i.neighborhood('edge').style("line-color", "#0db53f")
                i.neighborhood('edge').style("target-arrow-color", "#0db53f")
                i.neighborhood('node').style("background-color", "#057ead")
                i.neighborhood('node').data("reportee-top-most", "Reportees to the Top Most Leader")


              }
            }
            $("#legend_container").css("display", "block")

            $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='top-most-leader'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#0db53f'></div><span style='margin:5px;'>Top Most Leader</span></div>")
            $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='reportee-top-most'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#057ead'></div><span style='margin:5px;'>Reportees to the Top Most Leader</span></div>")
            $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='peers'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:gray'></div><span style='margin:5px;'>Peers of Reportees</span></div>")
          }

        }

      }


    }

    else if (selectedOption_string == 'leadership') {

      var hl_order = this.config_json_data["settings"]["hl_order"]
      var no_of_hl = this.config_json_data["report_filter"]["hl"].length;
      var leadership_count = parseInt(this.config_json_data["settings"]["hl_count"]);
      var leadership_levels = []
      if (this.config_json_data["settings"]["hl_order"] == "ASC") {
        var highest_level = 1;
        leadership_levels = [1]
        for (let i = 1; i < leadership_count; i++) {
          highest_level += 1

          leadership_levels.push(highest_level);
        }
      }
      else if (this.config_json_data["settings"]["hl_order"] == "DESC") {
        var highest_level = parseInt(no_of_hl);
        leadership_levels = [highest_level];
        for (let i = 1; i < leadership_count; i++) {
          highest_level -= 1

          leadership_levels.push(highest_level);
        }
      }
      console.log("leadership levels", leadership_levels);

      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {
          var is_a_leader = 0
          for (let level in leadership_levels) {
            if (graphnodes[node]["_private"]["data"]["hierarchy_level"] == leadership_levels[level]) {
              is_a_leader = 1
              graphnodes[node].style("background-color", "#019474");
              graphnodes[node].outgoers('edge').style('line-color', "#019474")
              graphnodes[node].outgoers('edge').style('target-arrow-color', "#019474")

              graphnodes[node]["_private"]["data"]["leadership"] = "leader"
            }
          }


          if (is_a_leader == 0) {
            graphnodes[node].style("background-color", "#E08341");
            graphnodes[node].outgoers('edge').style('line-color', "#E08341")
            graphnodes[node].outgoers('edge').style('target-arrow-color', "#E08341")
            graphnodes[node]["_private"]["data"]["leadership"] = "non-leader"

          }
        }
      }
      console.log("cy ", graphnodes)


      // $("#graph2legendONA").html("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#33b1f5;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Male</span></div>")
      // $("#graph2legendONA").append("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#fc60f7;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Female</span></div>")
      $("#legend_container").css("display", "block")

      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='leader'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#019474'></div><span style='margin:5px;'>Leaders</span></div>")
      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='non-leader'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#E08341'></div><span style='margin:5px;'>Non-Leaders</span></div>")


    }
    else if (selectedOption_string == 'gender') {
      var colorsingraphvalue: any = [['gender', 'male', '#33b1f5'], ['gender', 'female', '#fc7617'], ['gender', 'other', '#c4c4c4']];
      // if (use_existing_colors) {
      //   colorsingraphvalue = this.colorsingraphvalue
      // }
      var other_gender_found = 0
      if (change_existing_colors) {
        colorsingraphvalue = this.colorsingraphvalue
        for (let i in colorsingraphvalue) {
          if (changed_color[0] == colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) {
            console.log("change color of", colorsingraphvalue[i])

            colorsingraphvalue[i][2] = changed_color[1]
            console.log("changed color of", colorsingraphvalue[i])
          }
        }
      }
      this.colorsingraphvalue = colorsingraphvalue

      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {

          if (graphnodes[node]["_private"]["data"]["gender"] == "Male") {

            graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
            graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[0][2])
            graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[0][2])
          }
          else if (graphnodes[node]["_private"]["data"]["gender"] == "Female") {
            graphnodes[node].style("background-color", colorsingraphvalue[1][2]);
            graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[1][2])
            graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[1][2])
          }
          else if (graphnodes[node]["_private"]["data"]["gender"] == "Other") {
            other_gender_found = 1
            graphnodes[node].style("background-color", colorsingraphvalue[2][2]);
            graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[2][2])
            graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[2][2])
          }
        }
      }

      // $("#graph2legendONA").html("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#33b1f5;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Male</span></div>")
      // $("#graph2legendONA").append("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#fc60f7;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Female</span></div>")
      $("#legend_container").css("display", "block")
      $("#graph_node_legend").html("")
      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='male'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[0][2] + "'></div><span style='margin:5px;'>Male</span></div>")
      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='female'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[1][2] + "'></div><span style='margin:5px;'>Female</span></div>")
      if (other_gender_found == 1)
        $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='other'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[2][2] + "'></div><span style='margin:5px;'>Other</span></div>")


    }
    else if (selectedOption_string == 'tenure_status') {
      var colorsingraphvalue: any = [['tenure_status', 'tenured', '#fa9c05'], ['tenure_status', 'newhire', '#04de00']];
      // if (use_existing_colors) {
      //   colorsingraphvalue = this.colorsingraphvalue
      // }
      if (change_existing_colors) {
        colorsingraphvalue = this.colorsingraphvalue
        for (let i in colorsingraphvalue) {
          if (changed_color[0] == colorsingraphvalue[i][1].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) {
            console.log("change color of", colorsingraphvalue[i])

            colorsingraphvalue[i][2] = changed_color[1]
            console.log("changed color of", colorsingraphvalue[i])
          }
        }
      }
      this.colorsingraphvalue = colorsingraphvalue

      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {
          if (graphnodes[node]["_private"]["data"]["tenure_status"] == "tenured") {
            graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
            graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[0][2])
            graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[0][2])
          }
          else if (graphnodes[node]["_private"]["data"]["tenure_status"] == "new hire") {
            graphnodes[node].style("background-color", colorsingraphvalue[1][2]);
            graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[1][2])
            graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[1][2])
          }
        }
      }
      $("#legend_container").css("display", "block")
      $("#graph_node_legend").html("")

      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='tenured'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[0][2] + "'></div><span style='margin:5px;'>Tenured</span></div>")
      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='newhire'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:" + colorsingraphvalue[1][2] + "'></div><span style='margin:5px;'>New Hire</span></div>")

    }
    // else if (selectedOption == 0) {
    //   for (let node in graphnodes) {
    //     if (graphnodes[node]["_private"]) {
    //       graphnodes[node].style("background-color", "#00bfff");


    //     }
    //   }



    // }
    else if (selectedOption_string == 'gate_keeper' || selectedOption_string == 'hub') {


      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {

          if (graphnodes[node]["_private"]["data"][selectedOption_string] == 1) {

            graphnodes[node].style("background-color", "#009954");
            graphnodes[node].outgoers('edge').style('line-color', "#009954")
            graphnodes[node].outgoers('edge').style('target-arrow-color', "#009954")
          }

        }
      }

      $("#legend_container").css("display", "block")

      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='1'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#009954'></div><span style='margin:5px;'>" + selectedOption_string.toLocaleUpperCase() + "</span></div>")

    }
    // else if (selectedOption_string == 'influencer') {


    //   for (let node in graphnodes) {
    //     if (graphnodes[node]["_private"]) {

    //       if (graphnodes[node]["_private"]["data"][selectedOption_string] == 1) {

    //         graphnodes[node].style("background-color", "#8f04b5");
    //         graphnodes[node].outgoers('edge').style('line-color', "#8f04b5")
    //         graphnodes[node].outgoers('edge').style('target-arrow-color', "#8f04b5")
    //       }

    //     }
    //   }

    //   $("#legend_container").css("display","block")

    //   $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='1'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#8f04b5'></div><span style='margin:5px;'>" + selectedOption_string.toLocaleUpperCase() + "</span></div>")

    // }

    else if (selectedOption_string == 'silo') {


      for (let node in graphnodes) {
        if (graphnodes[node]["_private"]) {

          if (graphnodes[node]["_private"]["data"][selectedOption_string] == 1) {

            graphnodes[node].style("background-color", "#d94c00");
            graphnodes[node].outgoers('edge').style('line-color', "#d94c00")
            graphnodes[node].outgoers('edge').style('target-arrow-color', "#d94c00")
          }

        }
      }

      $("#legend_container").css("display", "block")

      $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='1'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#d94c00'></div><span style='margin:5px;'>" + selectedOption_string.toLocaleUpperCase() + "</span></div>")

    }
    // else if (
    //   selectedOption_string == 'disconnected' || selectedOption_string == 'collab_overload'
    //   || selectedOption_string == 'burnout') {


    //   for (let node in graphnodes) {
    //     if (graphnodes[node]["_private"]) {

    //       if (graphnodes[node]["_private"]["data"][selectedOption_string] == 1) {

    //         graphnodes[node].style("background-color", "#d60202");
    //         graphnodes[node].outgoers('edge').style('line-color', "#d60202")
    //         graphnodes[node].outgoers('edge').style('target-arrow-color', "#d60202")
    //       }

    //     }
    //   }

    //   $("#legend_container").css("display", "block")

    //   $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='1'><div style='border-radius:50%;height:20px;width:20px;margin:5px;background-color:#d60202'></div><span style='margin:5px;'>" + selectedOption_string.toLocaleUpperCase() + "</span></div>")

    // }

    else {
      var all_shades_tints = [
        // "#b30600", 
        "#ff0800",
        "#337eff",
        "#ffa966",
        "#009954",

        "#ff9d00",
        "#ccbe00",
        "#f50290",

        "#b3ff00",

        "#6600ff",

        "#66ff8c",

        "#6b9900",
        "#ff8c33",
        "#caff4d",
        "#ff6f00",
        "#00b7ff",
        "#ffee00",
        "#80034b",

        "#486600",
        "#009926",
        "#00ff40",
        "#99ffb3",
        "#00ff8c",
        "#4dffaf",
        "#99ffd1",
        "#ffb133",
        "#4dcdff",
        "#0042b3",

        "#cc7e00",
        "#fff780",
        "#3d0099",
        "#944dff",
        "#0080b3",

        "#c299ff",
        "#ff00ee",
        "#ffb3fa",
        "#b30053",

        "#ff0077",
        "#ff4da0",
        "#ffb3d6",
        "#ff524d",
        "#b34e00",
        "#669eff",
        "#99e2ff",
        "#ffba4d",
        "#b300a7",

        "#005eff",

      ]
      this.legend_entity_class_value = $("#node_color_dd").children("option:selected").val()

      if (change_existing_colors) {
        var legends_array = this.legends_array;
        let index_color = 0;
        let shuffeled_all_shades_tints = [];
        shuffeled_all_shades_tints = this.all_shades_tints;
        // for (let i in node_collection) {
        //   if (graphnodes[i]["_private"]) {
        //     for (let o in graphnodes[i]["_private"]["data"]) {
        //       if (o == selectedOption_string) {
        //         var appendtuple = [o];
        //         appendtuple.push(String(graphnodes[i]["_private"]["data"][o]));
        //         appendtuple.push(shuffeled_all_shades_tints[index_color]);
        //         var color_exists = 0;
        //         for (let j in legends_array) {

        //           if (legends_array[j][1] == graphnodes[i]["_private"]["data"][o]) {
        //             // console.log("colour exists 1",legends_array[j][1])
        //             color_exists = 1;
        //             break;
        //             // console.log("color exists",color_exists);
        //           }
        //         }
        //         if (color_exists == 0) {

        //           legends_array.push(appendtuple);
        //           index_color += 1;

        //         }
        //       }
        //     }
        //   }


        // }
        for (let i in legends_array) {
          if (changed_color[0] == legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) {
            console.log("change color of", legends_array[i])

            legends_array[i][1] = changed_color[1]
            console.log("changed color of", legends_array[i])
          }
        }
        this.legends_array = legends_array
        for (let k in graphnodes) {
          //for color

          for (let l in legends_array) {
            if (graphnodes[k]["_private"]) {
              if (legends_array[l][0] == graphnodes[k]["_private"]["data"][selectedOption_string]) {
                graphnodes[k].style("background-color", legends_array[l][1]);
                graphnodes[k].style("border-color", graphnodes[k].style("background-color"));
                // graphnodes[k].outgoers('edge').style('line-color', 'white')
                graphnodes[k].outgoers('edge').style('line-color', legends_array[l][1])
                graphnodes[k].outgoers('edge').style('target-arrow-color', legends_array[l][1])
              }
            }


          }


        }
        console.log("legends_array", legends_array);
        $("#legend_container").css("display", "block")
        legends_array = _.sortBy(legends_array, (element) => { return element[0] })
        for (let i in legends_array) {
          if (this.two_specific_entities_boolean) {
            if (legends_array[i][0] == String($("#team_dd_1 option:selected").text()) || legends_array[i][0] == String($("#team_dd_2 option:selected").text())) {
              $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + legends_array[i][1] + "' (click)='dynamic_change_color(" + legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>" + legends_array[i][0] + "</span></div>")

            }

          }
          else {
            $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + legends_array[i][1] + "' (click)='dynamic_change_color(" + legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>" + legends_array[i][0] + "</span></div>")

          }
          //  /\s/g is regex to remove all whitespaces
        }

      }
      else {
        var colorsingraphvalue: any = [];
        // if (use_existing_colors) {
        //   colorsingraphvalue = this.colorsingraphvalue
        // }
        // else{

        // }
        let index_color = 0;
        let shuffeled_all_shades_tints = [];
        shuffeled_all_shades_tints = this.all_shades_tints;
        var categories_entities = []
        for (let i in node_collection) {
          if (graphnodes[i]["_private"]) {
            for (let o in graphnodes[i]["_private"]["data"]) {
              if (o == selectedOption_string) {
                if (categories_entities.includes(graphnodes[i]["_private"]["data"][o])) continue;
                else categories_entities.push(graphnodes[i]["_private"]["data"][o])
              }
            }
          }
        }
        categories_entities = _.sortBy(categories_entities, (element) => { return element })

        // for (let i in node_collection) {
        //   if (graphnodes[i]["_private"]) {
        //     for (let o in graphnodes[i]["_private"]["data"]) {
        //       if (o == selectedOption_string) {
        for (let entity of categories_entities) {
          var appendtuple = [selectedOption_string];
          appendtuple.push(String(entity));
          appendtuple.push(shuffeled_all_shades_tints[index_color]);
          var color_exists = 0;
          for (let j in colorsingraphvalue) {

            if (colorsingraphvalue[j][1] == entity) {
              // console.log("colour exists 1",colorsingraphvalue[j][1])
              color_exists = 1;
              break;
              // console.log("color exists",color_exists);
            }
          }
          if (color_exists == 0) {

            colorsingraphvalue.push(appendtuple);
            index_color += 1;

          }
        }

        //       }
        //     }
        //   }


        // }

        // this.colorsingraphvalue = colorsingraphvalue
        this.legends_array = [];
        var sort_colors = _.sortBy(this.entity_colors_array[selectedOption_string], (element) => { return element[0] });
        this.entity_colors_array[selectedOption_string] = sort_colors
        for (let l in this.entity_colors_array[selectedOption_string]) {

          for (let k in graphnodes) {
            //for color

            if (graphnodes[k]["_private"]) {
              if (this.entity_colors_array[selectedOption_string][l][0] == graphnodes[k]["_private"]["data"][selectedOption_string]) {
                graphnodes[k].style("background-color", this.entity_colors_array[selectedOption_string][l][1]);
                graphnodes[k].style("border-color", graphnodes[k].style("background-color"));
                if (!this.legends_array.includes(this.entity_colors_array[selectedOption_string][l]))
                  this.legends_array.push(this.entity_colors_array[selectedOption_string][l])
                // graphnodes[k].outgoers('edge').style('line-color', colorsingraphvalue[l][1])
                // graphnodes[k].outgoers('edge').style('target-arrow-color', colorsingraphvalue[l][2])
              }
            }


          }


        }
        console.log("colorsingraphvalue", colorsingraphvalue);
        $("#legend_container").css("display", "block")
        $("#graph_node_legend").html("")

        // colorsingraphvalue = _.sortBy(colorsingraphvalue, (element) => { return element[1] })
        console.log("legends_Array", this.legends_array);
        for (let i in this.legends_array) {
          if (this.two_specific_entities_boolean) {
            if (this.legends_array[i][0] == String($("#team_dd_1 option:selected").text()) || this.legends_array[i][0] == String($("#team_dd_2 option:selected").text())) {
              $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + this.legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + this.legends_array[i][1] + "' (click)='dynamic_change_color(" + this.legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>" + this.legends_array[i][0] + "</span></div>")

            }

          }
          else {
            $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='" + this.legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + this.legends_array[i][1] + "' (click)='dynamic_change_color(" + this.legends_array[i][0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + ")'></div><span style='margin:5px;'>" + this.legends_array[i][0] + "</span></div>")

          }
          //  /\s/g is regex to remove all whitespaces
        }
      }


      //fixed colors for hierarchy levels 
      // for (let k in graphnodes) {
      //   //for color

      //   if (graphnodes[k]["_private"]) {
      //     if (graphnodes[k]["_private"]["data"][selectedOption_string] == 1) {
      //       graphnodes[k].style("background-color", "#8005e6");

      //     }
      //     if (graphnodes[k]["_private"]["data"][selectedOption_string] == 2) {
      //       graphnodes[k].style("background-color", "#00a336");

      //     } if (graphnodes[k]["_private"]["data"][selectedOption_string] == 3) {
      //       graphnodes[k].style("background-color", "#e302db");

      //     } if (graphnodes[k]["_private"]["data"][selectedOption_string] == 4) {
      //       graphnodes[k].style("background-color", "#04aad4");

      //     }



      //   }


      // }
    }
    // if (this.g_type_url_global == 'influencers') {

    //   var file_name = "influencers_org_dc2_v3"
    //   if ($("#team_type_dd").children("option:selected").val() != "org") file_name = "influencers_" + $("#team_dd").children("option:selected").val() + "_" + $("#team_type_dd").children("option:selected").val() + "_dc2_v3"

    //   this.appservice.getInsightJson(this.uid, file_name).then((influencers_data) => {
    //     console.log("influencers_data ", influencers_data)
    //     if (this.team_type == "org") {
    //       influencers_data = influencers_data["org"][String($("#network_dd").children("option:selected").val())]["people"];

    //     }
    //     else influencers_data = influencers_data[String($("#team_dd").children("option:selected").val())][String($("#network_dd").children("option:selected").val())]["people"];

    //     var max_connector_score = 0;

    //     for (let i in influencers_data) {
    //       for (let node in graphnodes) {
    //         if (graphnodes[node]["_private"]) {
    //           if (influencers_data[i]["id"] == graphnodes[node]["_private"]["data"]["id"]) {

    //             // graphnodes[node].style("background-color", "#3d0099");
    //             // if($("#node_label_dd").children("option:selected").val() == "none"
    //             //     || $("#node_label_dd").children("option:selected").val() == "full_name"){
    //             //       graphnodes[node].style("label", graphnodes[node]["_private"]["data"]["full_name"]);

    //             //     }

    //             // graphnodes[node].outgoers('edge').style('line-color', "#3d0099")
    //             // graphnodes[node].outgoers('edge').style('target-arrow-color', "#3d0099")
    //             // graphnodes[node]["_private"]["data"]["influencer"] = 1

    //             graphnodes[node]["_private"]["data"]["influencer_score"] = influencers_data[i]["influencer_score"]
    //             if (influencers_data[i]["influencer_score"] > max_connector_score) max_connector_score = influencers_data[i]["influencer_score"]
    //             break;
    //           }
    //         }
    //       }

    //     }
    //     var influenced_count = 0
    //     for (let node in graphnodes) {
    //       if (graphnodes[node]["_private"]) {
    //         if (graphnodes[node]["_private"]["data"]["influencer"] == 1) {
    //           for (let neighbour of graphnodes[node].neighborhood('node')) {
    //             if (neighbour["_private"]["data"]["influencer"] != 1) {
    //               if (neighbour["_private"]["data"]["influenced"] == 1) {
    //                 continue
    //               }
    //               else {
    //                 neighbour["_private"]["data"]["influenced"] = 1
    //                 influenced_count += 1
    //                 // neighbour.style("background-color", "#61a303");
    //               }


    //             }
    //           }
    //         }
    //       }
    //     }
    //     console.log('influenced_count', influenced_count)
    //     $("#legend_container").css("display", "block")

    //     for (let node in graphnodes) {
    //       if (graphnodes[node]["_private"]) {
    //         if (graphnodes[node]["_private"]["data"]["influencer_score"]) {
    //           var new_size = ((graphnodes[node]["_private"]["data"]["influencer_score"] / max_connector_score) * (15 * (Math.log(graphnodes.length)))) + 20 + "px";
    //           graphnodes[node].style("height", new_size)
    //           graphnodes[node].style("width", new_size)
    //         }

    //       }


    //     }
    //     var colorsingraphvalue: any = [['influencer', 1, '#3d0099'], ['influenced', 1, '#ff00ee']];
    //     // if (change_existing_colors) {
    //     //   colorsingraphvalue = this.colorsingraphvalue
    //     //   for (let i in colorsingraphvalue) {
    //     //     if (changed_color[0] == colorsingraphvalue[i][0]) {
    //     //       console.log("change color of", colorsingraphvalue[i])

    //     //       colorsingraphvalue[i][1] = changed_color[1]
    //     //       console.log("changed color of", colorsingraphvalue[i])
    //     //     }
    //     //   }
    //     // }
    //     this.colorsingraphvalue = colorsingraphvalue

    //     // for (let node in graphnodes) {
    //     //   if (graphnodes[node]["_private"]) {

    //     //     if (graphnodes[node]["_private"]["data"]["influencer"] == 1) {
    //     //       graphnodes[node].outgoers('edge').style('line-color', colorsingraphvalue[0][2])
    //     //       graphnodes[node].outgoers('edge').style('target-arrow-color', colorsingraphvalue[0][2])
    //     //       graphnodes[node].style("background-color", colorsingraphvalue[0][2]);
    //     //     }
    //     //     if (graphnodes[node]["_private"]["data"]["influenced"] == 1) {
    //     //       graphnodes[node].style("background-color", colorsingraphvalue[1][2]);
    //     //     }

    //     //   }
    //     // }

    //     // $("#graph2legendONA").html("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#33b1f5;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Male</span></div>")
    //     // $("#graph2legendONA").append("<div style='display:flex;margin:2px 0px;align-items:center;'><div style='height:7px;width:7px;background-color:#fc60f7;padding:5px;'></div><span style='font-size:10px;margin-left:5px;'>Female</span></div>")

    //     // $("#legend_container").css("display", "block")

    //     // $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='influencer'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[0][2] + "' (click)='dynamic_change_color(influencer)'></div><span style='margin:5px;'>Influencer</span></div>")
    //     // $("#graph_node_legend").append("<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;' id='influenced'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + colorsingraphvalue[1][2] + "' (click)='dynamic_change_color(influenced)'></div><span style='margin:5px;'>Influenced Employee</span></div>")



    //   })


    // }
    this.node_color_option_after_gen = $("#node_color_dd").children("option:selected").val()

    $(document).on("mouseenter", "#graph_node_legend > div > div", (element) => {
      console.log("element ", element.relatedTarget["id"])
      console.log("element ", element)
      console.log("element text", $("#" + element.relatedTarget['id'] + " > span").text())
      this.dynamic_change_color(element.relatedTarget["id"], $("#" + element.relatedTarget['id'] + " > span").text())

    })

    // $("#graph_node_legend > div > div").on("mouseenter", (element) => {
    //   console.log("element ", element.relatedTarget["id"])
    //   this.dynamic_change_color(element.relatedTarget["id"])

    // })
    $("#graph_node_legend div").on("click", (e) => {
      if ($("#" + e.currentTarget.id).attr("class") == "highlight_legend") { //if clicked on an already highlighted legend, it will deselect it
        $("#" + e.currentTarget.id).removeClass("highlight_legend");
        this.clickLegend(e.currentTarget.id, 0); // 0 for deselecting the nodes, 
      }
      else {
        // $("#graph_node_legend div").removeClass("highlight_legend");
        $("#" + e.currentTarget.id).addClass('highlight_legend');
        this.clickLegend(e.currentTarget.id, 1); // 1 for highlighting the nodes

      }
    })
    console.log("legend min icon click out", $("#graph_node_legend").css("display"))

    $("#legend_collapse_expand_icon").on("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();// to stop the trigger to be called twice

      // console.log("legend min icon click", $("#graph_node_legend").css("display") )
      if ($("#graph_node_legend").css("display") == "none") {
        console.log("legend min icon click max", $("#graph_node_legend").css("display"))

        $("#graph_node_legend").css("display", "block")
      }
      else if ($("#graph_node_legend").css("display") == "block") {
        console.log("legend min icon click", $("#graph_node_legend").css("display"))

        $("#graph_node_legend").css("display", "none")

      }

      if ($("#legend_collapse_expand_icon").html() == "expand_content") $("#legend_collapse_expand_icon").html("remove")

      else if ($("#legend_collapse_expand_icon").html() == "remove") $("#legend_collapse_expand_icon").html("expand_content")

    })
    this.cy.resize();
    this.cy.fit();
    var timestamp_end = new Date().getTime()
    console.log("Time taken for changing node color ", (timestamp_end - timestamp_start))

  }
  // getMyColor = () => {
  //   let n = (Math.random() * 0xfffff * 1000000).toString(16);
  //   return '#' + n.slice(0, 6);


  // };

  getTenur(tenure) {
    console.log("Mapping tenure data..");
    if (typeof tenure != 'undefined')
      return _.last(tenure.split(""));
    else
      return 0;
  }
  net_stat_load_screen: boolean = false;
  open_stats_modal() {
    $('#stats_modal_container').removeAttr('class').addClass('modalscreen-container one');
    this.net_stat_load_screen = true;
    setTimeout(() => {
      this.graph_stats_calculation();

    }, 500)

  }
  close_stats_modal() {
    $("#stats_modal_container").addClass('modalscreen-container out');

  }
  dynamic_change_color(id, text) {
    this.generate_legend_color_menu(text).then((menu) => {
      console.log(id)
      $("#node_color_menu").css("display", "block")

      $("#legends_color_menu").remove()
      // $("#"+id).append(menu)
      $("#node_color_menu").append(menu)


      $("#legends_color_menu").on("mouseenter", () => {
        clearTimeout(this.timer)
        $("#legends_color_menu").css("display", "grid")
        console.log("hover over legendes color menu")


      })
      $("#legends_color_menu").on("mouseleave", () => {
        this.timer = setTimeout(() => {
          $("#legends_color_menu").remove()
          $("#node_color_menu").css("display", "none")
        }, 1000)
      })
      $("#legends_color_menu > div").on("click", (element) => {
        console.log("color menu element ", element)
        this.changeNodecolorONA(this.cy.nodes(), String($("#node_color_dd").children("option:selected").val()), true, [id, element.currentTarget.id])

      })

    })
  }
  async generate_legend_color_menu(text) {
    var no_of_columns = 4
    var no_of_rows = Math.round((this.all_shades_tints.length) / no_of_columns)
    var row = 2;
    var column = 0


    var container = "<div style='width:200px;max-height:380px; display:grid;grid-template-columns:repeat(4,1fr);grid-template-rows:repeat(" + no_of_rows + ",1fr) position:absolute;overflow-y:scroll;z-index:12;background:white;padding:5px;' id ='legends_color_menu'><div style='margin:5px 0px;grid-row:1;grid-column:1/5;'>Change Color for " + text + "</div>"
    for (let i of this.all_shades_tints) {
      column += 1

      container += "<div style='display:flex;cursor:pointer;border-radius:20px;z-index:3;margin:2px;grid-row:" + row + ";grid-column:" + column + ";' id='" + i + "'><div style='border-radius:50%;height:20px;width:20px;z-index:10; margin:5px;background-color:" + i + "'></div></div>"
      if (column == 4) {
        column = 0
        row += 1
      }
    }
    container += "</div>"


    return container

  }
  show_deleted_nodes_list() {
    $("#deleted_nodes_list_container").css("display", "block")
  }
  hide_deleted_nodes_list() {
    $("#deleted_nodes_list_container").css("display", "none")

  }

  open_network_type_options() {
    if (parseInt($("#network_options").css("width")) > 100) {
      $("#network_options").css("height", "0px")

      $("#network_options").css("width", "0px")
    }
    else {
      console.log("open network type options");
      $("#network_options").css("height", "30px")

      $("#network_options").css("width", "600px")
    }

  }
  open_module_type_options() {
    if (parseInt($("#module_options").css("width")) > 100) {
      $("#module_options").css("height", "0px")

      $("#module_options").css("width", "0px")
    }
    else {
      console.log("open module type options");
      $("#module_options").css("height", "30px")

      $("#module_options").css("width", "600px")
    }

  }
  open_frequency_type_options() {
    if (parseInt($("#frequency_options").css("width")) > 100) {
      $("#frequency_options").css("height", "0px")

      $("#frequency_options").css("width", "0px")
    }
    else {
      console.log("open frequency type options");
      $("#frequency_options").css("height", "30px")

      $("#frequency_options").css("width", "600px")
    }

  }
  pan_zoon_defaults = {
    zoomFactor: 0.05, // zoom factor per zoom tick
    zoomDelay: 45, // how many ms between zoom ticks
    minZoom: 0.1, // min zoom level
    maxZoom: 10, // max zoom level
    fitPadding: 50, // padding when fitting
    panSpeed: 10, // how many ms in between pan ticks
    panDistance: 10, // max pan distance per tick
    panDragAreaSize: 75, // the length of the pan drag box in which the vector for panning is calculated (bigger = finer control of pan speed and direction)
    panMinPercentSpeed: 0.25, // the slowest speed we can pan by (as a percent of panSpeed)
    panInactiveArea: 8, // radius of inactive area in pan drag box
    panIndicatorMinOpacity: 0.5, // min opacity of pan indicator (the draggable nib); scales from this to 1.0
    zoomOnly: true, // a minimal version of the ui only with zooming (useful on systems with bad mousewheel resolution)
    fitSelector: undefined, // selector of elements to fit
    animateOnFit: function () { // whether to animate on fit
      return false;
    },
    fitAnimationDuration: 1000, // duration of animation on fit

    // icon class names
    sliderHandleIcon: 'fa fa-minus',
    zoomInIcon: 'fa fa-plus',
    zoomOutIcon: 'fa fa-minus',
    resetIcon: 'fa fa-expand'
  };

  panZoom() {
    // register extension
    // the default values of each option are outlined below:
    var defaults = {
      zoomFactor: 0.05, // zoom factor per zoom tick
      zoomDelay: 45, // how many ms between zoom ticks
      minZoom: 0.01, // min zoom level
      maxZoom: 10, // max zoom level
      fitPadding: 50, // padding when fitting
      panSpeed: 10, // how many ms in between pan ticks
      panDistance: 10, // max pan distance per tick
      panDragAreaSize: 75, // the length of the pan drag box in which the vector for panning is calculated (bigger = finer control of pan speed and direction)
      panMinPercentSpeed: 0.25, // the slowest speed we can pan by (as a percent of panSpeed)
      panInactiveArea: 8, // radius of inactive area in pan drag box
      panIndicatorMinOpacity: 0.5, // min opacity of pan indicator (the draggable nib); scales from this to 1.0
      zoomOnly: true, // a minimal version of the ui only with zooming (useful on systems with bad mousewheel resolution)
      fitSelector: undefined, // selector of elements to fit
      animateOnFit: function () { // whether to animate on fit
        return false;
      },
      fitAnimationDuration: 1000, // duration of animation on fit

      // icon class names
      sliderHandleIcon: 'fa fa-minus',
      zoomInIcon: 'fa fa-plus',
      zoomOutIcon: 'fa fa-minus',
      resetIcon: 'fa fa-expand'
    };

    // add the panzoom control
    this.cy.panzoom(defaults);


  }
  // <----------------
  node_cxtmenu() {
    // the default values of each option are outlined below:
    let defaults = {
      menuRadius: 80, // the radius of the circular menu in pixels
      selector: 'node', // elements matching this Cytoscape.js selector will trigger cxtmenus
      commands: this.getItemsForNodeCxtmenu(), // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
      fillColor: '#434e52', // the background colour of the menu
      activeFillColor: '#00bdaa', // the colour used to indicate the selected command : 'rgba(1, 105, 217, 0.75)'
      activePadding: 3, // additional size in pixels for the active command
      indicatorSize: 20, // the size in pixels of the pointer to the active command
      separatorWidth: 3, // the empty spacing in pixels between successive commands
      spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
      minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight
      maxSpotlightRadius: 30, // the maximum radius in pixels of the spotlight
      openMenuEvents: 'cxttapstart taphold', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
      itemColor: 'white', // the colour of text in the command's content
      itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
      zIndex: 9999, // the z-index of the ui div
      atMouse: false // draw menu at mouse position
    };

    let node_menu = this.cy.cxtmenu(defaults);
  }
  core_cxtmenu() {
    // the default values of each option are outlined below:
    let defaults = {
      menuRadius: 80, // the radius of the circular menu in pixels
      selector: 'core', // elements matching this Cytoscape.js selector will trigger cxtmenus
      commands: this.getItemsForCoreCxtmenu(), // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
      fillColor: '#434e52', // the background colour of the menu
      activeFillColor: '#00bdaa', // the colour used to indicate the selected command : 'rgba(1, 105, 217, 0.75)'
      activePadding: 3, // additional size in pixels for the active command
      indicatorSize: 20, // the size in pixels of the pointer to the active command
      separatorWidth: 3, // the empty spacing in pixels between successive commands
      spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
      minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight
      maxSpotlightRadius: 30, // the maximum radius in pixels of the spotlight
      openMenuEvents: 'cxttapstart taphold', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
      itemColor: 'white', // the colour of text in the command's content
      itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
      zIndex: 9999, // the z-index of the ui div
      atMouse: false // draw menu at mouse position
    };

    let core_menu = this.cy.cxtmenu(defaults);
  }
  edge_cxtmenu() {
    // the default values of each option are outlined below:
    let defaults = {
      menuRadius: 80, // the radius of the circular menu in pixels
      selector: 'edge:visible', // elements matching this Cytoscape.js selector will trigger cxtmenus
      commands: this.getItemsForEdgeCxtmenu(), // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
      fillColor: '#434e52', // the background colour of the menu
      activeFillColor: '#00bdaa', // the colour used to indicate the selected command : 'rgba(1, 105, 217, 0.75)'
      activePadding: 3, // additional size in pixels for the active command
      indicatorSize: 20, // the size in pixels of the pointer to the active command
      separatorWidth: 3, // the empty spacing in pixels between successive commands
      spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
      minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight
      maxSpotlightRadius: 30, // the maximum radius in pixels of the spotlight
      openMenuEvents: 'cxttapstart taphold', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
      itemColor: 'white', // the colour of text in the command's content
      itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
      zIndex: 9999, // the z-index of the ui div
      atMouse: false // draw menu at mouse position
    };

    let edge_menu = this.cy.cxtmenu(defaults);
  }

  getItemsForNodeCxtmenu() {
    var context = this;
    return [ // an array of commands to list in the menu or a function that returns the array

      // { // example command
      //   // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
      //   content: '<img title="add to collection" src="assets/cxtmenu/user-add-outline.svg" id="step5">', // html/text content to be displayed in the menu
      //   contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      //   select: function (ele) { // a function to execute when the command is selected
      //     // `ele` holds the reference to the active element
      //     if (context.lastSelection == undefined) {
      //       context.lastSelection = context.cy.collection();
      //     }

      //     context.lastSelection.merge(ele)
      //     // <span class="badge badge-secondary">Secondary</span>
      //     // context.show_notification=true;
      //     context.createNotification("success", " u selected me", ele.id());

      //   },
      //   enabled: true // whether the command is selectable
      // },
      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/user-delete-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          // `ele` holds the reference to the active element
          if (context.lastSelection == undefined) {
            context.lastSelection = context.cy.collection();
          }

          // removes if in collection else does nothing
          let length_of_selection = context.lastSelection["length"];
          // context.lastSelection.unmerge(ele);
          this.remove(ele);

          // context.show_notification=true;
          if (length_of_selection != context.lastSelection["length"]) {
            context.createNotification("success", " u removed me :(", ele.id());
          } else {
            context.createNotification("info", " Not in selection", ele.id());
          }
        },
        enabled: true // whether the command is selectable
      },
      // { // example command
      //   // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
      //   content: '<img src="assets/cxtmenu/trash.svg" >', // html/text content to be displayed in the menu
      //   contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      //   select: function (ele) { // a function to execute when the command is selected
      //     console.log(ele.id()); // `ele` holds the reference to the active element
      //     // let lastSelection = context.getLastSelection();
      //     context.lastSelection = context.cy.collection();

      //     context.createNotification("success", " u cleared node collection");
      //   },
      //   enabled: true // whether the command is selectable
      // },
      // { // example command
      //   // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
      //   content: '<img src="assets/cxtmenu/eye-outline.svg" >', // html/text content to be displayed in the menu
      //   contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      //   select: function (ele) { // a function to execute when the command is selected
      //     ele.style({
      //       'display': 'none'
      //     });
      //     if (context.hiddenNodes == "") {
      //       context.hiddenNodes = context.cy.collection();

      //     }
      //     context.hiddenNodes.merge(ele);
      //     context.createNotification("success", " node hidden", ele._private.data["first_name"]);
      //   },
      //   enabled: true // whether the command is selectable
      // },
      // { // example command
      //   // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
      //   content: '<img  src="assets/cxtmenu/brush.svg" >', // html/text content to be displayed in the menu
      //   contentStyle: {}, // css key:value pairs to set the command's css in js if you want
      //   select: function (ele) { // a function to execute when the command is selected
      //     console.log("----------");
      //     console.log("brush node");
      //     // if(context.lastSelection == undefined){
      //     //     context.lastSelection = context.cy.collection();
      //     //     context.lastSelection.merge(ele);
      //     // }

      //     // Create a new Picker instance and set the parent element.
      //     // By default, the color picker is a popup which appears when you click the parent.

      //     if (document.getElementById('edge_color_picker')) {
      //       var element = document.getElementById('edge_color_picker');
      //       element.parentNode.removeChild(element);
      //     }


      //     var p = document.getElementById('parent');
      //     var newElement = document.createElement('div');
      //     newElement.setAttribute('id', 'node_color_picker');
      //     // newElement.innerHTML = 'html';
      //     p.appendChild(newElement);

      //     let parent: HTMLElement = document.querySelector('#node_color_picker');
      //     let pickerNode = new Picker(parent);
      //     parent.click();
      //     // You can do what you want with the chosen color using two callbacks: onChange and onDone.
      //     pickerNode.onDone = function (color) {
      //       let color_selected = color.rgbaString;
      //       // context.lastSelection.style({
      //       //     'background-color': color_selected
      //       // });
      //       console.log("brush node IF");
      //       if (context.lastSelection == undefined) {
      //         console.log("brush node 1");

      //         context.cy.nodes().style({
      //           'background-color': color_selected
      //         });
      //       } else if (context.lastSelection["length"] > 0) {
      //         console.log("brush node 2");

      //         context.lastSelection.style({
      //           'background-color': color_selected
      //         });
      //       }
      //     };

      //     // onDone is similar to onChange, but only called when you click 'Ok'.

      //     context.createNotification("success", " node color");
      //     console.log("----------");

      //   },
      //   enabled: true // whether the command is selectable
      // }

    ];
  }
  getItemsForCoreCxtmenu() {
    var context = this;
    return [ // an array of commands to list in the menu or a function that returns the array

      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/eye-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected

          if (context.hiddenNodes != "") {
            context.hiddenNodes.style({
              'display': 'element'
            });
            context.createNotification("success", " unhide hidden");
          } else {
            context.createNotification("info", " nothing hidden");
          }

        },
        enabled: true // whether the command is selectable
      }, { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/refresh-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected

          if (context.history_of_nodes_removed.length) {
            context.value = context.message["info"] + ' Undo last';
            context.history_of_nodes_removed.pop().restore();
            context.createNotification("success", "  undo last delete");
          } else {
            context.value = context.message["info"] + ' Nothing to restore';
            context.createNotification("info", " Nothing to restore");
          }


        },
        enabled: true // whether the command is selectable
      },
      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/info-large-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          if (context.lastEdgeSelection != undefined && context.lastEdgeSelection.length != 0) {
            context.lastEdgeSelection.toggleClass("label");
            context.createNotification("success", " toogle edge data of collection");
          } else {
            context.cy.edges().toggleClass("label");
            context.createNotification("success", " toggle edge data");
          }

        },
        enabled: true // whether the command is selectable
      },


    ];
  }
  getItemsForEdgeCxtmenu() {
    var context = this;
    return [ // an array of commands to list in the menu or a function that returns the array

      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/info-large-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          ele.toggleClass("label");
          context.createNotification("success", " view edge data");

        },
        enabled: true // whether the command is selectable
      },
      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/user-add-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          // `ele` holds the reference to the active element
          if (context.lastEdgeSelection == undefined) {
            context.lastEdgeSelection = context.cy.collection();
          }
          context.lastEdgeSelection.merge(ele)
          // <span class="badge badge-secondary">Secondary</span>
          // context.show_notification=true;
          context.createNotification("success", " u added edge to collection");

        },
        enabled: true // whether the command is selectable
      },
      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/user-delete-outline.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          // `ele` holds the reference to the active element
          if (context.lastEdgeSelection != undefined) {
            // removes if in collection else does nothing
            let length_of_selection = context.lastEdgeSelection["length"];
            context.lastEdgeSelection.unmerge(ele);
            // context.show_notification=true;
            if (length_of_selection != context.lastEdgeSelection["length"]) {
              context.createNotification("success", " u removed me :(");
            } else {
              context.createNotification("info", " Not in selection");
            }
          } else {
            context.createNotification("info", " Selection is empty");
          }

        },
        enabled: true // whether the command is selectable
      },

      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img src="assets/cxtmenu/trash.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          console.log(ele.id()); // `ele` holds the reference to the active element
          // let lastSelection = context.getLastSelection();
          context.lastEdgeSelection = context.cy.collection();

          context.createNotification("success", " u cleared edge collection");
        },
        enabled: true // whether the command is selectable
      },
      { // example command
        // fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
        content: '<img  src="assets/cxtmenu/brush.svg" >', // html/text content to be displayed in the menu
        contentStyle: {}, // css key:value pairs to set the command's css in js if you want
        select: function (ele) { // a function to execute when the command is selected
          console.log("----------");
          console.log("brush edge");
          if (document.getElementById('node_color_picker')) {
            var element = document.getElementById('node_color_picker');
            element.parentNode.removeChild(element);

          }

          var p = document.getElementById('parent');
          var newElement = document.createElement('div');
          newElement.setAttribute('id', 'edge_color_picker');
          // newElement.innerHTML = 'html';
          p.appendChild(newElement);


          // Create a new Picker instance and set the parent element.
          // By default, the color picker is a popup which appears when you click the parent.
          let parent: HTMLElement = document.querySelector('#edge_color_picker');
          let pickerEdge = new Picker(parent);
          parent.click();
          // You can do what you want with the chosen color using two callbacks: onChange and onDone.
          pickerEdge.onDone = function (color) {
            let color_selected = color.rgbaString;
            console.log("brush edge IF");
            if (context.lastEdgeSelection == undefined) {
              console.log("brush edge 1");
              context.cy.edges().style({
                'line-color': color_selected
              });
            } else if (context.lastEdgeSelection["length"] > 0) {
              console.log("brush edge 2");

              context.lastEdgeSelection.style({
                'line-color': color_selected
              });
            }

          };

          // onDone is similar to onChange, but only called when you click 'Ok'.

          context.createNotification("success", " edge color");
          console.log("----------");


        },
        enabled: true // whether the command is selectable
      },
    ];
  }

  // ----------------->

  edgeEdit() {
    var options = {
      // this function specifies the positions of bend points
      bendPositionsFunction: function (ele) {
        return ele.data('bendPointPositions');
      },
      // whether to initilize bend points on creation of this extension automatically
      initBendPointsAutomatically: true,
      // whether the bend editing operations are undoable (requires cytoscape-undo-redo.js)
      undoable: false,
      // the size of bend shape is obtained by multipling width of edge with this parameter
      bendShapeSizeFactor: 3,
      // z-index value of the canvas in which bend points are drawn
      zIndex: 999,
      // whether to start the plugin in the enabled state
      enabled: true,
      /*An option that controls the distance (in pixels) within which a bend point is considered near the line segment between
        its two neighbors and will be automatically removed
        min value = 0 , max value = 20 , values less than 0 are set to 0 and values greater than 20 are set to 20
      */
      bendRemovalSensitivity: 8,
      // title of add bend point menu item (User may need to adjust width of menu items according to length of this option)
      addBendMenuItemTitle: "Add Bend Point",
      // title of remove bend point menu item (User may need to adjust width of menu items according to length of this option)
      removeBendMenuItemTitle: "Remove Bend Point",
      // whether the bend point can be moved by arrow keys
      moveSelectedBendPointsOnKeyEvents: function () {
        return true;
      },
      // this function handles reconnection of the edge, if undefined simply connect edge to its new source/target
      // handleReconnectEdge (newSource.id(), newTarget.id(), edge.data())
      handleReconnectEdge: undefined,
      // this function checks validation of the edge and its new source/target
      validateEdge: function (edge, newSource, newTarget) {
        return 'valid';
      },
      // this function is called if reconnected edge is not valid according to validateEdge function
      actOnUnsuccessfulReconnection: undefined,

    };
    this.cy.edgeEditing(options);
  }

  jqueryChangeDetector() {

  }
  // contextMenu() {
  //     var options = {
  //         // Customize event to bring up the context menu
  //         // Possible options https://js.cytoscape.org/#events/user-input-device-events
  //         evtType: 'cxttap',
  //         // List of initial menu items
  //         menuItems: [/*
  //             {
  //                 id: 'remove', // ID of menu item
  //                 content: 'remove', // Display content of menu item
  //                 tooltipText: 'remove', // Tooltip text for menu item
  //                 image: { src: "remove.svg", width: 12, height: 12, x: 6, y: 4 }, // menu icon
  //                 // Filters the elements to have this menu item on cxttap
  //                 // If the selector is not truthy no elements will have this menu item on cxttap
  //                 selector: 'node, edge',
  //                 onClickFunction: function () { // The function to be executed on click
  //                     console.log('remove element');
  //                 },
  //                 disabled: false, // Whether the item will be created as disabled
  //                 show: false, // Whether the item will be shown or not
  //                 hasTrailingDivider: true, // Whether the item will have a trailing divider
  //                 coreAsWell: false // Whether core instance have this item on cxttap
  //             },
  //             {
  //                 id: 'hide',
  //                 content: 'hide',
  //                 tooltipText: 'hide',
  //                 selector: 'node, edge',
  //                 onClickFunction: function () {
  //                     console.log('hide element');
  //                 },
  //                 disabled: true
  //             },
  //             {
  //                 id: 'add-node',
  //                 content: 'add node',
  //                 tooltipText: 'add node',
  //                 image: { src: "add.svg", width: 12, height: 12, x: 6, y: 4 },
  //                 selector: 'node',
  //                 coreAsWell: true,
  //                 onClickFunction: function () {
  //                     console.log('add node');
  //                 }
  //             }
  //         */],
  //         // css classes that menu items will have
  //         menuItemClasses: [
  //             // add class names to this list
  //         ],
  //         // css classes that context menu will have
  //         contextMenuClasses: [
  //             // add class names to this list
  //         ]
  //     };

  //     this.cy.contextMenus(options);
  // }
  // spread on click
  spreadLayout() {
    this.cy.layout({
      name: 'spread',
      animate: true, // Whether to show the layout as it's running
      // ready: undefined, // Callback on layoutready
      // stop: undefined, // Callback on layoutstop
      // fit: true, // Reset viewport to fit default simulationBounds
      // minDist: 20, // Minimum distance between nodes
      // padding: 20, // Padding
      // expandingFactor: -1.0, // If the network does not satisfy the minDist
      // // criterium then it expands the network of this amount
      // // If it is set to -1.0 the amount of expansion is automatically
      // // calculated based on the minDist, the aspect ratio and the
      // // number of nodes
      // prelayout: { name: 'euler' }, // Layout options for the first phase
      // maxExpandIterations: 4, // Maximum number of expanding iterations
      // boundingBox: undefined, // Constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
      // randomize: false // Uses random initial node positions on true
    }).run();

  }


  // cy.on('click', 'node', function(node) {
  //     // get nodes id
  //     var nodeId = node.target.id();

  //     // add all successors (nodes and edges) to a collection
  //     var childNodes = cy.nodes('[id="'+nodeId+'"]').successors();

  //     // add clicked node to collection
  //     childNodes = childNodes.add(node.target);

  //     // add other nodes to other collection
  //     var others = cy.elements().not(childNodes);

  //     //cy.remove() returns the deleted nodes and edges, so that you can just do cy.add() afterwards
  //     referenceNodes = cy.remove(others);

  //     // just call a new layout
  //     cy.elements().makeLayout({'name': 'dagre', 'padding': 5 }).run();
  //   });

  //  p.descendants().makeLayout( options ).run()

  // create custom notification
  createNotification(alert_type: string, alert_message: string, alert_emphasize: string = "Message : ") {

    switch (alert_type) {
      case "success": {
        alert_type = "alert-success";
        break;
      }
      case "error": {
        alert_type = "alert-danger";
        break;
      }
      case "info": {
        alert_type = "alert-info";
        break;
      }

    }

    let notification =
      `
        <div class="alert ` + alert_type + ` alert-dismissible fade show" role="alert">
        <strong>` + alert_emphasize + `</strong>` + alert_message + `
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="show_notification = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      `;
    let element = document.getElementById('notification');
    element.innerHTML = notification;
    element.className = 'show';

    $(".alert").delay(4000).slideUp(200, function () {
      (<any>jQuery(this)).alert('close');
    });

    //   this.notifications.push(notification);
    //   console.log(this.notifications);

  }
  // clear collection
  clearGlobalVariables() {
    this.lastEdgeSelection = this.cy.collection();
    this.lastSelection = this.cy.collection();

  }
  hide_show_popup(id) {
    console.log("hide_show")
    if ($("#" + id).css("display") != "none") {
      $("#" + id).css("display", "none")
    }
    else {
      $("#" + id).css("display", "block")

    }
  }
  show_sidenav() {

    $(".sidenav").css("width", "50%")

  }
  hide_sidenav() {
    $(".sidenav").css("width", "0")

  }
  open_close_settings() {
    $(".action").toggleClass('active')
  }
  open_edit() {
    $("#top_panel_display").css("display", "none");
    $("#top_panel_edit").css("display", "grid");

  }
  close_edit() {
    $("#top_panel_display").css("display", "grid");
    $("#top_panel_edit").css("display", "none");


  }
  //  openNav() {
  //  $("#panel_edit").css().width = "250px";
  // }

  //  closeNav() {
  //  $("#panel_edit").style.width = "250px";
  // }

  //highlight/deselect a set of node on clicking a legend
  clickLegend(legendId: string, highlight_boolean: number) {
    //get list of all legends selected
    all_legends_selected = $(".highlight_legend").map(function () { return $(this).attr("id"); }).get();
    console.log("legend selected ", all_legends_selected);
    selected_nodes_legend = []

    //if a node is already selected
    if (selected_node != '') {
      //if no legend is selected i.e. a new legend is clicked
      if (all_legends_selected.length == 0) {
        let selected = this.cy.$("#" + selected_node + "");
        console.log("inside clicklegend if ", selected);

        let neighbourOfSelected = selected.neighborhood();
        this.cy.elements().removeClass('hideN hideE highlightN outline selected highlightE');

        // Hide remaining
        this.cy.elements().not(neighbourOfSelected.merge(selected)).addClass('hideN hideE');

        // Highlight neighbourOfSelected
        neighbourOfSelected.addClass('highlightN outline');

        selected.addClass('selected');
        // Highlight edges between selected and neighbourOfSelected
        selected.edgesWith(neighbourOfSelected).addClass('highlightE');
        // Fit slected to view
        this.cy.fit(neighbourOfSelected, 20);
        //


      }
      //if some legends have  already been selected
      else {
        let selected = this.cy.$("#" + selected_node + "");
        //select neighborhood
        let neighbourOfSelected_edges = selected.neighborhood('edge');
        let neighbourOfSelected_nodes = selected.neighborhood('node');
        //to check if neighbor is amongst the legends selected. if it is present, highlight it otherwise hide it
        for (let i in neighbourOfSelected_nodes) {
          if (neighbourOfSelected_nodes[i]["_private"]) {

            let neighbour_node_in_legend = 0;

            for (let j in all_legends_selected) {
              if ($("#node_color_dd").children("option:selected").val() == this.g_type_url_global) {
                console.log("default node selected");
                if (this.g_type_url_global == "engagement") {
                  if (neighbourOfSelected_nodes[i]["_private"]["data"][all_legends_selected[j]]) {
                    neighbour_node_in_legend = 1;
                    neighbourOfSelected_nodes[i].removeClass("hideN");
                    // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                    neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                  }

                }
                else if (this.g_type_url_global == "informal-communities") {
                  if (neighbourOfSelected_nodes[i]["_private"]["data"]["Community"] == all_legends_selected[j]) {
                    neighbour_node_in_legend = 1;
                    neighbourOfSelected_nodes[i].removeClass("hideN");
                    // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                    neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                  }
                }
                else if (this.g_type_url_global == "collaboration" && this.collab_bet_entity_highlight) {
                  if (neighbourOfSelected_nodes[i]["_private"]["data"]["id"].toLowerCase() == all_legends_selected[j].toLowerCase) {
                    neighbour_node_in_legend = 1;
                    neighbourOfSelected_nodes[i].removeClass("hideN");
                    // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                    neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                  }
                }
                else if (this.g_type_url_global == "disc_emp") {
                  if (neighbourOfSelected_nodes[i]["_private"]["data"][all_legends_selected[j]] == all_legends_selected[j]) {
                    neighbour_node_in_legend = 1;
                    neighbourOfSelected_nodes[i].removeClass("hideN");
                    // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                    neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                  }
                }
                else if (this.g_type_url_global == "collab_overload") {
                  if (neighbourOfSelected_nodes[i]["_private"]["data"][all_legends_selected[j]] == 1) {
                    neighbour_node_in_legend = 1;
                    neighbourOfSelected_nodes[i].removeClass("hideN");
                    // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                    neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                  }
                }
                else {
                  for (let k in this.module_specific_nodes["people_details"]) {
                    var network = this.n_type_url_global;
                    if (this.n_type_url_global == "combined") {
                      network = "main_combined"
                    }
                    if (this.n_type_url_global == "professional") {
                      network = "main_professional"
                    }
                    if (this.n_type_url_global == "personal") {
                      network = "main_personal"
                    }
                    if (neighbourOfSelected_nodes[i]["_private"]["data"]["value"] == this.module_specific_nodes["people_details"][k]["id"] && this.module_specific_nodes["people_details"][k][network] == 1) {
                      neighbour_node_in_legend = 1;
                      neighbourOfSelected_nodes[i].removeClass("hideN");
                      // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                      neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
                    }
                  }
                }

              }
              else {
                if (String(neighbourOfSelected_nodes[i]["_private"]["data"][String(this.legend_entity_class_value)]).replace('(', '').replace('.', '').replace('.', '').replace(')', '').replace('-', '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == all_legends_selected[j]) {
                  neighbour_node_in_legend = 1;
                  neighbourOfSelected_nodes[i].removeClass("hideN");
                  // neighbourOfSelected_nodes[i].incomers('edge').removeClass("hideE");
                  neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")


                }

              }



            }

            if (neighbour_node_in_legend == 0) {
              neighbourOfSelected_nodes[i].removeClass("hideN");

              neighbourOfSelected_nodes[i].addClass("hideN");
              neighbourOfSelected_nodes[i].edgesWith(selected).removeClass("hideE")
              neighbourOfSelected_nodes[i].edgesWith(selected).addClass("hideE")

            }
          }
        }



      }

    }
    //if a node is not selected
    else {
      //if no legend is selected after clicking, show all elements
      if (all_legends_selected.length == 0) {
        this.cy.nodes().removeClass("hideN");
        this.cy.edges().removeClass("hideE");
      }
      //highlight all elements that lie in the set of legends
      else {
        console.log("inside clicklegend else");
        this.cy.nodes().removeClass("hideN");
        this.cy.edges().removeClass("hideE");
        this.cy.nodes().addClass("hideN");
        this.cy.edges().addClass("hideE");

        let graph_nodes_legend_click = this.cy.nodes();
        let graph_edges_legend_click = this.cy.edges();
        var nodes_selected = this.cy.collection()
        var drag_node = this.cy.collection()
        for (let i in graph_nodes_legend_click) {
          if (graph_nodes_legend_click[i]["_private"]) {

            for (let j in all_legends_selected) {
              console.log()
              if ($("#node_color_dd").children("option:selected").val() == this.g_type_url_global) {
                if (this.g_type_url_global == "engagement") {
                  if (graph_nodes_legend_click[i]["_private"]["data"][all_legends_selected[j]]) {
                    graph_nodes_legend_click[i].removeClass("hideN");
                    graph_nodes_legend_click[i].addClass("highlightN outline");


                    selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                  }

                }
                else if (this.g_type_url_global == "informal-communities") {
                  if (graph_nodes_legend_click[i]["_private"]["data"]["Community"] == all_legends_selected[j]) {
                    graph_nodes_legend_click[i].removeClass("hideN");
                    graph_nodes_legend_click[i].addClass("highlightN outline");
                    drag_node = this.cy.$("#" + graph_nodes_legend_click[i]["_private"]["data"]["id"])
                    nodes_selected.merge(this.cy.$("#" + graph_nodes_legend_click[i]["_private"]["data"]["id"]))
                    console.log("union ", nodes_selected, this.cy.$("#" + graph_nodes_legend_click[i]["_private"]["data"]["id"]))
                    selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                  }
                }
                else if (this.g_type_url_global == "collaboration" && this.collab_bet_entity_highlight) {
                  if (graph_nodes_legend_click[i]["_private"]["data"]["id"].toLowerCase() == all_legends_selected[j].toLowerCase) {
                    graph_nodes_legend_click[i].removeClass("hideN");
                    graph_nodes_legend_click[i].addClass("highlightN outline");


                    selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                  }
                }
                else if (this.g_type_url_global == "disc_emp") {
                  if (graph_nodes_legend_click[i]["_private"]["data"][all_legends_selected[j]] == all_legends_selected[j]) {
                    graph_nodes_legend_click[i].removeClass("hideN");
                    graph_nodes_legend_click[i].addClass("highlightN outline");


                    selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                  }
                }
                else if (this.g_type_url_global == "collab_overload") {
                  if (graph_nodes_legend_click[i]["_private"]["data"][all_legends_selected[j]] == 1) {
                    graph_nodes_legend_click[i].removeClass("hideN");
                    graph_nodes_legend_click[i].addClass("highlightN outline");


                    selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                  }
                }
                else {
                  console.log("default node selected");
                  for (let k in this.module_specific_nodes["people_details"]) {
                    var network = this.n_type_url_global;
                    if (this.n_type_url_global == "combined") {
                      network = "main_combined"
                    }
                    if (this.n_type_url_global == "professional") {
                      network = "main_professional"
                    }
                    if (this.n_type_url_global == "personal") {
                      network = "main_personal"
                    }
                    if (graph_nodes_legend_click[i]["_private"]["data"]["value"] == this.module_specific_nodes["people_details"][k]["id"] && this.module_specific_nodes["people_details"][k][network] == 1) {
                      graph_nodes_legend_click[i].removeClass("hideN");
                      graph_nodes_legend_click[i].addClass("highlightN outline");


                      selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                    }
                  }
                }

              }
              else {
                var legend_considered = graph_nodes_legend_click[i]["_private"]["data"][String(this.legend_entity_class_value)]
                if (typeof legend_considered != "string") {
                  legend_considered = String(graph_nodes_legend_click[i]["_private"]["data"][String(this.legend_entity_class_value)])
                }
                console.log(legend_considered.replace('(', '').replace('.', '').replace('.', '').replace(')', '').replace('-', '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase());
                if (legend_considered.replace('(', '').replace('.', '').replace('.', '').replace(')', '').replace('-', '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == all_legends_selected[j]) {
                  graph_nodes_legend_click[i].removeClass("hideN");
                  graph_nodes_legend_click[i].addClass("highlightN outline");

                  console.log("legends selected nodes ", nodes_selected)
                  selected_nodes_legend.push(graph_nodes_legend_click[i]["_private"]["data"]["value"])
                }
              }

            }
          }
        }

        for (let i in graph_edges_legend_click) {
          if (graph_edges_legend_click[i]["_private"]) {
            let sourcepresent = _.indexOf(selected_nodes_legend, parseInt(graph_edges_legend_click[i]["_private"]["data"]["source"]));

            let targetpresent = _.indexOf(selected_nodes_legend, parseInt(graph_edges_legend_click[i]["_private"]["data"]["target"]));
            if (sourcepresent != -1 && targetpresent != -1) {
              graph_edges_legend_click[i].removeClass("hideE");
              graph_edges_legend_click[i].addClass("highlightE");


            }

          }
        }
        console.log("nodes selected", nodes_selected)
        var automove_options = this.defaults_automove_nodes
        automove_options.nodesMatching = nodes_selected;
        automove_options.dragWith = nodes_selected;

        console.log("automove_options", automove_options)
        let rule = this.cy.automove(automove_options);
        rule.apply();
      }



    }






  }

  downloadImage() {
    const base64URI = this.cy.png({
      full:true,
      bg:'#ffffff'
    });
    var link = document.createElement('a');
    link.href = base64URI;  // use realtive url 
    link.download = $("#graph_heading").html() + '.png';
    if ($("#team_type_dd").children("option:selected").val()) {
      link.download = $("#graph_heading").html() + '.png';

    }
    document.body.appendChild(link);
    link.click();
  }



}


