import { Component, OnInit } from '@angular/core';

import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'abhi-cli-sna';
  constructor(private service: AppService
  ){

  }
  ngOnInit(): void {
    // if(this.service.getEnvVariableValue('auth_enabled') == "true"){
    //   this.service.getLoginAuth().then((response)=>{
    //     console.log('auth status ',response)
    //       this.service.redirectToDashboard();
        
    //   });
    // }
  }
}
