import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule} from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSortModule} from '@angular/material/sort';
import { MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CliComponent } from './cli/cli.component';
import { HttpClientModule } from '@angular/common/http';
// For ngModel
import { FormsModule } from '@angular/forms';
import { APP_INITIALIZER } from '@angular/core';
//
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// request api
import { AppService } from './app.service';
import { DialogComponent } from './cli/dialog/dialog.component';
import { from } from 'rxjs';

// export function initializeApp(service: AppService){
//   return () => service.loadEnvironmentVariables();
// }
@NgModule({
  declarations: [
    AppComponent,
    CliComponent,
    DialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    // NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule
  ],
  entryComponents: [DialogComponent],
  providers: [  AppService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [AppService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
